import React, { FunctionComponent, InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { CustomSpacer } from '../CustomSpacer';

export interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    selected: boolean;
    setSelected: () => void;
    disabled?: boolean;
}

export interface CircleProps {
    selected: boolean;
    disabled?: boolean;
}

export const RadioButton: FunctionComponent<RadioButtonProps> = ({
    label,
    selected,
    setSelected,
    disabled,
}: RadioButtonProps) => {
    return (
        <Radio onClick={setSelected} id="radioBtn" data-testid="radioBtn">
            <Circle selected={selected} data-testid="circle" disabled={disabled} />
            <CustomSpacer horizontal={true} space={'0.75rem'} />
            <Label selected={selected} disabled={disabled}>
                {label}
            </Label>
        </Radio>
    );
};

const Circle = styled.div`
    border-radius: 100rem;
    height: 1.125rem;
    width: 1.125rem;
    border: 1px solid #002043;
    &:hover {
        cursor: pointer;
    }
    ${(props: CircleProps) =>
        props.selected === true &&
        css`
            border-color: #c61230;
            border-width: 0.25rem;
        `};
    ${(props: CircleProps) =>
        props.disabled === true &&
        css`
            opacity: 0.6;
            pointer-events: none;
        `};
    ${(props: CircleProps) =>
        props.disabled === true &&
        props.selected === false &&
        css`
            opacity: 0.6;
            pointer-events: none;
            border-color: #002043;
            border-width: 0.05rem;
            background-color: #878787;
        `};
`;

export const Radio = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const Label = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #171717;
    ${(props: CircleProps) =>
        props.selected === true &&
        css`
            font-weight: 700;
        `};
`;
