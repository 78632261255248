import React from 'react';
import styled from 'styled-components';

import { TextDarkGrey5, TextNavyBlue } from '../../../constants';
import { FlexedDiv } from '../../atoms';
import Checkbox from '../../atoms/Checkbox';
import { LinkButton } from '../../atoms/LinkButton';

interface ISelectedCard {
    selected: boolean;
}
/**
 * A molecule component which creates a multi card as seen in {@link https://www.figma.com/file/PZFuynaWXPTvHO98iG5hMS/KIB-Design-System-(New)?node-id=2136%3A151190 figma}
 * uses atoms {@link MultiSelectCardBadge} {@link Checkbox} and {@link LinkButton}
 * @param props - {@link IMultiSelectCardProps}
 * @example <MultiSelectCard {...MOCK_MULTI_SELECT_CARD_DATA}/> //
 */

export const MultiSelectCard = (props: IMultiSelectCardProps): JSX.Element => {
    return (
        <div>
            <MultiSelectCardWrapper
                style={{ pointerEvents: props.disabled ? 'none' : 'auto', opacity: props.disabled ? 0.6 : 1 }}
                selected={props.checked.checked}
                data-testid="multiselect-card-wrapper"
            >
                <StyledLabel>
                    <FlexedDiv justifyContent={'space-between'} alignItems={'center'} margin={'0 0 4px'}>
                        <FlexedDiv style={{ gap: '8px' }} justifyContent="space-between" grow={1}>
                            <TextNavyBlue size="18px" weight="700" style={{ lineHeight: '24.3px' }}>
                                {props.documentName}
                            </TextNavyBlue>
                            <FlexedDiv style={{ gap: '8px' }} padding={'3px'} data-testid="checkbox-multiselectcard">
                                <Checkbox {...props.checked} />
                            </FlexedDiv>
                        </FlexedDiv>
                    </FlexedDiv>

                    <FlexedDiv justifyContent={'space-between'} margin={'8px 0 0'}>
                        <TextDarkGrey5 size="12px" weight="400">
                            {props.fileName}
                        </TextDarkGrey5>
                        <TextNavyBlue size="12px" weight="700">
                            {props.bottomTextBold}
                        </TextNavyBlue>
                    </FlexedDiv>
                </StyledLabel>
            </MultiSelectCardWrapper>
        </div>
    );
};

const MultiSelectCardWrapper = styled.div<ISelectedCard>`
    width: 292px;
    padding: 16px;
    border: 2px solid;
    border-radius: 8px;
    background: ${(props) => (props.selected ? '#E7EBF1' : '#FFFFFF')};
    border: 1px solid ${(props) => (props.selected ? '2px solid #002043' : '#ececec')};
    transition: box-shadow 0.15s ease 0.05s, transform 0.2s ease;
    &:hover {
        box-shadow: 0px 0px 0px ${(props) => (props.selected ? '0.05rem' : '0.12rem')} #002043;
    }
    cursor: pointer;

    user-select: none;
    height: 100%;
`;

const StyledLabel = styled.label`
    cursor: pointer;
`;
