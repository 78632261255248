import gql from 'graphql-tag';

export const adviserAccessDashboard = /* GraphQL */ gql`
    query sysAdAdviserDashboard($input: DashboardInput) {
        systemAdminAdviserDashboard(input: $input) {
            data {
                result {
                    advisers {
                        branch
                        advisers {
                            agentId
                            agentCode
                            agentName
                            bdmCode
                            bdmName
                            channel
                            createdOn
                            lastUpdated
                            status
                            omniEnabled
                        }
                    }
                    adviserCount
                    pages
                    page
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
