import gql from 'graphql-tag';

/**
 * @inboxQuery @query - Adviser Dashboard
 * @description:  Returns inboxes
 */

export const inboxQuery = /* GraphQL */ gql`
    query inboxDashboard($input: InboxInput) {
        inboxDashboard(input: $input) {
            data {
                result {
                    inbox {
                        notificationId
                        title
                        message
                        senderName
                        source
                        searchKey
                        searchId
                        searchLink
                        searchType
                        isRead
                        updatedAt
                        createdOn
                        isSeen
                    }
                    newMessageCount
                    page
                    pages
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
