import React, { Fragment, useContext, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { LABEL, Fs16SemiBoldSecPrimaryBlack } from '../../../constants';
import { FlexedDiv, CustomSpacer, Modal } from '../..';

import * as ROUTES from '../../../routes';
import moment from 'moment';
import AuthContext from '../../../context/AuthContext';
import WebSocketContext from '../../../context/WebSocketContext';

export const LogOutModal = ({ setToggleModal, toggleModal }: IModal): JSX.Element => {
    const { userLogoutFn, userLoginContext, authDispatch } = useContext(AuthContext);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [redirectUrl, setRedirectUrl] = useState<string>(ROUTES.signIn);
    const { logoutSuccess, setLogoutSuccess } = useContext(WebSocketContext);
    const history = useHistory();

    const handleConfirmLogout = () => {
        userLogoutFn();
        setToggleModal(false);
        setLogoutSuccess(true);
    };

    const handleCancel = () => {
        setToggleModal(false);
    };

    const handleLogoutSuccess = () => {
        setLogoutSuccess(false);
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? setRedirectUrl(
                  'https://sso-local-test.auth.ap-southeast-1.amazoncognito.com/logout?response_type=code&client_id=4cala10v2aue85mig9c5bt6ghj&redirect_uri=https://y3mtwo3fs3.execute-api.ap-southeast-1.amazonaws.com/v1&scope=openid',
              )
            : setRedirectUrl(ROUTES.signIn);

        history.push(ROUTES.signIn);
        authDispatch({ type: 'logOut' });
        window.location.reload();
        setRedirect(true);
    };
    const SSO_LOGOUT_URL =
        'https://sso-local-test.auth.ap-southeast-1.amazoncognito.com/logout?response_type=code&client_id=4cala10v2aue85mig9c5bt6ghj&redirect_uri=https://y3mtwo3fs3.execute-api.ap-southeast-1.amazonaws.com/v1&scope=openid';

    return (
        <Fragment>
            {logoutSuccess ? (
                <Modal
                    title={LABEL.logoutSuccessTitle}
                    modalActive={logoutSuccess}
                    setModalActive={setLogoutSuccess}
                    primaryBtn={{
                        onClick: handleLogoutSuccess,
                        label: LABEL.done,
                        primary: true,
                    }}
                    icon="logout-modal"
                    testId="custom_modal"
                    contentAlignment="center"
                    data-testid="custom_modal"
                >
                    <FlexedDiv direction="column" style={{ alignItems: 'center' }}>
                        <CustomSpacer space={'1rem'} />
                        <Fs16SemiBoldSecPrimaryBlack style={{ textAlign: 'center' }}>
                            {`${LABEL.logoutSuccessSubTitle} ${moment().format('dddd DD/MM/YYYY, LTS')}`}
                        </Fs16SemiBoldSecPrimaryBlack>
                    </FlexedDiv>
                </Modal>
            ) : null}

            {toggleModal ? (
                <Modal
                    title={LABEL.logoutTitle}
                    modalActive={toggleModal}
                    setModalActive={setToggleModal}
                    primaryBtn={{
                        onClick: handleConfirmLogout,
                        label: LABEL.yesLogout,
                        primary: true,
                    }}
                    secondaryBtn={{ onClick: handleCancel, label: LABEL.noCancel }}
                    testId="custom_modal_2"
                    data-testid="custom_modal_2"
                    contentAlignment="center"
                >
                    <FlexedDiv direction="column" style={{ alignItems: 'center' }}>
                        <Fs16SemiBoldSecPrimaryBlack style={{ textAlign: 'center' }}>
                            {LABEL.logoutSubtitle}
                        </Fs16SemiBoldSecPrimaryBlack>
                    </FlexedDiv>
                </Modal>
            ) : null}

            {redirect && redirectUrl === ROUTES.signIn ? <Redirect exact to={redirectUrl} /> : null}
            {redirect && redirectUrl === SSO_LOGOUT_URL ? window.location.replace(SSO_LOGOUT_URL) : null}
        </Fragment>
    );
};

export default LogOutModal;
