import gql from 'graphql-tag';
/**
 * @adviserSummary @query - Transaction Dashboard
 * @description: Shows adviser’s profile
 */

export const adviserSummaryQuery = /* GraphQL */ gql`
    query adviserSummary($input: AdviserSummaryInput) {
        adviserSummary(input: $input) {
            data {
                result {
                    investorCount
                    profile {
                        name
                        code
                        nric
                        personalDetails {
                            licenseCode
                            homeBranch
                            omniEnabled
                            bdmName
                            bdmCode
                            bdmEmail
                            uplineName
                            uplineCode
                            region
                            adviserChannel
                            adviserRank
                            agency
                        }
                        contactDetails {
                            email
                            mobileNo
                        }
                        addressInfo {
                            address
                            postcode
                            addrTown
                            state
                            country
                        }
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
            }
        }
    }
`;
