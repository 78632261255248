import gql from 'graphql-tag';
/**
 * @caseStatusMutation @Mutation - caseStatus
 * @description: mutation to update edd case status to satisfactory/not-satisfactory
 */
export const caseStatusMutation = gql`
    mutation caseStatus($input: CaseStatusInput) {
        caseStatus(input: $input) {
            data {
                result {
                    status
                    message
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
