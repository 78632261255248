import gql from 'graphql-tag';

/**
 * @eddClientListQuery @query - EDD Client List API
 * @description: Amla able to search through list of clients to pick one for account selection
 */
export const eddClientListQuery = /* GraphQL */ gql`
    query eddClientList($input: EddClientListInput) {
        eddClientList(input: $input) {
            data {
                result {
                    clients {
                        clientName
                        clientIdNum
                        clientAccountCount
                    }
                    totalResultCount
                    page
                    pages
                }
            }
            error {
                errorCode
                message
                errorList
            }
        }
    }
`;
