/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactText, useContext } from 'react';
import { useState } from 'react';
import { useComponentLoader } from '../../customHooks';
import { API, graphqlOperation } from 'aws-amplify';
import { LABEL } from '../../constants';
import { initialDateFilterBranch } from '../../pages/DashboardBranch/dashboardBranchTypes';
import AuthContext from '../AuthContext';
import ErrorHandlingContext from '../ErrorHandling/ErrorHandlingContext';
import moment from 'moment';
import { decoupleGroupedTrx } from '../../pages/DashboardBranch/decoupleGroupedTrx';
import PendingSubmissionContext from './PendingSubmissionContext';
import { pendingSubmissionBranchQuery } from '../../_graphql/queries/branchCE/transaction/pendingSubmission/pendingSubmission';
import { makeStatusesTags } from './makeStatusFilterTags';
import { allPendingSubmissionBranchQuery } from '../../_graphql/queries/branchCE/transaction/pendingSubmission/allPendingSubmission';
import { toTitleCase } from '../../utils';

interface PendingSubmissionProviderProps {
    children: React.ReactNode;
}

export const PendingSubmissionProvider: React.FC<PendingSubmissionProviderProps> = ({
    children,
}: PendingSubmissionProviderProps) => {
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const branchId = sessionStorage.getItem('branchId');
    const initialFilter: ISort_FilterInput = [
        { column: 'createdOn', value: '' },
        { column: 'tab', value: 'all' },
        { column: 'transactionType', value: '' },
        { column: 'accountType', value: '' },
    ];

    // Common States
    const [tab, setTab] = useState(0);
    const [tabsCount, setTabsCount] = useState<number[]>([]);
    const [pages, setPages] = useState<number>(1); //Pagination States
    const [page, setPage] = useState<number>(1); //Pagination States
    const [resultLimit, setResultLimit] = useState<number>(10); //Pagination States
    const [disableResultLimit, setDisableResultLimit] = useState<boolean>(false); //Result Limit Disable state
    const [searchInput, setSearchInput] = useState<ISearchInput>({ value: '', column: 'all' });
    const [sortData, setSortData] = useState<ISearchInput>({ column: 'createdOn', value: 'ascending' });

    const [pendingSubmission, setPendingSubmission] = useState<TGroupingInfo[]>([]);
    const [statusesCount, setStatusesCount] = useState<IStatusesTagsProps>({ statusCount: [], statusTags: [] }); //statusesCount State
    const [typeCount, setTypeCount] = useState<number[]>([]); //Type Count
    const [statusPills, setStatusPills] = useState<IDashboardFilterTagsGroup>({
        tab: 0,
        label: LABEL.status,
        tags: statusesCount.statusTags,
    });
    // Filter States
    const [dateFilter, setDateFilter] = useState<dateFilterBranch>(initialDateFilterBranch); //Data range + type filter
    const [tempFilters, setTempFilters] = useState<ISort_FilterInput>(initialFilter); // Temp Filters
    const [filterSelectStates, setFilterSelectStates] = useState<TypeFilterSelectState>({
        transactionType: '',
    }); // Initial Filter Values
    const [filterAccountTypes, setFilterAccountTypes] = useState<ReactText[]>([]); //State for AccountType MultiSelect
    const [filterInput, setFilterInput] = useState<ISort_FilterInput>(initialFilter); //Filters for other tabs
    const [searchbarFilterTags, setSearchbarFilterTags] = useState<Array<ISelectValue>>([]); // The filter tags to show when the dropdown filter is disabled
    const [tempTargetDate, setTempTarget] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
    const [accountTypeOptions, setAccountTypeOptions] = useState<ReactText[]>([]);
    const [transactionTypeOptions, setTransactionTypeOptions] = useState<ISelectValue[]>([]);
    const { loadingHandler, loading } = useComponentLoader();

    const queryInputFilter = (input: ISearchInput): boolean => {
        if (input.column === 'accountType') {
            return (
                accountTypeOptions.findIndex(
                    (option) => option.toString().toLowerCase() === input.value.toLowerCase(),
                ) !== -1
            );
        } else if (input.column === 'transactionType') {
            return (
                transactionTypeOptions.findIndex(
                    (option) => option.value.toLowerCase() === input.value.toLowerCase(),
                ) !== -1
            );
        } else {
            return true;
        }
    };

    //Queries
    /**@async query to fetch Incomplete tab data */
    const getPendingSubmissionDashboardCE = async () => {
        loadingHandler();
        const branchFromGlobalState = branchId !== null ? branchId : '';
        const selectBranchId = branchFromGlobalState;

        try {
            const response: any = await API.graphql(
                graphqlOperation(pendingSubmissionBranchQuery, {
                    input: {
                        page: page,
                        branchId: selectBranchId,
                        resultLimit: resultLimit,
                        sort: sortData,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        filter: filterInput.filter(queryInputFilter),
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.pendingSubmissionDashboard;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.pendingSubmissionDashboard.data;
            const decoupledRows = decoupleGroupedTrx(result.groupedTransactions.grouping);
            decoupledRows.length === 0 ? setDisableResultLimit(decoupledRows.length === 0) : null;
            setPendingSubmission(result.groupedTransactions.grouping);
            // (data)
            setTypeCount(result.typeCount);
            setAccountTypeOptions(
                result.filters.accountType.map((option: string) => {
                    return toTitleCase(option);
                }),
            );
            const _transactionTypeOptions: ISelectValue[] =
                result.filters && result.filters.transactionType
                    ? result.filters.transactionType.map((option: string) => {
                          return {
                              label: option,
                              value: option.toLowerCase(),
                          };
                      })
                    : [];
            setTransactionTypeOptions(_transactionTypeOptions);
            const temp = makeStatusesTags(result.statuses, statusPills.tags);
            filterInput[1].value === 'all' ? (temp.statusTags[0].active = true) : null;
            setStatusesCount(temp);
            setTabsCount([result.totalResultCount]);
            loadingHandler();
            if (page > result.pages) {
                setPage(1);
            }
            setPages(result.pages);
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Dashboard data',
                    testId: 'branch-error-modal',
                });
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    //Queries
    /**@async query to fetch All Incomplete tab data */
    const getAllPendingSubmissionDashboardCE = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(allPendingSubmissionBranchQuery, {
                    input: {
                        page: page,
                        resultLimit: resultLimit,
                        sort: sortData,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        filter: filterInput.filter(queryInputFilter),
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = response.data.allPendingDashboard;
            // checks if error exists
            if (resultCheck.error !== null) throw resultCheck.error;
            // continue to show results
            const { result } = response.data.allPendingDashboard.data;
            const decoupledRows = decoupleGroupedTrx(result.groupedTransactions.grouping);
            decoupledRows.length === 0 ? setDisableResultLimit(decoupledRows.length === 0) : null;
            setPendingSubmission(result.groupedTransactions.grouping);
            // (data)
            setTypeCount(result.typeCount);
            setAccountTypeOptions(
                result.filters.accountType.map((option: string) => {
                    return toTitleCase(option);
                }),
            );
            const _transactionTypeOptions: ISelectValue[] =
                result.filters && result.filters.transactionType
                    ? result.filters.transactionType.map((option: string) => {
                          return {
                              label: option,
                              value: option.toLowerCase(),
                          };
                      })
                    : [];
            setTransactionTypeOptions(_transactionTypeOptions);
            const temp = makeStatusesTags(result.statuses, statusPills.tags);
            filterInput[1].value === 'all' ? (temp.statusTags[0].active = true) : null;
            setStatusesCount(temp);
            setTabsCount([result.totalResultCount]);
            loadingHandler();
            if (page > result.pages) {
                setPage(1);
            }
            setPages(result.pages);
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Dashboard data',
                    testId: 'branch-error-modal',
                });
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    /**@async query to fetch other tabs data */

    const ProviderValue: PendingSubmissionContextProps = {
        statusesCount,
        statusPills,
        pendingSubmission,
        dateFilter,
        disableResultLimit,
        filterInput,
        filterSelectStates,
        getAllPendingSubmissionDashboardCE,
        getPendingSubmissionDashboardCE,
        loading,
        loadingHandler,
        page,
        pages,
        resultLimit,
        searchInput,
        setStatusesCount,
        setStatusPills,
        setDateFilter,
        setDisableResultLimit,
        setFilterInput,
        setFilterSelectStates,
        setPage,
        setPages,
        setResultLimit,
        setSearchInput,
        setSortData,
        setTab,
        setTempFilters,
        setTempTarget,
        sortData,
        tab,
        tabsCount,
        tempFilters,
        tempTargetDate,
        typeCount,
        searchbarFilterTags,
        setSearchbarFilterTags,
        accountTypeOptions,
        transactionTypeOptions,
        filterAccountTypes,
        setFilterAccountTypes,
    };
    return <PendingSubmissionContext.Provider value={ProviderValue}>{children}</PendingSubmissionContext.Provider>;
};

export default PendingSubmissionProvider;
