import gql from 'graphql-tag';

export const logOutQuery = /* GraphQL */ gql`
    query logOut {
        logOut {
            data {
                result {
                    message
                    status
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
