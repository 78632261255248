import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { IcoMoon } from '../../../icons';
import { CustomSpacer } from '../../atoms';
import { LABEL, TextWhite } from '../../../constants';
export const PaginationDropdown = ({
    items,
    selectedPage,
    disableDropdown,
    lastPage,
    limit,
    topLimit,
    bottomLimit,
    onClickJumpToFirst,
    onClickJumpToLast,
    onClickPageNav,
}: IPaginationDropdownProps): JSX.Element => {
    const [show, setShow] = useState<boolean>(false);
    const node = useRef<HTMLDivElement>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickOutside = (e: any) => {
        if (node.current?.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
        setShow(false);
    };

    useEffect(() => {
        if (show) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [show]);

    const menuItem = ({ icon, item, danger, handleItem, color, disabled, testId }: IPaginationDropdownItem) => {
        const iconName = icon !== undefined ? icon : 'check';
        const currentColor = color !== undefined ? color : '#002043';
        return (
            <DropdownItem
                isTrue={disabled ? disabled : false}
                onClick={() => {
                    disabled ? undefined : handleItem(item);
                    setShow(!show);
                }}
                id={testId}
            >
                {icon !== undefined ? (
                    <IcoMoon
                        style={{ marginRight: '0.5rem' }}
                        name={iconName}
                        size="1rem"
                        color={danger ? '#e84c3d' : currentColor}
                    />
                ) : null}
                <DropdownItemText isTrue={danger !== undefined ? danger : false}>{item}</DropdownItemText>
                <CustomSpacer horizontal={true} space={'1rem'} />
            </DropdownItem>
        );
    };
    const topOffset = node.current?.clientHeight !== undefined ? node.current?.clientHeight : 0;
    return (
        <Fragment>
            <DropdownContainer ref={node} disabled={disableDropdown} id="setShowBtn" data-testid="dropdown-setShowBtn">
                <DropDownInner id="dropdown-inner-btn" data-testid="dropdown-inner-btn" onClick={() => setShow(!show)}>
                    <DropDownSpan>{LABEL.page}</DropDownSpan>
                    <DropDownSpan>{selectedPage}</DropDownSpan>
                    <IcoMoon name="caret-down" size="1.5rem" color={disableDropdown ? '#97A3B1' : '#002043'} />
                </DropDownInner>

                <DropdownMenu
                    isTrue={show}
                    topOffset={topOffset}
                    data-testid="visible-menu-page"
                    id={`dropdown-menu-${show ? 'visible' : 'hidden'}`}
                    isFixedSet={lastPage !== undefined && lastPage > limit}
                >
                    {lastPage !== undefined && lastPage > limit && topLimit !== 1 ? (
                        <Fragment>
                            <JumpToEnds
                                onClick={() => {
                                    onClickJumpToFirst !== undefined ? onClickJumpToFirst() : undefined;
                                    setShow(!show);
                                }}
                                data-testid="jump-ends"
                            >
                                <IcoMoon
                                    style={{ marginRight: '0.5rem' }}
                                    name={'double-arrow-left'}
                                    size="1rem"
                                    color={'#FFFFFF'}
                                />
                                <TextWhite weight="700" size="0.75rem" lineHeight="15.6px">
                                    First
                                </TextWhite>
                            </JumpToEnds>
                            <DropdownItem
                                isTrue={false}
                                onClick={() => (onClickPageNav !== undefined ? onClickPageNav(2) : undefined)}
                                id={'testId'}
                                data-testid={'testId'}
                            >
                                <IcoMoon
                                    style={{ marginRight: '0.5rem' }}
                                    name={'caret-left'}
                                    size="1rem"
                                    color={'#0089EC'}
                                />
                                <PageNavButtons>Prev</PageNavButtons>
                            </DropdownItem>
                        </Fragment>
                    ) : null}

                    {items.map(({ icon, item, danger, handleItem, color, disabled, testId }, index) => {
                        return (
                            <div
                                key={`$item_${index}`}
                                style={{
                                    boxShadow: index !== items.length - 1 ? 'inset 0px -1px 0px #f2f4f6' : '',
                                }}
                            >
                                <Fragment>
                                    {menuItem({ icon, item, danger, handleItem, color, testId, disabled })}
                                </Fragment>
                            </div>
                        );
                    })}
                    {lastPage !== undefined && lastPage > limit && bottomLimit !== lastPage ? (
                        <Fragment>
                            <DropdownItem
                                isTrue={false}
                                onClick={() => (onClickPageNav !== undefined ? onClickPageNav(3) : undefined)}
                                id={'testId'}
                                data-testid={'testId1'}
                            >
                                <PageNavButtons>Next</PageNavButtons>
                                <IcoMoon
                                    style={{ marginLeft: '0.5rem' }}
                                    name={'caret-right'}
                                    size="1rem"
                                    color={'#0089EC'}
                                />
                            </DropdownItem>
                            <JumpToEnds
                                onClick={() => {
                                    onClickJumpToLast !== undefined ? onClickJumpToLast() : undefined;
                                    setShow(!show);
                                }}
                                data-testid="jump-to-end"
                            >
                                <TextWhite weight="700" size="0.75rem" lineHeight="15.6px">
                                    Last
                                </TextWhite>
                                <IcoMoon
                                    style={{ marginLeft: '0.5rem' }}
                                    name={'double-arrow-right'}
                                    size="1rem"
                                    color={'#FFFFFF'}
                                />
                            </JumpToEnds>
                        </Fragment>
                    ) : null}
                </DropdownMenu>
            </DropdownContainer>
        </Fragment>
    );
};
interface renderStyle {
    isTrue: boolean;
}
interface renderDropDownMenuStyle {
    isTrue: boolean;
    topOffset: number;
    isFixedSet?: boolean;
}
const DropdownMenu = styled.div<renderDropDownMenuStyle>`
    visibility: hidden;
    /* transition: all 0.5s ease-in; */
    position: absolute;
    top: ${(props) => `${props.topOffset}px`};
    right: 0;
    z-index: 100;
    border: 1px solid #f2f4f6;
    box-sizing: border-box;
    box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: #fff;
    max-height: ${(props) => (props.isFixedSet ? '468px' : '340px')};
    overflow-y: auto;
    width: max-content;
    /* padding: 0.5rem 0rem; */
    ${(props) =>
        props.isTrue &&
        css`
            visibility: visible;
        `}
`;
const DropdownItem = styled.div`
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    &:hover {
        background: #f4f4f4;
        cursor: pointer;
    }
    ${(props: renderStyle) =>
        props.isTrue &&
        css`
            opacity: 0.6;
            &:hover {
                background: #fff;
                cursor: default;
            }
        `}
    height: 2rem;
`;

const DropdownItemText = styled.div`
    color: #002043;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
    letter-spacing: -0.444444px;
    ${(props: renderStyle) =>
        props.isTrue &&
        css`
            color: #e84c3d;
        `}
`;

const DropdownContainer = styled.div<IOnPropStyles>`
    position: relative;
    display: flex;
    cursor: pointer;
    ${(props: IOnPropStyles) =>
        props.disabled &&
        css`
            color: #878787;
            pointer-events: none;
        `}
`;
const DropDownInner = styled.div`
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
`;
const DropDownSpan = styled.span`
    margin-right: 5px;
    :nth-child(2) {
        font-weight: bold;
    }
`;
const JumpToEnds = styled.div`
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    &:hover {
        background: rgba(0, 32, 67, 0.9);
        cursor: pointer;
    }
    background-color: #002043;
    height: 2rem;
`;
const PageNavButtons = styled.div`
    color: #0089ec;
    font-size: 0.75rem;
    line-height: 15.6px;
    font-weight: 700;
    letter-spacing: -0.444444px;
`;
