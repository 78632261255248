import React from 'react';
import styled, { css } from 'styled-components';
import { FlexedDiv } from '../..';
import { IcoMoon } from '../../../icons';

type CardProps = {
    disabled?: boolean;
    handleClick?: (tabInput: number, title: string) => void;
    label?: string;
    link?: string;
    mode?: string;
    onChange?: () => void;
    slideMargin?: number;
    tabInput?: number;
    title?: string;
    titleSize?: number;
    backgroundColor?: string;
    showCustomTitle?: boolean;
    subTitle?: string;
    customTitle?: string;
    visibleSlides?: number;
    testId?: string;
    letterSpacing?: number;
    isTitleBadge?: boolean;
};

export const Card: React.FC<CardProps> = ({
    disabled,
    handleClick,
    label,
    // link,
    // mode,
    slideMargin,
    tabInput,
    title,
    visibleSlides,
    titleSize,
    backgroundColor,
    showCustomTitle,
    subTitle,
    customTitle,
    testId,
    letterSpacing,
    isTitleBadge,
}: CardProps) => {
    const _tabInput = tabInput !== undefined ? tabInput : 0;
    const _label = label !== undefined ? label : '';
    const _titleSize = titleSize !== undefined ? titleSize : 3.8;
    const _slideMargin = slideMargin !== undefined ? slideMargin : 0.8;
    const _backgroundColor = backgroundColor !== undefined ? backgroundColor : '#fbfbfb';
    const _showCustomTitle = showCustomTitle !== undefined ? showCustomTitle : false;
    return (
        <CardWrapper
            backgroundColor={_backgroundColor}
            slideMargin={_slideMargin}
            visibleSlides={visibleSlides ?? 3}
            clickable={handleClick !== undefined && disabled !== true}
            onClick={() => handleClick && handleClick(_tabInput, _label)}
            id={testId}
            data-testid={testId}
        >
            {_showCustomTitle ? (
                <FlexedDiv direction="row" alignItems="center">
                    {customTitle && isTitleBadge ? (
                        <Badge>{customTitle}</Badge>
                    ) : (
                        <QuestionBodyStyle letterSpacing={letterSpacing}>{customTitle}</QuestionBodyStyle>
                    )}
                    <div>
                        <CardTile titleSize={_titleSize}>
                            <div style={!subTitle ? { marginTop: '4px' } : { marginTop: '0px' }}> {title}</div>
                        </CardTile>
                        <SubTitleStyle>{subTitle}</SubTitleStyle>
                    </div>
                </FlexedDiv>
            ) : (
                <CardTile titleSize={_titleSize}>{title}</CardTile>
            )}

            <BottomRow>
                <CardLabel>{label}</CardLabel>
                {handleClick !== undefined && disabled !== true ? (
                    <div onClick={() => handleClick(_tabInput, _label)} data-testid={`${testId}-caret-icon`}>
                        <IcoMoon
                            name="caret-right"
                            size="1.5rem"
                            style={{ cursor: 'pointer' }}
                            testId={`${testId}-caret-icon`}
                        />
                    </div>
                ) : null}
            </BottomRow>
        </CardWrapper>
    );
};

declare interface CardWrapperProps {
    clickable: boolean;
    slideMargin: number;
    visibleSlides: number;
    backgroundColor: string;
}
//component styles
const CardWrapper = styled.div<CardWrapperProps>`
    flex: 0 0 calc(100% / ${(props) => props.visibleSlides} - ${(props) => props.slideMargin * 2}px);
    margin: 0 ${(props) => props.slideMargin}px;
    background-color: ${(props) => props.backgroundColor}; // #fbfbfb;
    padding: 16px 24px;
    border: 1px solid #eaebee;
    border-radius: 8px;
    // margin: 0 0.8em;
    ${(props: CardWrapperProps) =>
        props.clickable &&
        css`
            box-shadow: 2px 2px 8px rgba(0, 32, 67, 0.04), 6px 6px 16px rgba(0, 32, 67, 0.04);
            background-color: #ffffff;
            &:hover {
                cursor: pointer;
            }
        `}
`;
declare interface CardSizeProps {
    titleSize: number;
}
declare interface QuestionBodyStyleProps {
    letterSpacing?: number;
}
const CardTile = styled.h1<CardSizeProps>`
    font-size: ${(props) => props.titleSize}em;
    font-weight: 700;
    display: flex;
    margin-bottom: 0px;
`;
const BottomRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const CardLabel = styled.p`
    font-size: 0.8rem;
    margin-bottom: 0px;
    font-weight: 600;
`;
const Badge = styled.span`
    background-color: rgb(255, 255, 255);
    padding: 5px 8px;
    border-radius: 16px;
    font-size: 12px;
    margin-right: 1rem;
`;
const QuestionBodyStyle = styled.div<QuestionBodyStyleProps>`
    font-weight: 400;
    font-size: 12px;
    color: rgb(23, 23, 23);
    background-color: #fff;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    letter-spacing: ${(props) => props.letterSpacing}px;
`;
const SubTitleStyle = styled.div`
    font-weight: normal;
    font-size: 12px;
    color: #4d4d4d;
`;
