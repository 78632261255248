import React, { ComponentProps } from 'react';
import { Moment } from 'moment';
import { IcoMoon } from '../../../icons';
import styled from 'styled-components';

type divProps = ComponentProps<'div'>;

interface DatePaginatorProps extends divProps {
    date: Moment;
    setDate: (value: Moment) => void;
    rangeMode?: boolean;
    previousMonth: boolean;
    formatMonth: string;
}

export const DatePaginator = ({
    date,
    setDate,
    rangeMode,
    previousMonth,
    formatMonth,
}: DatePaginatorProps): JSX.Element => {
    const arrowSize = '18px';
    return (
        <FlexRow2 style={{ justifyContent: 'space-between', width: 'auto' }} id="date-picker-header">
            {rangeMode && previousMonth ? (
                <FlexRow2>
                    <IconGroup onClick={() => setDate(date.subtract(1, 'years'))}>
                        <IcoMoon name="double-arrow-left" size={arrowSize} />
                    </IconGroup>
                    <IconGroup onClick={() => setDate(date.subtract(1, 'months'))}>
                        <IcoMoon name="caret-left" size="24px" />
                    </IconGroup>
                </FlexRow2>
            ) : (
                <FlexRow2>
                    <IconGroup onClick={() => setDate(date.subtract(1, 'years'))}>
                        <IcoMoon name="double-arrow-left" size={arrowSize} />
                    </IconGroup>
                    <IconGroup onClick={() => setDate(date.subtract(1, 'months'))}>
                        <IcoMoon name="caret-left" size="24px" />
                    </IconGroup>
                </FlexRow2>
            )}

            {rangeMode && !previousMonth ? (
                <FlexRow2 style={{ width: '100%', justifyContent: 'center' }}>
                    <DatePaginatorYear>{date.clone().add(1, 'months').format(formatMonth)}</DatePaginatorYear>
                </FlexRow2>
            ) : (
                <FlexRow2 style={{ width: '100%', justifyContent: 'center' }}>
                    <DatePaginatorYear>{date.format(formatMonth)}</DatePaginatorYear>
                </FlexRow2>
            )}
            {rangeMode && !previousMonth ? (
                <FlexRow2>
                    <IconGroup onClick={() => setDate(date.add(1, 'months'))}>
                        <IcoMoon name="caret-right" size="24px" />
                    </IconGroup>
                    <IconGroup onClick={() => setDate(date.add(1, 'years'))}>
                        <IcoMoon name="double-arrow-right" size={arrowSize} />
                    </IconGroup>
                </FlexRow2>
            ) : (
                <FlexRow2>
                    <IconGroup onClick={() => setDate(date.add(1, 'months'))}>
                        <IcoMoon name="caret-right" size="24px" />
                    </IconGroup>
                    <IconGroup onClick={() => setDate(date.add(1, 'years'))}>
                        <IcoMoon name="double-arrow-right" size={arrowSize} />
                    </IconGroup>
                </FlexRow2>
            )}
        </FlexRow2>
    );
};
const DatePaginatorYear = styled.span`
    font-size: 1rem;
    font-weight: 700;
`;
const IconGroup = styled.div`
    display: flex;
    cursor: pointer;
`;

const FlexRow2 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
`;
