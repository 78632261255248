import gql from 'graphql-tag';

export const ActivityLogsQuery = gql`
    query activityLogDashboard($input: DashboardInput) {
        activityLogDashboard(input: $input) {
            data {
                result {
                    logs {
                        operationName
                        name
                        code
                        id
                        roles
                        userName
                        eventType
                        fileSize
                        createdOn
                        status
                        action
                    }
                    activityCount
                    reportCount
                    pages
                    page
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
