import { emptyCheck } from '../emptyCheck';
import { LABEL } from '../../constants';

/* Search Terms */
// Redemption Type
// Fund Redemption
// Proceed Payable

function payableBankAccounts(bankSummary: TProceedPayableBankSummary) {
    const subSectionData: ISectionWithSubSections[] = [];
    const { localBank, foreignBank } = bankSummary;

    [...localBank, ...foreignBank].map((ele) => {
        const proceedPayableWithSubSection: ISectionWithSubSections = {
            sectionTitle: {
                title: ele.currency[0],
            },
            subSection: [],
        };

        const tempObjBankInfo: ISection = {
            data: [
                {
                    label: LABEL.bankName,
                    data: emptyCheck(ele.bankName),
                },
                { label: LABEL.bankAccountHolder, data: emptyCheck(ele.bankAccountName) },
                { label: LABEL.bankAccountNumber, data: emptyCheck(ele.bankAccountNumber) },
                { label: LABEL.bankSwiftCode, data: emptyCheck(ele.bankSwiftCode) },
            ],
            subSectionWrapperStyle: { margin: '5px 0px 0px 0px' },
        };
        if (ele.isNewBank) {
            proceedPayableWithSubSection.sectionTitle.badgeLabel = LABEL.new;
            proceedPayableWithSubSection.sectionTitle.badgeColor = '#4D4D4D';
        }

        proceedPayableWithSubSection.subSection.push(tempObjBankInfo);
        subSectionData.push(proceedPayableWithSubSection);
    });

    return subSectionData;
}

export function redemptionDataStructring({ redeemSummary, redemptionSummary }: IOrderSummaryResult) {
    // Default value
    if (!redemptionSummary.length) return [undefined, undefined, undefined];

    /*********************************Redemption Type***************************************/
    const _redemptionType: IProfileSection = { title: 'Redemption Type', sections: [{ data: [] }] };

    _redemptionType.sections[0].data.push({
        label: 'Redemption Reason',
        data: emptyCheck(redeemSummary.redeemType),
    });

    /*************************************Fund Redemption***********************************/

    const _redemptionDetailsGroup: ISectionWithSubSections[] = [];
    redemptionSummary.map((ele) => {
        let rightLabel = `${ele.units} units`;
        let availableLabel = 'Available Units';
        let availableValue = emptyCheck(ele.availableUnits);

        if (ele.fundType === 'AMP') {
            rightLabel = `${ele.fundCurrency} ${ele.redeemAmount}`;
            availableLabel = 'Available Amount';
            availableValue = `${ele.fundCurrency} ${emptyCheck(ele.availableAmounts)}`;
        }

        const redemptionDetailsWithSubSection: ISectionWithSubSections = {
            sectionTitle: {
                title: ele.fundName,
                bottomLabel: ele.fundIssuer,
                rightLabel,
                bottomLabelStyle: { margin: '5px 0px 0px 0px' },
            },
            subSection: [],
            subSectionWrapperStyle: { margin: '5px 0px 0px 0px' },
        };

        const tempObjRedemptInfo: ISection = {
            data: [
                { label: 'Fund Code', data: emptyCheck(ele.fundCode) },
                { label: 'Class', data: emptyCheck(ele.fundClass) },
                { label: 'Currency', data: emptyCheck(ele.fundCurrency) },
                {
                    label: availableLabel,
                    data: availableValue,
                    dataStyle: { marginBottom: '-1rem' },
                },
            ],
            subSectionWrapperStyle: { margin: '5px 0px 0px 0px' },
        };

        redemptionDetailsWithSubSection.subSection.push(tempObjRedemptInfo);
        _redemptionDetailsGroup.push(redemptionDetailsWithSubSection);
    });

    /***********************************Proceed Payable**************************************/

    let _proceedPayableDetailsGroup: ISectionWithSubSections[] | ISection[] = [];
    const { proceedPayable, redemptionMethod } = redeemSummary;

    /**
     * Redeemed amount can be paid to "Bank Account", "CTA", "EPF".
     */
    switch (redemptionMethod) {
        case 'bankAccount':
            _proceedPayableDetailsGroup = payableBankAccounts(proceedPayable.bankSummary!);
            break;
        case 'cta':
            const ctaPayable: ISection[] = [{ data: [] }];

            ctaPayable[0].data.push(
                {
                    label: 'Client Name',
                    data: emptyCheck(proceedPayable.ctaSummary?.name),
                },
                {
                    label: 'Client Trust Account Number',
                    data: emptyCheck(proceedPayable.ctaSummary?.ctaNumber),
                },
            );

            _proceedPayableDetailsGroup = ctaPayable;

            break;
        default:
            /**
             *  @default case when the value for redemptionMethod is null, the proceed payable was done through EPF
             */
            const epfPayable: ISection[] = [{ data: [] }];

            epfPayable[0].data.push(
                {
                    label: 'Withdrawal EPF Member Number',
                    data: emptyCheck(proceedPayable.epfDetails?.epfNumber),
                },
                {
                    label: 'EPF Account Type',
                    data: emptyCheck(proceedPayable.epfDetails?.epfType),
                },
            );

            _proceedPayableDetailsGroup = epfPayable;

            break;
    }
    return [_redemptionType, _redemptionDetailsGroup, _proceedPayableDetailsGroup];
}
