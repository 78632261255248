import gql from 'graphql-tag';
/**
 * @funddataDashboardExportListQuery @query - Uploads Dashboard Export List
 * @description: Export list queries for all tabs
 */

export const fundsExportList = /* GraphQL */ gql`
    query dashboardExportList($input: DashboardExportInput) {
        dashboardExportList(input: $input) {
            data {
                result {
                    funddocumentdashboard {
                        fileName
                        lastUpdate
                        status
                        fileSize
                        remark
                        fileType
                        uploadedOn
                        fundDocumentId
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
export const bulkDocumentsExportList = /* GraphQL */ gql`
    query dashboardExportList($input: DashboardExportInput) {
        dashboardExportList(input: $input) {
            data {
                result {
                    funddocumentdashboard {
                        fileName
                        status
                        remark
                        uploadedOn
                        totalDocuments
                        fundDocumentId
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
