/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { emptyCheck } from './emptyCheck';
import { exportXLSX } from './exportXLSX';
import { getFileSize } from './masterFundValidation';

export const exportListUploadsDashboard = (tabName: string, data: any[]): void => {
    const fileName = `Export_List_${tabName}_${moment().format('DD/MM/YYYY_h:mm:ssa')}`;

    const keys = Object.keys(data[0]);

    const list: { [k: string]: any }[] = [];
    data.map((item) => {
        const tempObj: string[][] = [];

        keys.map((key) => {
            const temp: any[] = [];
            switch (key) {
                case 'fileName':
                    temp.push('File Name', emptyCheck(item[key]));
                    break;
                case 'fileSize':
                    const fileSize = getFileSize(item[key]);
                    temp.push('File Size', emptyCheck(fileSize));
                    break;
                case 'fileType':
                    temp.push('File Type', emptyCheck(item[key]));
                    break;
                case 'lastUpdate':
                    const lastUpdated = item[key] !== null ? moment(item[key], 'x').format('DD/MM/YYYY,h:mm:ss a') : '';
                    temp.push('Last Updated', emptyCheck(lastUpdated));
                    break;
                case 'remark':
                    temp.push('Remarks', emptyCheck(item[key]));
                    break;
                case 'status':
                    temp.push('Status', emptyCheck(item[key]));
                    break;
                case 'totalDocuments':
                    temp.push('Total Documents', emptyCheck(item[key]));
                    break;
                case 'uploadedOn':
                    const uploadedOn = item[key] !== null ? moment(item[key], 'x').format('DD/MM/YYYY,h:mm:ss a') : '';
                    temp.push('Uploaded On', emptyCheck(uploadedOn));
                    break;

                default:
            }
            if (temp.length !== 0) tempObj.push(temp);
        });
        const obj = Object.fromEntries(tempObj);
        list.push(obj);
    });

    exportXLSX(list, fileName);
};
