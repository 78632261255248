import gql from 'graphql-tag';
/**
 * @reportDashboard @query - Report Dashboard
 * @description:  Returns all report dashboard data
 */
export const getOperationalReportDashboard = /*GraphQL */ gql`
    query getOperationalReportDashboard($input: ReportDashboardInput) {
        getOperationalReportDashboard(input: $input) {
            data {
                result {
                    reportName
                    lastDownloaded
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
