import gql from 'graphql-tag';

export const transactionReasonList = /* GraphQL */ gql`
    query transactionReasonList($input: TransactionReasonListInput) {
        transactionReasonList(input: $input) {
            data {
                result {
                    reasons {
                        reasonId
                        category
                        subCategory
                    }
                    targetDate
                    rerouteDocuments {
                        fileName
                        issuingHouse
                        documentName
                        id
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;

export const transactionReasonListV2 = /* GraphQL */ gql`
    query transactionReasonListV2($input: TransactionReasonListInputV2) {
        transactionReasonListV2(input: $input) {
            data {
                result {
                    reasons {
                        reasonId
                        category
                        subCategory
                    }
                    targetDate
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
