/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { RegulartTooltipTemplate, Tooltip } from '../components';
import { IcoMoon } from '../icons';

export const RemarksColumnItem = ({ data }: ITableCustomItem): JSX.Element => {
    const tooltipItems: Array<ITooltip> = [];
    if (data.remark) {
        const tempObject = {
            label: '',
        };

        tempObject.label = data.remark;
        tooltipItems.push(tempObject);
    }

    return (
        <Fragment>
            {tooltipItems.length !== 0 ? (
                <Tooltip tooTipContent={<RegulartTooltipTemplate message={tooltipItems} />}>
                    <div>
                        <IcoMoon name="warning" color="#E89700" size="1.667vw" />
                    </div>
                </Tooltip>
            ) : (
                <div></div>
            )}
        </Fragment>
    );
};
