const filters = ['Money Sighted', 'Non-Money Sighted'];
const cutOffTime = ['All', '9.30am', '11am', '12pm', '2pm', '4pm'];
function makeObject(array: string[]): IDashboardFilterTags[] {
    const tags: IDashboardFilterTags[] = [];
    array.map((item, i) => {
        const title = item;
        const index = i;
        tags.push({ title: title, index: index, active: index === 0 ? true : false });
    });
    return tags;
}
export const BRANCH_FILTER_TAGS = {
    daily: {
        fundType: {
            tab: 0,
            label: 'Types:',
            tags: makeObject(filters),
        },
        cutOffTime: {
            tab: 0,
            label: 'Batch Cut Off Time:',
            tags: makeObject(cutOffTime),
        },
    },
};
