import gql from 'graphql-tag';

/**
 * @createUser @mutation System admin Dashboard
 * @description mutation for creating new user
 */

export const editUser = gql`
    mutation editUser($input: EditUserAdminInput) {
        editUser(input: $input) {
            data {
                result {
                    status
                }
            }
            error {
                errorCode
                message
                errorList
            }
        }
    }
`;

export default editUser;
