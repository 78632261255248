import React from 'react';
import styled, { css } from 'styled-components';

export const ShadowEffect = ({ color, placement }: IShadowEffectProps): JSX.Element => {
    return <Shadow color={color} placement={placement} data-testid="shadow-effect" />;
};
const Shadow = styled.div<IShadowEffectProps>`
    ${(props) =>
        props &&
        css`
            background: ${props.color};
            border-top-left-radius: ${props.placement === 'left' ? '12px' : '0px'};
            border-bottom-left-radius: ${props.placement === 'left' ? '12px' : '0px'};
            width: 8px;
            height: 100%;
            position: absolute;
            left: ${props.placement === 'left' ? '0px' : 'unset'};
            right: ${props.placement === 'right' ? '0px' : 'unset'};
            border-top-right-radius: ${props.placement === 'right' ? '12px' : '0px'};
            border-bottom-right-radius: ${props.placement === 'right' ? '12px' : '0px'};
        `}
`;
