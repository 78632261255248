import gql from 'graphql-tag';

export const printTransactionReportPdf = gql`
    query transactionPdf($input: TransactionPdfInput) {
        transactionPdf(input: $input) {
            data {
                result {
                    message
                    status
                    path
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
