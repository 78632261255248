import gql from 'graphql-tag';

export const dailySummaryCount = gql`
    query dailySummaryCount($input: DailySummaryCountInput) {
        dailySummaryCount(input: $input) {
            data {
                result {
                    count
                    result
                    enableBulkVerify
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
