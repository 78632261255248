import { SYSTEM_ADMIN } from './labels';
import { UserTypes } from './branchRoleCollapsible';

interface CommonCollapsible {
    header?: string;
    title: string;
    subtitle?: string;
    data: UserTypes;
}

interface IHqCollapsible {
    accountManagement: CommonCollapsible;
    userManagement: CommonCollapsible;
    activityLogs: CommonCollapsible;
    systemSettings: CommonCollapsible;
    ceDashboard: CommonCollapsible;
    branchOffices: CommonCollapsible;
    eddCase: CommonCollapsible;
    financeDashboard: CommonCollapsible;
    financeCases: CommonCollapsible;
    productSettings: CommonCollapsible;
    uploads: CommonCollapsible;
    operationDashboard: CommonCollapsible;
    operationCases: CommonCollapsible;
    reports: CommonCollapsible;
}

export const HQ_ROLE: IHqCollapsible = {
    accountManagement: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_LOGIN_ACCESS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_LOGIN_ACCESS_SUB,
        data: {
            general: [
                {
                    keyName: 'login',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_LOGIN_LOGOUT,
                    actions: [],
                    // SYSTEM_ADMIN.ADD_ROLE.LABEL_PASSWORD_RECOVERY
                },
                {
                    keyName: 'inbox',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_INBOX,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_READ_NOTIFICATION],
                },
                {
                    keyName: 'profile',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PROFILE,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_STAFF_DETAILS],
                },
            ],
        },
    },
    userManagement: {
        header: SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_MANAGEMENT,
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_MANAGEMENT,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_MANAGEMENT_SUB,
        data: {
            maker: [
                {
                    keyName: 'userTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_USERS_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_USERS_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_USER_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_USER,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_BULK_IMPORT_USER,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_USER_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_USER,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_USER,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_TERMINATE_USER,
                    ],
                },
                {
                    keyName: 'userGroupTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_GROUP_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_USER_GROUP_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_USER_GROUP,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_USER_GROUP,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_USER_GROUP,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_USER_GROUP,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_USER_GROUP,
                    ],
                },
                {
                    keyName: 'rolesPermissionTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLES_PERMISSION_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_ROLES_PERMISSION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_CREATE_USER_ROLES_PERMISSIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_USER_ROLES_PERMISSIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_USER_ROLES_PERMISSIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_USER_ROLES_PERMISSIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_USER_ROLES_PERMISSIONS,
                    ],
                },
            ],
            checker: [
                {
                    keyName: 'userTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_USERS_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_USERS_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_USER_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_CREATE_NEW_USER_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_BULK_IMPORT_USER_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_DETAILS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_USER_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_USER_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_USER_REQUEST,
                    ],
                },
                {
                    keyName: 'userGroupTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_GROUP_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_USER_GROUP_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_CREATE_NEW_GROUP_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_GROUP_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_GROUP_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_GROUP_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_GROUP_REQUEST,
                    ],
                },
                {
                    keyName: 'rolesPermissionTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLES_PERMISSION_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_ROLES_PERMISSION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_CREATE_ROLES_PERMISSION_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_ROLES_PERMISSION_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_ROLES_PERMISSION_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_ROLES_PERMISSION_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_ROLES_PERMISSION_REQUEST,
                    ],
                },
            ],
            autoAuthorizer: [
                {
                    keyName: 'userTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_USERS_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_USERS_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_USER_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_USER,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_BULK_IMPORT_USER,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_USER_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_USER,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_USER,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_TERMINATE_USER,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_CREATE_NEW_USER_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_BULK_IMPORT_USER_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_DETAILS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_USER_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_USER_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_TERMINATE_USER_REQUEST,
                    ],
                },
                {
                    keyName: 'userGroupTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_GROUP_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_USER_GROUP_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_USER_GROUP,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_USER_GROUP,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_USER_GROUP,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_USER_GROUP,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_USER_GROUP,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_CREATE_NEW_GROUP_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_GROUP_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_GROUP_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_GROUP_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_GROUP_REQUEST,
                    ],
                },
                {
                    keyName: 'rolesPermissionTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLES_PERMISSION_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_ROLES_PERMISSION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_CREATE_USER_ROLES_PERMISSIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_USER_ROLES_PERMISSIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_USER_ROLES_PERMISSIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_USER_ROLES_PERMISSIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_USER_ROLES_PERMISSIONS,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_CREATE_ROLES_PERMISSION_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_ROLES_PERMISSION_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_SUSPEND_ROLES_PERMISSION_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REACTIVATE_ROLES_PERMISSION_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DELETE_ROLES_PERMISSION_REQUEST,
                    ],
                },
            ],
        },
    },
    activityLogs: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ACTIVITY_LOGS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_ACTIVITY_LOGS_SUB,
        data: {
            general: [
                {
                    keyName: 'userActivityTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_ACTIVITY_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_USER_ACTIVITY_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_ACTIVITY_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_REPORT,
                    ],
                },
                {
                    keyName: 'reportsTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REPORTS_TAB,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_REPORTS_LIST, SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST],
                },
            ],
        },
    },
    systemSettings: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_SYSTEM_SETTINGS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_SYSTEM_SETTINGS_SUB,
        data: {
            maker: [
                {
                    keyName: 'epfTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_EPF,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_EPF_UTMC_TOGGLE],
                },
            ],
            checker: [
                {
                    keyName: 'epfTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_EPF,
                    actions: [],
                    reviewApproval: [SYSTEM_ADMIN.ADD_ROLE.LABEL_EDIT_EPF_UTMC_TOGGLE],
                    reviewApprovalTitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_APPROVAL,
                },
            ],
        },
    },
    ceDashboard: {
        header: SYSTEM_ADMIN.ADD_ROLE.LABEL_CUSTOMER_EXP,
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_CUSTOMER_EXP_HQ_DASHBOARD,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_CUSTOMER_EXP_DASHBOARD_SUB,
        data: {
            maker: [
                {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_OVERVIEW],
                },
                {
                    keyName: 'allBranch',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ALL_BRANCH_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_ALL_BRANCH_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'pendingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PENDING,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_TRANSACTION_REPORT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_SUMMARY_REPORT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VERIFY_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS,
                    ],
                },
                {
                    keyName: 'upcomingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_UPCOMING,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'hardCopyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_WITH_HARD_COPY,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_RECEIVE_HARD_COPY,
                    ],
                },
                {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DUE_DATE_EXTENSION_REQUEST,
                    ],
                },
                {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_TRANSACTION_REPORT_PDF,
                    ],
                },
                {
                    keyName: 'dueDateTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DUE_DATE_EXTENSION_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DUE_DATE_EXTENSION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
            ],
            checker: [
                {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_OVERVIEW],
                },
                {
                    keyName: 'allBranch',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ALL_BRANCH_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_ALL_BRANCH_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'pendingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PENDING,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_TRANSACTION_REPORT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_SUMMARY_REPORT,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VERIFY_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS_REQUEST,
                    ],
                },
                {
                    keyName: 'upcomingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_UPCOMING,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'hardCopyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_WITH_HARD_COPY,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_RECEIVE_HARD_COPY,
                    ],
                },
                {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_TRANSACTION_REPORT_PDF,
                    ],
                },
                {
                    keyName: 'dueDateTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DUE_DATE_EXTENSION_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DUE_DATE_EXTENSION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [SYSTEM_ADMIN.ADD_ROLE.LABEL_DUE_DATE_EXTENSION_REQUEST],
                },
            ],
            autoAuthorizer: [
                {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_OVERVIEW],
                },
                {
                    keyName: 'allBranch',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ALL_BRANCH_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_ALL_BRANCH_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'pendingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PENDING,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_TRANSACTION_REPORT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_SUMMARY_REPORT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VERIFY_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VERIFY_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS_REQUEST,
                    ],
                },
                {
                    keyName: 'upcomingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_UPCOMING,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'hardCopyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_WITH_HARD_COPY,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_RECEIVE_HARD_COPY,
                    ],
                },
                {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DUE_DATE_EXTENSION_REQUEST,
                    ],
                },
                {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_TRANSACTION_REPORT_PDF,
                    ],
                },
                {
                    keyName: 'dueDateTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DUE_DATE_EXTENSION_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DUE_DATE_EXTENSION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [SYSTEM_ADMIN.ADD_ROLE.LABEL_DUE_DATE_EXTENSION_REQUEST],
                },
            ],
        },
    },
    reports: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REPORTS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_REPORTS_SUB,
        data: {
            general: [
                {
                    keyName: 'operationalReport',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OPERATIONAL_REPORT,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_3RD_PARTY_DAILY_BOOKING_REPORT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_MSF_CTA,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_REGULAR_INVESTMENT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_SWITCHING_SUBMISSION,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_REDEMPTION_SUBMISSION,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_BRANCH_CONTROL_SALES_SUBMISSION,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_SALES_SUBMISSION_STATISTIC_SUMMARY_AND_MONEY_MARKET,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_SALES_SUBMISSION_STATISTIC_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_EPF_APPLICATION_STATUS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_USER_ACCESS_AUDIT_TRAIL,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_USER_ACCOUNT_STATUS,
                    ],
                },
            ],
        },
    },
    branchOffices: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_OFFICES,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_OFFICES_SUB,
        data: {
            general: [
                {
                    keyName: 'allBranchTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ALL_BRANCH_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_ALL_BRANCH_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_ADVISER_INVESTOR,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'advisersTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ADVISERS_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_ADVISER_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_ADVISER_PROFILE,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'investorsTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_INVESTORS_TAB,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_INVESTOR_LIST],
                },
            ],
        },
    },
    eddCase: {
        header: SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD,
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD_MANAGEMENT,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD_SUB,
        data: {
            maker: [
                {
                    keyName: 'newTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_CASE_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS_CASE,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_ADD_NEW_CASE,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_MONTHLY_STATUS_REPORT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD_CASE_REVIEW,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_CANCEL_CASE,
                    ],
                },
                {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_REROUTED_CASE_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS_CASE,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD_CASE_REVIEW,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_EDD_REPORT_REMARKS,
                    ],
                },
                {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_HISTORY_CASES_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS_CASE,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_EDD_REPORT,
                    ],
                },
            ],
            checker: [
                {
                    keyName: 'newTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_CASE_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS_CASE,
                        // SYSTEM_ADMIN.ADD_ROLE.LABEL_ADD_NEW_CASE,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_MONTHLY_STATUS_REPORT,
                        // SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD_CASE_REVIEW,
                        // SYSTEM_ADMIN.ADD_ROLE.LABEL_CANCEL_CASE,
                    ],
                    reviewApprovalTitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_APPROVAL,
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD_CASE_REVIEW_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_CANCEL_CASE_REQUEST,
                    ],
                },
                {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_REROUTED_CASE_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS_CASE,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        // SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD_CASE_REVIEW,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_EDD_REPORT_REMARKS,
                    ],
                    reviewApprovalTitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_APPROVAL,
                    reviewApproval: [SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD_CASE_REVIEW_REQUEST],
                },
                {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_HISTORY_CASES_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS_CASE,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_EDD_REPORT,
                    ],
                },
            ],
            autoAuthorizer: [
                {
                    keyName: 'newTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_CASE_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS_CASE,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_ADD_NEW_CASE,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_MONTHLY_STATUS_REPORT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD_CASE_REVIEW,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_CANCEL_CASE,
                    ],
                    reviewApprovalTitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_APPROVAL,
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD_CASE_REVIEW_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_CANCEL_CASE_REQUEST,
                    ],
                },
                {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_REROUTED_CASE_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS_CASE,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD_CASE_REVIEW,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_EDD_REPORT_REMARKS,
                    ],
                    reviewApprovalTitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_APPROVAL,
                    reviewApproval: [SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD_CASE_REVIEW_REQUEST],
                },
                {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_HISTORY_CASES_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS_CASE,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_EDD_REPORT,
                    ],
                },
            ],
        },
    },
    financeDashboard: {
        header: SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE,
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE_DASHBOARD,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE_DASHBOARD_SUB,
        data: {
            maker: [
                {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_OVERVIEW],
                },
                {
                    keyName: 'branchVerifiedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_VERIFIED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_BRANCH_VERIFIED_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_RECEIVE_PAYMENT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS,
                    ],
                },
                {
                    keyName: 'moneySightedFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_RECEIVE_PAYMENT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS,
                    ],
                },
            ],
            checker: [
                {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_OVERVIEW],
                },
                {
                    keyName: 'branchVerifiedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_VERIFIED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_BRANCH_VERIFIED_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_RECEIVE_PAYMENT_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS_REQUEST,
                    ],
                },
                {
                    keyName: 'moneySightedFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_RECEIVE_PAYMENT_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS_REQUEST,
                    ],
                },
            ],
            autoAuthorizer: [
                {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_OVERVIEW],
                },
                {
                    keyName: 'branchVerifiedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_VERIFIED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_BRANCH_VERIFIED_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_RECEIVE_PAYMENT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_RECEIVE_PAYMENT_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS_REQUEST,
                    ],
                },
                {
                    keyName: 'moneySightedFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_RECEIVE_PAYMENT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_RECEIVE_PAYMENT_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS_REQUEST,
                    ],
                },
            ],
        },
    },
    financeCases: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE_CASES,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE_CASES_SUB,
        data: {
            general: [
                {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_REROUTED_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [SYSTEM_ADMIN.ADD_ROLE.LABEL_DUE_DATE_EXTENSION_REQUEST],
                },
                {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_HISTORY_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
            ],
        },
    },
    productSettings: {
        header: SYSTEM_ADMIN.ADD_ROLE.LABEL_FUND_MANAGEMENT,
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PRODUCT_SETTINGS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_PRODUCT_SETTINGS_SUB,
        data: {
            general: [
                {
                    keyName: 'allFundsTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ALL_FUNDS_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_FUND_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS_FUND,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'allAmpTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ALL_AMP_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_AMP_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS_AMP,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'navPerUnitTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_NAV_PER_UNIT_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_NAV_PER_UNIT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS_HISTORY,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'distributionTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DISTRIBUTION_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_HISTORY_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS_DISTRIBUTION,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'documentsTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DOCUMENTS_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DOCUMENTS_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_PREVIEW_PROSPECTUS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_PREVIEW_HIGHLIGHT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_PREVIEW_REPORT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_PREVIEW_FUND_FACT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
            ],
        },
    },
    uploads: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_FUND_UPLOADS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_FUND_UPLOADS_SUB,
        data: {
            maker: [
                {
                    keyName: 'masterFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MASTER_FUND_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_REROUTED_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_PREVIEW_MASTER_FUND,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_IMPORT_MASTER_FUND,
                    ],
                },
                {
                    keyName: 'navPerUnitTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_NAV_PER_UNIT_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_REJECTED_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_PREVIEW_NAV,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_IMPORT_NAV,
                    ],
                },
                {
                    keyName: 'distributionTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DISTRIBUTION_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_HISTORY_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_PREVIEW_DISTRIBUTION,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_IMPORT_DISTRIBUTION,
                    ],
                },
                {
                    keyName: 'documentsTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DOCUMENTS_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_HISTORY_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_PREVIEW_DOCUMENTS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_BULK_UPLOAD_DOCUMENTS,
                    ],
                },
            ],
            checker: [
                {
                    keyName: 'masterFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MASTER_FUND_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_REROUTED_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_PREVIEW_MASTER_FUND,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [SYSTEM_ADMIN.ADD_ROLE.LABEL_IMPORT_MASTER_FUND_REQUEST],
                },
                {
                    keyName: 'navPerUnitTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_NAV_PER_UNIT_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_REJECTED_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_PREVIEW_NAV,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [SYSTEM_ADMIN.ADD_ROLE.LABEL_IMPORT_NAV_REQUEST],
                },
                {
                    keyName: 'distributionTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DISTRIBUTION_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_HISTORY_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_PREVIEW_DISTRIBUTION,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [SYSTEM_ADMIN.ADD_ROLE.LABEL_IMPORT_DISTRIBUTION_REQUEST],
                },
                {
                    keyName: 'documentsTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DOCUMENTS_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_HISTORY_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_PREVIEW_DOCUMENTS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [SYSTEM_ADMIN.ADD_ROLE.LABEL_BULK_UPLOAD_DOCUMENTS_REQUEST],
                },
            ],
            autoAuthorizer: [
                {
                    keyName: 'masterFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MASTER_FUND_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_REROUTED_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_PREVIEW_MASTER_FUND,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_IMPORT_MASTER_FUND,
                    ],
                    reviewApproval: [SYSTEM_ADMIN.ADD_ROLE.LABEL_IMPORT_MASTER_FUND_REQUEST],
                },
                {
                    keyName: 'navPerUnitTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_NAV_PER_UNIT_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_REJECTED_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_PREVIEW_NAV,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_IMPORT_NAV,
                    ],
                    reviewApproval: [SYSTEM_ADMIN.ADD_ROLE.LABEL_IMPORT_NAV_REQUEST],
                },
                {
                    keyName: 'distributionTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DISTRIBUTION_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_HISTORY_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_PREVIEW_DISTRIBUTION,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_IMPORT_DISTRIBUTION,
                    ],
                    reviewApproval: [SYSTEM_ADMIN.ADD_ROLE.LABEL_IMPORT_DISTRIBUTION_REQUEST],
                },
                {
                    keyName: 'documentsTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DOCUMENTS_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_HISTORY_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_PREVIEW_DOCUMENTS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_BULK_UPLOAD_DOCUMENTS,
                    ],
                    reviewApproval: [SYSTEM_ADMIN.ADD_ROLE.LABEL_BULK_UPLOAD_DOCUMENTS_REQUEST],
                },
            ],
        },
    },
    operationDashboard: {
        header: SYSTEM_ADMIN.ADD_ROLE.LABEL_OPERATION,
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OPERATION_DASHBOARD,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_OPERATION_DASHBOARD_SUB,
        data: {
            maker: [
                {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_OVERVIEW],
                },
                {
                    keyName: 'branchVerifiedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_VERIFIED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_BRANCH_VERIFIED_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS,
                    ],
                },
                {
                    keyName: 'moneySightedFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS,
                    ],
                },
                {
                    keyName: 'epfTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_EPF_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_EPF_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS,
                    ],
                },
                {
                    keyName: 'ddaTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DDA_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DDA_TRANSACTIONS_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS,
                    ],
                },
                {
                    keyName: 'financeReRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE_REROUTED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_FINANCE_REROUTED_TRANSACTIONS_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_FREEZE_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_UNFREEZE_TRANSACTIONS,
                    ],
                },
            ],
            checker: [
                {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_OVERVIEW],
                },
                {
                    keyName: 'branchVerifiedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_VERIFIED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_BRANCH_VERIFIED_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS_REQUEST,
                    ],
                },
                {
                    keyName: 'moneySightedFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS_REQUEST,
                    ],
                },
                {
                    keyName: 'epfTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_EPF_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_EPF_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS_REQUEST,
                    ],
                },
                {
                    keyName: 'ddaTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DDA_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DDA_TRANSACTIONS_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS_REQUEST,
                    ],
                },
                {
                    keyName: 'financeReRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE_REROUTED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_FINANCE_REROUTED_TRANSACTIONS_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_FREEZE_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_UNFREEZE_TRANSACTIONS_REQUEST,
                    ],
                },
            ],
            autoAuthorizer: [
                {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_OVERVIEW],
                },
                {
                    keyName: 'branchVerifiedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_VERIFIED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_BRANCH_VERIFIED_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS_REQUEST,
                    ],
                },
                {
                    keyName: 'moneySightedFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS_REQUEST,
                    ],
                },
                {
                    keyName: 'epfTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_EPF_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_EPF_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS_REQUEST,
                    ],
                },
                {
                    keyName: 'ddaTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DDA_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DDA_TRANSACTIONS_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS_REQUEST,
                    ],
                },
                {
                    keyName: 'financeReRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE_REROUTED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_FINANCE_REROUTED_TRANSACTIONS_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_FREEZE_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_UNFREEZE_TRANSACTIONS,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_FREEZE_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_UNFREEZE_TRANSACTIONS_REQUEST,
                    ],
                },
            ],
        },
    },
    operationCases: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OPERATION_CASES,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_OPERATION_CASES_SUB,
        data: {
            general: [
                {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_REROUTED_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [SYSTEM_ADMIN.ADD_ROLE.LABEL_DUE_DATE_EXTENSION_REQUEST],
                },
                {
                    keyName: 'rejectedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECTED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_REJECTED_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                    reviewApproval: [SYSTEM_ADMIN.ADD_ROLE.LABEL_DUE_DATE_EXTENSION_REQUEST],
                },
                {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_HISTORY_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
            ],
        },
    },
};

export const HQ_ROLE_KEYS = {
    accountManagement: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_LOGIN_ACCESS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_LOGIN_ACCESS_SUB,
        data: {
            general: {
                login: {
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_LOGIN_LOGOUT,
                    actions: [],
                    // 'canDoPasswordRecovery'
                },
                inbox: {
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_INBOX,
                    actions: ['canReadNotifications'],
                },
                profile: {
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PROFILE,
                    actions: ['canViewStaffDetails'],
                },
            },
        },
    },
    userManagement: {
        header: SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_MANAGEMENT,
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_MANAGEMENT,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_MANAGEMENT_SUB,
        data: {
            maker: {
                userTab: {
                    keyName: 'userTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_USERS_TAB,
                    actions: [
                        'canViewUserList',
                        'canViewUserDetails',
                        'canExportList',
                        'canCreateNewUser',
                        'canBulkImport',
                        'canEditUserDetails',
                        'canSuspendUser',
                        'canReactivateUser',
                        'canTerminateUser',
                    ],
                },
                userGroupTab: {
                    keyName: 'userGroupTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_GROUP_TAB,
                    actions: [
                        'canViewUserGroupList',
                        'canExportList',
                        'canCreateNewUserGroup',
                        'canEditUserGroup',
                        'canSuspendUserGroup',
                        'canReactivateUserGroup',
                        'canDeleteUserGroup',
                    ],
                },
                rolesPermissionTab: {
                    keyName: 'rolesPermissionTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLES_PERMISSION_TAB,
                    actions: [
                        'canViewRolesList',
                        'canExportList',
                        'canCreateUserRole',
                        'canEditUserRoleDetails',
                        'canSuspendUserRole',
                        'canReactivateUserRole',
                        'canDeleteUserRole',
                    ],
                },
            },
            checker: {
                userTab: {
                    keyName: 'userTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_USERS_TAB,
                    actions: ['canViewUserList', 'canViewUserDetails', 'canExportList'],
                    reviewApproval: [
                        'canApproveCreateNewUser',
                        'canApproveBulkImport',
                        'canApproveEditUserDetails',
                        'canApproveSuspendUser',
                        'canApproveReactivateUser',
                        'canApproveTerminateUser',
                    ],
                },
                userGroupTab: {
                    keyName: 'userGroupTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_GROUP_TAB,
                    actions: ['canViewUserGroupList', 'canExportList'],
                    reviewApproval: [
                        'canApproveCreateNewUserGroup',
                        'canApproveEditUserGroup',
                        'canApproveSuspendUserGroup',
                        'canApproveReactivateUserGroup',
                        'canApproveDeleteUserGroup',
                    ],
                },
                rolesPermissionTab: {
                    keyName: 'rolesPermissionTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLES_PERMISSION_TAB,
                    actions: ['canViewRolesList', 'canExportList'],
                    reviewApproval: [
                        'canApproveCreateUserRole',
                        'canApproveEditUserRole',
                        'canApproveSuspendUserRole',
                        'canApproveReactivateUserRole',
                        'canApproveDeleteUserRole',
                    ],
                },
            },
            autoAuthorizer: {
                userTab: {
                    keyName: 'userTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_USERS_TAB,
                    actions: [
                        'canViewUserList',
                        'canViewUserDetails',
                        'canExportList',
                        'canCreateNewUser',
                        'canBulkImport',
                        'canEditUserDetails',
                        'canSuspendUser',
                        'canReactivateUser',
                        'canTerminateUser',
                    ],
                    reviewApproval: [
                        'canApproveCreateNewUser',
                        'canApproveBulkImport',
                        'canApproveEditUserDetails',
                        'canApproveSuspendUser',
                        'canApproveReactivateUser',
                        'canApproveTerminateUser',
                    ],
                },
                userGroupTab: {
                    keyName: 'userGroupTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_GROUP_TAB,
                    actions: [
                        'canViewUserGroupList',
                        'canExportList',
                        'canCreateNewUserGroup',
                        'canEditUserGroup',
                        'canSuspendUserGroup',
                        'canReactivateUserGroup',
                        'canDeleteUserGroup',
                    ],
                    reviewApproval: [
                        'canApproveCreateNewUserGroup',
                        'canApproveEditUserGroup',
                        'canApproveSuspendUserGroup',
                        'canApproveReactivateUserGroup',
                        'canApproveDeleteUserGroup',
                    ],
                },
                rolesPermissionTab: {
                    keyName: 'rolesPermissionTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ROLES_PERMISSION_TAB,
                    actions: [
                        'canViewRolesList',
                        'canExportList',
                        'canCreateUserRole',
                        'canEditUserRoleDetails',
                        'canSuspendUserRole',
                        'canReactivateUserRole',
                        'canDeleteUserRole',
                    ],
                    reviewApproval: [
                        'canApproveCreateUserRole',
                        'canApproveEditUserRole',
                        'canApproveSuspendUserRole',
                        'canApproveReactivateUserRole',
                        'canApproveDeleteUserRole',
                    ],
                },
            },
        },
    },
    activityLogs: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ACTIVITY_LOGS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_ACTIVITY_LOGS_SUB,
        data: {
            general: {
                userActivityTab: {
                    keyName: 'userActivityTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_USER_ACTIVITY_TAB,
                    actions: ['canViewUserActivityList', 'canViewActivityDetails', 'canExportList', 'canExportReport'],
                },
                reportsTab: {
                    keyName: 'reportsTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REPORTS_TAB,
                    actions: ['canViewReportList', 'canExportList'],
                },
            },
        },
    },
    systemSettings: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_SYSTEM_SETTINGS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_SYSTEM_SETTINGS_SUB,
        data: {
            maker: {
                epfTab: {
                    keyName: 'epfTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_EPF_TAB,
                    actions: ['canChangeSettings'],
                },
            },
            checker: {
                epfTab: {
                    keyName: 'epfTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_EPF_TAB,
                    actions: [],
                    reviewApproval: ['canApproveChange'],
                },
            },
        },
    },
    ceDashboard: {
        header: SYSTEM_ADMIN.ADD_ROLE.LABEL_CUSTOMER_EXP_DASHBOARD,
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_CUSTOMER_EXP_DASHBOARD,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_CUSTOMER_EXP_DASHBOARD_SUB,
        data: {
            maker: {
                overview: {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: ['canViewOverview'],
                },
                allBranch: {
                    keyName: 'allBranch',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ALL_BRANCH_TAB,
                    actions: ['canViewAllBranchList', 'canExportList'],
                },
                pendingTab: {
                    keyName: 'pendingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PENDING,
                    actions: [
                        'canViewTransactionList',
                        'canViewDetails',
                        'canExportList',
                        'canDownloadTransactionReport',
                        'canDownloadDailySummary',
                        'canVerifyTransactions',
                        'canReroute',
                        'canReject',
                    ],
                },
                upcomingTab: {
                    keyName: 'upcomingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_UPCOMING,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList'],
                },
                hardCopyTab: {
                    keyName: 'hardCopyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_WITH_HARD_COPY,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canReceiveHardCopy'],
                },
                reRoutedTab: {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canDoDueDateExtension'],
                },
                historyTab: {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        'canViewTransactionList',
                        'canViewDetails',
                        'canExportList',
                        'canDownloadTransactionReport',
                    ],
                },
                dueDateTab: {
                    keyName: 'dueDateTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DUE_DATE_EXTENSION_TAB,
                    actions: ['canViewDueDateExtensionList', 'canViewDetails', 'canExportList'],
                },
            },
            checker: {
                overview: {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: ['canViewOverview'],
                },
                allBranch: {
                    keyName: 'allBranch',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ALL_BRANCH_TAB,
                    actions: ['canViewAllBranchList', 'canExportList'],
                },
                pendingTab: {
                    keyName: 'pendingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PENDING,
                    actions: [
                        'canViewTransactionList',
                        'canViewDetails',
                        'canExportList',
                        'canDownloadTransactionReport',
                        'canDownloadDailySummary',
                    ],
                    reviewApproval: ['canApproveVerify', 'canApproveReRoute', 'canApproveReject'],
                },
                upcomingTab: {
                    keyName: 'upcomingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_UPCOMING,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList'],
                },
                hardCopyTab: {
                    keyName: 'hardCopyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_WITH_HARD_COPY,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canReceiveHardCopy'],
                },
                reRoutedTab: {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList'],
                },
                historyTab: {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        'canViewTransactionList',
                        'canViewDetails',
                        'canExportList',
                        'canDownloadTransactionReport',
                    ],
                },
                dueDateTab: {
                    keyName: 'dueDateTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DUE_DATE_EXTENSION_TAB,
                    actions: ['canViewDueDateExtensionList', 'canViewDetails', 'canExportList'],
                    reviewApproval: ['canApproveDueDateExtension'],
                },
            },
            autoAuthorizer: {
                overview: {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: ['canViewOverview'],
                },
                allBranch: {
                    keyName: 'allBranch',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ALL_BRANCH_TAB,
                    actions: ['canViewAllBranchList', 'canExportList'],
                },
                pendingTab: {
                    keyName: 'pendingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PENDING,
                    actions: [
                        'canViewTransactionList',
                        'canViewDetails',
                        'canExportList',
                        'canDownloadTransactionReport',
                        'canDownloadDailySummary',
                        'canVerifyTransactions',
                        'canReroute',
                        'canReject',
                    ],
                    reviewApproval: ['canApproveVerify', 'canApproveReRoute', 'canApproveReject'],
                },
                upcomingTab: {
                    keyName: 'upcomingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_UPCOMING,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList'],
                },
                hardCopyTab: {
                    keyName: 'hardCopyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_WITH_HARD_COPY,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canReceiveHardCopy'],
                },
                reRoutedTab: {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canDoDueDateExtension'],
                },
                historyTab: {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        'canViewTransactionList',
                        'canViewDetails',
                        'canExportList',
                        'canDownloadTransactionReport',
                    ],
                },
                dueDateTab: {
                    keyName: 'dueDateTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DUE_DATE_EXTENSION_TAB,
                    actions: ['canViewDueDateExtensionList', 'canViewDetails', 'canExportList'],
                    reviewApproval: ['canApproveDueDateExtension'],
                },
            },
        },
    },
    branchOffices: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_OFFICES,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_OFFICES_SUB,
        data: {
            general: {
                allBranchTab: {
                    keyName: 'allBranchTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ALL_BRANCH_TAB,
                    actions: ['canViewAllBranchList', 'canViewAdvisersInvestors', 'canExportList'],
                },
                advisersTab: {
                    keyName: 'advisersTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ADVISERS_TAB,
                    actions: ['canViewAdviserList', 'canViewAdvisersProfile', 'canExportList'],
                },
                investorsTab: {
                    keyName: 'investorsTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_INVESTORS_TAB,
                    actions: ['canViewInvestorsList'],
                },
            },
        },
    },
    reports: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REPORTS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_REPORTS_SUB,
        data: {
            general: {
                operationalReport: {
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OPERATIONAL_REPORT,
                    actions: [
                        'canDownloadThirdPartyDailyBookingReport',
                        'canDownloadMoneySightedCTAReport',
                        'canDownloadRegularInvestmentReport',
                        'canDownloadSwitchingSubmissionReport',
                        'canDownloadRedemptionSubmissionReport',
                        'canDownloadBranchControlSalesSubmissionSummaryReport',
                        'canDownloadSalesSubmissionStatisticSummaryMoneyMarketReport',
                        'canDownloadSalesSubmissionStatisticDetailsReport',
                        'canDownloadEpfApplicationStatusReport',
                        'canDownloadUserAccessAuditTrailReport',
                        'canDownloadUserAccountStatusReport',
                    ],
                },
            },
        },
    },
    eddCase: {
        header: SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD_MANAGEMENT,
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_EDD_SUB,
        data: {
            maker: {
                newTab: {
                    keyName: 'newTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_TAB,
                    actions: [
                        'canViewNewCasesList',
                        'canViewDetails',
                        'canAddNewCase',
                        'canExportList',
                        'canDownloadEddStatusReport',
                        'canCaseReview',
                        'canCancelCase',
                    ],
                },
                reRoutedTab: {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: [
                        'canViewReRoutedCasesList',
                        'canViewDetails',
                        'canExportList',
                        'canCaseReview',
                        'canDownloadEddRemarksReport',
                    ],
                },
                historyTab: {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: ['canViewHistoryCasesList', 'canViewDetails', 'canExportList', 'canDownloadEddReport'],
                },
            },
            checker: {
                newTab: {
                    keyName: 'newTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_TAB,
                    actions: ['canViewNewCasesList', 'canViewDetails', 'canExportList', 'canDownloadEddStatusReport'],
                    reviewApproval: ['canApproveCaseReview', 'canApproveCancelCase'],
                    reviewApprovalTitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_APPROVAL,
                },
                reRoutedTab: {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: [
                        'canViewReRoutedCasesList',
                        'canViewDetails',
                        'canExportList',
                        'canDownloadEddRemarksReport',
                    ],
                    reviewApproval: ['canApproveCaseReview'],
                    reviewApprovalTitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_APPROVAL,
                },
                historyTab: {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    reviewApprovalTitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_APPROVAL,
                    actions: ['canViewHistoryCasesList', 'canViewDetails', 'canExportList', 'canDownloadEddReport'],
                },
            },
            autoAuthorizer: {
                newTab: {
                    keyName: 'newTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_NEW_TAB,
                    actions: [
                        'canViewNewCasesList',
                        'canViewDetails',
                        'canAddNewCase',
                        'canExportList',
                        'canDownloadEddStatusReport',
                        'canCaseReview',
                        'canCancelCase',
                    ],

                    reviewApproval: ['canApproveCaseReview', 'canApproveCancelCase'],
                    reviewApprovalTitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_APPROVAL,
                },
                reRoutedTab: {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: [
                        'canViewReRoutedCasesList',
                        'canViewDetails',
                        'canExportList',
                        'canCaseReview',
                        'canDownloadEddRemarksReport',
                    ],
                    reviewApproval: ['canApproveCaseReview'],
                    reviewApprovalTitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_APPROVAL,
                },
                historyTab: {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: ['canViewHistoryCasesList', 'canViewDetails', 'canExportList', 'canDownloadEddReport'],
                    reviewApprovalTitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_REVIEW_APPROVAL,
                },
            },
        },
    },
    financeDashboard: {
        header: SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE,
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE_DASHBOARD,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE_DASHBOARD_SUB,
        data: {
            maker: {
                overview: {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: ['canViewOverview'],
                },
                branchVerifiedTab: {
                    keyName: 'branchVerifiedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_VERIFIED_TAB,
                    actions: [
                        'canViewBranchVerifiedList',
                        'canViewDetails',
                        'canExportList',
                        'canReceivePayment',
                        'canReRouteTransaction',
                    ],
                },
                moneySightedFundTab: {
                    keyName: 'moneySightedFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TAB,
                    actions: [
                        'canViewBranchVerifiedList',
                        'canViewDetails',
                        'canExportList',
                        'canReceivePayment',
                        'canReRouteTransaction',
                    ],
                },
            },
            checker: {
                overview: {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: ['canViewOverview'],
                },
                branchVerifiedTab: {
                    keyName: 'branchVerifiedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_VERIFIED_TAB,
                    actions: ['canViewBranchVerifiedList', 'canViewDetails', 'canExportList'],
                    reviewApproval: ['canApproveReceivePayment', 'canApproveReRouteTransaction'],
                },
                moneySightedFundTab: {
                    keyName: 'moneySightedFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TAB,
                    actions: ['canViewBranchVerifiedList', 'canViewDetails', 'canExportList'],
                    reviewApproval: ['canApproveReceivePayment', 'canApproveReRouteTransaction'],
                },
            },
            autoAuthorizer: {
                overview: {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: ['canViewOverview'],
                },
                branchVerifiedTab: {
                    keyName: 'branchVerifiedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_VERIFIED_TAB,
                    actions: [
                        'canViewBranchVerifiedList',
                        'canViewDetails',
                        'canExportList',
                        'canReceivePayment',
                        'canReRouteTransaction',
                    ],
                    reviewApproval: ['canApproveReceivePayment', 'canApproveReRouteTransaction'],
                },
                moneySightedFundTab: {
                    keyName: 'moneySightedFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TAB,
                    actions: [
                        'canViewBranchVerifiedList',
                        'canViewDetails',
                        'canExportList',
                        'canReceivePayment',
                        'canRerouteTransaction',
                    ],
                    reviewApproval: ['canApproveReceivePayment', 'canApproveReRouteTransaction'],
                },
            },
        },
    },
    financeCases: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE_CASES,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE_CASES_SUB,
        data: {
            general: {
                reRoutedTab: {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: ['canViewReRoutedList', 'canViewDetails', 'canExportList'],
                    reviewApproval: ['canDoDueDateExtension'],
                },
                historyTab: {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: ['canViewHistoryList', 'canViewDetails', 'canExportList'],
                },
            },
        },
    },
    productSettings: {
        header: SYSTEM_ADMIN.ADD_ROLE.LABEL_FUND_MANAGEMENT,
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PRODUCT_SETTINGS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_PRODUCT_SETTINGS_SUB,
        data: {
            general: {
                allFundsTab: {
                    keyName: 'allFundsTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ALL_FUNDS_TAB,
                    actions: ['canViewFundList', 'canViewDetails', 'canExportList'],
                },
                allAmpTab: {
                    keyName: 'allAmpTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ALL_AMP_TAB,
                    actions: ['canViewAmpList', 'canViewDetails', 'canExportList'],
                },
                navPerUnitTab: {
                    keyName: 'navPerUnitTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_NAV_PER_UNIT_TAB,
                    actions: ['canViewNavPerUnitList', 'canViewDetails', 'canExportList'],
                },
                distributionTab: {
                    keyName: 'distributionTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DISTRIBUTION_TAB,
                    actions: ['canViewHistoryList', 'canViewDetails', 'canExportList'],
                },
                documentsTab: {
                    keyName: 'documentsTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DOCUMENTS_TAB,
                    actions: [
                        'canViewFundDocumentsList',
                        'canPreviewProspectus',
                        'canPreviewHighlight',
                        'canPreviewAnnual',
                        'canPreviewMonthly',
                        'canExportList',
                    ],
                },
            },
        },
    },
    uploads: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_FUND_UPLOADS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_FUND_UPLOADS_SUB,
        data: {
            maker: {
                masterFundTab: {
                    keyName: 'masterFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MASTER_FUND_TAB,
                    actions: ['canViewReRoutedList', 'canPreviewMasterFund', 'canExportList', 'canImportMasterFund'],
                },
                navPerUnitTab: {
                    keyName: 'navPerUnitTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_NAV_PER_UNIT_TAB,
                    actions: ['canViewRejectedList', 'canPreviewNav', 'canExportList', 'canImportNav'],
                },
                distributionTab: {
                    keyName: 'distributionTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DISTRIBUTION_TAB,
                    actions: ['canViewHistoryList', 'canPreviewDistribution', 'canExportList', 'canImportDistribution'],
                },
                documentsTab: {
                    keyName: 'documentsTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DOCUMENTS_TAB,
                    actions: ['canViewHistoryList', 'canViewDocuments', 'canExportList', 'canBulkUpload'],
                },
            },
            checker: {
                masterFundTab: {
                    keyName: 'masterFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MASTER_FUND_TAB,
                    actions: ['canViewReRoutedList', 'canPreviewMasterFund', 'canExportList'],
                    reviewApproval: ['canApproveMasterFund'],
                },
                navPerUnitTab: {
                    keyName: 'navPerUnitTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_NAV_PER_UNIT_TAB,
                    actions: ['canViewRejectedList', 'canPreviewNav', 'canExportList'],
                    reviewApproval: ['canApproveNav'],
                },
                distributionTab: {
                    keyName: 'distributionTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DISTRIBUTION_TAB,
                    actions: ['canViewHistoryList', 'canPreviewDistribution', 'canExportList'],
                    reviewApproval: ['canApproveDistribution'],
                },
                documentsTab: {
                    keyName: 'documentsTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DOCUMENTS_TAB,
                    actions: ['canViewHistoryList', 'canViewDocuments', 'canExportList'],
                    reviewApproval: ['canApproveBulkUpload'],
                },
            },
            autoAuthorizer: {
                masterFundTab: {
                    keyName: 'masterFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MASTER_FUND_TAB,
                    actions: ['canViewReRoutedList', 'canPreviewMasterFund', 'canExportList', 'canImportMasterFund'],
                    reviewApproval: ['canApproveMasterFund'],
                },
                navPerUnitTab: {
                    keyName: 'navPerUnitTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_NAV_PER_UNIT_TAB,
                    actions: ['canViewRejectedList', 'canPreviewNav', 'canExportList', 'canImportNav'],
                    reviewApproval: ['canApproveNav'],
                },
                distributionTab: {
                    keyName: 'distributionTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DISTRIBUTION_TAB,
                    actions: ['canViewHistoryList', 'canPreviewDistribution', 'canExportList', 'canImportDistribution'],
                    reviewApproval: ['canApproveDistribution'],
                },
                documentsTab: {
                    keyName: 'documentsTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DOCUMENTS_TAB,
                    actions: ['canViewHistoryList', 'canViewDocuments', 'canExportList', 'canBulkUpload'],
                    reviewApproval: ['canApproveBulkUpload'],
                },
            },
        },
    },
    operationDashboard: {
        header: SYSTEM_ADMIN.ADD_ROLE.LABEL_OPERATION,
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OPERATION_DASHBOARD,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_OPERATION_DASHBOARD_SUB,
        data: {
            maker: {
                overview: {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: ['canViewOverview'],
                },
                branchVerifiedTab: {
                    keyName: 'branchVerifiedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_VERIFIED_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canReRoute', 'canReject'],
                },
                moneySightedFundTab: {
                    keyName: 'moneySightedFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canReRoute', 'canReject'],
                },
                epfTab: {
                    keyName: 'epfTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_EPF_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canReroute', 'canReject'],
                },
                ddaTab: {
                    keyName: 'ddaTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DDA_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canReRoute', 'canReject'],
                },
                financeReRoutedTab: {
                    keyName: 'financeReRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE_REROUTED_TAB,
                    actions: [
                        'canViewTransactionList',
                        'canViewDetails',
                        'canExportList',
                        'canFreezeTransaction',
                        'canUnfreezeTransaction',
                    ],
                },
            },
            checker: {
                overview: {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: ['canViewOverview'],
                },
                branchVerifiedTab: {
                    keyName: 'branchVerifiedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_VERIFIED_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList'],
                    reviewApproval: ['canApproveReRoute', 'canApproveReject'],
                },
                moneySightedFundTab: {
                    keyName: 'moneySightedFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList'],
                    reviewApproval: ['canApproveReRoute', 'canApproveReject'],
                },
                epfTab: {
                    keyName: 'epfTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_EPF_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList'],
                    reviewApproval: ['canApproveReRoute', 'canApproveReject'],
                },
                ddaTab: {
                    keyName: 'ddaTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DDA_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList'],
                    reviewApproval: ['canApproveReRoute', 'canApproveReject'],
                },
                financeReRoutedTab: {
                    keyName: 'financeReRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE_REROUTED_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList'],
                    reviewApproval: ['canApproveFreeze', 'canApproveUnFreeze'],
                },
            },
            autoAuthorizer: {
                overview: {
                    keyName: 'overview',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OVERVIEW,
                    actions: ['canViewOverview'],
                },
                branchVerifiedTab: {
                    keyName: 'branchVerifiedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_BRANCH_VERIFIED_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canReRoute', 'canReject'],
                    reviewApproval: ['canApproveReRoute', 'canApproveReject'],
                },
                moneySightedFundTab: {
                    keyName: 'moneySightedFundTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_MONEY_SIGHTED_FUND_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canReRoute', 'canReject'],
                    reviewApproval: ['canApproveReRoute', 'canApproveReject'],
                },
                epfTab: {
                    keyName: 'epfTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_EPF_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canReRoute', 'canReject'],
                    reviewApproval: ['canApproveReRoute', 'canApproveReject'],
                },
                ddaTab: {
                    keyName: 'ddaTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_DDA_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canReRoute', 'canReject'],
                    reviewApproval: ['canApproveReRoute', 'canApproveReject'],
                },
                financeReRoutedTab: {
                    keyName: 'financeReRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_FINANCE_REROUTED_TAB,
                    actions: [
                        'canViewTransactionList',
                        'canViewDetails',
                        'canExportList',
                        'canFreezeTransaction',
                        'canUnfreezeTransaction',
                    ],
                    reviewApproval: ['canApproveFreeze', 'canApproveUnFreeze'],
                },
            },
        },
    },
    operationCases: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OPERATION_CASES,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_OPERATION_CASES_SUB,
        data: {
            general: {
                reRoutedTab: {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: ['canViewReRoutedList', 'canViewDetails', 'canExportList'],
                    reviewApproval: ['canApproveDueDateExtension'],
                },
                rejectedTab: {
                    keyName: 'rejectedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECTED_TAB,
                    actions: ['canViewRejectedList', 'canViewDetails', 'canExportList'],
                },
                historyTab: {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: ['canViewHistoryList', 'canViewDetails', 'canExportList'],
                },
            },
        },
    },
};
