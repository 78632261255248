import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FlexedDiv } from '../components';
import { subTitleStyle } from '../styles';

interface ITransactionNumberColumnItem {
    transactionNumber: string;
    orderNo: string;
    new?: boolean;
}

export const TransactionNumberColumnItem = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
    const transaction: ITransactionNumberColumnItem = { transactionNumber: '', orderNo: '', new: false };
    itemKey.map((item) => {
        const iKey = item.key;
        if (iKey === 'transactionRef') {
            transaction.transactionNumber = data[iKey];
        } else if (iKey === 'orderNo') {
            transaction.orderNo = data[iKey];
        }
    });
    return (
        <Fragment>
            <FlexedDiv direction="column" style={{ position: 'relative' }}>
                <div>
                    {transaction.orderNo !== null ? (
                        <span style={{ fontWeight: isSortedColumn === 'transactionRef' ? 700 : 'normal' }}>
                            {transaction.orderNo}
                        </span>
                    ) : (
                        <span>_</span>
                    )}
                    {data.isSeen ? null : <Badge />}
                    {transaction.transactionNumber !== null && (
                        <>
                            <div style={{ ...subTitleStyle, fontSize: '0.875rem' }}>
                                {transaction.transactionNumber}
                            </div>
                        </>
                    )}
                </div>
            </FlexedDiv>
        </Fragment>
    );
};

const Badge = styled.div`
    width: 0.5rem;
    height: 0.5rem;
    background: #c61230;
    border-radius: 50%;
    padding-left: 0.25rem;
    padding-bottom: 0.5rem !important;
    display: inline-block;
    position: absolute;
    left: 100%;
`;
