import React, { Fragment } from 'react';
import styled from 'styled-components';

import { FlexedDiv } from '../FlexDiv';

export const TextArea = ({
    disabled,
    maxLength,
    label,
    handleInput,
    name,
    style,
    value,
    testId,
    placeHolder,
}: ITextAreaProps): JSX.Element => {
    return (
        <Fragment>
            <FlexedDiv direction="column" style={{ ...style }}>
                {label && <AreaLabel>{label}</AreaLabel>}
                <InputArea
                    disabled={disabled}
                    name={name}
                    maxLength={maxLength}
                    onChange={handleInput}
                    value={value}
                    style={{ backgroundColor: disabled ? 'rgba(239,239,239,0.3)' : '' }}
                    id={testId}
                    data-testid={testId}
                    placeholder={placeHolder}
                />
                {maxLength ? (
                    <CountLabel data-testid="countLabel">{`${value.toString().length}/${maxLength}`}</CountLabel>
                ) : null}
            </FlexedDiv>
        </Fragment>
    );
};

const AreaLabel = styled.span`
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: #333333;
`;
const InputArea = styled.textarea`
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    border: 1px solid #cecece;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #333333;
    min-height: 6rem;
    resize: none;
    &:disabled {
        pointer-events: none;
    }
    &:focus {
        outline: none;
        box-shadow: 0px 2px 8px rgba(0, 32, 67, 0.06), 0px 0px 4px rgba(0, 32, 67, 0.02);
        border: 2px solid #002043;
    }
    &:hover {
        border: 1px solid #66798e;
        &:focus {
            outline: none;
            box-shadow: 0px 2px 8px rgba(0, 32, 67, 0.06), 0px 0px 4px rgba(0, 32, 67, 0.02);
            border: 2px solid #002043;
        }
    }
`;
const CountLabel = styled.span`
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: right;
    letter-spacing: 0.2px;
    color: #4d4d4d;
    margin-top: 0.5rem;
`;
