/**
 * Converts a given string to Title Case
 * @param string :string to covert to Title Case
 */
export const toTitleCase = (string: string): string => {
    return string
        ? string
              .toLowerCase()
              .split(' ')
              .map(function (word) {
                  return word.charAt(0).toUpperCase() + word.slice(1);
              })
              .join(' ')
        : '';
};
