import moment from 'moment';
import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { FlexedDiv } from '../components';
import { TableBadge } from '../components/atoms/TableBadge';
import { subTitleStyle } from '../styles';
interface IDateColumnItem extends ITableCustomItem {
    tableBadge?: ITableBadge;
    boldHeading?: boolean;
}
export const DateColumnItem = ({ data, itemKey, isSortedColumn, tableBadge }: IDateColumnItem): JSX.Element => {
    let day = '-';
    let time = '';

    itemKey.map(({ key }) => {
        if (data[key] && data[key].length > 10) {
            day = data[key] !== null ? moment(data[key], 'x').format('DD/MM/YYYY') : '-';
            time = data[key] !== null ? moment(data[key], 'x').format('hh:mm A') : '';
        } else {
            day = data[key] !== null ? moment.unix(data[key]).format('DD/MM/YYYY ') : '-';
            time = data[key] !== null ? moment.unix(data[key]).format('hh:mm A') : '';
        }
    });
    let highlightDay = false;
    itemKey.map(({ key }) => {
        if (key === 'startDate') {
            isSortedColumn === 'fundStartDate' ? (highlightDay = true) : (highlightDay = false);
        } else {
            key === isSortedColumn ? (highlightDay = true) : (highlightDay = false);
        }
    });

    return (
        <Fragment>
            <FlexedDiv>
                <Shadow checked={tableBadge !== undefined} />
                <FlexedDiv direction="column" padding={tableBadge !== undefined ? '10px 8px' : '10px 0px'}>
                    {tableBadge !== undefined ? (
                        <MarginBottom4px checked={true}>
                            <TableBadge color={tableBadge.color} text={tableBadge.text} filled={tableBadge.filled} />
                        </MarginBottom4px>
                    ) : null}
                    <Day isBold={highlightDay}>{day}</Day>
                    <div style={{ ...subTitleStyle }}>{time}</div>
                </FlexedDiv>
            </FlexedDiv>
        </Fragment>
    );
};

const Day = styled.div`
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: -0.2px;
    color: #002043;
    font-weight: ${(props: IOnPropStyles) => (props.isBold ? 700 : 'normal')};
`;

const MarginBottom4px = styled.div<IOnPropStyles>`
    margin-bottom: 0.25rem;
`;
const Shadow = styled.div<IOnPropStyles>`
    ${(props: IOnPropStyles) =>
        props.checked &&
        css`
            background: #171717;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            width: 8px;
            height: 100%;
            position: absolute;
            left: 0;
        `}
`;
