import React from 'react';
import { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { IcoMoon } from '../../../icons';

interface IChipsPillProps {
    disabled?: boolean;
    selected?: boolean;
}

const MultiSelectChips = ({ handleChip, item, testId, disable, icon, iconColor }: IChipsProps): JSX.Element => {
    const iconName = icon !== undefined ? icon : 'check';
    const currentColor = iconColor ?? '#002043';
    return (
        <Fragment>
            <MultiSelectChipsStyled
                disabled={disable}
                onClick={() => handleChip(item)}
                data-testid={testId}
                id={testId}
                selected={item.active}
            >
                {item.active === true ? <IcoMoon name={iconName} size="1rem" color={currentColor} /> : null}
                {item.title}
            </MultiSelectChipsStyled>
        </Fragment>
    );
};

const MultiSelectChipsStyled = styled.button<IChipsPillProps>`
    /* position: absolute; */
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 0.625rem;
    width: auto;
    height: 2rem;
    left: 1rem;
    top: 1rem;
    border-radius: 1.5rem;
    border: 1px solid #002043;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-right: 0.5rem;

    background-color: transparent;
    color: #002043;

    /* State=Hover */
    &:hover {
        background: rgba(198, 18, 48, 0.2); /* Primary/Red */
        border-color: #c61230;
        color: #171717;
    }
    /* State=Selected */
    background: ${(props) => (props.selected ? 'rgba(198, 18, 48, 0.2)' : 'transparent')};
    border-color: ${(props) => (props.selected ? '#C61230' : '#002043')};

    color: ${(props) => (props.selected ? '#171717' : '#002043')};
    pointer-events: ${(props) => (props.selected ? 'none' : 'auto')};

    /* State=Disabled */
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

    /* State=Selected Disabled */
    ${(props: IChipsPillProps) =>
        props.selected &&
        props.disabled &&
        css`
            background: rgba(198, 18, 48, 0.2)
            border-color: #c61230;
            opacity : 0.6;
            color: #171717;
            pointer-events: auto;
        `}
`;
export default MultiSelectChips;
