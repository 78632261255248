import moment from 'moment';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FlexedDiv } from '../components';
import { subTitleStyle } from '../styles';

export const Aging = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
    const day = [''];
    const aging = [''];
    itemKey.map(({ key }) => {
        day.push(data[key] !== null && key === 'targetDate' ? moment(data[key], 'x').format('DD/MM/YYYY ') : '');
        aging.push(data[key] !== null && key === 'aging' ? data[key] : '');
    });
    const date = day.join('');
    const age = aging.join('');
    return (
        <Fragment>
            <FlexedDiv>
                <FlexedDiv direction="column">
                    <Day isBold={isSortedColumn === 'targetDate'}>{date}</Day>
                    <div style={{ ...subTitleStyle }}>{age}</div>
                </FlexedDiv>
            </FlexedDiv>
        </Fragment>
    );
};

const Day = styled.div`
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: -0.2px;
    color: #002043;
    font-weight: ${(props: IOnPropStyles) => (props.isBold ? 700 : 'normal')};
`;
