import gql from 'graphql-tag';

export const pendingSubmissionBranchQuery = gql`
    query pendingSubmissionDashboard($input: BranchDashboardInput) {
        pendingSubmissionDashboard(input: $input) {
            data {
                result {
                    filters {
                        transactionType
                        accountType
                    }
                    page
                    pages
                    totalResultCount
                    statuses {
                        tab
                        count
                    }
                    groupedTransactions {
                        grouping {
                            type
                            groupingData {
                                principalName
                                principalNumber
                                orderNo
                                jointName
                                jointNumber
                                sameBranch: viewable
                            }
                            transactionData {
                                data {
                                    createdOn
                                    lastUpdated
                                    orderNo
                                    accountType
                                    clientName
                                    clientIdNum
                                    transactionType
                                    fundType
                                    paymentMethod
                                    totalInvestment {
                                        fundCurrency
                                        investmentAmount
                                    }
                                    totalUnits
                                    agentName
                                    agentCode
                                    status
                                    aging
                                }
                            }
                        }
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
