/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useReducer, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { adviserAccessDashboard } from '../../../_graphql/queries/systemadmin/adviserDashboard';
import { LABEL } from '../../../constants';
import { IDropdownWithKey } from '../../../pages';
import AdviserAccessContext from '.';
import AuthContext from '../../AuthContext';
import ErrorHandlingContext from '../../ErrorHandling/ErrorHandlingContext';

interface IAdviserAccessProviderProps {
    children: React.ReactNode;
}

export const AdviserAccessProvider: React.FC<IAdviserAccessProviderProps> = ({
    children,
}: IAdviserAccessProviderProps) => {
    const _initStates: IAdviserAccessStates = {
        createdOn: 'createdOn',
        dateRange: '',
        homeBranch: [],
        adviserChannel: [],
        agency: [],
        status: '',
        OMNI: '',
        createdOnDropdown: { value: 'Created On', keyName: 'createdOn' },
        sortData: [{ column: 'agentName', value: 'ascending' }],
        searchbarFilterTags: [],
        shouldUpdate: false,
        statusFilter: LABEL.agentStatus,
    };

    // Dashboard functionality states
    const [tab, setTab] = useState(0);
    const [disableResultLimit, setDisableResultLimit] = useState<boolean>(false);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [searchInput, setSearchInput] = useState({ value: '', column: 'all' });
    const [counter, setCounter] = useState<Array<number>>([]);
    const [label, setLabel] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [resultLimit, setResultLimit] = useState(10);

    // Dashboard tab data states
    const [allAdvisers, setAllAdvisers] = useState<ITableData[]>([]);
    // UI states
    const [loading, setLoading] = useState<boolean>(false);

    const { userLoginContext } = useContext(AuthContext);
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    const handleTempFilter = (tempState: IAdviserAccessStates, action: TAdviserAccessInitState) => {
        const _tempFilter = { ...tempState };
        switch (action.type) {
            case 'createdOn':
            case 'dateRange':
            case 'status':
            case 'OMNI':
            case 'statusFilter':
                _tempFilter[action.type] = action.payload as string;
                break;
            case 'homeBranch':
            case 'adviserChannel':
            case 'agency':
                _tempFilter[action.type] = action.payload as unknown as string[];
                break;
            case 'createdOnDropdown':
                _tempFilter[action.type] = action.payload as IDropdownWithKey;
                break;
            case 'sortData':
                _tempFilter[action.type] = action.payload as ISort_FilterInput;
                break;
            case 'searchbarFilterTags':
                _tempFilter[action.type] = action.payload as ISelectValue[];
                break;
            case 'shouldUpdate':
                _tempFilter[action.type] = action.payload as boolean;
                break;
            case 'reset':
                (_tempFilter.createdOn = 'createdOn'),
                    (_tempFilter.dateRange = ''),
                    (_tempFilter.homeBranch = []),
                    (_tempFilter.adviserChannel = []),
                    (_tempFilter.agency = []),
                    (_tempFilter.status = ''),
                    (_tempFilter.OMNI = ''),
                    (_tempFilter.createdOnDropdown = { value: 'Created On', keyName: 'createdOn' }),
                    (_tempFilter.sortData = [{ column: 'agentName', value: 'ascending' }]),
                    (_tempFilter.searchbarFilterTags = []);
                break;
        }
        return _tempFilter;
    };

    const [tempState, dispatch] = useReducer(handleTempFilter, {
        createdOn: _initStates.createdOn,
        dateRange: _initStates.dateRange,
        homeBranch: _initStates.homeBranch,
        adviserChannel: _initStates.adviserChannel,
        agency: _initStates.agency,
        status: _initStates.status,
        OMNI: _initStates.OMNI,
        createdOnDropdown: _initStates.createdOnDropdown,
        sortData: _initStates.sortData,
        searchbarFilterTags: _initStates.searchbarFilterTags,
        shouldUpdate: _initStates.shouldUpdate,
        statusFilter: _initStates.statusFilter,
    });

    //fn to handle loading
    const loadingHandler = () => {
        setLoading((prev) => !prev);
    };

    const handleInput = (column: string, value: string) => {
        if (value === '') {
            return undefined;
        }
        return { column, value };
    };

    const getData = () => {
        const filterInput = [];
        const dateFilter: IColumnValue = {
            column: tempState.createdOn,
            value: tempState.dateRange,
        };
        const agencyStatusInput = handleInput('status', tempState.status);
        const OMNIInput = handleInput('omni', tempState.OMNI);
        tempState.homeBranch.forEach((value: string) => {
            if (value) {
                filterInput.push({ column: 'branch', value });
            }
        });
        tempState.adviserChannel.forEach((value: string) => {
            if (value) {
                filterInput.push({ column: 'channel', value });
            }
        });
        tempState.agency.forEach((value: string) => {
            if (value) {
                filterInput.push({ column: 'agency', value });
            }
        });
        if (dateFilter) filterInput.push(dateFilter);
        if (agencyStatusInput) filterInput.push(agencyStatusInput);
        if (OMNIInput) filterInput.push(OMNIInput);
        return filterInput;
    };
    //call query to get all adviser
    const getAllAdvisers = async () => {
        const filterInput = getData();
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(adviserAccessDashboard, {
                    input: {
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: tempState.sortData,
                        filter: filterInput,
                    },
                }),
                idTokenHeader,
            );

            const { result } = await response.data.systemAdminAdviserDashboard.data;

            setCounter([result.adviserCount]);

            const allAdvisers: ITableData[] = [];
            if (response.data.systemAdminAdviserDashboard.data !== null) {
                result.advisers.map((location: any) => {
                    location.advisers.map((adviser: ITableData) => {
                        const adviserObj = { ...adviser, branch: location.branch };
                        allAdvisers.push(adviserObj);
                    });
                });

                setAllAdvisers(allAdvisers);
                setDisableResultLimit(allAdvisers.length === 0);
                if (currentPage > result.pages) {
                    setCurrentPage(1);
                }
                setMaxPages(result.pages);
            } else {
                throw response.data.systemAdminAdviserDashboard.error;
            }
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Dashboard Data',
                    testId: 'adviseraccess-dashboard-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    const ProviderValue: IAdviserAccessContext = {
        allAdvisers,
        counter,
        disableResultLimit,
        getAllAdvisers,
        label,
        loading,
        maxPages,
        searchInput,
        setCounter,
        setLabel,
        setSearchInput,
        setTab,
        loadingHandler,
        tab,
        tempState,
        dispatch,
        currentPage,
        setCurrentPage,
        resultLimit,
        setResultLimit,
    };
    return <AdviserAccessContext.Provider value={ProviderValue}>{children}</AdviserAccessContext.Provider>;
};
