import React, { ComponentProps } from 'react';
import styled from 'styled-components';

type divProps = ComponentProps<'div'>;
interface DatePickerCalendarProps extends divProps {
    onClose?: () => void;
    popupX?: number;
    popupY?: number;
    popupMargin?: string;
    testId?: string;
    rangeMode?: boolean;
}
type DatePickerCalendarInnerProps = {
    rangeMode: boolean;
};
export const DatePickerCalendar = (props: DatePickerCalendarProps): JSX.Element => {
    const { popupY, rangeMode } = props;
    const Ypos = popupY ? popupY : 0;
    const Y = `${42 + Ypos}px`;
    return (
        <DatePickerCalendarElm
            style={{
                top: Y,
            }}
        >
            <DatePickerPointer />
            <DatePickerCalendarInner id="date-picker-calendar-inner" rangeMode={rangeMode ? rangeMode : false}>
                {props.children}
            </DatePickerCalendarInner>
        </DatePickerCalendarElm>
    );
};
const DatePickerCalendarElm = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    position: absolute;
    flex-direction: column;
`;
const DatePickerPointer = styled.div`
    width: 0;
    height: 0;
    box-sizing: border-box;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
`;

const DatePickerCalendarInner = styled.div<DatePickerCalendarInnerProps>`
    display: flex;
    width: ${(props) => (props.rangeMode ? '100%' : '360px')};
    background-color: #fff;
    border-radius: 16px;
    z-index: 999;
    box-shadow: -2px 2px 8px 0px #0020430f;
`;
