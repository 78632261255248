/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, graphqlOperation } from 'aws-amplify';
import { getBranchList } from '../_graphql/queries/common/getBranchList';
type IBranchDropdownType = { id: string; value: string };
let branches: IBranchDropdownType[] = [];
let name = '';
export const getBranchName = async (
    branchId: string,
    idTokenHeader?: { Authorization: string; strategy: string },
): Promise<string> => {
    const x = await getAllBranches(branchId, idTokenHeader);

    return x;
};

const getAllBranches = async (
    branchId: string,
    idTokenHeader?: { Authorization: string; strategy: string },
): Promise<string> => {
    try {
        const response: any = await API.graphql(graphqlOperation(getBranchList), idTokenHeader);
        const resultCheck = response.data.getDropDownList;
        if (resultCheck.error !== null) throw resultCheck.error;
        branches = resultCheck.data.result.branches;
        branches.map(({ id, value }) => {
            id === branchId ? (name = value) : null;
        });
    } catch (error) {}
    return name;
};
