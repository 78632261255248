/**
 * @createTransactionsRef @mutation - Branch Dashboard
 * @description:  When hardcopy received, transaction numbers (transaction_ref) are generated. Transactions are split by cut off time and category
 */

export const createTransactionsRef = /* GraphQL */ `
    mutation createTransactionsRef($input: CreateTransactionsRefInput) {
        createTransactionsRef(input: $input) {
            data {
                result {
                    status
                    message
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
