import React from 'react';
import styled from 'styled-components';
export const TableBadge = ({ color, text, filled }: ITableBadge): JSX.Element => {
    return (
        <StyledBadge color={color} filled={filled} data-testid="style-badge">
            {text}
        </StyledBadge>
    );
};
type tableBadgeType = {
    color: string;
    filled?: boolean;
};
const StyledBadge = styled.div<tableBadgeType>`
    color: ${(props) => props.color};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0.125rem 0.25rem;
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    background: ${(props) => (props.filled ? '#F4F4F4' : 'inherit')};
    box-shadow: ${(props) => `inset 0px 0px 0px 1px ${props.color}`};
    height: 1rem;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    max-width: fit-content;
`;
