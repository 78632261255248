/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { RouteComponentProps } from 'react-router-dom';
import { userLogin } from '../_graphql/mutations/userLogin';
import AuthContext, { IUserLoginContext } from '../context/AuthContext';
import { RouteLoader } from '../components';

import WebSocketContext from '../context/WebSocketContext';

export interface IMatchParams {
    id_token: string;
    refresh_token: string;
    user: string;
}

export const CallBackRedirect: React.FC<RouteComponentProps<IMatchParams>> = ({
    match,
}: RouteComponentProps<IMatchParams>) => {
    const { id_token, refresh_token, user } = match.params;

    const { setPath, setUrl } = useContext(WebSocketContext);
    const { roleBasedRedirect, authDispatch } = useContext(AuthContext);

    const userLoginFn = async () => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(userLogin, {
                    input: { username: user, password: '' },
                }),
                { Authorization: id_token, strategy: 'JWT' },
            );

            if (response.data.userLogin.error !== null) throw response.data.userLogin.error;

            const { branchName, email, inboxCount, name, permission, role, sessionToken, username } =
                response.data.userLogin.data.result;
            // Context implementation
            const _authContext: IUserLoginContext = {
                role: role,
                username: name,
                loginId: username,
                email: email,
                sessionToken: JSON.stringify(sessionToken),
                department: '',
                permission: permission,
                accessLevel: '',
                inboxCount: inboxCount,
                branchName: branchName,
                expirationDate: '',
                isAuthenticated: true,
                idToken: id_token,
                refreshToken: refresh_token,
            };

            // sessionStorage.setItem('session', JSON.stringify(_authContext));
            localStorage.setItem('session', JSON.stringify(_authContext));
            authDispatch({ type: 'saveApiResponse', payload: _authContext });
            authDispatch({ type: 'saveToken', payload: _authContext.sessionToken });
            authDispatch({ type: 'saveIdToken', payload: _authContext.idToken?.toString() });
            const WEBSOCKET_URL = 'jesmx0e0o4.execute-api.ap-southeast-1.amazonaws.com'; //SIT
            // const WEBSOCKET_URL = 'jyjtd6ddf5.execute-api.ap-southeast-1.amazonaws.com'; //UAT
            // const WEBSOCKET_URL = '2jpl3c2dt8.execute-api.ap-southeast-1.amazonaws.com'; //Dev
            const stage = 'dev'; //SIT
            // const stage = 'uat'; //UAT
            // const opt = {
            //     host: WEBSOCKET_URL,
            //     region: 'ap-southeast-1',
            //     path: `/${stage}?username=${username}&x-api-key=abc`,
            //     signQuery: true,
            // };
            const path = `/${stage}?username=${username}&x-api-key=abc`; //SIT
            // sessionStorage.setItem('url', WEBSOCKET_URL);
            // sessionStorage.setItem('path', path);
            localStorage.setItem('url', WEBSOCKET_URL);
            localStorage.setItem('path', path);
            setUrl(WEBSOCKET_URL);
            setPath(path);
            roleBasedRedirect(permission);
        } catch (error) {
            const _error = error as any;
            console.log('failed to run user login', _error.message);
        }
    };

    useEffect(() => {
        userLoginFn();
    }, []);
    return (
        <div>
            <RouteLoader />
        </div>
    );
};
