import React from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';

interface StandardTableProps {
    columns: IStandardColumns[];
    data: ITableCellData[];
}

type TdCellProps = {
    value: number;
};

export const StandardTable: React.FC<StandardTableProps> = ({ data, columns }: StandardTableProps) => {
    return (
        <StyledTable>
            <StyledThead data-testid="thread">
                <tr>
                    {columns.map((th: IStandardColumns) => (
                        <Th key={th.thTitle}>{th.thTitle}</Th>
                    ))}
                </tr>
            </StyledThead>
            <StyledTbody data-testid="standard-row">
                {data.map((cell, index) => {
                    return (
                        <Tr key={index}>
                            {Object.keys(cell).map((key) => {
                                // _tempId = getTdElement(cell, key);
                                if (key === 'closingDate') {
                                    return (
                                        <Td key={key} value={cell[key]}>
                                            {cell[key] !== null && moment(cell[key], 'x').format('DD/MM/YYYY')}
                                        </Td>
                                    );
                                } else if (key === 'change' || key === 'changePercentage') {
                                    return (
                                        <TdColor key={key} value={cell[key]}>
                                            {cell[key] !== null ? cell[key] : '-'}
                                        </TdColor>
                                    );
                                } else {
                                    return (
                                        <Td key={key} value={cell[key]}>
                                            {cell[key] !== null ? cell[key] : '-'}
                                        </Td>
                                    );
                                }
                            })}
                        </Tr>
                    );
                })}
            </StyledTbody>
        </StyledTable>
    );
};
const StyledTable = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
`;

const StyledThead = styled.tbody`
    width: 100%;
`;

const Th = styled.th`
    width: auto;
    padding: 0.5rem;
    font-size: 0.6rem;
    font-weight: 700;
`;

const StyledTbody = styled.tbody`
    width: 100%;
`;
const Tr = styled.tr`
    width: auto;
`;

const Td = styled.td<TdCellProps>`
    width: auto;
    padding: 0.5rem;
    font-size: 0.875rem;
    background-color: #fff;
    border: 1px solid #eaebee;
`;
const TdColor = styled(Td)<TdCellProps>`
    ${(props) =>
        !isNaN(props.value) &&
        props.value > 0 &&
        css`
            color: #2ecc82;
        `}
    ${(props) =>
        !isNaN(props.value) &&
        props.value < 0 &&
        css`
            color: #e84c3d;
        `}
`;
