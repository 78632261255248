import gql from 'graphql-tag';

/**
 * @userLogin mutation for the user login HTTP POST Request
 */

export const userLogin = /* GraphQL */ gql`
    mutation userLogin($input: LoginInput) {
        userLogin(input: $input) {
            data {
                result {
                    identityId
                    accessKeyId
                    sessionToken
                    secretAccessKey
                    name
                    email
                    role
                    username
                    branchName
                    permission
                    inboxCount
                    branchName
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
