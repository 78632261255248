import React, { Fragment } from 'react';
import FlexSpacer, { CustomSpacer, FlexedDiv } from '../..';
import { Fs12RegSecNavyBlueLh16 } from '../../../constants';
import styled from 'styled-components';
import { IcoMoon } from '../../../icons';

interface IDownloadProgressProps {
    progressWidth: number;
    reportType: string;
    reportName?: string;
    testId?: string;
}
type ProgressProps = {
    width: number;
};

const DownloadProgress: React.FC<IDownloadProgressProps> = ({
    progressWidth,
    reportType,
    reportName,
    testId,
}: IDownloadProgressProps) => {
    return (
        <Fragment>
            <FlexedDiv direction="row" justifyContent="center" test-id={testId}>
                <FlexedDiv justifyContent="center" direction="column">
                    <Subtitle>
                        Please do not close this page or navigate away. Download will occur automatically.
                    </Subtitle>
                </FlexedDiv>
            </FlexedDiv>

            <CustomSpacer space="2rem" />
            <DocumentWrapper data-testid="docs-wrapper">
                <DocumentInner>
                    <FlexedDiv direction="row">
                        <FlexedDiv direction="column" style={{ justifyContent: 'center' }}>
                            <IcoMoon name="report-navIcon" size="2rem" />
                        </FlexedDiv>
                        <FlexedDiv direction="column" style={{ marginLeft: '20px', width: '325px' }}>
                            <Title>{reportType}</Title>
                            <FlexedDiv direction="row">
                                <FlexedDiv direction="column" style={{ width: '90%' }}>
                                    <Fs12RegSecNavyBlueLh16 style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                        {reportName}
                                    </Fs12RegSecNavyBlueLh16>
                                </FlexedDiv>
                                <FlexSpacer />
                                <FlexedDiv direction="column">
                                    <Fs12RegSecNavyBlueLh16>{`${Math.trunc(progressWidth)}%`}</Fs12RegSecNavyBlueLh16>
                                </FlexedDiv>
                            </FlexedDiv>

                            <ProgressBarStyles id="progress">
                                <ProgressBar>
                                    <Progress width={progressWidth}></Progress>
                                </ProgressBar>
                            </ProgressBarStyles>
                        </FlexedDiv>
                    </FlexedDiv>
                </DocumentInner>
            </DocumentWrapper>
        </Fragment>
    );
};
export default DownloadProgress;

const ProgressBarStyles = styled.div`
    width: 100%;
    max-width: 376px;
    overflow: hidden;
    margin-top: 12px;
    border-radius: 10px;
`;
const ProgressBar = styled.div`
    background-color: #eaebee;
`;
const Progress = styled.div<ProgressProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 10px;
    border-radius: 10px;
    background-color: #2ecc82;
    transition: width 0.3s ease;
    width: ${(props) => props.width}%;
`;

const Subtitle = styled.div`
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #333333;
`;

const DocumentWrapper = styled.div`
    padding: 16px 24px;
    background-color: #fff;
    border-radius: 8px;
    max-width: 680px;
    box-shadow: 2px 2px 8px rgba(0, 32, 67, 0.04), 6px 6px 16px rgba(0, 32, 67, 0.04);
`;

const DocumentInner = styled.div`
    width: 100%;
`;
const Title = styled.div`
    font-family: Nunito Sans;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: -0.05px;
`;
