import React from 'react';
import styled from 'styled-components';

interface ITooltipArrowProps extends ICustomTooltipStyleProps {
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    ref: React.RefObject<SVGSVGElement> | null;
}

export const TooltipArrow = (props: ITooltipArrowProps): JSX.Element => {
    return (
        <>
            <TooltipArrowWrapper
                width="34"
                height={props.light ? '10' : '7'}
                viewBox={`0 0 34 ${props.light ? '10' : '7'}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                placement={props.placement}
                light={props.light}
                onMouseOver={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
                ref={props.ref}
            >
                {props.light ? (
                    <>
                        <path
                            d="M19.6213 6.34129L23.2844 3.32457C24.7168 2.145 26.5146 1.5 28.3701 1.5L34 1.5V0.5L17 0.5L0 0.5V1.5L5.99412 1.5C7.9319 1.5 9.80382 2.20334 11.2622 3.47938L14.4444 6.26388C15.9184 7.55359 18.1094 7.58635 19.6213 6.34129Z"
                            fill="white"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.5152 5.52856C15.8845 6.82381 18.1155 6.82381 19.4848 5.52857L22.3133 2.85315C23.9092 1.34356 26.0681 0.5 28.3137 0.5H34V1.5H28.3137C26.3159 1.5 24.4051 2.25096 23.0004 3.57963L20.172 6.25505C18.4171 7.91499 15.5828 7.91498 13.828 6.25505L10.9996 3.57963C9.59489 2.25095 7.68411 1.5 5.68629 1.5H0V0.5H5.68629C7.93194 0.5 10.0908 1.34356 11.6867 2.85314L14.5152 5.52856Z"
                            fill="#0089EC"
                        />
                    </>
                ) : (
                    <path
                        d="M14.1716 5.17158L11.3431 2.34315C9.84286 0.842855 7.80802 0 5.68629 0H28.3137C26.192 0 24.1571 0.842852 22.6569 2.34314L19.8284 5.17157C18.2663 6.73367 15.7337 6.73367 14.1716 5.17158Z"
                        fill="#171717"
                    />
                )}
            </TooltipArrowWrapper>
            {props.light && <TooltipLightBorderRemover placement={props.placement} light={props.light} />}
        </>
    );
};

/**
 *
 * @param position - positon of the tool tip
 * @param light - is it light theme or dark
 * @param borderStyle - optional parameter true when the styles needed to return is for the tooltip border remover
 * @returns - string literal of css styles
 */
const getTooltipArrowWrapperPlacementStyle = (
    position: TTooltipPosition,
    light?: boolean,
    borderStyle?: boolean,
): string => {
    switch (position) {
        case 'Top':
            return `left:50%;
            ${!borderStyle ? `bottom:${light ? '-9px' : '-7px'};` : 'bottom:-1px;'}
            transform:translateX(-50%);`;

        case 'Bottom':
            return `left:50%;
            ${!borderStyle ? `top:${light ? '-10px' : '-7px'};` : 'top:-1px;'}
            transform:translateX(-50%) rotate(180deg);`;
        case 'Left':
            return `top:50%;
            ${!borderStyle ? `right:${light ? '-22px' : '-20px'};` : 'right:-1px;'}
            transform:translateY(-50%) ${!borderStyle ? 'rotate(270deg);' : ';'}
            ${!borderStyle ? '' : 'height:18px;'}
            `;
        case 'Right':
            return `top:50%;
            ${!borderStyle ? `left ${light ? '-22px' : '-20px'};` : 'left:-1px;'}
            transform:translateY(-50%) ${!borderStyle ? 'rotate(90deg);' : ';'}
            ${!borderStyle ? '' : 'height:18px;'}
            `;
        default:
            return `left:50%;
            ${!borderStyle ? `top:${light ? '-10px' : '-7px'};` : 'top:-1px;'}
            transform:translateX(-50%) rotate(180deg);`;
    }
};
const TooltipArrowWrapper = styled.svg<ICustomTooltipStyleProps>`
    position: absolute;
    ${(props) => getTooltipArrowWrapperPlacementStyle(props.placement ?? 'Bottom', props.light)}
`;
const TooltipLightBorderRemover = styled.div<ICustomTooltipStyleProps>`
    position: absolute;
    ${(props) => getTooltipArrowWrapperPlacementStyle(props.placement ?? 'Bottom', props.light, true)}
    border: 1.5px solid #FFFFFF;
`;
