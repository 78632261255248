import gql from 'graphql-tag';

export const transactionTrackingReport = /* GraphQL */ gql`
    mutation transactionTrackingReport($input: TransactionTrackingReportInput) {
        transactionTrackingReport(input: $input) {
            data {
                result {
                    message
                    status
                    path
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
