import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { IcoMoon } from '../../../icons';
import Container from '../Container';

export const Snackbar: FunctionComponent<ISnackbar> = ({
    onClose,
    open,
    autoHide,
    closable,
    timeOut,
    icon,
    message,
    description,
}: ISnackbar) => {
    useEffect(() => {
        if (open) {
            if (autoHide === true || autoHide === undefined) {
                timeOut !== undefined ? setTimeout(() => onClose(), timeOut * 1000) : setTimeout(() => onClose(), 5000);
            }
        }
    }, [open]);

    return (
        <Container visibility={open} testId="container-snackbar">
            <MessageContainer isOnFocus={open} data-testid="message_container">
                <div style={{ display: 'flex' }}>
                    {icon !== undefined ? (
                        <IconDiv data-testid="icon">
                            <IcoMoon {...icon} />
                        </IconDiv>
                    ) : null}
                    <MessageDiv data-testid="message">
                        <div>{message}</div>
                        {description && <DescriptionDiv>{description}</DescriptionDiv>}
                    </MessageDiv>
                </div>
                {closable || autoHide === false ? (
                    <CloseIcon data-testid="padding" isOnProp={description !== undefined} onClick={() => onClose()}>
                        <IcoMoon name="close" color="#0089EC" size="1rem" />
                    </CloseIcon>
                ) : null}
            </MessageContainer>
        </Container>
    );
};
const MessageContainer = styled.div<IOnPropStyles>`
    position: fixed;
    padding: 1rem;
    color: #171717;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 15.6px;
    display: flex;
    align-items: center;
    right: 3rem;
    top: 2rem;
    background: #171717;
    box-sizing: border-box;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 100;
    height: ${(props) => (props.isOnFocus ? '3.5rem' : '0px')};
    transition: height ease 0.5s;
`;
const DescriptionDiv = styled.div`
    color: #ffffff;
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
`;
const MessageDiv = styled.div`
    display: flex;
    margin-right: 1.5rem;
    flex-direction: column;
    color: #ffffff;
`;
const IconDiv = styled.div`
    height: 18px;
    margin-right: 0.5rem;
`;
const CloseIcon = styled.div<IOnPropStyles>`
    cursor: pointer;
    height: ${(props) => (props.isOnProp ? '1.5rem' : '1.25rem')};
`;
export default Snackbar;
