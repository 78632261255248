import gql from 'graphql-tag';

/**
 * @statusDropDown @query - Endpoint to get status
 * @description: Returns all statuses from dashboard
 */
export const statusDropDownQuery = /* GraphQL */ gql`
    query statusDropDown($input: StatusDropDownInput) {
        statusDropDown(input: $input) {
            data {
                result {
                    statusList
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
