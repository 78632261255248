import gql from 'graphql-tag';

/**
 * @createUser @mutation System admin Dashboard
 * @description mutation for creating new user
 */

export const createUser = gql`
    mutation CreateUser($input: UserAdminInput) {
        createUser(input: $input) {
            data {
                result {
                    status
                    message
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;

export default createUser;
