/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, ReactText, useContext, useReducer, useRef } from 'react';
import HQCEContext from './HQCEContext';
import { useComponentLoader } from '../../customHooks';
import { IColumnValue } from '../../pages/Advisers';
import { API, graphqlOperation } from 'aws-amplify';
import { allBranchQuery } from '../../_graphql/queries/hqCE/dashboard/allBranches/allBranch';
import { dueDateExtensionQuery } from '../../_graphql/queries/hqCE/dashboard/dueDateExtension/dueDateExtension';
import moment from 'moment';
import AuthContext from '../AuthContext';
import ErrorHandlingContext from '../ErrorHandling/ErrorHandlingContext';
import { IDropdownWithKey } from '../../pages/DashboardHQCE';
import { groupTransactionsByBranch } from './groupByBranch';
import { SearchOptionsHQCE } from '../../pages/DashboardHQCE/searchDropdown';

export interface HQCEProviderProps {
    children: React.ReactNode;
}

const HQCEProvider: React.FC<HQCEProviderProps> = ({ children }: HQCEProviderProps) => {
    const _initHQCEStates: IHQCEStates = {
        date: 'lastUpdated',
        state: [],
        product: [],
        funding: [],
        dateRange: '',
        searchbarFilterTags: [],
        account: '',
    };
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const [tab, setTab] = useState(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const mockOverView: ISummaryCardData[] = [
        {
            data: '0',
            title: 'Daily Branch',
            testId: 'daily-branch-transaction',
            dataLabel: 'Transaction(s)',
            dataIcon: 'transactions-overview',
            noClickIcon: true,
        },
        {
            title: 'Branch Rerouted',
            data: '0',
            testId: 'rerouted-transaction',
            dataLabel: 'Transaction(s)',
            dataIcon: 'transactions-overview',
            noClickIcon: true,
        },
        {
            data: '0',
            title: 'Pending Physical Document',
            dataLabel: 'Transaction(s)',
            testId: 'pending-hardcopy',
            dataIcon: 'transactions-overview',
            noClickIcon: true,
        },
        {
            data: '0',
            title: 'Due Date Extension',
            dataLabel: 'Request(s)',
            dataIcon: 'reverse-clock',
            testId: 'due-date-extensionreuest',
            cardId: 1,
        },
    ];
    const [targetDateRange, setTargetRange] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
    const [orderCounter, setOrderCounter] = useState<Array<number>>([1, 1]);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [resultLimit, setResultLimit] = useState<number>(10);
    const [disableResultLimit, setDisableResultLimit] = useState<boolean>(false);
    const [searchInput, setSearchInput] = useState({ value: '', column: SearchOptionsHQCE['branch'][0].value });
    const [overview, setOverview] = useState<ISummaryCardData[]>([...mockOverView]);
    const [offices, setOffices] = useState<ITableData[]>([]);
    const [sortData, setSortData] = useState<ISort_FilterInput>([{ column: 'name', value: 'Ascending' }]);
    const [targetDate, setTargetDate] = useState<ITableData[]>([]);
    const [stateList, setStateList] = useState<string[]>([]);
    const [label, setLabel] = useState<string[]>([]);
    const [lastUpdatedDropdown, setLastUpdatedDropdown] = useState<IDropdownWithKey>({
        value: 'Created On',
        keyName: 'createdOn',
    });
    const initialRender = useRef<boolean>(true);
    const [totalResultCount, setTotalResultCount] = useState<number>(0);

    const handleTempFilter = (tempState: IHQCEStates, action: THQInitState) => {
        const _tempFilter = { ...tempState };
        switch (action.type) {
            case 'date':
            case 'dateRange':
            case 'account':
                _tempFilter[action.type] = action.payload as string;
                break;
            case 'product':
            case 'funding':
            case 'state':
                _tempFilter[action.type] = action.payload as ReactText[];
                break;
            case 'searchbarFilterTags':
                _tempFilter[action.type] = action.payload as ISelectValue[];
                break;
                // case 'sortData':
                //     _tempFilter[action.type] = action.payload as ISort_FilterInput;
                break;
            case 'reset':
                (_tempFilter.date = 'lastUpdated'),
                    (_tempFilter.dateRange = ''),
                    (_tempFilter.account = ''),
                    (_tempFilter.state = []),
                    (_tempFilter.product = []),
                    (_tempFilter.searchbarFilterTags = []),
                    (_tempFilter.funding = []);

                break;
        }
        return _tempFilter;
    };
    const [tempState, dispatch] = useReducer(handleTempFilter, {
        state: _initHQCEStates.state,
        date: _initHQCEStates.date,
        dateRange: _initHQCEStates.dateRange,
        product: _initHQCEStates.product,
        funding: _initHQCEStates.funding,
        account: _initHQCEStates.account,
        // transaction: _initHQCEStates.transaction,
        searchbarFilterTags: _initHQCEStates.searchbarFilterTags,
    });

    const { loading, loadingHandler } = useComponentLoader();

    const handleInput = (column: string, value: string) => {
        if (value === '') {
            return undefined;
        }
        return { column, value };
    };

    const getInputs = () => {
        const filterInput: ISort_FilterInput = [];

        const accountInput = handleInput('accountType', tempState.account);

        const dateFilterInput: IColumnValue = {
            column: tempState.date,
            value: tempState.dateRange,
        };
        if (accountInput) filterInput.push(accountInput);
        if (dateFilterInput) filterInput.push(dateFilterInput);

        tempState.product.map((value) => {
            if (value) {
                filterInput.push({ column: 'fundType', value });
            }
        });
        tempState.funding.map((value) => {
            if (value) {
                filterInput.push({ column: 'paymentMethod', value });
            }
        });

        return { filterInput };
    };

    const getInputsState = () => {
        const filterInputState: ISort_FilterInput = [];
        tempState.state.map((value) => {
            if (value) {
                filterInputState.push({ column: 'state', value });
            }
        });

        return { filterInputState };
    };

    /*** @getDateExtensionData @APICall */
    const getDateExtensionData = async () => {
        const { filterInput } = getInputs();
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(dueDateExtensionQuery, {
                    input: {
                        tab: 'extension',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: sortData,
                        filter: filterInput,
                    },
                }),
                idTokenHeader,
            );

            const resultCheck = await response.data.hqCeDashboard;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = resultCheck.data;
            if (result !== null) {
                setOverview([
                    { ...overview[0], data: result.overview.daily },
                    { ...overview[1], data: result.overview.reroute },
                    { ...overview[2], data: result.overview.hardcopy },
                    { ...overview[3], data: result.overview.extension },
                ]);
                if (currentPage > result.pages) {
                    setCurrentPage(1);
                }
                setOrderCounter([result.branchCount, result.extensionCount]);
                setMaxPages(result.pages);
                setTargetDate(groupTransactionsByBranch(result.transactions));
                setDisableResultLimit(result.transactions.length === 0);
            }
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Due Date Extensions Data',
                    testId: 'hq-dashboard-error-modal',
                });
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    /** * @getAllBranchData @APICall */
    const getAllBranchData = async () => {
        const { filterInputState } = getInputsState();
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(allBranchQuery, {
                    input: {
                        tab: 'branch',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        sort: sortData,
                        filter: filterInputState,
                    },
                }),
                idTokenHeader,
            );
            const resultCheck = await response.data.hqCeDashboard;
            if (resultCheck.error !== null) throw resultCheck.error;
            const { result } = resultCheck.data;
            if (result !== null) {
                setOverview([
                    { ...overview[0], data: result.overview.daily },
                    { ...overview[1], data: result.overview.reroute },
                    { ...overview[2], data: result.overview.hardcopy },
                    { ...overview[3], data: result.overview.extension },
                ]);
                if (currentPage > result.pages) {
                    setCurrentPage(1);
                }
                setStateList(result.stateList);
                setTotalResultCount(result.totalResultCount);
                setOrderCounter([result.branchCount, result.extensionCount]);
                setMaxPages(result.pages);
                setOffices(result.branches);
                setDisableResultLimit(result.branches.length === 0);
            }
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            if (Object.keys(_error).includes('errorCode')) {
                handleErrorHandler();
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Branch Data',
                    testId: 'hq-dashboard-error-modal',
                });
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    const ProviderValue: HQCEContextData = {
        currentPage,
        disableResultLimit,
        getAllBranchData,
        getDateExtensionData,
        label,
        lastUpdatedDropdown,
        loading,
        loadingHandler,
        maxPages,
        offices,
        orderCounter,
        overview,
        resultLimit,
        searchInput,
        setCurrentPage,
        setLabel,
        setLastUpdatedDropdown,
        setMaxPages,
        setOffices,
        setOrderCounter,
        setOverview,
        setResultLimit,
        setSearchInput,
        setSortData,
        setStateList,
        setTab,
        setTargetDate,
        setTargetRange,
        setTotalResultCount,
        sortData,
        stateList,
        totalResultCount,
        tab,
        targetDate,
        targetDateRange,
        dispatch,
        tempState,
        initialRender,
    };

    return <HQCEContext.Provider value={ProviderValue}>{children}</HQCEContext.Provider>;
};

export default HQCEProvider;
