import React, { Fragment, FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import FlexSpacer, { CustomSpacer, FlexedDiv } from '../..';
import CustomCheckbox from '../../atoms/Checkbox';
import { TextDarkBlack } from '../../../constants';
import { IcoMoon } from '../../../icons';

declare interface CollapsibleHeaderStyleProps {
    children: ReactNode;
}

declare interface ICollapsibleProps {
    checkboxDisabled?: boolean;
    content: ReactNode;
    checked?: boolean;
    disabled?: boolean;
    expanded?: boolean;
    index?: number;
    handleChecked?: () => void;
    handleExpand?: (index: number) => void;
    isCheckable: boolean;
    subtitle?: string;
    title: string;
    testId?: string;
    noXMargin?: boolean;
    noMargin?: boolean;
    showBoxShadow?: boolean;
}

export const Collapsible: FunctionComponent<ICollapsibleProps> = ({
    checkboxDisabled,
    content,
    checked,
    disabled,
    expanded,
    index,
    handleChecked,
    handleExpand,
    isCheckable,
    subtitle,
    title,
    testId,
    noXMargin,
    noMargin,
    showBoxShadow,
}: ICollapsibleProps) => {
    const [height, setHeight] = useState<number>(0);
    const [width, setWidth] = useState<number>();
    const ref = useRef<HTMLDivElement>(null);
    const handleHeight = () => {
        if (expanded === true && ref !== null && ref.current?.scrollHeight !== 0) {
            setHeight(ref.current?.scrollHeight as number);
        } else {
            setHeight(0);
        }
    };

    const CollapsibleHeaderStyle = ({ children }: CollapsibleHeaderStyleProps) => {
        return (
            <Fragment>
                {expanded === true ? (
                    <CollapsedHeader container={`${width}px`} data-testid={`${testId}-header`} id={`${testId}-header`}>
                        {children}
                    </CollapsedHeader>
                ) : (
                    <CollapsibleHeader data-testid={`${testId}-header`} id={`${testId}-header`}>
                        {children}
                    </CollapsibleHeader>
                )}
            </Fragment>
        );
    };

    useEffect(() => {
        if (expanded === true && ref !== null && ref.current?.scrollHeight !== 0) {
            setHeight(ref.current?.scrollHeight as number);
        }
    }, [content]);

    useEffect(() => {
        if (expanded !== undefined) {
            handleHeight();
        }
    }, [expanded]);

    useEffect(() => {
        if (ref.current !== undefined && ref.current?.clientWidth !== undefined) {
            setWidth(100);
        }
    }, []);

    return (
        <StyledContainer disabled={disabled} id={testId} noXMargin={noXMargin} noMargin={noMargin}>
            <CollapsibleHeaderStyle>
                <FlexedDiv style={{ flex: 1 }}>
                    {isCheckable === true && checked !== undefined ? (
                        <Fragment>
                            <div style={{ alignSelf: 'center' }}>
                                <CustomCheckbox
                                    checked={checked}
                                    disabled={checkboxDisabled}
                                    handleCheckbox={handleChecked}
                                />
                            </div>
                            <CustomSpacer horizontal={true} space={'1.25rem'} />
                        </Fragment>
                    ) : null}
                    <FlexedDiv direction="column">
                        {isCheckable && checked !== undefined ? (
                            <TextDarkBlack weight={checked ? '700' : '400'} lineHeight="24px">
                                {title}
                            </TextDarkBlack>
                        ) : (
                            <TextDarkBlack weight="700" lineHeight="24px">
                                {title}
                            </TextDarkBlack>
                        )}
                        {subtitle !== undefined ? <SubTitleText>{subtitle}</SubTitleText> : null}
                    </FlexedDiv>
                    <FlexSpacer />
                    {handleExpand !== undefined && (
                        <Icon
                            onClick={() => handleExpand(index !== undefined ? index : 0)}
                            style={{ alignSelf: 'center' }}
                            id={`${testId}-toggle`}
                            data-testid={`${testId}-toggle`}
                        >
                            <IcoMoon
                                name={'caret-down'}
                                size="1.5rem"
                                style={{
                                    transform: expanded === true ? 'rotate(180deg)' : 'rotate(0deg)',
                                }}
                            />
                        </Icon>
                    )}
                </FlexedDiv>
            </CollapsibleHeaderStyle>

            <CollapsibleContainer
                ref={ref}
                style={{
                    maxHeight: `${height}px`,
                    overflow: 'hidden',
                }}
                data-testid={`${testId}-container`}
                id={`${testId}-container`}
                showBoxShadow={showBoxShadow}
                expanded={expanded ? expanded : false}
            >
                <div>{content}</div>
            </CollapsibleContainer>
        </StyledContainer>
    );
};

declare interface CollapsedHeaderProps {
    container?: string;
}

declare interface StyledContainerProps {
    disabled?: boolean;
    noXMargin?: boolean;
    noMargin?: boolean;
}
declare interface CollapsibleContainerProps {
    showBoxShadow?: boolean;
    expanded?: boolean;
}
declare interface TitleTextProps {
    isChecked?: boolean;
}
const StyledContainer = styled.div<StyledContainerProps>`
    background-color: #ffffff;
    border-radius: 16px;
    margin: ${(props) => (props.noXMargin ? (!props.noMargin ? '2rem 0' : '0') : '2rem')};
    ${(props: StyledContainerProps) =>
        props.disabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`;

const CollapsibleHeader = styled.div`
    padding: 16px 24px;
    align-items: center;
    border-width: 5px;
    border: 1px solid #eaebee;
    border-radius: 16px;
    box-shadow: 3px 3px 14px rgba(0, 32, 67, 0.12), -2px 2px 8px rgba(0, 32, 67, 0.06);
    z-index: 1;
    display: flex;
    flex: 1;
`;

const CollapsedHeader = styled.div`
    padding: 16px 24px;
    align-items: center;
    background-color: #ffffff;
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #ececec;
    box-shadow: 3px 3px 14px rgba(0, 32, 67, 0.12), -2px 2px 8px rgba(0, 32, 67, 0.06);
    position: relative;
    display: flex;
    ${(props: CollapsedHeaderProps) =>
        props.container &&
        css`
            width: ${props.container}%;
        `}
    z-index: 2;
`;

const Icon = styled.div`
    &:hover {
        cursor: pointer;
    }
`;

const CollapsibleContainer = styled.div<CollapsibleContainerProps>`
    border-radius: 0 0 16px 16px;
    border-right: 1px solid #eaebee;
    border-left: 1px solid #eaebee;
    border-bottom: ${(props) => (props.expanded ? '1px solid #eaebee' : '0px')};
    transition: max-height 0.2s ease-out;
    background-color: #ffffff;
    z-index: 0;
    box-shadow: ${(props) => (props.showBoxShadow ? '0px 4px 4px rgba(0, 0, 0, 0.1)' : null)};
    padding: ${(props) => (props.expanded ? '1rem 1.5rem 1.5rem' : '0px 1.5rem')};
`;

const SubTitleText = styled.div`
    font-size: 0.75rem;
    font-weight: 400;
    color: #4d4d4d;
    line-height: 1rem;
`;
