import gql from 'graphql-tag';

export const approveRejectSettingsMutation = gql`
    mutation approveRejectSettings($input: ApproveRejectInput) {
        approveRejectSettings(input: $input) {
            data {
                result {
                    status
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
