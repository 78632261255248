import React from 'react';
import styled from 'styled-components';
import { FlexedDiv } from '../components';
import { TextBlue6, TextNavyBlue } from '../constants';

export const EPFSettingColumnItem = ({ data, itemKey }: ITableCustomItem): JSX.Element => {
    return (
        <FlexedDiv style={{ position: 'relative' }}>
            {itemKey[0].key === 'displayName' ? (
                <FlexedDiv direction="column">
                    <TextNavyBlue weight="700" size="14px">
                        <span style={{ position: 'relative' }}>
                            {data.displayName} {data.isSeen ? null : itemKey[0].key === 'displayName' && <Badge />}
                        </span>
                    </TextNavyBlue>
                    <TextBlue6 size="12px">{data.description}</TextBlue6>
                </FlexedDiv>
            ) : (
                <div style={itemKey[0].textStyle}>.{data.description}</div>
            )}
        </FlexedDiv>
    );
};
const Badge = styled.div`
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    background: #c61230;
    border-radius: 50%;
    right: -15px;
    top: 0;
`;
