import React from 'react';
import styled from 'styled-components';
import { IcoMoon } from '../../../icons';
import { sectionSubTextStyle } from '../../../styles';
import { FlexedDiv } from '../../atoms';
import { TextDarkGrey5, TextDarkBlack } from '../../../constants';
import { LinkButton } from '../../atoms/LinkButton';
import { useHistory } from 'react-router-dom';

interface IDataWithLabelProps {
    label: string;
    data: string;
    labelIcon?: string;
    dataIcon?: string;
    prefix?: string;
    subText?: string;
    prefixStyle?: React.CSSProperties;
    subTextStyle?: React.CSSProperties;
    direction?: React.CSSProperties['flexDirection'];
    onClickData?: string;
    testId?: string;
    multiLinks?: linkTypes[];
    labelStyle?: React.CSSProperties;
    dataStyle?: React.CSSProperties;
}
export const DataWithLabel: React.FC<IDataWithLabelProps> = ({
    label,
    labelIcon,
    data,
    dataIcon,
    prefix,
    prefixStyle,
    subText,
    subTextStyle,
    direction,
    onClickData,
    testId,
    multiLinks,
    labelStyle,
    dataStyle,
}: IDataWithLabelProps) => {
    const defaultPrefixStyle: React.CSSProperties = {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000000',
        display: 'inline',
        marginRight: '4px',
    };
    const history = useHistory();
    return (
        <FlexedDiv direction={direction}>
            <FlexedDiv data-testid="label_div">
                <TextDarkGrey5 size="12px" data-testid="label_font" id="LABEL">
                    <div style={{ ...labelStyle }}>{label}</div>
                </TextDarkGrey5>

                {labelIcon !== undefined ? <IcoMoon name={labelIcon} size="1.25rem" /> : null}
            </FlexedDiv>
            <DataDiv direction={direction} data-testid="direction">
                {multiLinks === undefined ? (
                    <FlexedDiv direction="column">
                        <FlexedDiv>
                            <span>
                                <TextDarkBlack weight="700">
                                    {prefix !== undefined ? (
                                        <div style={{ ...defaultPrefixStyle, ...prefixStyle }}>{prefix}</div>
                                    ) : null}
                                    <div style={{ ...dataStyle }}>
                                        {' '}
                                        {data}
                                        {onClickData !== undefined && onClickData !== null ? (
                                            <DataLink
                                                href={onClickData}
                                                rel="noreferrer"
                                                target="_blank"
                                                id="dataIconBtn"
                                            >
                                                {dataIcon !== undefined &&
                                                dataIcon !== null &&
                                                dataIcon !== '-' &&
                                                dataIcon !== '' ? (
                                                    <Icon>
                                                        <IcoMoon name={dataIcon} size="1.125rem" />
                                                    </Icon>
                                                ) : null}
                                            </DataLink>
                                        ) : null}
                                    </div>
                                </TextDarkBlack>
                            </span>
                        </FlexedDiv>
                        {subText !== undefined ? (
                            <div style={{ ...sectionSubTextStyle, ...subTextStyle }} id={`${testId}-secondary-label`}>
                                {subText}
                            </div>
                        ) : null}
                    </FlexedDiv>
                ) : (
                    <FlexedDiv direction="column">
                        {multiLinks.map((item, index) => {
                            return (
                                <FlexedDiv
                                    id={`multiLinks-${index + 1}`}
                                    key={`multiLinks-key-${index + 1}`}
                                    alignItems="center"
                                >
                                    <TextDarkBlack weight="700">{item.value}</TextDarkBlack>&nbsp;
                                    <LinkButton
                                        data-testid={`linkButton-${index + 1}`}
                                        buttonColor="#0089EC"
                                        noFill
                                        suffixIcon={item.linkIcon}
                                        onClick={() => {
                                            item.link && history.push(item.link, { accountNo: item.value });
                                        }}
                                    >
                                        {item.linkText}
                                    </LinkButton>
                                </FlexedDiv>
                            );
                        })}
                    </FlexedDiv>
                )}
            </DataDiv>
        </FlexedDiv>
    );
};

const Icon = styled.div`
    margin-left: 0.5rem;
    fill: #0089ec;
    cursor: pointer;
    &:hover {
        fill: #006dbd;
    }
`;
const DataDiv = styled.div`
    display: flex;
    padding: ${(p: IFlexDiv) => setPadding(p.direction)};
`;
const DataLink = styled.a`
    display: inline-block;
`;
function setPadding(direction: React.CSSProperties['flexDirection']) {
    let padding: React.CSSProperties['padding'] = '';

    switch (direction) {
        case 'column':
        case 'column-reverse':
            padding = '0rem 0rem 1rem 0rem';
            break;
        case 'row':
        case 'row-reverse':
            padding = '0rem 0.5rem';
            break;
        default:
            padding = 'inherit';
            break;
    }
    return padding;
}

export default DataWithLabel;
