import styled from 'styled-components';

interface ICounterTagsProps {
    active: boolean;
}
const CounterTags = styled.div`
    background: ${(p: ICounterTagsProps) => (p.active ? '#c61230' : '#4D637B')};
    margin-left: 0.5rem;
    font-weight: 700;
    color: white;
    line-height: 16px;
    font-size: 0.625rem;
    padding: 0rem 0.5rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    text-align: center;
`;
export default CounterTags;
