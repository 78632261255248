import React, { Fragment } from 'react';
import { getFileSize, getSpecificFileSize } from '../utils';
import { FlexedDiv } from '../components';

import styled from 'styled-components';
export const FileSizeColumnItem = ({ data, itemKey, type, isSortedColumn }: ITableCustomItem): JSX.Element => {
    return (
        <Fragment>
            <FlexedDiv>
                <FlexedDiv direction="column">
                    {type !== undefined ? (
                        <Day isBold={itemKey[0].key === isSortedColumn}>
                            {data[itemKey[0].key] !== null ? getSpecificFileSize(data[itemKey[0].key], type) : '-'}
                        </Day>
                    ) : (
                        <Day isBold={itemKey[0].key === isSortedColumn}>
                            {data[itemKey[0].key] !== null ? getFileSize(data[itemKey[0].key]) : '-'}
                        </Day>
                    )}
                </FlexedDiv>
            </FlexedDiv>
        </Fragment>
    );
};

const Day = styled.div`
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: -0.2px;
    color: #002043;
    font-weight: ${(props: IOnPropStyles) => (props.isBold ? 700 : 'normal')};
`;
