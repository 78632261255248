/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, lazy, useContext, useEffect } from 'react';
import { Route, RouteProps, Switch, useHistory } from 'react-router-dom';
import * as ROUTES from '../../routes';
//Context
import AuthContext from '../../context/AuthContext';
import DashboardBranchProvider from '../../context/BranchContext/DashboardBranchProvider';
import WebSocketContext from '../../context/WebSocketContext';
import AdvisersProvider from '../../context/AdviserContext/AdvisersProvider';
import { InboxProvider } from '../../context/InboxContext';
import TrackOrderProvider from '../../context/TrackOrderContext/TrackOrderProvider';
import InvestorsProvider from '../../context/InvestorContext/InvestorsProvider';
import { PendingSubmissionProvider } from '../../context/PendingSubmissionContext';
//Main pages
const DashboardBranch = lazy<any>(() => import('../../pages/DashboardBranch/DashboardBranch'));
const OrderDetails = lazy<any>(() => import('../../pages/OrderDetails'));
const OrderSummaryNew = lazy<any>(() => import('../../pages/OrderDetails/OrderSummaryChangeRequest'));
const AdviserDashboard = lazy<any>(() => import('../../pages/Advisers/AdviserDashboard'));

const InvestorDashboard = lazy<any>(() => import('../../pages/Investors/InvestorDashboard'));
const AccountProfile = lazy<any>(() => import('../../pages/Investors/Pages/AccountProfile'));
const InvestorProfile = lazy<any>(() => import('../../pages/Investors/Pages/InvestorProfile'));
const AdviserProfile = lazy<any>(() => import('../../pages/AdvisorProfile/AdvisorProfile'));
const TrackOrder = lazy<any>(() => import('../../pages/TrackOrder/TrackOrder'));
const ExtendTargetDateBranch = lazy<any>(
    () => import('../../pages/DashboardBranch/ExtendTargetDate/ExtendTargetDateBranch'),
);
const CEExtendDueDateRequest = lazy<any>(() => import('../../pages/Inbox/CEPages/CEExtendDueDateRequest'));
const PendingSubmission = lazy<any>(() => import('../../pages/PendingSubmission/PendingSubmission'));
const RejectTransaction = lazy<any>(() => import('../../pages/RejectTransaction/RejectTransaction'));
const RerouteTransaction = lazy<any>(() => import('../../pages/RerouteTransaction/RerouteTransactions'));
const Inbox = lazy<any>(() => import('../../pages/Inbox/Inbox'));
const BranchWrapper = lazy<any>(() => import('../../pages/Wrapper/BranchWrapper'));
const Profile = lazy<any>(() => import('../../pages/Profile/Profile'));
const Error404 = lazy<any>(() => import('../../pages/ExceptionHandling/Error404'));
const DueDateExtension = lazy<any>(() => import('../../pages/ExtendTargetDate'));
const Reports = lazy<any>(() => import('../../pages/Reports/ReportDashboard'));
const GenerateReport = lazy<any>(() => import('../../pages/Reports/Pages/GenerateReport'));

//Components
import { onRefreshFn } from '../../utils/authServices';
import { FlexedDiv, Modal } from '../../components';
import { LABEL } from '../../constants';
import { IdleTimer } from '../../components/molecules/IdleTimer/IdleTimer';
import { ReportProvider } from '../../context/ReportContext/ReportProvider';

export interface IBranchRouteProps extends RouteProps {
    component?: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const BranchRoute = ({ component: Component, ...rest }: IBranchRouteProps): JSX.Element => {
    const { isLoggedOut, setIsLoggedOut, logoutSuccess } = useContext(WebSocketContext);
    const { userLoginContext, windowReloadFn, userLogoutFn } = useContext(AuthContext);
    const history = useHistory();

    const checkPermission = (permission: any) => {
        const parsedPermission = JSON.parse(permission);
        if (parsedPermission.branch.grant === 'branch' || parsedPermission.hq.grant === 'hq') {
            return true;
        }
        return false;
    };

    const checkTransactions = (permission: any) => {
        const parsedPermission = JSON.parse(permission);
        if (parsedPermission.branch.permission.ceTransaction.isAll === 'true') {
            return true;
        }
        return false;
    };

    const checkAdvisers = (permission: any) => {
        const parsedPermission = JSON.parse(permission);
        if (parsedPermission.branch.permission.adviser.isAll === 'true') {
            return true;
        }
        return false;
    };
    const checkReports = (permission: any) => {
        const parsedPermission = JSON.parse(permission);
        if (parsedPermission.branch.permission.reports) {
            return parsedPermission.branch.permission.reports.isAll === 'true' ? true : false;
        }
        return false;
    };
    useEffect(() => {
        windowReloadFn();
    }, [onRefreshFn]);
    return (
        <Fragment>
            {userLoginContext.isAuthenticated && checkPermission(userLoginContext.permission) ? (
                <InboxProvider>
                    <BranchWrapper>
                        <DashboardBranchProvider>
                            <TrackOrderProvider>
                                <AdvisersProvider>
                                    <InvestorsProvider>
                                        <ReportProvider>
                                            <PendingSubmissionProvider>
                                                {isLoggedOut === false && logoutSuccess === false && (
                                                    <IdleTimer timeout={900000} />
                                                )}
                                                <Switch>
                                                    {checkTransactions(userLoginContext.permission) ? (
                                                        <Route
                                                            exact
                                                            path={ROUTES.dashboardBranch}
                                                            component={DashboardBranch}
                                                            // render={()=> <DashboardBranch/>}
                                                        />
                                                    ) : null}

                                                    <Route
                                                        exact
                                                        path={ROUTES.branchTrackOrder}
                                                        component={TrackOrder}
                                                    />
                                                    <Route
                                                        exact
                                                        path={ROUTES.branchPendingSubmissionTrackOrder}
                                                        component={TrackOrder}
                                                    />
                                                    <Route exact path={ROUTES.branchInbox} component={Inbox} />
                                                    <Route
                                                        // exact
                                                        path={ROUTES.branchInboxOrderSummary}
                                                        component={OrderDetails}
                                                    />
                                                    <Route
                                                        // exact
                                                        path={ROUTES.branchInboxOrderSummaryChangeRequest}
                                                        component={OrderSummaryNew}
                                                    />
                                                    <Route
                                                        exact
                                                        path={ROUTES.branchInboxReRouteReview}
                                                        component={RerouteTransaction}
                                                    />
                                                    <Route
                                                        exact
                                                        path={ROUTES.branchInboxRejectReview}
                                                        component={RejectTransaction}
                                                    />
                                                    <Route exact path={ROUTES.profile} component={Profile} />

                                                    <Route
                                                        exact
                                                        path={`${ROUTES.branchInbox}/due-date-extension`}
                                                        component={CEExtendDueDateRequest}
                                                    />
                                                    {checkAdvisers(userLoginContext.permission) ? (
                                                        <Route
                                                            exact
                                                            path={ROUTES.advisers}
                                                            component={AdviserDashboard}
                                                        />
                                                    ) : null}
                                                    <Route
                                                        exact
                                                        path={ROUTES.branchAdvisorProfile}
                                                        component={AdviserProfile}
                                                    />
                                                    <Route
                                                        exact
                                                        path={ROUTES.branchInvestors}
                                                        component={InvestorDashboard}
                                                    />
                                                    <Route
                                                        exact
                                                        path={ROUTES.branchAccountProfile}
                                                        component={AccountProfile}
                                                    />
                                                    <Route
                                                        exact
                                                        path={ROUTES.branchInvestorProfile}
                                                        component={InvestorProfile}
                                                    />
                                                    {checkReports(userLoginContext.permission) ? (
                                                        <Route exact path={ROUTES.branchReports} component={Reports} />
                                                    ) : null}
                                                    {checkReports(userLoginContext.permission) ? (
                                                        <Route
                                                            exact
                                                            path={ROUTES.branchGenerateReports}
                                                            component={GenerateReport}
                                                        />
                                                    ) : null}

                                                    <Route
                                                        exact
                                                        path={ROUTES.branchPendingSubmission}
                                                        component={PendingSubmission}
                                                    />
                                                    <Route
                                                        path={`${ROUTES.branchPendingSubmission}/${ROUTES.orderSummary}`}
                                                        component={OrderDetails}
                                                    />
                                                    <Route
                                                        path={`${ROUTES.branchPendingSubmission}/${ROUTES.orderSummaryChangeRequest}`}
                                                        component={OrderSummaryNew}
                                                    />
                                                    <Route
                                                        path={`/branch/dashboard/${ROUTES.orderSummary}`}
                                                        component={OrderDetails}
                                                    />
                                                    <Route
                                                        path={`/branch/dashboard/${ROUTES.orderSummaryChangeRequest}`}
                                                        component={OrderSummaryNew}
                                                    />
                                                    <Route
                                                        exact
                                                        path={`/branch/dashboard/${ROUTES.extendTargetDate}`}
                                                        component={ExtendTargetDateBranch}
                                                    />
                                                    <Route
                                                        exact
                                                        path={`/branch/dashboard/${ROUTES.extendTargetDateRequest}`}
                                                        component={DueDateExtension}
                                                    />
                                                    <Route
                                                        exact
                                                        path={`/branch/dashboard/${ROUTES.rejectTransaction}`}
                                                        component={RejectTransaction}
                                                    />
                                                    <Route
                                                        exact
                                                        path={`/branch/dashboard/${ROUTES.rerouteTransaction}`}
                                                        component={RerouteTransaction}
                                                    />

                                                    <Route component={Error404} />
                                                </Switch>
                                            </PendingSubmissionProvider>
                                        </ReportProvider>
                                    </InvestorsProvider>
                                </AdvisersProvider>
                            </TrackOrderProvider>
                        </DashboardBranchProvider>
                    </BranchWrapper>
                </InboxProvider>
            ) : null}
            {isLoggedOut && (
                <Modal
                    title={'Duplicate log in'}
                    modalActive={isLoggedOut}
                    setModalActive={setIsLoggedOut}
                    primaryBtn={{
                        onClick: () => {
                            userLogoutFn();
                            history.push(ROUTES.signIn);
                            window.location.reload();
                            setIsLoggedOut(!isLoggedOut);
                        },
                        label: LABEL.okay,
                        primary: true,
                        size: 'large',
                    }}
                    icon="logout-modal-error"
                    contentAlignment="center"
                    testId="notification-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {`Your account has been logged in from another device.`}
                    </FlexedDiv>
                </Modal>
            )}
        </Fragment>
    );
};

export default BranchRoute;
