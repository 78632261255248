/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, Fragment, ReactText, useEffect, useMemo, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import styled, { css } from 'styled-components';
import { LABEL } from '../../../constants';
import { IcoMoon } from '../../../icons';
import { removeArrayElement } from '../../../utils';
import { FlexedDiv } from '../../atoms';
import CustomCheckbox from '../../atoms/Checkbox';
// import Container from '../../atoms/Container';
import Tag from '../../atoms/Tag';
import FilterSearch from '../FilterSearch';
interface IMultiSelectProps {
    showSearch?: boolean;
    label: string;
    options: Array<ReactText>;
    defaultOption?: string;
    placeHolder: string;
    noOverlay?: boolean;
    hasSelectAll?: boolean;
    checkedState: Array<ReactText>;
    handleMultiSelect: (item: Array<ReactText>, fn: string, id: number) => void;
    expandable?: boolean;
    disabled?: boolean;
    selectId?: number;
    testId?: string;
}
const MultiSelect = ({
    showSearch,
    label,
    options,
    placeHolder,
    noOverlay,
    hasSelectAll,
    checkedState,
    handleMultiSelect,
    expandable,
    disabled,
    selectId,
    testId,
}: IMultiSelectProps): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false);
    const [checked, setChecked] = useState<Array<boolean>>([false]);
    const [containerWidth, setContainerWidth] = useState<number>(0);
    const [widths, setWidths] = useState<Array<number>>([]);
    const [maxWidth, setMaxWidth] = useState<string>('24');
    const node = useRef<HTMLDivElement>(null);
    const tagsContainerRef = useRef<HTMLDivElement>(null);
    const tagRefs = useRef<Array<HTMLButtonElement | null>>([]);
    const expandCondition = expandable !== undefined ? expandable : false;
    const [expandDiv, setExpandDiv] = useState<boolean>(expandCondition);
    const [selectAll, setSelectAll] = useState({ checked: false, value: '' });
    const [isSearching, setIsSearching] = useState(false);
    const [searchedOption, setSearchedOption] = useState<ReactText[]>(options);
    const [searchInput, setSearchInput] = useState('');
    const [selected, setSelected] = useState<ReactText[]>([]);

    const sortedOptions = [...selected]
        .sort((a, b) => a.toString().localeCompare(b.toString()))
        .concat(
            options
                .filter((option) => !selected.includes(option))
                .sort((a, b) => a.toString().localeCompare(b.toString())),
        );

    const searchOptions = (searchInput: string): ReactText[] => {
        const filteredOptions = sortedOptions.filter((option) => {
            if (typeof option === 'string') {
                return option.toLowerCase().includes(searchInput.toLowerCase());
            } else if (typeof option === 'number') {
                return option.toString().includes(searchInput);
            }
        });
        return [...new Set([...filteredOptions])];
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const searchInput = event.target.value;
        setSearchInput(searchInput);
        if (searchInput !== '') {
            setIsSearching(true);
            setSearchedOption(searchOptions(searchInput));
        } else {
            setIsSearching(false);
        }
    };

    const handleClear = () => {
        setSearchInput('');
        setIsSearching(false);
    };

    const handleOpenSelect = () => {
        setSelected(sortedOptions.filter((option) => checkedState.includes(option)));
        setOpen(!open);
    };
    const handleClickOutside = (e: any) => {
        if (node.current?.contains(e.target)) {
            // inside click
            return;
        }
        // outside  click
        setOpen(false);
    };
    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);
    useEffect(() => {
        if (checkedState.length === 0) {
            setSelectAll({ checked: false, value: '' });
        }
        const temp: Array<number> = [];
        const resizeObserver = new ResizeObserver((entries: any) => {
            setContainerWidth(entries[0].contentRect.width);
            setMaxWidth(calculateMaxWidth(checkedState.length, entries[0].contentRect.width));
        });

        tagsContainerRef.current !== null ? resizeObserver.observe(tagsContainerRef.current) : null;
        // This part fixes the issue of not checked checkboxes at mounting when checkedState.length is bigger than 0
        if (sortedOptions.length !== 0) {
            const cs = checkedState.filter(Boolean).length;
            const c = checked.filter(Boolean).length;

            if (c !== cs) {
                defaultSelection();
            } else {
                checkedState.length === sortedOptions.length
                    ? defaultSelectAll()
                    : setSelectAll({ value: '', checked: false });
            }
            for (let i = 0; i < checkedState.length; i++) {
                tagRefs.current = tagRefs.current.slice(0, checkedState.length);
                const tempWidth = tagRefs.current[i]?.clientWidth;
                temp.push(tempWidth !== undefined ? tempWidth : 0);
                setWidths(temp);
            }
        }
    }, [checkedState, tagsContainerRef, tagRefs, checked]);
    const defaultSelection = () => {
        const ch = [];
        for (let i = 0; i < sortedOptions.length; i++) {
            if (checkedState.includes(sortedOptions[i] as ReactText)) {
                ch.push(true);
            } else {
                ch.push(false);
            }
        }

        setChecked(ch);
    };
    const defaultSelectAll = () => {
        setSelectAll({ checked: true, value: 'selectAll' });
    };
    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const check = e.target.checked;
        let tempValues = [...checkedState];
        const tempChecked = [...checked];
        if (check) {
            tempValues.push(value);
        } else {
            tempValues = removeArrayElement(tempValues, value);
        }
        setChecked(tempChecked);
        // setCheckedValues(tempValues);
        handleMultiSelect(tempValues, 'select', selectId !== undefined ? selectId : 1);
    };
    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        const check = e.target.checked;
        let tempValues = [...checkedState];
        const tempChecked = [...checked];
        if (check) {
            sortedOptions.map((item, index) => {
                tempValues.includes(item) ? null : tempValues.push(item);
                tempChecked[index] = true;
            });
            setSelectAll({ value: 'All', checked: true });
        } else {
            tempValues = [];
            for (let i = 0; i < tempChecked.length; i++) tempChecked[i] = false;
            setSelectAll({ value: '', checked: false });
        }
        setChecked(tempChecked);
        handleMultiSelect(tempValues, 'selectAll', selectId !== undefined ? selectId : 1);
    };
    const handleTag = (index: number | undefined) => {
        let tempValues = [...checkedState];
        const tempChecked = [...checked];
        let id = -1;
        if (index !== undefined) {
            for (let i = 0; i < options.length; i++) {
                if (options[i] === tempValues[index]) {
                    id = i;
                    break;
                }
            }
            tempValues = removeArrayElement(tempValues, tempValues[index]);
            tempChecked[id] = false;
            setChecked(tempChecked);
            handleMultiSelect(tempValues, 'remove', selectId !== undefined ? selectId : 1);
        }
    };
    const calculateMaxWidth = (tagCount: number, _containerWidth: number) => {
        const fraction = _containerWidth;
        let calc = fraction / widths.length;
        if (selectAll.checked) {
            calc = 104;
        } else {
            const widthsLength = widths.length - 1 === 0 ? 1 : widths.length - 1;
            calc = fraction / widthsLength;
        }
        switch (tagCount) {
            case 1:
                return `${(fraction / 16).toString()}rem`;
            default:
                return `${(calc / 16).toString()}rem`;
        }
    };
    const useCounter = (containerWidth: number, widths: Array<number>) => {
        return useMemo(() => {
            let breakPoint = 0;
            let total = 0;
            for (let i = 0; i < widths.length; i++) {
                total = total + widths[i];
                if (total >= containerWidth - 18) {
                    breakPoint = i;
                    break;
                }
            }
            const condition = breakPoint !== 0 ? checkedState.length - breakPoint : 0;
            return condition;
        }, [checkedState, containerWidth, widths]);
    };
    const counter = useCounter(containerWidth, widths);
    const menu = (isSelectAll: boolean) => {
        return (
            <>
                {isSelectAll ? (
                    <Fragment>
                        <MapItem>
                            <CustomCheckbox
                                value={'selectAll'}
                                checked={selectAll.checked}
                                label={LABEL.selectAll}
                                handleCheckbox={handleSelectAll}
                                id={'selectAll'}
                            />
                        </MapItem>
                        <SeparatorLine />
                    </Fragment>
                ) : null}
                {isSearching ? (
                    searchedOption.map((item, index) => (
                        <MapItem key={`${index} ${item}`}>
                            <CustomCheckbox
                                value={item}
                                checked={checkedState.includes(item) ? true : false}
                                label={item.toString()}
                                handleCheckbox={handleCheck}
                                id={`${testId}-option-${index}`}
                                disabled={disabled}
                            />
                        </MapItem>
                    ))
                ) : (
                    <>
                        {sortedOptions?.map((item, index) => (
                            <Fragment key={`${index} ${item}`}>
                                <MapItem>
                                    <CustomCheckbox
                                        value={item}
                                        checked={checkedState.includes(item) ? true : false}
                                        label={item.toString()}
                                        handleCheckbox={handleCheck}
                                        id={`${testId}-option-${index}`}
                                        disabled={disabled}
                                    />
                                </MapItem>
                                {index < sortedOptions.length - 1 &&
                                    selected.includes(item) !== selected.includes(sortedOptions[index + 1]) && (
                                        <SeparatorLine />
                                    )}
                            </Fragment>
                        ))}
                    </>
                )}
            </>
        );
    };
    const findLabel = (val: string) => {
        for (let i = 0; i < sortedOptions.length; i++) {
            if (sortedOptions[i] === val) return sortedOptions[i];
        }
    };
    return (
        <Fragment>
            <FlexedDiv direction="column" data-testid="multiSelect">
                <DropdownLabel>{label}</DropdownLabel>
                <div ref={node}>
                    <MultiSelectDiv renderStyle={open} disabled={disabled} id={testId}>
                        {checkedState.length === 0 ? (
                            <TagsContainer renderStyle={expandDiv} ref={tagsContainerRef}>
                                <PlaceHolder>{placeHolder}</PlaceHolder>
                            </TagsContainer>
                        ) : (
                            <TagsContainer
                                disabled={disabled}
                                renderStyle={expandDiv}
                                ref={tagsContainerRef}
                                id="select_tag"
                            >
                                {checkedState.map((item, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <Tag
                                                onClose={handleTag}
                                                index={index}
                                                maxWidth={maxWidth !== '0rem' ? '100%' : '32px'}
                                                style={{ cursor: 'default', maxWidth: '90%' }}
                                                ref={(ref) => (tagRefs.current[index] = ref)}
                                                disabled={disabled}
                                                data-testid="label-tag"
                                            >
                                                {findLabel(item.toString())}
                                            </Tag>
                                        </Fragment>
                                    );
                                })}
                            </TagsContainer>
                        )}
                        <div style={{ position: 'relative' }}>
                            <FlexedDiv style={{ position: 'absolute', right: 0 }} data-testid="counter-span">
                                {expandDiv === false && counter > 0 ? (
                                    <CounterSpan
                                        renderStyle={counter > 0}
                                        onClick={() => setExpandDiv(true)}
                                        id="expandBtn"
                                        data-testid="multiselect-counter"
                                    >
                                        <IcoMoon name="plus" size="1rem" color="inherit" test-id="toExpandIcon" />
                                        {counter}
                                    </CounterSpan>
                                ) : null}
                                <div
                                    style={{ cursor: 'pointer', right: 0 }}
                                    onClick={() => handleOpenSelect()}
                                    id="handleOpenSBtn"
                                    data-testid="handleOpenSBtn"
                                >
                                    <IcoMoon name="caret-down" size="1.5rem" />
                                </div>
                            </FlexedDiv>
                        </div>
                    </MultiSelectDiv>
                    {noOverlay || noOverlay === undefined ? (
                        <MultiSelectMenu
                            showSearch={showSearch}
                            renderStyle={open}
                            direction="column"
                            style={{ position: 'absolute', zIndex: 200 }}
                            disabled={disabled}
                            data-testid={`${label.toLowerCase()}-multiselect-menu`}
                        >
                            {showSearch ? (
                                <FilterSearch
                                    handleClearContents={handleClear}
                                    searchValue={searchInput}
                                    handleSearch={handleSearch}
                                    isSimpleSearch={true}
                                    placeHolder={`Search ${label}`}
                                    testId={`${label}`}
                                />
                            ) : null}

                            {menu(hasSelectAll !== undefined ? hasSelectAll : false)}
                        </MultiSelectMenu>
                    ) : (
                        <div style={{ position: 'relative' }}>
                            <MultiSelectMenuOverlay renderStyle={open} direction="column" disabled={disabled}>
                                {showSearch ? (
                                    <FilterSearch
                                        handleClearContents={handleClear}
                                        searchValue={searchInput}
                                        handleSearch={handleSearch}
                                        isSimpleSearch={true}
                                        placeHolder={`Search ${label}`}
                                        testId={`${label}`}
                                    />
                                ) : null}
                                {menu(hasSelectAll !== undefined ? hasSelectAll : false)}
                            </MultiSelectMenuOverlay>
                        </div>
                    )}
                </div>
            </FlexedDiv>
        </Fragment>
    );
};
interface IOnRenderStyleProps {
    renderStyle: boolean;
    disabled?: boolean;
    error?: boolean;
}
const PlaceHolder = styled.div`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #878787;
`;
const MultiSelectDiv = styled.div`
    display: flex;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
    padding: 0.75rem 1rem;
    transition: all 0.2s ease;
    background: #ffffff;
    justify-content: space-between;
    border-radius: 32px;
    border: 1px solid #cecece;
    box-sizing: border-box;
    min-width: 22.5rem;
    width: 100%;
    max-width: 360px;
    ${(props: IOnRenderStyleProps) =>
        props.disabled &&
        css`
            border: 1px solid #cecece;
            background: #f4f4f4;
        `}
    ${(props: IOnRenderStyleProps) =>
        props.renderStyle &&
        css`
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border: 2px solid #002043;
            box-shadow: 0px 2px 8px rgba(0, 32, 67, 0.06), 0px 0px 4px rgba(0, 32, 67, 0.02);
            box-sizing: border-box;
        `};
    ${(props: IOnRenderStyleProps) =>
        props.error &&
        css`
            border: 1px solid #e84c3d;
        `}
`;
const MultiSelectMenu = styled((props) => <FlexedDiv {...props} />)`
    transition: all 0.4s ease-out;
    background: #ffffff;
    box-sizing: border-box;
    padding: 0rem 1.25rem;
    height: 0px;
    opacity: 0;
    min-width: 22.5rem;
    overflow-y: auto;
    width: 100%;
    max-width: 360px;
    border-top: none;
    &:hover {
        cursor: pointer;
    }
    ${(props: IOnRenderStyleProps) =>
        props.renderStyle &&
        css`
            height: fit-content;
            max-height: 25.5rem;
            opacity: 1;
            padding: 0.5rem 1rem;
            box-shadow: inset 2px -2px 0px #002043, inset -2px -2px 0px #002043;
            border-radius: 0px 0px 16px 16px;
        `}
    ${(props: IOnRenderStyleProps) =>
        props.disabled &&
        css`
            pointer-events: none;
        `}
`;
const MultiSelectMenuOverlay = styled(MultiSelectMenu)`
    position: absolute;
    z-index: 200;
`;
const TagsContainer = styled.div`
    width: 280px;
    overflow: hidden;
    flex-wrap: wrap;
    height: 1.5rem;
    display: flex;
    ${(props: IOnRenderStyleProps) =>
        props.renderStyle &&
        css`
            height: auto;
        `}
    ${(props: IOnRenderStyleProps) =>
        props.disabled &&
        css`
            overflow: unset;
            width: unset;
        `}
`;
const DropdownLabel = styled.div`
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: #333333;
    font-weight: 700;
`;
const MapItem = styled.div`
    margin: 0.5rem 0rem;
    padding-left: 0.5rem;
`;
const CounterSpan = styled.span`
    visibility: hidden;
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    ${(props: IOnRenderStyleProps) =>
        props.renderStyle &&
        css`
            visibility: visible;
            &:hover {
                fill: #0089ec;
                color: #0089ec;
                cursor: pointer;
            }
        `};
`;
const SeparatorLine = styled.div`
    margin: 0px -1rem 0rem -1rem;
    height: 0;
    border: 0.5px solid #eaebee;
    opacity: 0.5;
`;
export default MultiSelect;
