import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { IcoMoon } from '../../../icons';
import styled from 'styled-components';
import { statusBadgeStyle } from '../../../utils';
import { CustomButton, CustomSpacer, FlexedDiv } from '../../atoms';
import { TextDarkGrey5, TextDarkGrey6 } from '../../../constants';

interface PreviousPageProps {
    backIcon?: boolean;
    title?: string;
    subTitle?: string | React.ReactNode;
    secondSubtitle?: string | React.ReactNode;
    handleBack?: string;
    handleBackFunction?: () => void;
    suffixHelper?: string;
    subTitleColor?: string;
    subTitleWeight?: string;
    buttons?: ICustomButton[];
}
interface IStatusIndicatorProps {
    background: string;
    color: string;
}

type PrevPageTopProps = {
    justifyBetween: boolean;
};

const defaultProps = {
    backIcon: true,
};

export const PreviousPage: React.FC<PreviousPageProps> = ({
    backIcon,
    title,
    subTitle,
    secondSubtitle,
    handleBack,
    handleBackFunction,
    suffixHelper,
    subTitleColor,
    subTitleWeight,
    buttons,
}: PreviousPageProps) => {
    const history = useHistory();
    const status: IStatusIndicatorProps =
        suffixHelper !== undefined ? statusBadgeStyle(suffixHelper) : { background: '', color: '' };

    return (
        <Fragment>
            <PrevPageContainer data-testid="prev_page_container">
                <PrevPageTop justifyBetween={buttons && buttons.length !== 0 ? true : false}>
                    <FlexedDiv alignItems="center">
                        {backIcon && (
                            <Fragment>
                                <Icon
                                    onClick={() => {
                                        if (typeof handleBackFunction === 'function') handleBackFunction();
                                        else if (handleBack) history.push(handleBack);
                                        else history.goBack();
                                    }}
                                    style={{ margin: 0 }}
                                    id="prevPageIconBtn"
                                    data-testid="prevPageIconBtn"
                                >
                                    <IcoMoon name="arrow-left" size="1.5rem" />
                                </Icon>
                                <CustomSpacer horizontal={true} space={'1rem'} />
                            </Fragment>
                        )}
                        <TextDarkGrey6
                            weight="700"
                            size="1.5rem"
                            style={{ lineHeight: '2.063rem', textTransform: 'capitalize' }}
                        >
                            {title}
                        </TextDarkGrey6>

                        <StatusIndicator background={status.background} color={status.color}>
                            {suffixHelper}
                        </StatusIndicator>
                    </FlexedDiv>

                    {buttons && buttons.length !== 0 && (
                        <ButtonContainer>
                            {buttons.map((button, index) => (
                                <CustomButton key={index} {...button} />
                            ))}
                        </ButtonContainer>
                    )}
                </PrevPageTop>
                {subTitle && (
                    <PageDescription style={{ marginLeft: backIcon ? '2.5rem' : undefined }}>
                        <TextDarkGrey5 weight={subTitleWeight}>
                            {subTitleColor && subTitleColor !== '' ? (
                                <span style={{ color: subTitleColor }}>
                                    {subTitle} {secondSubtitle !== undefined && <div>{secondSubtitle}</div>}
                                </span>
                            ) : (
                                <Fragment>
                                    {subTitle} {secondSubtitle !== undefined && <div>{secondSubtitle}</div>}
                                </Fragment>
                            )}
                        </TextDarkGrey5>
                    </PageDescription>
                )}
            </PrevPageContainer>
        </Fragment>
    );
};

PreviousPage.defaultProps = defaultProps;

const PrevPageContainer = styled.div`
    display: flex;
    margin-bottom: 2rem;
    flex-direction: column;
`;
const PrevPageTop = styled.div<PrevPageTopProps>`
    display: flex;
    align-items: center;
    justify-content: ${(props: PrevPageTopProps) => (props.justifyBetween ? 'space-between' : 'flex-start')};
`;
const Icon = styled.div`
    margin: 0 1rem 0.4rem 1rem;
    align-content: center;
    &:hover {
        cursor: pointer;
    }
`;
const StatusIndicator = styled.div`
    height: 1.25rem;
    padding: 0.25rem 0.5rem;
    line-height: 1rem;
    font-size: 0.75rem;
    text-transform: capitalize;
    border-radius: 16px;
    color: ${(props: IStatusIndicatorProps) => props.color};
    background: ${(props: IStatusIndicatorProps) => props.background};
    font-weight: 600;
    margin-left: 10px;
    letter-spacing: 0.01em;
`;

const PageDescription = styled.div`
    margin-top: 0.5rem;
    line-height: 1.5rem;
    letter-spacing: -0.05px;
`;

const ButtonContainer = styled.div`
    border-radius: 24px;
    border: 1px solid #002043;
`;
export default PreviousPage;
