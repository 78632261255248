import React from 'react';
import { getFileSize } from '../../../utils';
import styled from 'styled-components';
import { RegulartTooltipTemplate } from '../..';
import { Tooltip } from '../../molecules/Tooltip';

interface ISheetCellProps {
    cell: ISheetCell;
    readyOnly: boolean;
    columnWidths?: IFixedColumnWidths;
}
type CellStyleProps = {
    isValid?: boolean;
    colWidths: IFixedColumnWidths | undefined;
};
export const CellSheet: React.FC<ISheetCellProps> = ({ cell, columnWidths }: ISheetCellProps) => {
    return (
        <StickyTd isValid={cell.isValid} colWidths={columnWidths && columnWidths}>
            {cell.isValid ? (
                <div> {cell.isFileSize ? getFileSize(cell.cellValue) : cell.cellValue}</div>
            ) : (
                <Tooltip
                    placement="Top"
                    tooTipContent={<RegulartTooltipTemplate message={[{ label: 'This value is invalid' }]} />}
                >
                    <ErrorDiv>{cell.cellValue !== '' ? cell.cellValue : <span>&nbsp;</span>}</ErrorDiv>
                </Tooltip>
            )}
        </StickyTd>
    );
};
const ErrorDiv = styled.div`
    height: 100%;
    line-height: 32px;
`;
const StickyTd = styled.td<CellStyleProps>`
    border: ${(props) => (props.isValid ? '1px solid #eaebee' : '2px solid #E84C3D')};
    background-color: ${(props) => (props.isValid ? '#fff' : 'rgba(232, 76, 61, 0.05)')};
    color: ${(props) => (props.isValid ? '#000' : '#00000099')};
    padding: 0.8em 0.5em;
    white-space: nowrap;
    font-size: 12px;
    max-width: 220px;
    overflow: hidden;
    height: 52px;
    position: relative;
    text-overflow: ellipsis;
    &:nth-child(2) {
        width: ${(props) => (props.colWidths ? `${props.colWidths.secondColWidth}px` : '96px')};
        left: ${(props) =>
            props.colWidths ? `-${props.colWidths.firstColWidth + props.colWidths.secondColWidth}px` : '-376px'};
        position: absolute;
        background-color: transparent;
        max-width: none;
        white-space: normal;
    }
    &:nth-child(3) {
        width: ${(props) => (props.colWidths ? `${props.colWidths.firstColWidth}px` : '280px')};
        position: absolute;
        background-color: transparent;
        left: ${(props) => (props.colWidths ? `-${props.colWidths.firstColWidth}px` : '-280px')};
        max-width: none;
        white-space: normal;
    }
`;
