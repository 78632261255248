interface QUICK_ACTIONS_PROPS {
    quickActionsReports: IMenuItem[];
    quickActionsSysAdmin: IMenuItem[];
}
export const EMPTY_STATES: Record<string, IEmptyState> = {
    emptyStateTablePending: {
        title: 'No pending orders yet',
        helperText: 'This section will contain the status of your pending orders.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateTableApproved: {
        title: 'No approved orders yet',
        helperText: 'This section will contain the status of your approved orders.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateTableCancelled: {
        title: 'No cancelled orders yet',
        helperText: 'This section will contain the status of your cancelled orders.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateTableAllOrders: {
        title: 'No completed orders yet',
        helperText: 'This section will contain the status of all completed orders from branches.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateTableDaily: {
        title: 'No transactions yet',
        helperText: 'This section will contain the status of your today’s orders.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateTableCEFilters: {
        title: 'No Results Found',
        helperText: 'Sorry, we couldn’t find any content for applied filter and search.',
        secondaryText: 'Try adjusting your search or filter to find what you’re looking for.',
        icon: { name: 'empty-results', size: '9rem' },
    },
    emptyStateTableUpcoming: {
        title: 'No upcoming transactions yet',
        helperText: 'This section will contain the status of your upcoming transactions.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateTableRerouted: {
        title: 'No rerouted transactions yet',
        helperText: 'This section will contain the status of your rerouted transactions.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateTableHistory: {
        title: 'No transactions yet',
        helperText: 'This section will contain the status of your orders.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateTableWithHardcopy: {
        title: 'No orders yet',
        helperText: 'This section will contain the status of your orders that needs physical documents.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateTableOffices: {
        title: 'No Offices to list',
        helperText: 'This section will list all the branches under HQ.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateDueDateExtension: {
        title: 'No Due Date Extension requests to list',
        helperText: 'This section will list all the Due Date Extension requests.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateAdviserList: {
        title: 'No Advisers in this branch to list',
        helperText: 'This section will list all the Advisers.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateAdviserAccess: {
        title: 'No Advisers to list',
        helperText: 'This section will list all the Advisers.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateActivityLogs: {
        title: 'No Activities to list',
        helperText: 'This section will list all the Activities.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateReports: {
        title: 'No Reports to list',
        helperText: 'This section will list all the Reports downloaded.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateTableOpenCases: {
        title: 'No cases yet',
        helperText: 'This section will contain the status of your open cases.',
        icon: { name: 'empty-eddDashbaord', size: '9rem' },
    },
    emptyStateTableClosedCases: {
        title: 'No cases yet',
        helperText: 'This section will contain the status of your rerouted cases.',
        icon: { name: 'empty-eddDashbaord', size: '9rem' },
    },
    emptyStateTableAllCases: {
        title: 'No cases yet',
        helperText: 'This section will contain all historical cases.',
        icon: { name: 'empty-eddDashbaord', size: '9rem' },
    },
    emptyStateInvestors: {
        title: 'No Investors yet',
        helperText: 'This section will contain the status of all your EDD cases.',
        icon: { name: 'profile_user', size: '9rem' },
    },
    emptyStateOpCasesReroute: {
        title: 'No rerouted transactions yet',
        helperText: 'This section will contain the status of your rerouted cases.',
        icon: { name: 'empty-edd', size: '9rem' },
    },
    emptyStateOpCasesRejected: {
        title: 'No rejected cases yet',
        helperText: 'This section will contain the status of your rejected cases.',
        icon: { name: 'empty-edd', size: '9rem' },
    },
    emptyStateOpCasesHistory: {
        title: 'No cases yet',
        helperText: 'You don’t have any case in your history.',
        icon: { name: 'empty-edd', size: '9rem' },
    },
    emptyStateBranchVerified: {
        title: 'No branch verified transactions yet',
        helperText: 'This section will contain all completed branch transactions.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateAllfund: {
        title: 'No funds yet',
        helperText: 'This section will contain all uploaded funds',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateAllAMP: {
        title: 'No AMP funds yet',
        helperText: 'This section will contain all AMP uploaded funds',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateAllDistribution: {
        title: 'No Distribution funds yet',
        helperText: 'This section will contain all distribution uploaded funds',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateAllNAVPerUnit: {
        title: 'No NAV per Unit funds yet',
        helperText: 'This section will contain all  NAV per Unit uploaded funds',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateAllDocuments: {
        title: 'No Documents yet',
        helperText: 'This section will contain all documents',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateImportMasterFund: {
        title: 'No Master Funds uploads yet',
        helperText: 'This section will contain all Master Fund uploads',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateImportNAV: {
        title: 'No NAV uploads yet',
        helperText: 'This section will contain all NAV uploads',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateImportDistribution: {
        title: 'No Distribution uploads yet',
        helperText: 'This section will contain Distribution uploads',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateImportDocuments: {
        title: 'No Document uploads yet',
        helperText: 'This section will contain Document uploads',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateTableAllUsers: {
        title: 'No user yet',
        helperText: 'This section will contain the list of the users created.',
        icon: { name: 'no-users', size: '9rem' },
    },
    emptyStateTableUserGroups: {
        title: 'No user groups yet',
        helperText: 'This section will contain the list of the user groups created.',
        icon: { name: 'no-users', size: '9rem' },
    },
    emptyStateTableRolesPermissions: {
        title: 'No user roles and assigned permission yet',
        helperText: 'This section will contain the list of the user roles and permissions created.',
        icon: { name: 'no-users', size: '9rem' },
    },
    emptyStateFilterNoResultUsers: {
        title: 'No Filter results found',
        helperText: 'This selected filters did not match with any existing users.',
        icon: { name: 'no-users', size: '9rem' },
    },
    emptyStateFilterNoResultGroups: {
        title: 'No Filter results found',
        helperText: 'This selected filters did not match with any existing groups.',
        icon: { name: 'no-users', size: '9rem' },
    },
    emptyStateFilterNoResultRoles: {
        title: 'No Filter results found',
        helperText: 'This selected filters did not match with any existing Roles and permissions.',
        icon: { name: 'no-users', size: '9rem' },
    },
    emptyStateTrackOrder: {
        title: 'No results found',
        helperText: 'Sorry, we couldn’t find any content for ‘<input>’ and your selected filters.',
        secondaryText: 'Try adjusting your search or filter to find what you’re looking for.',
        icon: { name: 'empty-results', size: '9rem' },
    },
    emptyStateTrackingSummary: {
        title: 'No results found',
        helperText: '',
        icon: { name: 'empty-results', size: '9rem' },
    },
    emptyStateInvestorList: {
        title: 'No Investors in this branch to list',
        helperText: 'This section will list all the Investors.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateReportList: {
        title: 'No Reports in this branch to list',
        helperText: 'This section will list all the Reports.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
    emptyStateoInboxList: {
        title: 'No notifications yet.',
        helperText: 'You’re all caught up! Check back later for new notifications.',
        icon: { name: 'empty_orders', size: '9rem' },
    },
};

export const QUICK_ACTIONS: QUICK_ACTIONS_PROPS = {
    quickActionsReports: [
        {
            label: 'Daily Summary Report',
            subtext: 'in pdf & xlsx',
            index: 0,
        },
        {
            label: 'Account Opening Receipt',
            subtext: 'in pdf',
            index: 1,
        },
    ],
    quickActionsSysAdmin: [
        {
            label: 'Add User',
            index: 0,
        },
        {
            label: 'Remove User',
            index: 1,
        },
        {
            label: 'Demo',
            index: 2,
        },
    ],
};
export const LABEL = {
    account: 'Account',
    accountMenuTitle: 'My Account',
    accountDetails: 'Account Details',
    accountInformation: 'Account Information',
    accountNumber: 'Account No.',
    accountNo: 'Account Number',
    accountOpenedOn: 'Account Opened On',
    accountStatus: 'Account Status',
    accountTypes: 'Account Type',
    accTypeJoint: 'Joint Account Holder',
    accTypePrincipal: 'Principal Account Holder',
    accTypeIndividual: 'Individual Account Holder',
    actions: 'Actions',
    action: 'Action',
    active: 'Active',
    addNewCase: 'Add New Case',
    addNewUser: 'Add New User',
    addNewUserGroup: 'Add New User Group',
    addNewUserGroupSubHeading:
        'Please provide the required information below and review this group before you create it.',
    editUserGroupSubHeading: 'You can modify the information below.',
    addNewUserSubHeading:
        'Please provide the required information below and review this user profile before you create it.',
    addRemark: 'Add remark',
    addRoles: 'Add Roles',
    addRolesDisclaimer: 'Please provide the required information below and review this role before you create it.',
    adviser: 'Adviser',
    adviserName: 'Adviser Name',
    adviserChannel: 'Adviser Channel',
    adviserProfile: 'Adviser Profile',
    advisers: 'Advisers',
    accounts: 'Accounts',
    advisorCode: 'Advisor Code',
    bdmName: 'BDM Name',
    advisorName_ID: 'Adviser Name | ID',
    advisorName: 'Adviser Name',
    accOpeningDate: 'Acc Opening Date',
    agent: 'Advisors',
    agentStatus: 'Agent Status',
    agency: 'Agency',
    aging: 'Aging',
    ageing: 'Ageing',
    all: 'All',
    allUsers: 'All Users',
    ampCategory: ' AMP Categories',
    ampName: 'AMP Name',
    annualReport: 'Annual Report',
    apply: 'Apply',
    approve: 'Approve',
    approveRequest: 'Approve Request',
    approvedTargetDateModal: 'Target date extension approved',
    approveDueDate: 'Approve Due Date',
    answers: 'Answers',
    rejectDueDate: 'Reject Due Date',
    approveOrder: 'Approve Order',
    approveOrderModalMsg: 'has been verified and adviser will be notified.',
    approveTargetDate: 'Approve Target Date',
    areYouSure: 'Are you sure you want to edit this profile?',
    assignedUserGroup: 'Assigned User Group',
    assignUserGroup: 'Assign User Group',
    assignedAdviser: 'Assigned Adviser',
    assignUserRole: 'Assign Role',
    autoAuthorizer: 'auto-authorizer',
    awesome: 'Awesome!',
    bankAccountHolder: "Bank Account Holder's Name",
    bankAccountNumber: 'Bank Account Number',
    bankLocation: 'Bank Location',
    bankName: 'Bank Name',
    bankSwiftCode: 'Bank Swift Code',
    batchCutOffTime: 'Batch Cut-off:',
    batchCutOffTime2: 'Batch Cut Off Time',
    bdmNumber: 'BDM Number',
    both: 'Both',
    branch: 'Branch',
    branches: 'Branches',
    branchID: 'Branch ID',
    branchName: 'Branch Name',
    branchOffice: 'Branch Office',
    branchOffices: 'Branch Offices',
    branchReject: 'BR - Rerouted',
    brRerouted: 'BR - Rerouted',
    bulkImportUsers: 'Bulk Import Users',
    bulkImportUsersDescription:
        'You can use spreadsheets to make changes to the user profile status, then upload your changes all at once.',
    bulkVerify: 'Bulk Verify',
    bulkTransactionVerification: 'Bulk Transaction Verification',
    bulkUploadDocuments: 'Bulk Upload Documents',
    by: 'by',
    cancel: 'Cancel',
    cancelled: 'Cancelled',
    cancelDownload: 'Cancel Download',
    cancelDownloadDescription: `Cancelling the download of the files will stop its progress, and transactions status will remain pending.`,
    cancelDownloadDescriptionLine2: ' Are you sure you want to cancel the download?',
    caseSummary: 'Case Summary',
    close: 'Close',
    checker: 'checker',
    clearAll: 'Clear All',
    clients: 'Clients',
    closedOn: 'Closed On',
    code: 'Code',
    collapse: 'collapse',
    collapseAll: 'Collapse All',
    completed: 'Completed',
    confirmAndReject: 'Confirm & Reject',
    confirmAndReroute: 'Confirm & Reroute',
    confirmNewPassword: 'Confirm New Password',
    continue: 'Submit',
    createdOn: 'Created On',
    currency: 'Currency',
    dailySummaryReportFormA: 'Daily Summary Report', //Changing Label value and removing Form A as CR is the only new Form B trx
    dailyTransactions: 'Daily Transactions',
    dateRange: 'Date Range',
    dateSorting: 'Date',
    daysLeft: 'Days Left',
    delete: 'Deleted',
    sortBy: 'Sort by:',
    departments: 'Departments',
    department: 'Department',
    description: 'Description (optional)',
    descriptionOnly: 'Description',
    details: 'Order Details',
    disabled: 'Disabled',
    document: 'Document',
    documentReceived: 'Document Received',
    cancelCase: 'Cancel Case',
    done: 'Done',
    download: 'Download',
    declarations: 'Declarations',
    downloadAll: 'Download All',
    downloadCancelled: 'Download cancelled',
    downloadDailySummaryReport: 'Daily Summary Report downloaded successfully',
    downloadMonthlyReport: 'Download Monthly Report',
    downloadEDDReport: 'Download EDD Report',
    downloadEDDReportInternal: 'Download EDD Internal Report',
    downloadReports: 'Download Daily Summary Report',
    downloadSelected: 'Download Selected',
    downloadTransactionReport: 'Download Transactions Report ',
    downloadTransactionReportsSuccessfullyMessage: 'transactions report downloaded successfully',
    downloadTransactionReportSuccessfullyMessage: 'Transaction report downloaded successfully',
    downloadPPAOnlineAOFormsSuccessfullyMessage: 'PPA Online Account Opening Forms downloaded sucessfully',
    downloadPPAOnlineAOFormSuccessfullyMessage: ' PPA Online Account Opening Form downloaded sucessfully',
    dropdownPlaceholderReason: 'Select reason',
    dueDate: 'Due Date',
    resetAll: 'Reset All',
    dueDateExtension: 'Due date extension',
    dueDateExtensionApprovedSubtitle1: 'Due date has been updated to',
    dueDateExtensionApprovedSubtitle2: 'and a notification will be sent to the adviser & CE branch.',
    dueDateExtensionApprovedTitle: 'Due date extension request approved',
    dueDateExtensionRejectedSubtitle:
        'Default due date remained and a notification will be sent to the adviser & CE branch.',
    dueDateExtensionRejectedTitle: 'Due date extension request rejected',
    dueDateRequestSubtitle: 'This request has been submitted and<br/> pending for review by checker.',
    dueDateRequestTitle: ' Due Date Extension request submitted',
    dueDateUpdatedTitle: 'Due date updated successfully',
    dueDateUpdateSubtitle1: 'Due date has been updated to',
    dueDateUpdateSubtitle2: 'and a notification will be sent to the adviser & CE branch.',
    eddAdditionalQuestion: 'Additional Question',
    eddAddNewCaseSubtitle: 'Choose an EDD question template and you can add additional question(s) if necessary.',
    eddCases: 'EDD Cases',
    eddCaseID: 'EDD Case ID',
    eddCreatedOn: 'Edd Created On',
    eddManagement: 'EDD Management',
    eddNewCaseFormSubtitle: 'Fill in the EDD details to proceed.',
    eddPlaceHolder: 'Write your question here...',
    eddReason: 'EDD Details',
    editUserGroup: 'Edit User Group',
    editUserGroupSubtitle: 'Please provide the required information below and review this group before you edit it.',
    editUserSubmitted: 'Edit User request submitted',
    editUser: 'Edit User',
    emailAddress: 'Email Address',
    emailID: 'Email ID',
    enabled: 'Enabled',
    english: 'EN',
    enterPassword: 'Enter New Password',
    epfApplicationStatusReport: 'EPF Application Status Report',
    errPasswordEmpty: 'Password cannot be empty!',
    errStaffNricError: 'Staff Nric cannot be empty',
    eventType: 'Event Type',
    expand: 'expand',
    expandAll: 'Expand All',
    extendDueDateRequest: 'Extend Due Date Request',
    extendTargetDate: 'Extend Target Date',
    extendDueDate: 'Extend Due Date',
    extendTargetDateModal: 'Target date extension request sent',
    extendTargetDateModalMessage: 'Your request to extend target date has been sent to HQ for approval.',
    extendTargetDateRequest: 'Extend Target Date Request',
    failed: 'Failed',
    fileName: 'File Name',
    fileSize: 'File Size',
    filterTooltip: 'Filter',
    filterNewCasesBy: 'Filter New Cases by',
    filterReroutedCasesBy: 'Filter Rerouted Cases by',
    filterHistoryCasesBy: 'Filter History Cases by',
    filterUsersBy: 'Filter Users by',
    filterGroupsBy: 'Filter User Groups by',
    filterRolesBy: 'Filter Roles & Permissions by',
    filterTransactionsBy: 'Filter Transactions by',
    filterOrdersBy: 'Filter Orders by',
    filterAdvisersBy: 'Filter Advisers by',
    filterActivityLogsBy: 'Filter Activity Logs by',
    filterOrderBy: 'Filter Orders by',
    financeDashboardPlaceHolder:
        'Search Branch Verified Transactions by Adviser Name and Code, Investors Name and ID, Order number, User ID',
    footerText: 'KIB © All Rights Reserved',
    forgotPassword: 'Forgot Password',
    fundClass: 'Fund Class',
    fundCode: 'Fund Code',
    fundCutOffTime: 'Fund Cut Off Time',
    funding: 'Funding',
    fundingOptions: 'Funding Options',
    fundingOption: 'Funding Option',
    fundName: 'Fund Name',
    fundType: 'Fund Type',
    fundTypes: 'Fund Types',
    fundStatus: 'Fund Status',
    fundUtmc: 'UTMC',
    fundCurrency: 'Fund Currency',
    grantAccessLevel: 'Grant Access Level',
    grossDistribution: 'Gross Distribution',
    groupDescription: 'Group Description (optional)',
    groupName: 'Group Name',
    hasBeenRejected: 'has been rejected.',
    hasBeenVerified: 'has been verified and adviser will be notified.',
    HeadOfDepartments: 'Head of Department',
    hide: 'Hide',
    high: 'High',
    home: 'Home',
    homeBranch: 'Home Branch',
    hq: 'HQ',
    hqReject: 'HQ - Rerouted',
    hqRerouted: 'HQ - Rerouted',
    hqStaff: 'HQ Staff',
    id: 'ID',
    idNo: 'ID No.',
    idNumber: 'ID Number',
    idWithColon: 'ID:',
    importMasterFund: 'Import Master Fund',
    inbox: 'Inbox',
    information: 'Information',
    inReview: 'In Review',
    investmentAmount: 'Investment Amount',
    investments: 'Investments',
    investorName: 'Investor Name',
    investors: 'Investors',
    investorProfile: 'Investor Profile',
    items: 'Items:',
    item: 'Item:',
    jointName: 'Joint Name',
    jointHolder: 'Joint Holder',
    kib: 'KENANGA INVESTORS BERHAD',
    kibAllBranch: 'KIB (All Branches)',
    klBranch: 'Kuala Lumpur Branch',
    lastAccountActivity: 'Last Account Activity',
    lastDownloadedDate: 'Last Downloaded Date',
    lastLogin: 'Last Login',
    lastUpdated: 'Last Updated',
    settingsName: 'Settings Name',
    lastUpdate: 'Last Update',
    level: 'Level:',
    loginButton: 'Login now',
    loginButtonSSO: 'SSO Login',
    loginID: 'Login ID',
    loginStatus: 'Login Status',
    logoutSuccessTitle: 'You have successfully logged out.',
    logoutSuccessSubTitle: 'Logged out on',
    loginText: 'Login to your account',
    logoutButton: 'Logout',
    logoutMessage: 'Are you sure you want to log out?',
    logoutSecondaryMessage: 'Congratulations on opening an account with us. ',
    low: 'Low',
    noCancel: 'Cancel',
    yesLogout: 'Logout',
    logoutTitle: 'Log out from your account',
    logoutSubtitle: 'Are you sure you want to log out?',
    maker: 'maker',
    malay: 'BM',
    managers: 'Managers',
    readALL: 'Read All',
    markAllAsRead: 'Mark all as read',
    medium: 'Medium',
    mobileNo: 'Mobile No.',
    moneyMarket: 'Money Market',
    monthlyfundFact: 'Monthly Fund Fact',
    name: 'Name',
    nameID: 'Name | ID',
    navBack: 'Back',
    navNext: 'Next',
    navSubmit: 'Submit',
    netDistribution: 'Net Distribution',
    new: 'New',
    newDueDate: 'New Due Date',
    newUserApproved: 'New User request approved',
    enableUserApproved: 'Reactivate User request approved',
    enableUserApprovedSubtitle: 'user has been enabled and the maker will be notified.',
    newUserEditApproved: 'Edit User request approved',
    noInformation: 'No Information Yet',
    userEditApproved: 'Edit User request approved',
    userGroupEditApproved: 'Edit User Group request approved',
    userGroupEditApprovedSubtitle: ' user group has been updated and the maker will be notified.',
    userGroupEditRejected: 'Edit User Group request rejected',
    newUserSuspendApproved: 'New User Suspend request approved',
    userSuspendApproved: 'Suspend User request approved',
    userSuspendRequest: 'Suspend User request submitted',
    submissionBranch: 'Submission Branch',
    suspendUserGroupApproved: 'Suspend User Group request approved',
    suspendUserGroupRejected: 'Suspend User Group request rejected',
    suspendUserGroupApprovedSubtitle: 'user group has been suspended and the maker will be notified.',
    suspendUserGroupRequest: 'Suspend User Group request submitted',
    reactivateUserGroupApproved: 'Reactivate User Group request approved',
    reactivateUserGroupRejected: 'Reactivate User Group request rejected',
    reactivateUserGroupApprovedSubtitle: 'user group has been reactivated and the maker will be notified.',
    reactivateUserGroupRequest: 'Reactivate User Group request submitted',
    deleteUserGroupApproved: 'Delete User Group request approved',
    deleteUserGroupRejected: 'Delete User Group request rejected',
    deleteUserGroupApprovedSubtitle: 'user group has been deleted and the maker will be notified.',
    deleteUserGroupRequest: 'Delete User Group request submitted',
    userReactivateRequest: 'Reactivate User request submitted',
    userTerminateRequest: 'Terminate User request submitted',
    newUserTerminationApproved: 'New User Termination request approved',
    userTerminationApproved: 'User Termination request approved',
    newUserCreated: ' account has been created and the maker will be notified.',
    userEnabled: ' account has been enabled and the maker will be notified.',
    newUserEdited: 'details has been updated and the maker will be notified.',
    userEditedApprovedSubtitle: ' user has been updated and the maker will be notified.',
    newUserApprovedSubtitle: ' user has been created and the maker will be notified.',
    newUserSuspended: ' account has been suspended and the maker will be notified.',
    userSuspendApprovedSubtitle: ' user has been suspended and the maker will be notified.',
    newUserTerminated: ' account has been terminated and the maker will be notified.',
    userTerminationApprovedSubtitle: ' user has been terminated and the maker will be notified.',
    newUserCreatedAA: 'New User created successfully',
    newUserEditedAA: 'New User edited successfully',
    newUserGroupCreatedAA: 'New User Group created',
    newUserGroupSubmitted: 'New User Group request submitted',
    newUserGroupApproved: 'New User Group request approved',
    newUserGroupApprovedSubtitle: ' user group has been created and the maker will be notified.',
    newUserGroupRejected: 'New User Group request rejected',
    editUserRejected: 'Edit User request rejected',
    enableUserRejected: 'Reactivate User request rejected',
    suspendUserRejected: 'Suspend User request rejected',
    terminateUserRejected: 'Terminate User request rejected',
    newUserRejected: 'New User request rejected',
    newUserSubmit: 'New User request submitted',
    newFund: 'New Fund',
    no: 'No.',
    noCompletedTransactionMessage: 'No completed transaction is available for download now.',
    noGoBack: 'No, Go Back',
    noNo: 'No',
    noOfAgents: 'No. of Agents',
    noOfClients: 'No. of Clients',
    noOfDailyTransactions: 'No. of Daily Transactions',
    noOfHardcopyPending: 'No. of Physical Documents Pending',
    noOfHardcopy: 'No. of Physical Documents',
    noOfRerouteTransactions: 'No. of Rerouted Transactions',
    noResultsFound: 'No Results found',
    noUser: 'No user yet',
    nric: 'Staff NRIC',
    nricUpper: 'NRIC',
    number: 'No.',
    numberOfAdvisers: 'No. of Advisers',
    numberOfInvestors: 'No. of Investors',
    omni: 'OMNI',
    omniAccountTypes: 'OMNI Account Types',
    okay: 'Okay',
    opCasesSearchPlaceHolder: 'Search by Adviser Name and Code, Investors Name and ID, Order number, User ID',
    openCase: 'Add New Case',
    operationCases: 'Operation Cases',
    order: 'Order',
    orderAndTransactionNo: 'Order & Transaction Number ',
    orderApproved: 'Order approved',
    orderNo: 'Order No.',
    orderNumber: 'Order Number',
    orderRejected: 'Order rejected',
    orderRerouted: 'Order rerouted',
    orders: 'Orders',
    orderSummary: 'Order Summary',
    orderSummaryRejectRemarks: 'This transaction was rejected by',
    orderSummaryRerouteRemarks: 'This transaction was rerouted by',
    others: 'Others',
    overdue1: 'Overdue-1',
    overdue2: 'Overdue-2',
    page: 'Page:',
    password: 'Password',
    passwordRecovery: 'Password Recovery',
    passwordWarning1: 'Password must be of 8 characters',
    passwordWarning2: 'Must contain numbers and characters',
    pending: 'Pending',
    pendingApproval: 'Pending Approval',
    pendingReceive: 'Pending Receive',
    pendingSubmissionOrder: 'Pending Submission Order',
    pendingTargetDate: 'Pending Target Date',
    permissions: 'Permissions',
    plus: '+',
    principalHolder: 'Principal Holder',
    printOnboardingReceipt: 'Print Onboarding Receipt',
    printTransactionsReport: 'Print Transactions Report',
    privacyPolicy: 'Privacy Policy',
    processingBranch: 'Processing Branch',
    productAndServiceManagement: 'Product & Services Management',
    productHighlight: 'Product Highlight',
    products: 'Product',
    productCategory: 'Product Category',
    productTypes: 'Product Type',
    profile: 'Profile',
    prospectusInformation: 'Prospectus Information',
    ppaOnlineAccountOpeningForm: 'PPA Online Account Opening Form',
    ppaOnlineAccountOpeningFormDownload: 'Download PPA Online Account Opening Form',
    quickActions: 'Quick Actions',
    quickOverview: 'Quick Overview',
    reactivated: 'Reactivated',
    reason: 'Reason',
    reasons: 'Reasons',
    reasonsToReject: 'Reason(s) to reject order',
    reasonsToReroute: 'Reason(s) to reroute order',
    receive: 'Receive',
    receiveHardCopyMessage: 'physical document received successfully. ',
    reject: 'Reject',
    rejectRequest: 'Reject Request',
    rejected: 'Rejected',
    rejectReason: 'Reject Reason',
    rejectedReason: 'Rejected Reason',
    rejectOrder: 'Reject Order',
    rejectOrderDescription:
        'This order will be rejected with the selected reasons and added remarks. Adviser needs to make a new application.',
    rejectOrderDescription_part2:
        'Other transaction(s) grouped in the same order will be rejected upon rejecting this transaction.',
    rejectModalTitle: 'Reject Transaction request submitted',
    rejectModalSubtitle: 'This request has been submitted and pending for review.',
    rejectOrderModalMsg: ' has been rejected and adviser will be notified.',
    rejectRemark: 'Reject Remarks',
    rejectTextAreaPlaceholder: 'Please input your remarks here',
    rejectRequestTextAreaPlaceholder: 'Please provide your rejection remarks here',
    rejectRemarkDescription: 'You’re about to reject this request.',
    rejectTargetDate: 'Reject Target Date',
    rejectTargetDateModal: 'Target date extension rejected',
    rejectTransaction: 'Reject Transaction',
    rejectTransactionDescription: 'This transaction will be rejected, and the adviser needs to make a new application.',
    remarks: 'Remarks',
    addRemarks: 'Add Remarks',
    remarksOptional: 'Remarks (optional)',
    rememberMe: 'Remember Me',
    remove: 'Remove',
    report: 'Reports',
    reportName: 'Report Name',
    requestApproved: 'This request has been approved.',
    sample: 'sample',
    requestApprovedMessage: 'Others have already completed this request.',
    requestedBy: 'Requested by',
    requestRejected: 'This request has been rejected.',
    requestRejectedBy: 'Request Rejected by',
    requestRejectRejected: 'Reject Transaction Request Rejected',
    requestRerouteRejected: 'Reroute Transaction Request Rejected',
    reroute: 'Reroute',
    rerouteDueDate: 'Reroute Due Date',
    rerouted: 'Rerouted',
    reroutedReason: 'Rerouted Reason',
    rerouteOrder: 'Reroute Order',
    rerouteOrderDescription: 'This order will be rerouted to the adviser with the following reasons and remarks.',
    rerouteOrderDescription_part2:
        ' Other transaction(s) grouped in the same order will be rerouted back to the adviser upon rerouting this transaction.',
    rerouteModalTitle: 'Reroute Transaction request submitted',
    rerouteModalSubtitle: 'This request has been submitted and pending for review.',
    rerouteOrderModalMsg: ' has been rerouted and adviser will be notified.',
    rerouteTransaction: 'Reroute Transaction',
    rerouteTransactionDescription: 'This transaction will be rerouted to the adviser.',
    reset: 'Reset',
    responses: 'Responses',
    reviewBulkVerifyModalTitle: 'Bulk Verify Transactions request submitted',
    reviewMasterFund: 'Review Import Master Fund Request',
    reviewRequest: 'Review Request',
    reviewRejectRequest: 'Review Reject Request',
    reviewRerouteRequest: 'Review Reroute Request',
    reviewUser: 'Review User Request',
    reviewUserDescription:
        'Please review the following request. You can either approve it or reject it using the buttons in the bottom right corner.',
    reviewUserDescription2: 'You’ll also be able to leave additional remark if you reject it.',
    reviewUserGroup: 'Review User Group Request',
    reviewVerifyModalTitle: 'Verify Transaction request submitted',
    reviewVerifyModalSubtitle: 'This request has been submitted and pending for review.',
    risk: 'Risk',
    riskCategory: 'Risk Category',
    roleDetails: 'Roles Details',
    roleName: 'Role Name',
    role: 'Role',
    roles: 'Roles',
    roleStatus: 'Role Status',
    requestSubmittedToChecker: 'This request has been submitted and pending for review by checker.',
    salesAo: 'Sales-AO',
    salesCharge: 'Sales Charge (%)',
    save: 'Save',
    satisfactory: 'Mark Satisfactory',
    satisfactoryConfirmTitle: 'Are you sure you want to mark the case as Satisfactory',
    satisfactoryConfirmSubTitle: 'This EDD case is marked satisfactory and will now be closed.',
    search: 'Search',
    searchEmptyWithInput: 'Sorry, we couldn’t find any content for',
    searchEmptyWithoutInput: 'Sorry, we couldn’t find any content for applied filter and search.',
    searchPlaceholderAmla: 'Search for Investors by  ID',
    searchPlaceholderAmlaAllCases: 'Search all cases by Investor ID',
    searchPlaceholderAmlaCloseCases: 'Search closed cases by Investor ID',
    searchPlaceholderAmlaOpenCases: 'Search open cases by Investor ID',
    searchPlaceholderAmlaEddNewCases:
        'Search New by Adviser Name and Code, Investor Name and ID, BDM Name, EDD Case ID',
    searchPlaceholderAmlaEddReroutedCases:
        'Search Rerouted by Adviser Name and Code, Investor Name and ID, BDM Name, EDD Case ID',
    searchPlaceholderAmlaEddHistoryCases:
        'Search History by Adviser Name and Code, Investor Name and ID, BDM Name, EDD Case ID',
    searchPlaceHolderByID: 'Search by Investors NRIC and ID',
    searchPlaceHolderByNameID: 'Search by Investors Name and ID',
    searchPlaceHolderByUserName: 'Search by User Name and ID, Department, User Group, Email',
    searchPlaceHolderByUserGroup: 'Search User Groups by User Group Name',
    searchPlaceHolderByUserRole: 'Search Roles & Permissions by Role Name',
    searchPlaceholderActivityLogs: 'Search User Activity by Operation Name, User Name & ID, User Roles',
    searchPlaceholderReports: 'Search Reports by Operation Name, User Name & ID, User Roles',
    searchPlaceholderAdviserAccess: 'Search by Adviser Name and Code, Branch Name',
    searchPlaceholderInbox: 'Search Notifications',
    searchPlaceholderStaff: 'Search by Account type, transaction type, or processing branch',
    searchAdvisers: 'Search by Adviser Name and Code',
    searchPlaceholderProductSettings: 'Search by Fund Name and Code, UTMC',
    searchPlaceholderAMPs: 'Search by AMP Name and Code, UTMC',
    searchPlaceholderUploads: 'Search by file Name',
    searchPlaceHolderEDDClientAccounts: 'Search by Account Number, EDD Case ID',
    searchSearchSettings: 'Search Settings',
    searchAccounts: 'Search Accounts',
    select: 'Select',
    selectAll: 'Select All',
    selectDocument: 'Select document(s) to be resubmitted by adviser',
    selectOne: 'Select One',
    selectOneBranch: 'Select One Branch',
    selectOneOrMore: 'Select one or more',
    selectOneOrMultiple: 'Select one or multiple group',
    selectOneOrMultipleBranch: 'Select one or multiple branch',
    selectRoles: 'Select role(s)',
    showAll: 'Show all',
    ssoID: 'SSO ID (optional)',
    staffName: 'Staff Name',
    staffProfile: 'Staff Profile',
    state: 'State',
    status: 'Status',
    statusHistoryTracking: 'Status History Tracking',
    statusOverview: 'Status Overview',
    effectiveDate: 'Effective Date',
    submit: 'Submit',
    submitted: 'Submitted',
    submittedMsg: 'This request has been submitted and is pending review.',
    submittedMsgAA: ' has been created.',
    editedMsgAA: ' has been edited.',
    submittedOn: 'Submitted On',
    submittedOrder: 'Submitted Order',
    suspended: 'Suspended',
    successful: 'Successful',
    systemConfiguration: 'System Configuration',
    terminated: 'Terminated',
    terminateTransactions: 'Terminate Transactions',
    termsAndConditions: 'Terms & Conditions',
    thisSection: 'This section will contain the list of the users created.',
    thisRequestRejectedBy: 'This request was rejected by',
    titleTransaction: { investment: 'Investment', account: 'Account Details' },
    totalAmount: 'Total Amount',
    totalDocuments: 'Total Documents',
    totalInvestment: 'Total Investment',
    totalInvestments: 'Total Investments',
    totalPermissions: 'Total Permissions',
    totalRecurring: 'Total Recurring',
    totalSales: 'Total Sales',
    totalSwitchOut: 'Total Switch Out Units',
    totalRedeemUnits: 'Total Redeem Units',
    totalRedeemAmount: 'Total Redeem Amount',
    totalUnits: 'Total Units',
    totalUsers: 'Total Users',
    tracking: 'Tracking',
    transaction: 'Transaction',
    transactionDetails: 'Transaction Details',
    transactionRerouted: 'Transactions rerouted',
    transactionRejected: 'Transactions rejected',
    transactions: 'Transactions',
    transactionsReport: 'Transactions Report',
    transactionsSelected: 'Transactions selected',
    transactionTypeLong: 'Transaction Type',
    transactionTypeShort: 'Trans. Types',
    branchTransactions: 'Branch Transactions',
    transactionVerified: 'Transaction verified successfully',
    transactionRequestRejected: 'Verify transaction request rejected',
    transactionTrackingReportDownload: 'Transaction Tracking Report successfully exported.',
    transNo: 'Trans. No.',
    hqBranchOfficesSearch: 'Search by Branch Name and Code, Adviser Name and Code',
    transTypes: 'Trans. Type',
    transactionDate: 'Transaction Date',
    transactionNo: 'Transaction Number',
    tryAdjustingFilter: 'Try adjusting your search or filter to find what you’re looking for.',
    accountTypeAndNumber: 'Account Type & Number',
    signatureAuthorization: 'Signature Authorization',
    type: 'Type',
    types: 'Types:',
    unableToSuspendUserGroup: 'Unable to suspend user group',
    unableToSuspendUserRole: 'Unable to suspend user role',
    unableToSuspendUserGroupSubTitle:
        'A user must have at least one user group. In order to suspend this user group, first remove all the user that assigned to it.',
    unableToSuspendUserRoleSubTitle:
        'A user group must have at least one user role. In order to suspend this user role, first remove all the user groups that assigned to it.',
    unableToTerminateUserGroup: 'Unable to delete user group',
    unableToTerminateUserRole: 'Unable to delete user role',
    unableToTerminateUserGroupSubTitle:
        'A user must have at least one user group. In order to delete this user group, first remove all the user that assigned to it.',
    unableToTerminateUserRoleSubTitle:
        'A user group must have at least one user role. In order to delete this user role, first remove all the user groups that assigned to it.',
    uplineName: 'Adviser Upline Name',
    uploadedOn: 'Uploaded On',
    uploadedDocument: 'Uploaded Document',
    userGroup: 'User Group',
    userGroupDetails: 'User Group Details',
    userGroups: 'User Groups',
    userManagement: 'User Management',
    customerExperienceCE: 'Customer Experience (CE)',
    finance: 'Finance',
    EDD: 'EDD',
    accessActivity: 'Acccess & Activity',
    operation: 'Operation',
    hqCeAllBranchSearch: 'Search All Branches by Branch Name',
    hqCeDueDateSearch:
        'Search Due Date Extension by Branch Name, Adviser Name and Code, Investor Name and ID, Order number, User ID',
    branchDailySearch: 'Search Daily',
    branchUpcomingSearch: 'Search Upcoming',
    branchHardCopySearch: 'Search Physical Doc',
    branchReroutedSearch: 'Search Rerouted',
    branchHistorySearch: 'Search History',
    branchIncompleteSearch: 'Search Incomplete',
    unsatisfactory: 'Mark Unsatisfactory',
    username: 'Username',
    userProfile: 'User Profile',
    users: 'Users',
    utmc: 'UTMC',
    verified: 'Verified',
    verifiedOn: 'Verified On',
    verify: 'Verify',
    verifyBulkTransactionsMessage: 'Transactions verified successfully',
    verifyBulkTransactionsSubtext: 'transactions has been verified and adviser will be notified.',
    verifySelected: 'Verify Selected',
    verifyTransaction: 'Verify Transaction',
    view: 'View',
    viewDetails: 'View Details',
    void: 'Void',
    welcome: 'Welcome!',
    welcomeBack: 'Welcome back,',
    yes: 'Yes',
    yesCancel: 'Yes, Cancel',
    resources: 'Resources',
    generateOperationalReport: 'Generate Operational Report',
    generateOperationalReportSubtitle: 'Adjust the extraction criteria to proceed.',
    reportType: 'Report Type',
    dailyBookingReport: '3rd Party Daily Booking Report',
    dailyBookingReportSubtitle: 'Adjust the extraction criteria and fill in the report sender details to proceed.',
    msfCTAReport: 'MSF Sales from CTA Payment',
    regularInvestmentReport: 'Regular Investment UT, PRS & AMP',
    switchingSubmissionReport: 'Switching Submission Report',
    redemptionSubmissionReport: 'Redemption Submission Report',
    userAccessAuditTrailReport: 'User Access Audit Trail Report',
    userAccountStatusReport: 'User Account Status Report',
    branchControlSalesSubmissionReport: 'Branch Control Sales Submission Summary Report',
    salesSubmissionStaticSummaryMMReport: 'Sales Submission Statistic Summary & Money Market Report',
    salesSubmissionStaticDetailsReport: 'Sales Submission Statistic Details Report',
};
export const FUND_DATA = {
    LABEL_UNIT_SPLIT_CANCELLATION: 'Unit Split / Cancellation',
};
export const SYSTEM_ADMIN = {
    BUTTON_CREATE: 'Create',
    BUTTON_CANCEL: 'Cancel',
    BUTTON_SUBMIT: 'Submit',
    BUTTON_DONE: 'Done',
    BUTTON_SAVE: 'Save',
    BUTTON_YES: 'Yes',
    BUTTON_NO: 'No',
    BUTTON_REJECT: 'Reject',
    BUTTON_APPROVE: 'Approve',
    LABEL_NEW_USER: 'New User request submitted',
    LABEL_USER_SUBMITTED: 'Request submitted successfully.',
    LABEL_USER_CREATE_SUBMITTED: 'Create New User Request has been submitted and pending for review.',
    LABEL_USER_ACTIVATE_SUBMITTED: ' account activation request has been submitted and pending for review.',
    LABEL_USER_TERMINATE_SUBMITTED: 'Terminate User Request has been submitted and pending for review.',
    LABEL_USER_SUSPEND_SUBMITTED: 'Suspend User Request has been submitted and pending for review.',
    LABEL_USER_GROUP_SUSPEND_SUBMITTED: 'Suspend User Group Request has been submitted and pending for review.',
    LABEL_USER_GROUP_DELETE_SUBMITTED: 'Delete User Group Request has been submitted and pending for review.',
    LABEL_USER_GROUP_REACTIVE_SUBMITTED: 'Reactivate User Group Request has been submitted and pending for review.',
    LABEL_PENDING_APPROVAL: 'Pending Approval',
    LABEL_REQUEST_PENDING_APPROVAL: 'Request Pending Approval',
    LABEL_OMNI_ENABLED: 'OMNI Enabled',
    LABEL_OMNI_ENABLE_FOR_THIS_ADVISER: 'Enable OMNI for this adviser?',
    LABEL_REQUESTED_BY: 'Requested by',
    LABEL_EDIT: 'Edit',
    LABEL_WELCOME_BACK: 'Welcome back',
    LABEL_SUSPEND_USER: 'Suspend User',
    LABEL_DEACTIVATE_USER: 'Deactivate User',
    LABEL_SUSPEND_USER_GROUP: 'Suspend User Group',
    LABEL_REACTIVATE_USER_GROUP: 'Reactivate User Group',
    LABEL_DELETE_USER_GROUP: 'Delete User Group',
    LABEL_SUSPEND_ROLE: 'Suspend Role',
    LABEL_DELETE_ROLE: 'Delete Role',
    LABEL_STAFF_NAME: 'Staff Name',
    LABEL_CODE: 'Code',
    LABEL_DEPARTMENTS: 'Departments',
    LABEL_CREATED_ON: 'Created On',
    LABEL_ASSIGNED_USER_GROUPS: 'Assigned User Group',
    LABEL_LAST_LOGIN: 'Last Login',
    LABEL_LAST_UPDATE: 'Last Update',
    LABEL_LOGIN_STATUS: 'Login Status',
    LABEL_EDIT_VIEW: 'Edit /View',
    LABEL_ACTIONS: 'Actions',
    LABEL_USER_GROUP_NAME: 'User Group Name',
    LABEL_BRANCH: 'Branch',
    LABEL_TOTAL_USERS: 'Total Users',
    LABEL_NUMBER_OF_ROLES: 'Number of Roles',
    LABEL_STATUS: 'Status',
    LABEL_ROLE_NAME: 'Role Name',
    LABEL_ROLE_DESCRIPTION: 'Role Description',
    LABEL_ACCESS_LEVEL: 'Access Level',
    LABEL_ACTIVITY_LOGS: 'Activity Logs',
    LABEL_EXPORT_REPORT: 'Export Report',
    LABEL_USER_ACTIVITY: 'User Activity',
    LABEL_REPORTS: 'Reports',
    LABEL_OPERATION_NAME: 'Operation Name',
    LABEL_INITIATED_BY: 'Initiated By',
    LABEL_ROLES: 'Roles',
    LABEL_EVENT_TYPE: 'Event Type',
    LABEL_FILE_SIZE: 'Files Size',
    LABEL_VIEW: 'View',
    LABEL_REACTIVATE_USER: 'Reactivate User',
    LABEL_TERMINATE_USER: 'Terminate User',
    LABEL_SUSPEND_USER_MODAL: 'Are you sure you want to suspend this user?',
    LABEL_SUSPEND_USER_CONFIRMATION_MODAL:
        'Temporarily block this user from accessing OMNI Backoffice by suspending this user. You can reactivate it at any time.',
    LABEL_SUSPEND_USER_GROUP_CONFIRMATION_MODAL:
        'Temporarily block this user group from accessing OMNI Backoffice by suspending this user group. You can reactivate it at any time.',
    LABEL_SUSPEND_USER_MODAL_SUB: 'It is advisable to check the activity log of the user before suspend an account.',
    LABEL_REACTIVATE_USER_MODAL: 'Are you sure you want to reactivate this user?',
    LABEL_REACTIVATE_USER_MODAL_SUB:
        'Reactivated user will regain access to OMNI Backoffice and their previously assigned user group.',
    LABEL_TERMINATE_USER_MODAL: 'Are you sure you want to terminate this user?',
    LABEL_TERMINATE_USER_MODAL_SUB:
        'Terminated user will lose all access to the system immediately.\nYou will not be able to recover this user information.',
    // LABEL_TERMINATE_USER_MODAL_SUB:
    //     'Terminated user will lose all access to the system immediately.\nThis action cannot be undone.',
    LABEL_USER_SUSPEND_SUCCESS: 'User suspended successfully',
    LABEL_ACCOUNT_HAS_BEEN_SUSPENDED: 'account has been suspended.',
    LABEL_USER_REACTIVATED_SUCCESS: 'User reactivated successfully',
    LABEL_ACCOUNT_HAS_BEEN_ACTIVATED: 'account has been activated.',
    LABEL_USER_TERMINATED_SUCCESS: 'User terminated successfully',
    LABEL_ACCOUNT_HAS_BEEN_TERMINATED: 'account has been terminated.',
    LABEL_SUSPEND_USER_GROUP_MODAL: 'Are you sure you want to suspend this user group?',
    LABEL_SUSPEND_USER_GROUP_MODAL_SUB:
        'Temporarily block this user group by suspending this user group. You can reactivate it at any time.',
    LABEL_REACTIVATE_USER_GROUP_MODAL: 'Are you sure you want to reactivate this user group?',
    LABEL_REACTIVATE_USER_GROUP_MODAL_SUB:
        'It is advisable to check the activity log of the user before reactivate a user group.',
    LABEL_DELETE_USER_GROUP_MODAL: 'Are you sure you want to delete this user group?',
    LABEL_DELETE_USER_GROUP_MODAL_SUB:
        'Deleted user group will lose all access to the system immediately.\nThis action cannot be undone.',
    LABEL_USER_GROUP_SUSPEND_SUCCESS: 'User group suspended successfully',
    LABEL_GROUP_HAS_BEEN_SUSPENDED: 'user group has been suspended.',
    LABEL_USER_GROUP_REACTIVATED_SUCCESS: 'User Group reactivated successfully',
    LABEL_GROUP_HAS_BEEN_ACTIVATED: 'user group has been activated.',
    LABEL_USER_GROUP_DELETE_SUCCESS: 'User group deleted successfully',
    LABEL_GROUP_HAS_BEEN_DELETED: 'user group has been deleted.',
    LABEL_REACTIVATE_USER_ROLE: 'Reactivate Role',
    LABEL_SUSPEND_USER_ROLE_MODAL: 'Are you sure you want to suspend this user role?',
    LABEL_SUSPEND_USER_ROLE_MODAL_SUB:
        'Temporarily block this role by suspending this role. You can reactivate it at any time.',
    LABEL_REACTIVATE_USER_ROLE_MODAL: 'Are you sure you want to reactivate this user role?',
    LABEL_REACTIVATE_USER_ROLE_MODAL_SUB:
        'This user role will be reactivated and can be used in OMNI Backoffice again.',
    LABEL_DELETE_USER_ROLE_MODAL: 'Are you sure you want to delete this user role?',
    LABEL_DELETE_USER_ROLE_MODAL_SUB: 'This user role will be deleted and this process cannot be undone.',
    LABEL_USER_ROLE_SUSPEND_SUCCESS: 'User role suspended successfully',
    LABEL_USER_ROLE_SUSPEND_REQUEST: 'Suspend user role request submitted',
    LABEL_USER_ROLE_REACTIVATE_REQUEST: 'Reactivate user role request submitted',
    LABEL_USER_ROLE_DELETE_REQUEST: 'Delete user role request submitted',
    LABEL_ROLE_HAS_BEEN_SUSPENDED: 'user role has been suspended.',
    LABEL_USER_ROLE_REACTIVATED_SUCCESS: 'User role reactivated successfully',
    LABEL_ROLE_HAS_BEEN_ACTIVATED: 'user role has been activated.',
    LABEL_ROLE_DELETE_SUCCESS: 'User role deleted successfully',
    LABEL_ROLE_HAS_BEEN_DELETED: 'user role has been deleted.',
    LABEL_ADVISERS: 'Advisers',
    LABEL_ADVISER_NAME: 'Adviser Name',
    LABEL_BDM_NAME: 'BDM Name',
    LABEL_AGENCY: 'Agency',
    LABEL_UPLINE_CODE: 'Upline Code',
    LABEL_LICENSE_CODE: 'License Code',
    LABEL_AGENT_STATUS: 'Agent Status',
    LABEL_OMNI: 'OMNI',
    LABEL_OMNI_ENABLED_SUCCESS: 'OMNI enabled successfully',
    LABEL_OMNI_DISABLED_SUCCESS: 'OMNI disabled successfully',
    LABEL_HAS_BEEN_GRANTED: 'has been granted access for OMNI.',
    LABEL_HAS_BEEN_DENIED: 'has been denied access for OMNI.',
    LABEL_PROFILE: 'Profile',
    LABEL_OMNI_ENABLED_ACTIVITY: 'OMNI Enable Activity',
    ADD_ROLE: {
        LABEL_ACTIONS: 'Actions',
        LABEL_ACTIVITY_LOGS_SUB: 'Audit logs of all activities, such as verify, reroute, reject transactions, etc',
        LABEL_ACTIVITY_LOGS: 'Activity Logs',
        LABEL_ADD_NEW_CASE: 'Add New Case',
        LABEL_ADD_ROLE: 'Add New Role',
        LABEL_ADVISER_INVESTOR: 'View Adviser and Investors',
        LABEL_ADVISERS_SUB: 'List and details of advisers and associated investors',
        LABEL_ADVISERS_TAB: 'Advisers Tab',
        LABEL_ADVISERS: 'Advisers',
        LABEL_ALL_AMP_TAB: 'All AMP Tab',
        LABEL_ALL_BRANCH_TAB: 'All Branch Tab',
        LABEL_ALL_FUNDS_TAB: 'All Funds Tab',
        LABEL_ALLOW: 'Allow',
        LABEL_ASSIGN_PERM: 'Assign Permissions',
        LABEL_AUTO_AUTHORIZER_CONTENT: 'Full Access to all maker & checker actions',
        LABEL_AUTO_AUTHORIZER: 'Auto Authoriser',
        LABEL_BRANCH_BACKOFFICE: 'Branch Backoffice',
        LABEL_BRANCH_OFFICES_SUB: 'Manage branch associated advisers and investors information',
        LABEL_BRANCH_OFFICES: 'Branch Offices',
        LABEL_BRANCH_PRIVILEGES: 'Branch Backoffice Privileges',
        LABEL_BRANCH_VERIFIED_TAB: 'Branch Verified Tab',
        LABEL_BULK_IMPORT_USER_REQUEST: 'Bulk Import User Request',
        LABEL_BULK_IMPORT_USER: 'Bulk Import User',
        LABEL_BULK_UPLOAD_DOCUMENTS_REQUEST: 'Bulk Upload Documents Request',
        LABEL_BULK_UPLOAD_DOCUMENTS: 'Bulk Upload Documents',
        LABEL_CANCEL_CASE_REQUEST: 'Cancel Case Request',
        LABEL_CANCEL_CASE: 'Cancel Case',
        LABEL_CASE_RESPONSE: 'Case Response (Satisfactory, Not Satisfactory)',
        LABEL_CASES_ACTIONS: 'Cases Actions',
        LABEL_CHECKER_CONTENT: 'Limited Access to review and approve maker’s actions.Can not approve his/her actions.',
        LABEL_CHECKER_CONTENT1: 'Limited Access to review and approve maker’s actions.',
        LABEL_CHECKER_CONTENT2: 'Can not approve his/her actions.',
        LABEL_CHECKER: 'Checker',
        LABEL_CLOSED_TAB: 'Closed Tab',
        LABEL_COLLAPSE_ALL: 'Collapse all',
        LABEL_CONFIGURE_CHECKER_MAKER_SUB: 'Define and modify maker checker tasks',
        LABEL_CONFIGURE_CHECKER_MAKER: 'Configure Maker Checker Tasks',
        LABEL_CREATE_NEW_GROUP_REQUEST: 'Create New User Group Request',
        LABEL_CREATE_NEW_USER_REQUEST: 'Create New User Request',
        LABEL_CREATE_ROLES_PERMISSION_REQUEST: 'Create User Roles & Permissions Request',
        LABEL_CREATE_USER_ROLES_PERMISSIONS: 'Create User Roles & Permissions',
        LABEL_CUSTOMER_EXP_DASHBOARD_SUB: 'Manage branch transactions and approval request',
        LABEL_CUSTOMER_EXP_DASHBOARD: 'Customer Experience Dashboard',
        LABEL_CUSTOMER_EXP_HQ_DASHBOARD: 'Customer Experience (HQ) Dashboard',
        LABEL_CUSTOMER_EXP_SUB: 'All aspects of transactions and sales process.',
        LABEL_CUSTOMER_EXP_TRANSACTIONS: 'Customer Experience - Transactions',
        LABEL_CUSTOMER_EXP: 'Customer Experience',
        LABEL_DDA_TAB: 'DDA Tab',
        LABEL_DDA_TRANSACTIONS_LIST: 'View DDA Transactions List',
        LABEL_DELETE_GROUP_REQUEST: 'Delete User Group Request',
        LABEL_DELETE_ROLE_REQUEST_APPROVED_SUB_UPDATED: 'has been deleted and the maker will be notified.',
        LABEL_DELETE_ROLE_REQUEST_APPROVED: 'Delete Role request approved',
        LABEL_DELETE_ROLE_REQUEST_REJECTED: 'Delete Role request rejected',
        LABEL_DELETE_ROLE_PERMISSION_REQUEST_APPROVED: 'Delete Roles & Permissions request approved',
        LABEL_DELETE_ROLE_PERMISSION_REQUEST_REJECTED: 'Delete Roles & Permissions request rejected',
        LABEL_DELETE_ROLES_PERMISSION_REQUEST: 'Delete User Roles & Permissions Request',
        LABEL_DELETE_USER_GROUP: 'Delete User Group',
        LABEL_DELETE_USER_REQUEST: 'Delete User Request',
        LABEL_DELETE_USER_ROLES_PERMISSIONS: 'Delete User Roles & Permissions',
        LABEL_DELETE_USER: 'Delete User',
        LABEL_DISTRIBUTION_TAB: 'Distribution Tab',
        LABEL_DOCUMENTS_TAB: 'Documents Tab',
        LABEL_DOWNLOAD_EDD_REPORT_REMARKS: 'Download EDD Report (With Remarks)',
        LABEL_DOWNLOAD_EDD_REPORT: 'Download EDD Report',
        LABEL_DOWNLOAD_MSF_CTA: 'Download MSF Sales from CTA Payment Report ',
        LABEL_DOWNLOAD_REGULAR_INVESTMENT: 'Download Regular Investment UT, PRS & AMP Report ',
        LABEL_DOWNLOAD_SWITCHING_SUBMISSION: 'Download Switching Submission Report',
        LABEL_DOWNLOAD_REDEMPTION_SUBMISSION: 'Download Redemption Submission Report',
        LABEL_DOWNLOAD_BRANCH_CONTROL_SALES_SUBMISSION: 'Download Branch Control Sales Submission Summary Report ',
        LABEL_DOWNLOAD_SALES_SUBMISSION_STATISTIC_SUMMARY_AND_MONEY_MARKET:
            'Download Sales Submission Statistic Summary & Money Market Report',
        LABEL_DOWNLOAD_SALES_SUBMISSION_STATISTIC_DETAILS: 'Download Sales Submission Statistic Details Report',
        LABEL_DOWNLOAD_EPF_APPLICATION_STATUS: 'Download EPF Application Status Report',
        LABEL_DOWNLOAD_USER_ACCESS_AUDIT_TRAIL: 'Download User Access Audit Trail Report',
        LABEL_DOWNLOAD_USER_ACCOUNT_STATUS: 'Download User Account Status Report',
        LABEL_DOWNLOAD_3RD_PARTY_DAILY_BOOKING_REPORT: 'Download 3rd Party Daily Booking Report',
        LABEL_DOWNLOAD_SUMMARY_REPORT: 'Download Daily Summary Report',
        LABEL_DOWNLOAD_TRANSACTION_REPORT_PDF: 'Download Transactions Report (pdf)',
        LABEL_DOWNLOAD_TRANSACTION_REPORT: 'Download Transactions Report ',
        LABEL_DUE_DATE_EXTENSION_REQUEST: 'Due Date Extension Request',
        LABEL_DUE_DATE_EXTENSION_TAB: 'Due Date Extension Tab',
        LABEL_EDD_CASE_REVIEW_REQUEST: 'EDD Case Review (Satisfactory, Unsatisfactory) Request',
        LABEL_EDD_CASE_REVIEW: 'EDD Case Review (Satisfactory, Unsatisfactory)',
        LABEL_EDD_MANAGEMENT: 'EDD Management',
        LABEL_EDD_SUB: 'All aspects of EDD case management',
        LABEL_EDD: 'EDD',
        LABEL_EDIT_EPF_UTMC_TOGGLE: 'Edit Single/Multiple UTMC Request',
        LABEL_EDIT_GROUP_REQUEST: 'Edit User Group Request',
        LABEL_EDIT_MODAL: 'Are you sure you want to edit this role?',
        LABEL_EDIT_ROLE_REQUEST_APPROVED_SUB_UPDATED: 'has been updated and the maker will be notified.',
        LABEL_EDIT_ROLE_REQUEST_APPROVED: 'Edit Role request approved',
        LABEL_EDIT_ROLE_REQUEST_REJECTED: 'Edit Role request rejected',
        LABEL_EDIT_ROLE_REQUEST_SUBMITTED: 'Edit Role request submitted',
        LABEL_EDIT_ROLE_PERMISSION_REQUEST_SUBMITTED: 'Edit Roles & Permissions request submitted',
        LABEL_EDIT_ROLE_PERMISSION_REQUEST_APPROVED: 'Edit Roles & Permissions request approved',
        LABEL_EDIT_ROLE_PERMISSION_REQUEST_REJECTED: 'Edit Roles & Permissions request rejected',

        LABEL_EDIT_ROLE: 'Edit Role',
        LABEL_EDIT_ROLES_PERMISSION_REQUEST: 'Edit User Roles & Permissions Request',
        LABEL_EDIT_USER_DETAILS: 'Edit User',
        LABEL_EDIT_USER_GROUP: 'Edit User Group',
        LABEL_EDIT_USER_REQUEST: 'Edit User Request',
        LABEL_EDIT_USER_ROLES_PERMISSIONS: 'Edit User Roles & Permissions',
        LABEL_EPF_TAB: 'EPF Tab',
        LABEL_EPF: 'EPF',
        LABEL_EXPAND_ALL: 'Expand all',
        LABEL_EXPORT_LIST: 'Export List',
        LABEL_EXPORT_REPORT: 'Export Report',
        LABEL_FINANCE_CASES_SUB: 'Manage rerouted cash transactions',
        LABEL_FINANCE_CASES: 'Finance Cases',
        LABEL_FINANCE_DASHBOARD_SUB: 'Manage cash transactions and payment request',
        LABEL_FINANCE_DASHBOARD: 'Finance Dashboard',
        LABEL_FINANCE_REROUTED_TAB: 'Finance Rerouted Tab',
        LABEL_FINANCE: 'Finance',
        LABEL_FREEZE_TRANSACTIONS_REQUEST: 'Freeze Transactions Request',
        LABEL_FREEZE_TRANSACTIONS: 'Freeze Transactions',
        LABEL_FUND_MANAGEMENT: 'Fund Management',
        LABEL_FUND_UPLOADS_SUB: 'Manage file uploads for product and services, NAV, distribution, documents',
        LABEL_FUND_UPLOADS: 'Fund Uploads',
        LABEL_GENERAL_ACCESS_CONTENT: 'Basic Access to view page.',
        LABEL_GENERAL_ACCESS: 'General Access',
        LABEL_GRANT_PRIVILEGES: 'Grant Privileges',
        LABEL_HAS_BEEN_UPDATED_SUCCESSFULLY: 'has been updated successfully',
        LABEL_HISTORY_TAB: 'History Tab',
        LABEL_HQ_BACKOFFICE: 'HQ Backoffice',
        LABEL_HQ_PRIVILEGES: 'HQ Backoffice Privileges',
        LABEL_IMPORT_DISTRIBUTION_REQUEST: 'Import Distribution Request',
        LABEL_IMPORT_DISTRIBUTION: 'Import Distribution',
        LABEL_IMPORT_MASTER_FUND_REQUEST: 'Import Master Fund Request',
        LABEL_IMPORT_MASTER_FUND: 'Import Master Fund',
        LABEL_IMPORT_NAV_REQUEST: 'Import NAV Request',
        LABEL_IMPORT_NAV: 'Import NAV',
        LABEL_INBOX: 'Inbox',
        LABEL_INVESTOR_LIST: 'View Investors List',
        LABEL_INVESTORS_TAB: 'Investors Tab',
        LABEL_LOGIN_ACCESS_SUB: 'All aspects of login & account management',
        LABEL_LOGIN_ACCESS: 'Login Access & Account Management',
        LABEL_LOGIN_LOGOUT: 'Login & Logout',
        LABEL_MAKER_CONTENT: 'Limited Access to maker’s actions (eg: create, edit, download, verify, reroute, reject)',
        LABEL_MAKER_NOTIFIED: 'The maker will be notified.',
        LABEL_MAKER: 'Maker',
        LABEL_MASTER_FUND_TAB: 'Master Fund Tab',
        LABEL_MONEY_SIGHTED_FUND_TAB: 'Money Sighted Fund Tab',
        LABEL_MONEY_SIGHTED_FUND_TRANSACTION_LIST: 'View Money Sighted Fund Transactions List',
        LABEL_MONTHLY_REPORT: 'Download Monthly Report',
        LABEL_MONTHLY_STATUS_REPORT: 'Download EDD Status Report',
        LABEL_NAV_PER_UNIT_TAB: 'NAV per unit Tab',
        LABEL_NEW_CASE_LIST: 'View New Cases List',
        LABEL_NEW_ROLE_REQUEST_APPROVED_SUB_UPDATED: 'has been created and the maker will be notified.',
        LABEL_NEW_ROLE_REQUEST_REJECTED_SUB_UPDATED: 'has been rejected and the maker will be notified.',
        LABEL_NEW_ROLE_REQUEST_SUB: 'This request has been submitted and is pending review.',
        LABEL_NEW_ROLE_REQUEST: 'New Role request submitted',
        LABEL_NEW_ROLE_AND_PERMISSION_REQUEST: 'New Roles & Permissions request submitted',
        LABEL_NEW_TAB: 'New Tab',
        LABEL_NEW_USER_GROUP: 'Create New User Group',
        LABEL_NEW_USER: 'Create New User',
        LABEL_OPERATION_CASES_SUB: 'Manage rerouted & rejected transactions',
        LABEL_OPERATION_CASES: 'Operation Cases',
        LABEL_OPERATION_DASHBOARD_SUB: 'Manage branch verified transactions and perform level 2 checking',
        LABEL_OPERATION_DASHBOARD: 'Operation Dashboard',
        LABEL_OPERATION: 'Operation',
        LABEL_OPERATIONAL_REPORT: 'Operational Report',
        LABEL_OVERVIEW: 'Overview',
        LABEL_PASSWORD_RECOVERY: 'Password Recovery',
        LABEL_PENDING: 'Daily Tab',
        LABEL_PREVIEW_DISTRIBUTION: 'Preview (Distribution)',
        LABEL_PREVIEW_DOCUMENTS: 'Preview (Documents)',
        LABEL_PREVIEW_FUND_FACT: 'Preview (Monthly Fund Fact)',
        LABEL_PREVIEW_HIGHLIGHT: 'Preview (Product Highlight)',
        LABEL_PREVIEW_MASTER_FUND: 'Preview (Master Fund)',
        LABEL_PREVIEW_NAV: 'Preview (NAV per unit)',
        LABEL_PREVIEW_PROSPECTUS: 'Preview (Prospectus Information)',
        LABEL_PREVIEW_REPORT: 'Preview (Annual Report)',
        LABEL_PRODUCT_SETTINGS_SUB: 'Manage the data of product and services, NAV, distribution, documents',
        LABEL_PRODUCT_SETTINGS: 'Product Settings',
        LABEL_PROFILE: 'Profile',
        LABEL_REACTIVATE_GROUP_REQUEST: 'Reactivate User Group Request',
        LABEL_REACTIVATE_ROLE_REQUEST_APPROVED_SUB_UPDATED: 'has been reactivated and the maker will be notified.',
        LABEL_REACTIVATE_ROLE_REQUEST_APPROVED: 'Reactivate Role request approved',
        LABEL_REACTIVATE_ROLE_REQUEST_REJECTED: 'Reactivate Role request rejected',
        LABEL_REACTIVATE_ROLE_PERMISSION_REQUEST_APPROVED: 'Reactivate Roles & Permissions request approved',
        LABEL_REACTIVATE_ROLE_PERMISSION_REQUEST_REJECTED: 'Reactivate Roles & Permissions request rejected',
        LABEL_REACTIVATE_ROLES_PERMISSION_REQUEST: 'Reactivate User Roles & Permissions Request',
        LABEL_REACTIVATE_USER_GROUP: 'Reactivate User Group',
        LABEL_REACTIVATE_USER_REQUEST: 'Reactivate User Request',
        LABEL_REACTIVATE_USER_ROLES_PERMISSIONS: 'Reactivate User Roles & Permissions',
        LABEL_REACTIVATE_USER: 'Reactivate User',
        LABEL_READ_NOTIFICATION: 'Read Notifications',
        LABEL_RECEIVE_HARD_COPY: 'Receive Physical Document',
        LABEL_RECEIVE_PAYMENT_REQUEST: 'Receive Payment Request',
        LABEL_RECEIVE_PAYMENT: 'Receive Payment',
        LABEL_REJECT_REMARKS_SUB: 'You’re about to reject this request.',
        LABEL_REJECT_REMARKS: 'Reject Remarks',
        LABEL_REJECT_TRANSACTIONS_REQUEST: 'Reject Transactions Request',
        LABEL_REJECT_TRANSACTIONS: 'Reject Transactions',
        LABEL_REJECTED_TAB: 'Rejected Tab',
        LABEL_REMARKS: 'Remarks',
        LABEL_REPORTS_SUB: 'Access to generate and download Operational Reports',
        LABEL_REPORTS_TAB: 'Reports Tab',
        LABEL_REPORTS: 'Reports',
        LABEL_REROUTE_TRANSACTIONS_REQUEST: 'Reroute Transactions Request',
        LABEL_REROUTE_TRANSACTIONS: 'Reroute Transactions',
        LABEL_REROUTED_TAB: 'Rerouted Tab',
        LABEL_RESOURCES: 'Resources',
        LABEL_REVIEW_APPROVAL: 'Review & Approval',
        LABEL_REVIEW_EDIT_ROLE_REQUEST: 'Review New Role',
        LABEL_REVIEW_ROLE_REQUEST_SUB_1:
            'Please review the following request. You can either approve it or reject it using the buttons in the bottom right corner.',
        LABEL_REVIEW_ROLE_REQUEST_SUB_2: 'You’ll also be able to leave additional remark if you reject it. ',
        LABEL_REVIEW_ROLE_REQUEST: 'Review Role Request',
        LABEL_ROLE_CREATED_SUB: 'has been created',
        LABEL_ROLE_CREATED: 'New Role created successfully',
        LABEL_ROLE_DESCRIPTION: 'Role Description (optional)',
        LABEL_ROLE_DETAILS: 'Role Details',
        LABEL_ROLE_NAME: 'Role Name',
        LABEL_ROLE_REQUEST_APPROVED_SUB_UPDATED: 'has been updated',
        LABEL_ROLE_REQUEST_APPROVED_SUB: 'has been created',
        LABEL_ROLE_REQUEST_APPROVED: 'Roles & Permissions request approved',
        LABEL_ROLE_REQUEST_REJECTED: 'Roles & Permissions request rejected',
        LABEL_ROLE_SUBTITLE_1: 'User roles let you define what users in different roles can see and do in OMNI.',
        LABEL_ROLE_SUBTITLE_2: 'Decide, which modules they can use and which access rights they have.',
        LABEL_ROLE_UPDATED: 'Role Updated',
        LABEL_ROLES_PERMISSION_TAB: 'Roles & Permissions Tab',
        LABEL_STAFF_DETAILS: 'View Staff Details',
        LABEL_STATUS: 'Status',
        LABEL_SUSPEND_GROUP_REQUEST: 'Suspend User Group Request',
        LABEL_SUSPEND_ROLE_REQUEST_APPROVED_SUB_UPDATED: 'has been suspended and the maker will be notified.',
        LABEL_SUSPEND_ROLE_REQUEST_APPROVED: 'Suspend Role request approved',
        LABEL_SUSPEND_ROLE_REQUEST_REJECTED: 'Suspend Role request rejected',
        LABEL_SUSPEND_ROLE_PERMISSION_REQUEST_APPROVED: 'Suspend Roles & Permissions request approved',
        LABEL_SUSPEND_ROLE_PERMISSION_REQUEST_REJECTED: 'Suspend Roles & Permissions request rejected',
        LABEL_SUSPEND_ROLES_PERMISSION_REQUEST: 'Suspend User Roles & Permissions Request',
        LABEL_SUSPEND_USER_GROUP: 'Suspend User Group',
        LABEL_SUSPEND_USER_REQUEST: 'Suspend User Request',
        LABEL_SUSPEND_USER_ROLES_PERMISSIONS: 'Suspend User Roles & Permissions',
        LABEL_SUSPEND_USER: 'Suspend User',
        LABEL_SYSTEM_SETTINGS_SUB: 'Configure and update on system level',
        LABEL_SYSTEM_SETTINGS: 'System Settings',
        LABEL_SYSTEM: 'System',
        LABEL_TERMINATE_USER_REQUEST: 'Terminate User Request',
        LABEL_TERMINATE_USER: 'Terminate User',
        LABEL_UNFREEZE_TRANSACTIONS_REQUEST: 'Unfreeze Transactions Request',
        LABEL_UNFREEZE_TRANSACTIONS: 'Unfreeze Transactions',
        LABEL_UPCOMING: 'Upcoming Tab',
        LABEL_USER_LOGIN: 'User Login',
        LABEL_USER_ACTIVITY_TAB: 'User Activity Tab',
        LABEL_USER_DETAILS_REQUEST: 'Edit User Details Request',
        LABEL_USER_GROUP_TAB: 'User Group Tab',
        LABEL_USER_MANAGEMENT_SUB: 'Define and modify user, user group, user roles and associated permissions',
        LABEL_USER_MANAGEMENT: 'User Management',
        LABEL_USER_REQUEST: 'Delete User Request',
        LABEL_USERS_TAB: 'Users Tab',
        LABEL_VERIFY_TRANSACTIONS_REQUEST: 'Verify Transactions Request',
        LABEL_VERIFY_TRANSACTIONS: 'Verify Transactions',
        LABEL_VIEW_ACTIVITY_DETAILS: 'View Activity Details',
        LABEL_VIEW_ADVISER_LIST: 'View Advisers List',
        LABEL_VIEW_ADVISER_PROFILE: 'View Advisers Profile',
        LABEL_VIEW_ALL_BRANCH_LIST: 'View All Branch List',
        LABEL_VIEW_AMP_LIST: 'View AMP List',
        LABEL_VIEW_BRANCH_TRANSACTIONS: 'View Branch Transactions',
        LABEL_VIEW_BRANCH_VERIFIED_LIST: 'View All Branch Verified List',
        LABEL_VIEW_BRANCH_VERIFIED_TRANSACTION_LIST: 'View Branch Verified Transactions List',
        LABEL_VIEW_CLOSED_CASE_LIST: 'View Closed Cases List',
        LABEL_VIEW_DETAILS_ADVISER: 'View Details (Adviser Profile, Investor List)',
        LABEL_VIEW_DETAILS_AMP: 'View Details (AMP Details)',
        LABEL_VIEW_DETAILS_CASE: 'View Details (Responses, Case Summary, Assigned Adviser)',
        LABEL_VIEW_DETAILS_DISTRIBUTION:
            'View Details (Gross Distribution, Net Distribution, Unit Split / Cancellation)',
        LABEL_VIEW_DETAILS_FUND: 'View Details (Fund Details)',
        LABEL_VIEW_DETAILS_HISTORY: 'View Details (History)',
        LABEL_VIEW_DETAILS: 'View Details (Investments, Investors Profile, Transactions)',
        LABEL_VIEW_DOCUMENTS_LIST: 'View Fund Documents List',
        LABEL_VIEW_DUE_DATE_EXTENSION_LIST: 'View Due Date Extension List',
        LABEL_VIEW_EPF_TRANSACTION_LIST: 'View EPF Transactions List',
        LABEL_VIEW_FINANCE_REROUTED_TRANSACTIONS_LIST: 'View Finance Rerouted Transactions List',
        LABEL_VIEW_FUND_LIST: 'View Fund List',
        LABEL_VIEW_HISTORY_CASES_LIST: 'View History Cases List',
        LABEL_VIEW_HISTORY_LIST: 'View History List',
        LABEL_VIEW_NAV_PER_UNIT_LIST: 'View NAV per unit List',
        LABEL_VIEW_OVERVIEW: 'View Overview',
        LABEL_VIEW_REJECTED_LIST: 'View Rejected List',
        LABEL_VIEW_REPORTS_LIST: 'View Reports List',
        LABEL_VIEW_REROUTED_CASE_LIST: 'View Rerouted Cases List',
        LABEL_VIEW_REROUTED_LIST: 'View Rerouted List',
        LABEL_VIEW_ROLES_PERMISSION_LIST: 'View User Roles & Permissions List',
        LABEL_VIEW_TRANSACTION_LIST: 'View Transactions List',
        LABEL_VIEW_USER_ACTIVITY_LIST: 'View User Activity List',
        LABEL_VIEW_USER_DETAILS: 'View User Details',
        LABEL_VIEW_USER_GROUP_LIST: 'View User Group List',
        LABEL_VIEW_USERS_LIST: 'View User List',
        LABEL_WITH_HARD_COPY: 'Physical Doc Tab',
    },
    ADVISER_PROFILE: {
        LABEL_ADVISER_NAME_CODE: 'Adviser Name & Code',
        LABEL_NRIC: 'NRIC',
        LABEL_BDM: 'BDM Details',
        LABEL_PERSONAL_DETAILS: 'Personal Details',
        LABEL_LICENSE_CODE: 'License Code',
        LABEL_HOME_BRANCH: 'Home Branch',
        LABEL_REGION: 'Region',
        LABEL_ADVISER_CHANNEL: 'Adviser Channel',
        LABEL_OMNI_ENABLED: 'OMNI Enabled',
        LABEL_ADVISER_RANK: 'Adviser Rank',
        LABEL_ADVISER_BDM: "Adviser's BDM Name & Code",
        LABEL_ADVISER_BDM_EMAIL: "Adviser's BDM Email",
        LABEL_UPLINE_NAME_CODE: 'Upline Name & Code',
        LABEL_AGENCY: 'Agency',
        LABEL_EMAIL: 'Email Address',
        LABEL_MOBILE: 'Mobile Number',
        LABEL_CONTACT_DETAILS: 'Contact Details',
        LABEL_ADDRESS_INFO: 'Address Information',
        LABEL_PERMANENT_ADDRESS: 'Permanent Address',
        LABEL_POSTCODE: 'Postcode',
        LABEL_TOWN: 'Town',
        LABEL_STATE: 'State',
        LABEL_COUNTRY: 'Country',
    },
    SYSTEM_SETTING: {
        LABEL_UTMC_SETTING: 'Edit Multiple UTMC Settings',
        LABEL_UTMC_SETTING_REVIEW: 'Review Edit Multiple UTMC Settings Request',
        LABEL_BANNER_REQUEST_PENDING_APPROVAL: 'Request Pending Approval',
        BUTTON_SUBMIT_REQUEST: 'Submit Request',
        LABEL_MULTIPLE_UTMC: 'Multiple UTMC',
        LABEL_EFFECTIVE_DATE: 'Effective Date',
        LABEL_SEND_EMAIL_INBOX: 'Send push, inbox and email notification to all UT Licensed Advisers',
        LABEL_SEND_INBOX: 'Send inbox notification to all Backoffice Users',
        LABEL_MULTIPLE_UTMC_DESCRIPTION:
            'Disable this to request switching from Multiple to Single UTMC per application for all EPF funding option investment.',
        LABEL_REQUEST_SETTING: 'Request Settings',
        LABEL_UTMC_SECOND_DESCRIPTION: 'By default the effective time of transition is set at 12.00 am.',
        LABEL_UTMC_SECOND_DESCRIPTION_APPROVE_REJECT:
            'You’ll also be able to leave additional remark if you reject it.',
        LABEL_UTMC_DESCRIPTION_APPROVE_REJECT:
            'Please review the following request. You can either approve it or reject it using the buttons in the bottom right corner.',
        LABEL_UTMC_DESCRIPTION:
            'The following settings will configure the multiple UTMC submission per application for all EPF funded investments.',
        YES_OR_NO_TEXT: `Click ‘Yes’ to submit, or ‘No’ to go back.`,
        YES_OR_NO_APPROVE_TEXT: `Click ‘Yes’ to approve, or ‘No’ to go back.`,
    },
};

export const EDD = {
    LABEL_ADD_EDD: 'Add New EDD Case',
    LABEL_AMLA_REMARKS: 'AMLA Remarks',
    LABEL_AMLA: 'AMLA',
    LABEL_EDD_FILTER_TITLE: 'Filter EDD Cases by',
    LABEL_EDD_DATE: 'Date',
    LABEL_EDD_DATE_RANGE: 'Date Range',
    LABEL_EDD_STATUS: 'Status',
    LABEL_DOWNLOAD_EDD_STATUS_REPORT: 'Download EDD Status Report',
    LABEL_ADD_EDD_SUB: 'Please select an account to proceed.',
    LABEL_Nationality: 'Nationality',
    LABEL_Malaysia: 'Malaysia',
    LABEL_Email: 'Email',
    LABEL_Phone: 'Phone',
    LABEL_Occupation: 'Occupation',
    LABEL_Employer_Name: 'Employer Name',
    LABEL_Annual_Income: 'Gross Annual Income (MYR)',
    LABEL_Account_Summary: 'Account Summary',
    LABEL_Account_Number: 'Account Number',
    LABEL_Account_Created_On: 'Account Created on',
    LABEL_Type_Investor: 'Type of Investor',
    LABEL_Account_Holder: 'Account Holder',
    LABEL_Assigned: 'Assigned Advisor & Code',
    LABEL_LICENSE_CODE: 'License Code',
    LABEL_ADVISER_RANK: 'Adviser Rank',
    LABEL_HOME_BRANCH: 'Home Branch',
    LABEL_REGION: 'Region',
    LABEL_UNSATISFACTORY_RATING: 'Reason for unsatisfactory rating',
    LABEL_REROUTE_CASE: 'Reroute EDD Case',
    LABEL_REROUTE_CASE_SUB: 'This case will be rerouted to the adviser.',
    LABEL_REASON_OPTIONAL: 'Reason (optional)',
    LABEL_INVESTORS_SELECTED: 'Investors selected',
    LABEL_INVESTOR_SELECTED: 'Investor selected',
    LABEL_ADVISER_CHANNEL: 'Adviser Channel',
    LABEL_OMNI_ENABLED: 'OMNI Enabled',
    LABEL_ADVISERS_BDM_NAME_CODE: "Adviser's BDM Name & Code",
    LABEL_UPLINE_NAME_CODE: 'Upline Name & Code',
    LABEL_AGENCY: 'Agency',
    LABEL_Onboarding_Receipts: 'Onboarding Receipts',
    LABEL_Product_Type: 'Product Type',
    LABEL_Funding_Option: 'Funding Option',
    LABEL_Trigger: 'EDD Trigger',
    LABEL_Target_Due_Date: 'Target Due Date',
    LABEL_Reason: 'EDD Reason',
    LABEL_Others: 'Others',
    LABEL_Select_Option: 'Select an option',
    LABEL_Select_One: 'Select One',
    LABEL_SELECT_STATUSES: 'Select Statuses',
    LABEL_Select_Date: 'Select a date',
    LABEL_REPORT_TYPE: 'Report Type',
    LABEL_REPORT_TYPE_PH: 'Monthly',
    LABEL_EDD_CASE_STATUS_PH: 'Select One',
    LABEL_EDD_CASE_STATUS: 'EDD Case Status',
    DROPDOWN_Account_Opening: 'Account Opening',
    DROPDOWN_Transaction: 'Transaction',
    DROPDOWN_End_of_Day: 'End of Day',
    DROPDOWN_Client_Confirmation: 'Client Confirmation ',
    DROPDOWN_High_Risk: 'ML/TF High Risk',
    DROPDOWN_High_Risk_Country: 'High Risk Country',
    DROPDOWN_High_Risk_Business: 'High Risk Business',
    DROPDOWN_Regulatory_Enforcement: 'Regulatory Enforcement',
    DROPDOWN_PEP: 'Politically Exposed Person (PEP)',
    DROPDOWN_PEC: 'Politically Exposed Corporation (PEC)',
    BUTTON_CANCEL: 'Cancel',
    BUTTON_SUBMIT: 'Submit',
    BUTTON_YES: 'Yes',
    BUTTON_NO: 'No',
    BUTTON_CONTINUE: 'Continue',
    BUTTON_DELETE: 'DELETE',
    BUTTON_SAVE_CLOSE: 'Save & Close',
    BUTTON_DOWNLOAD: 'Download',
    BUTTON_ADDITIONAL_QUESTION: 'Additional Question',
    CANCEL_REQUEST: 'Cancel Request',
    CANCEL_REQUEST_SUB: 'This case will be cancelled.',
    CASE_CANCELLED: 'Case Cancelled',
    CASE_CANCELLED_SUB: 'has been cancelled.',
    DEFAULT_VALUE: 'Default Edd Questions',
    DOWNLOAD_MONTHLY_REPORT: 'Download Monthly Report',
    DOWNLOAD_MONTHLY_REPORT_SUB: 'Proceed to download an excel sheet of the EDD Status Report.',
    DOWNLOAD_MONTHLY_REPORT_MODAL_SUB: 'EDD Status Report downloaded successfully.',
    LABEL_EMAIL: 'Email Address',
    LABEL_EMAIL_INFO: 'alanpoe@gmail.com',
    LABEL_MOBILE_NO: 'Mobile Number',
    LABEL_MOBILE_NO_INFO: '+601 186602166',
    LABEL_PERMANENT_ADDRESS_1: 'Permanent Address 1',
    LABEL_PERMANENT_ADDRESS_1_INFO: 'B-2-3A, Level 4',
    LABEL_PERMANENT_ADDRESS_2: 'Permanent Address 2',
    LABEL_PERMANENT_ADDRESS_2_INFO: 'Menara Olympia',
    LABEL_PERMANENT_ADDRESS_3: 'Permanent Address 3',
    LABEL_PERMANENT_ADDRESS_3_INFO: 'Jalan Raja Chulan',
    LABEL_POSTCODE: 'Postcode',
    LABEL_POSTCODE_INFO: '55400',
    LABEL_CITY_TOWN: 'City/Town',
    LABEL_CITY_TOWN_INFO: 'Petalling Jaya',
    LABEL_STATE: 'State',
    LABEL_STATE_INFO: 'Selangor',
    LABEL_COUNTRY: 'Country',
    LABEL_COUNTRY_INFO: 'Malaysia',
    LABEL_CONTACT_DETAIL: 'Contact Details',
    LABEL_ADDRESS_INFO: 'Address Information',
    LABEL_NOT_SATISFACTORY: 'UnSatisfactory',
    LABEL_SATISFACTORY: 'Satisfactory',
    LABEL_CASE_DETAILS: 'Case Details',
    LABEL_ADVISER_NAME_CODE: 'Adviser Name & Code',
    LABEL_ADVISERS_BDM_EMAIL: "Adviser's BDM Email",
    LABEL_CASE_ID: 'EDD Case ID',
    LABEL_CREATED_DATE: 'Created Date',
    LABEL_ACCOUNT_SUMMARY: 'Account Summary',
    LABEL_EDD_TRIGGER: 'EDD Trigger',
    LABEL_EDD_TRIGGER_INFO: 'Malaysia',
    LABEL_EDD_REASON: 'EDD Reason',
    LABEL_EDD_REASON_INFO: 'ML/TF High Risk',
    LABEL_SATISFACTORY_STATUS: 'Satisfactory Status',
    LABEL_SATISFACTORY_STATUS_INFO: '-',
    LABEL_CLOSED_DATE: 'Closed Date',
    LABEL_CANCELLED_DATE: 'Cancelled Date',
    LABEL_CANCELLATION_DATE: 'Cancellation Date',
    LABEL_CLOSED_DATE_INFO: '-',
    LABEL_EDD_ANSWER: 'EDD Answer',
    LABEL_EDD_QUESTIONS: 'EDD Questions',
    LABEL_EDD_HEADER: 'Mouaz Ali Al Balushi',
    LABEL_EDD_MODAL_DOWNLOAD_TITLE: 'Download EDD Report (with remarks)',
    LABEL_EDD_MODAL_DOWNLOAD_SUCCESS: 'EDD Report downloaded successfully',
    LABEL_EDD_MODAL_SATISFACTORY_TITLE: 'Case marked as satisfactory',
    LABEL_EDD_MODAL_UNSATISFACTORY_TITLE: 'Case marked as unsatisfactory',
    LABEL_EDD_MODAL_UNSATISFACTORY_CONFIRMATION_TITLE: 'Are you sure you want to mark the case as unSatisfactory?',
    LABEL_EDD_MODAL_UNSATISFACTORY_CONFIRMATION_SUB_TITLE:
        'This EDD case is marked unsatisfactory and will now be rerouted.',
    LABEL_EDD_MODAL_UNSATISFACTORY_SUB_TITLE: ' has been rerouted back to adviser and the adviser will be notified',
    LABEL_EDD_MODAL_SUB: 'has been closed and the adviser will be notified.',
    LABEL_EDD_MODAL_CONFIRM_TITLE: 'Are you sure you want to mark the case as Satisfactory?',
    LABEL_EDD_MODAL_CONFIRM_TITLE_SUB: 'This EDD case is marked satisfactory and will now be closed.',
    LABEL_EDD_MODAL_CONFIRM_TITLE_SUB_2: 'EDD Report (with remarks) downloaded successfully.',
    LABEL_ADD_NEW_CASE: 'Add New EDD Case',
    LABEL_ADD_NEW_CASE_SUB: 'Please select an investor to proceed.',
    LABEL_INVESTOR_COUNT: '24 Investor(s) found',
    LABEL_NAME_BANNER: 'Alima Hester',
    LABEL_NAME_BANNER_SUB: '880808088888 | 3 Accounts',
    LABEL_QUESTION_TEMPLATE: 'Edd Question Template',
    LABEL_EDD_CASE_TITLE: 'Case initiated successfully',
    LABEL_EDD_CASE_SUB_TITLE: 'New EDD case has been created and the adviser will be notified.',
    LABEL_PERSONAL_DETAILS: 'Personal Details',
    LABEL_REMARKS: 'Remarks',
    LABEL_SATISFACTORY_BANNER_TITLE: 'EDD Case Review',
    LABEL_UNSATISFACTORY_BANNER_TITLE: "Mark The Case 'Unsatisfactory'?",
    // LABEL_UNSATISFACTORY_BANNER_SUB: `Add minimum one remark for ${key} to proceed`
    PENDING_RESPONSE_TITLE: 'No response yet',
    PENDING_RESPONSE_DESCRIPTION: 'We’ll notify you when there is response from adviser',
};

export const ERRORCODE = {
    TITLE404: 'Oops! This is not the page you’re looking for.',
    LABEL_ERROR_TITLE_MAINTENANCE: "Sorry! We're down for maintenance.",
    LABEL_ERROR_CODE: 'Error code: 404',
    LABEL_ERROR_CODE_503: 'Error code: 503',
    ERROR_MESSAGE_404: 'Double check the address, you may have mistyped the URL or the page has moved.',
    LABEL_ERROR_MESSAGE_500: 'Unexpected error encountered. Please contact support.',
    LABEL_ERROR_MESSAGE_503:
        'We will be back up shortly. If you need help for urgent issues, email us at info@kenanga.com.my',
    BUTTON_BACK: 'Back to Dashboard',
    LABEL_ERROR_APP_TITLE: 'Error related to the Application Functionality.',
    LABEL_ERROR_APP_MESSAGE: 'Error related to the Application Functionality.',
    LABEL_ERROR_APP_CODE: 'Error code: App Error',
    LABEL_ERROR_CODE_500: 'Error code: 500',
};

export const ORDER_SUMMARY_LABELS = {
    TITLES: {
        transactionSummary: 'Transaction Summary',
        accountSummary: 'Account Summary',
        personalDetails: 'Personal Details',
        bankInformation: 'Banking Details',
        employmentInfo: 'Employment Information',
        epfDetails: 'EPF Details',
        fatca: 'Foreign Account Tax Compliance Act (FATCA) Declaration',
        crs: 'Common Reporting Standard (CRS) Declaration',
        fea: 'FEA Declaration',
        transactionDetails: 'Transaction Details',
        investmentSummary: 'Investment Summary',
        paymentSummary: 'Payment Summary',
        contactDetails: 'Contact Details',
        addressInformation: 'Address Information',
    },
};

export const CE_DASHBOARD_LABELS = {
    DAILY: {
        submitTransactionRequest: 'Verify transaction request submitted',
        approveTransactionRequest: 'Approve verify transaction request?',
        approveTransactionRequestOrderSummary: 'Approve verify transaction request?',
        transactionRequestApproved: 'Verify transaction request approved',
        transactionRequestReject: 'Verify transaction request rejected',
        verifyThisTransaction: 'Verify this transaction',
        verifyThisTransactionMessage:
            'Upon verifying this transaction, other transaction(s) grouped in the same order can only be verified.',
        verifyThisTransactionMessageCr: 'The status of this transaction will be changed to pending approval.',
        verifyThisTransactionMessageCrBulk: 'The status of this transaction(s) will be changed to pending approval.',
        verifyOnlyThisTransactionMessage: 'The status of this transaction will be changed to completed.',
        verifyOnlyThisTransactionMessageBulk: 'The status of this transaction(s) will be changed to completed.',
        yesOrNoMessage: `Click ‘Yes’ to submit, or ‘No’ to go back.`,
        yesOrNoApprove: `Click ‘Yes’ to approve, or ‘No’ to go back.`,
        yesOrNoVerify: `Click ‘Yes’ to verify, or ‘No’ to go back.`,
        approveRerouteOrderRequest: 'Approve reroute order request?',
        approveRerouteOrderRequestMessage:
            'Other transaction(s) grouped in the same order will be rerouted back to the adviser upon rerouting this transaction.',
        approveRejectOrderRequest: 'Approve reject order request?',
        approveRejectOrderRequestMessage:
            'Other transaction(s) grouped in the same order will be rejected upon rejection of this transaction.',
        rerouteRequestSubmit: 'Reroute order request submitted',
        rerouteRequestApproved: 'Reroute order request approved',
        rerouteRequestRejected: 'Reroute order request rejected',
        rejectRequestSubmit: 'Reject order request submitted',

        rejectRequestApproved: 'Reject order request approved',
        rejectRequestRejected: 'Reject order request rejected',
        viewMore: 'View More',
        viewLess: 'View Less',
        reviewBulkVerifyModalTitle: 'Bulk verify transactions request submitted',
    },
    REROUTED: {},
    EXPORT_LIST_SUCCESS_MESSAGE: 'File exporting is in progress. We will update you once exporting is done.',
};
