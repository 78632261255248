import React, { useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import styled from 'styled-components';
import { SummaryCard } from '../..';

import { IcoMoon } from '../../../icons';
import { CustomButton, FlexedDiv } from '../../atoms';
import FilterPill from '../../../components/molecules/FilterPills';
import { TextDarkGrey5, LABEL } from '../../../constants';

const numberOfSlides = (maxVisibleSlides: number, windowWidth: number) => {
    const maxw = maxVisibleSlides < 4 ? 4 : maxVisibleSlides;
    if (windowWidth > 1200) return maxw;
    if (windowWidth > 992) return 4;
    if (windowWidth > 768) return 3;
    return 2;
};
export const SliderOverview: React.FC<ISliderOverviewProps> = ({
    slideMargin,
    maxVisibleSlides,
    overviewData,
    handleCardClick,
    customTitle,
    filterpillData,
}: ISliderOverviewProps) => {
    const [currentSlidePage, setCurrentSlidePage] = useState(0);
    const [scrollSize, setScrollSize] = useState(0);
    const sliderRef = useRef<HTMLInputElement | null>(null);

    const visibleSlides = numberOfSlides(maxVisibleSlides, scrollSize);
    const totalPages: number = Math.ceil(overviewData.length / visibleSlides) - 1;

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            setScrollSize(entries[0].contentRect.width);
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        resizeObserver.observe(sliderRef.current);
    }, [sliderRef, scrollSize, totalPages]);

    // Position slider on resize
    useEffect(() => {
        if (sliderRef && sliderRef.current) {
            if (currentSlidePage > totalPages) setCurrentSlidePage(totalPages);
            sliderRef.current.style.transform = `translate3D(-${currentSlidePage * scrollSize}px, 0, 0)`;
        }
    }, [sliderRef, currentSlidePage, scrollSize, totalPages]);

    const handleSlideMove = (forward: boolean) => {
        if (sliderRef.current && (currentSlidePage !== 0 || currentSlidePage !== totalPages)) {
            sliderRef.current.style.transform = `translate3D(-${
                (currentSlidePage + (forward ? 1 : -1)) * scrollSize
            }px, 0, 0)`;
            setCurrentSlidePage(currentSlidePage + (forward ? 1 : -1));
        }
    };
    return (
        <SliderContainer data-testid="slider-container">
            <SliderInner>
                <FlexedDiv justifyContent={'space-between'}>
                    <SliderTitle>{customTitle || LABEL.quickOverview}</SliderTitle>
                    <FlexedDiv>
                        {filterpillData && (
                            <FlexedDiv
                                style={{ columnGap: '8px', marginRight: '40px' }}
                                alignItems="center"
                                data-testid="filter-pill-data"
                            >
                                <TextDarkGrey5 size="12px"> {filterpillData.title}:</TextDarkGrey5>
                                {filterpillData.pills.map((pill, index) => {
                                    return (
                                        <FilterPill
                                            key={pill.title + index}
                                            label={pill.title}
                                            active={pill.active}
                                            item={pill}
                                            handleFilterPill={filterpillData.handlePills}
                                            testId={pill.testId}
                                        />
                                    );
                                })}
                            </FlexedDiv>
                        )}
                        {totalPages > 0 && (
                            <FlexedDiv style={{ columnGap: '8px' }}>
                                <CustomButton
                                    style={{
                                        borderRadius: '50%',
                                        width: '40px',
                                        height: '40px',
                                        padding: 0,
                                        borderColor: '#C6CBD4',
                                    }}
                                    onClick={() => handleSlideMove(false)}
                                    disabled={currentSlidePage === 0}
                                    id="slide-move-left"
                                >
                                    <IcoMoon
                                        name="caret-left"
                                        size="1.5rem"
                                        style={{ opacity: currentSlidePage === 0 ? '0.6' : '1' }}
                                    />
                                </CustomButton>
                                <CustomButton
                                    style={{
                                        borderRadius: '50%',
                                        width: '40px',
                                        height: '40px',
                                        padding: 0,
                                        borderColor: '#C6CBD4',
                                    }}
                                    onClick={() => handleSlideMove(true)}
                                    disabled={currentSlidePage === totalPages}
                                    id="slide-move-right"
                                >
                                    <IcoMoon
                                        name="caret-right"
                                        size="1.5rem"
                                        style={{ opacity: currentSlidePage === totalPages ? '0.6' : '1' }}
                                    />
                                </CustomButton>
                            </FlexedDiv>
                        )}
                    </FlexedDiv>
                </FlexedDiv>

                <SliderRow>
                    <SliderRowWrapper
                        ref={sliderRef}
                        maxVisibleSlides={visibleSlides}
                        slideMargin={slideMargin}
                        data-testid="slider-row-wrapper"
                    >
                        {overviewData.map((card, index) => {
                            return (
                                <SummaryCard
                                    key={index}
                                    dataLabel={card.dataLabel}
                                    handleClick={handleCardClick}
                                    testId={card.testId}
                                    title={card.title}
                                    data={card.data}
                                    dataIcon={card.dataIcon}
                                    cardId={card.cardId}
                                    clickIconName={card.clickIconName}
                                    noClickIcon={card.noClickIcon}
                                />
                            );
                        })}
                    </SliderRowWrapper>
                </SliderRow>
            </SliderInner>
        </SliderContainer>
    );
};

//component styles
const SliderContainer = styled.div`
    margin-bottom: 2rem;
`;
const SliderInner = styled.div`
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 24px;
    position: relative;
`;
const SliderTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: -0.666667px;
    margin-bottom: 1rem;
    color: #002043;
    overflow-wrap: break-word;
`;
const SliderRow = styled.div`
    overflow: hidden;
    margin-top: 16px;
`;
const SliderRowWrapper = styled.div<ISliderInfo>`
    left: 0;
    display: grid;
    grid-auto-flow: column;
    margin-bottom: 8px;
    column-gap: ${(props) => props.slideMargin}px;
    grid-auto-columns: ${(props) =>
        `calc((100% -  ${(props.maxVisibleSlides - 1) * props.slideMargin}px)/${props.maxVisibleSlides});`};
    transition: transform 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
`;
