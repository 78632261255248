import * as ROUTES from '../routes';

export const checkSubMenuLinkStatus = (route: string): boolean => {
    let temp = false;
    const activeSubMenuRoutes = {
        hqAllSubmissions: [
            ROUTES.hqAllSubmissions,
            `${ROUTES.hqAllSubmissions}/${ROUTES.orderSummary}`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.orderSummary}/document`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.orderSummary}/track`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.orderSummaryChangeRequest}`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.orderSummaryChangeRequest}/profile`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.orderSummaryChangeRequest}/document`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.orderSummaryChangeRequest}/track`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.rejectTransaction}`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.rerouteTransaction}`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.extendTargetDate}`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.rejectTargetDate}`,
            `${ROUTES.hqAllSubmissions}/${ROUTES.extendTargetDateRequest}`,
            ROUTES.hqAllSubmissionsTrackOrder,
            `${ROUTES.hqAllSubmissionsTrackOrder}/${ROUTES.orderSummary}`,
            `${ROUTES.hqAllSubmissionsTrackOrder}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.hqAllSubmissionsTrackOrder}/${ROUTES.orderSummary}/document`,
            `${ROUTES.hqAllSubmissionsTrackOrder}/${ROUTES.orderSummary}/track`,
            `${ROUTES.hqAllSubmissionsTrackOrder}/${ROUTES.orderSummaryChangeRequest}`,
            `${ROUTES.hqAllSubmissionsTrackOrder}/${ROUTES.orderSummaryChangeRequest}/profile`,
            `${ROUTES.hqAllSubmissionsTrackOrder}/${ROUTES.orderSummaryChangeRequest}/document`,
            `${ROUTES.hqAllSubmissionsTrackOrder}/${ROUTES.orderSummaryChangeRequest}/track`,
        ],
        hqAllPendingSubmission: [
            ROUTES.hqAllPendingSubmission,
            `${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummary}`,
            `${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummary}/document`,
            `${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummary}/track`,
            `${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummaryChangeRequest}`,
            `${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummaryChangeRequest}/profile`,
            `${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummaryChangeRequest}/document`,
            `${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummaryChangeRequest}/track`,
            `${ROUTES.hqAllPendingSubmissionTrackOrder}/${ROUTES.orderSummary}`,
            `${ROUTES.hqAllPendingSubmissionTrackOrder}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.hqAllPendingSubmissionTrackOrder}/${ROUTES.orderSummary}/document`,
            `${ROUTES.hqAllPendingSubmissionTrackOrder}/${ROUTES.orderSummary}/track`,
            `${ROUTES.hqAllPendingSubmissionTrackOrder}/${ROUTES.orderSummaryChangeRequest}`,
            `${ROUTES.hqAllPendingSubmissionTrackOrder}/${ROUTES.orderSummaryChangeRequest}/profile`,
            `${ROUTES.hqAllPendingSubmissionTrackOrder}/${ROUTES.orderSummaryChangeRequest}/document`,
            `${ROUTES.hqAllPendingSubmissionTrackOrder}/${ROUTES.orderSummaryChangeRequest}/track`,
            ROUTES.hqAllPendingSubmissionTrackOrder,
        ],
        dashboardBranch: [
            ROUTES.dashboardBranch,
            `${ROUTES.dashboardBranch}/${ROUTES.orderSummary}`,
            `${ROUTES.dashboardBranch}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.dashboardBranch}/${ROUTES.orderSummary}/document`,
            `${ROUTES.dashboardBranch}/${ROUTES.orderSummary}/track`,
            `${ROUTES.dashboardBranch}/${ROUTES.orderSummaryChangeRequest}`,
            `${ROUTES.dashboardBranch}/${ROUTES.orderSummaryChangeRequest}/profile`,
            `${ROUTES.dashboardBranch}/${ROUTES.orderSummaryChangeRequest}/document`,
            `${ROUTES.dashboardBranch}/${ROUTES.orderSummaryChangeRequest}/track`,
            `${ROUTES.dashboardBranch}/${ROUTES.rejectTransaction}`,
            `${ROUTES.dashboardBranch}/${ROUTES.rerouteTransaction}`,
            `${ROUTES.dashboardBranch}/${ROUTES.extendTargetDate}`,
            `${ROUTES.dashboardBranch}/${ROUTES.rejectTargetDate}`,
            `${ROUTES.dashboardBranch}/${ROUTES.extendTargetDateRequest}`,
            ROUTES.branchTrackOrder,
            `${ROUTES.branchTrackOrder}/${ROUTES.orderSummary}`,
            `${ROUTES.branchTrackOrder}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.branchTrackOrder}/${ROUTES.orderSummary}/document`,
            `${ROUTES.branchTrackOrder}/${ROUTES.orderSummary}/track`,
            `${ROUTES.branchTrackOrder}/${ROUTES.orderSummaryChangeRequest}`,
            `${ROUTES.branchTrackOrder}/${ROUTES.orderSummaryChangeRequest}/profile`,
            `${ROUTES.branchTrackOrder}/${ROUTES.orderSummaryChangeRequest}/document`,
            `${ROUTES.branchTrackOrder}/${ROUTES.orderSummaryChangeRequest}/track`,
        ],
        advisersDashboard: [ROUTES.advisers, ROUTES.branchAdvisorProfile],
        activityLogsDashboard: [
            ROUTES.activityLogs,
            ROUTES.activityLogsFund,
            ROUTES.activityLogsRoles,
            ROUTES.activityLogsGroups,
            ROUTES.activityLogsUser,
            ROUTES.activityLogsReroute,
            ROUTES.activityLogsDueDate,
            ROUTES.activityLogsOrderSummary,
            ROUTES.activityLogsReject,
            ROUTES.activityLogsAdviser,
            ROUTES.activityLogsBulkUpload,
            ROUTES.activityLogsBulkImport,
            ROUTES.activityLogsEpfSettings,
        ],
        branchPendingSubmission: [
            ROUTES.branchPendingSubmission,
            `${ROUTES.branchPendingSubmission}/${ROUTES.orderSummary}`,
            `${ROUTES.branchPendingSubmission}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.branchPendingSubmission}/${ROUTES.orderSummary}/document`,
            `${ROUTES.branchPendingSubmission}/${ROUTES.orderSummary}/track`,
            `${ROUTES.branchPendingSubmission}/${ROUTES.orderSummaryChangeRequest}`,
            `${ROUTES.branchPendingSubmission}/${ROUTES.orderSummaryChangeRequest}/profile`,
            `${ROUTES.branchPendingSubmission}/${ROUTES.orderSummaryChangeRequest}/document`,
            `${ROUTES.branchPendingSubmission}/${ROUTES.orderSummaryChangeRequest}/track`,
            ROUTES.branchPendingSubmissionTrackOrder,
            `${ROUTES.branchPendingSubmissionTrackOrder}/${ROUTES.orderSummary}`,
            `${ROUTES.branchPendingSubmissionTrackOrder}/${ROUTES.orderSummary}/profile`,
            `${ROUTES.branchPendingSubmissionTrackOrder}/${ROUTES.orderSummary}/document`,
            `${ROUTES.branchPendingSubmissionTrackOrder}/${ROUTES.orderSummary}/track`,
            `${ROUTES.branchPendingSubmissionTrackOrder}/${ROUTES.orderSummaryChangeRequest}`,
            `${ROUTES.branchPendingSubmissionTrackOrder}/${ROUTES.orderSummaryChangeRequest}/profile`,
            `${ROUTES.branchPendingSubmissionTrackOrder}/${ROUTES.orderSummaryChangeRequest}/document`,
            `${ROUTES.branchPendingSubmissionTrackOrder}/${ROUTES.orderSummaryChangeRequest}/track`,
        ],
    };
    switch (route) {
        case ROUTES.hqAllSubmissions:
            if (activeSubMenuRoutes.hqAllSubmissions.includes(window.location.pathname)) temp = true;
            else temp = false;
            break;
        case ROUTES.hqAllPendingSubmission:
            if (activeSubMenuRoutes.hqAllPendingSubmission.includes(window.location.pathname)) temp = true;
            else temp = false;
            break;
        case ROUTES.dashboardBranch:
            if (activeSubMenuRoutes.dashboardBranch.includes(window.location.pathname)) temp = true;
            else temp = false;
            break;
        case ROUTES.branchPendingSubmission:
            if (activeSubMenuRoutes.branchPendingSubmission.includes(window.location.pathname)) temp = true;
            else temp = false;
            break;
    }
    return temp;
};
