import gql from 'graphql-tag';

/**
 * @caseDetailsQuery @query - EDD Client Case Details API
 * @description: Amla officer can view the case details from edd dashboard
 */
export const caseDetailsStatusQuery = /* GraphQL */ gql`
    query caseDetails($input: CaseDetailsInput) {
        caseDetails(input: $input) {
            data {
                result {
                    status
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
