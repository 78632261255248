import gql from 'graphql-tag';

/**
 * @approveReject @mutation System admin Dashboard
 * @description mutation for reject/approve new user request
 */

export const approveReject = gql`
    mutation approveReject($input: ApproveRejectInput) {
        approveReject(input: $input) {
            data {
                result {
                    status
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;

export default approveReject;
