import gql from 'graphql-tag';

/**
 * @createRole @mutation - createRole Dashboard
 * @description: Creates the user role
 */

export const createRoleMutation = /* GraphQL */ gql`
    mutation createRole($input: RoleInput) {
        createRole(input: $input) {
            data {
                result {
                    status
                }
            }
            error {
                errorCode
                message
                errorList
            }
        }
    }
`;
