import { toTitleCase } from './toTitleCase';
export const formatTextCase = (data: ITableData[], labels: string[]): ITableData[] => {
    const _data: ITableData[] = data;
    _data.map((obj, objIndex) => {
        labels.map((label) => {
            if (obj.hasOwnProperty(label)) {
                switch (label) {
                    case 'paymentMethod':
                        if (obj[label] !== null && obj[label] !== undefined) {
                            if (obj[label].toLowerCase().includes('cash')) {
                                _data[objIndex][label] =
                                    obj[label] === 'CASH'
                                        ? toTitleCase(obj[label])
                                        : 'Cash' + String(obj[label]).substring(4);
                            } else if (obj[label] !== 'EPF') {
                                _data[objIndex][label] = toTitleCase(obj[label]);
                            }
                        } else _data[objIndex][label] = '-';
                        break;
                    case 'uplineName':
                        if (obj[label] !== null && obj[label].trim().includes('-')) {
                            let before = obj[label].trim().substring(0, obj[label].indexOf('-'));
                            const after = obj[label].trim().substring(obj[label].indexOf('-') + 1);
                            before = toTitleCase(before);
                            _data[objIndex][label] = `${before} - ${after}`;
                        } else if (obj['uplineCode'] === 'KIB') {
                            let after = obj[label].trim().substring(obj[label].indexOf('KIB') + 4);
                            after = toTitleCase(after);
                            _data[objIndex][label] = `KIB ${after !== '' ? '-' : ''} ${after}`;
                        } else {
                            _data[objIndex][label] = toTitleCase(obj[label]);
                        }
                        break;
                    default:
                    // _data[objIndex][label] = toTitleCase(obj[label]);
                }
            }
        });
    });

    return _data;
};
