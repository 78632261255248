import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ColumnSheet } from './ColumnSheet';
import { CellSheet } from './CellSheet';
import { IcoMoon } from '../../../icons';

interface TableSheetProps {
    columns: ISheetColumn[];
    rows: ISheetCell[][];
    handleMarkDelete?: (index: number) => void;
    handleMarkOkay?: (index: number) => void;
    handleMarkAll?: () => void;
    handleUndoAll?: () => void;
    isRowMarked?: boolean;
    readyOnly: boolean;
    externalLinks?: string[];
    hasExternalLinks?: boolean;
    columnWidths?: IFixedColumnWidths;
}

type ActionIconProps = {
    isMarked?: boolean;
    isDelete?: boolean;
};

export const TableSheet: React.FC<TableSheetProps> = ({
    rows,
    columns,
    isRowMarked,
    handleMarkOkay,
    handleMarkDelete,
    handleMarkAll,
    handleUndoAll,
    readyOnly,
    externalLinks,
    hasExternalLinks,
    columnWidths,
}: TableSheetProps) => {
    const isRowValid = (row: ISheetCell[]) => {
        if (row.some((cell) => cell.isValid === false)) {
            return false;
        } else {
            return true;
        }
    };
    // generate each row's action buttons
    const generateCellActions = (row: ISheetCell[], index: number) => {
        const cellActions: JSX.Element = isRowValid(row) ? (
            <StickyActionTd>
                <IconPlaceholderStyled
                    onClick={() => (handleMarkOkay != undefined ? handleMarkOkay(index) : '')}
                    isMarked={row[0].isMarked}
                    isDelete={row[0].isDelete}
                    id={`mark-action-btn-${index + 1}`}
                >
                    <IcoMoon
                        name={(row[0].isMarked && 'check') || (row[0].isDelete && 'close') || 'check'}
                        size="1rem"
                        color={row[0].isMarked || row[0].isDelete ? '#fff' : '#002043'}
                    />
                </IconPlaceholderStyled>

                {!row[0].isMarked ? (
                    <ActionBtn
                        onClick={() => handleMarkDelete && handleMarkDelete(index)}
                        id={`${row[0].isDelete ? 'undo' : 'delete'}-action-btn-${index + 1}`}
                    >
                        {row[0].isDelete ? 'Undo' : 'Delete'}
                    </ActionBtn>
                ) : (
                    <ActionBtn
                        onClick={() => handleMarkOkay != undefined && handleMarkOkay(index)}
                        id={`undo-action-btn-${index + 1}`}
                    >
                        Undo
                    </ActionBtn>
                )}
            </StickyActionTd>
        ) : (
            <StickyActionTd>
                <IconPlaceholderStyled isDelete={true}>
                    <IcoMoon name="close" size="1rem" color="#fff" />
                </IconPlaceholderStyled>
            </StickyActionTd>
        );
        return cellActions;
    };
    return (
        <StyledSheet colWidths={columnWidths && columnWidths}>
            <StyledSheetInner>
                <StyledSheetTable>
                    <thead>
                        {readyOnly ? (
                            <ColumnSheet
                                columns={columns}
                                readyOnly={true}
                                hasExternalLinks={hasExternalLinks}
                                columnWidths={columnWidths && columnWidths}
                            />
                        ) : (
                            <ColumnSheet
                                columns={columns}
                                handleMarkAll={handleMarkAll}
                                isRowMarked={isRowMarked}
                                handleUndoAll={handleUndoAll}
                                readyOnly={false}
                                columnWidths={columnWidths && columnWidths}
                            />
                        )}
                    </thead>
                    <tbody>
                        {!readyOnly
                            ? rows.map((row, index) => (
                                  <tr key={index}>
                                      {hasExternalLinks ? (
                                          <IndexTd colWidths={columnWidths && columnWidths}>
                                              <Link
                                                  to={{ pathname: externalLinks && externalLinks[index] }}
                                                  target="_blank"
                                                  id={`view-item-btn-${index + 1}`}
                                              >
                                                  <IcoMoon name="eye-show" size="1rem" color="#002043" />
                                              </Link>
                                          </IndexTd>
                                      ) : (
                                          <IndexTd colWidths={columnWidths && columnWidths}>{index + 1}</IndexTd>
                                      )}
                                      {row.map((cell, index) => (
                                          <CellSheet
                                              key={index}
                                              cell={cell}
                                              readyOnly={false}
                                              columnWidths={columnWidths && columnWidths}
                                          />
                                      ))}
                                      {generateCellActions(row, index)}
                                  </tr>
                              ))
                            : rows.map((row, index) => (
                                  <tr key={index}>
                                      {hasExternalLinks ? (
                                          <IndexTd colWidths={columnWidths && columnWidths}>
                                              <Link
                                                  to={{ pathname: externalLinks && externalLinks[index] }}
                                                  target="_blank"
                                              >
                                                  <IcoMoon name="eye-show" size="1rem" color="#002043" />
                                              </Link>
                                          </IndexTd>
                                      ) : (
                                          <IndexTd colWidths={columnWidths && columnWidths}>{index + 1}</IndexTd>
                                      )}

                                      {row.map((cell, index) => (
                                          <CellSheet
                                              key={index}
                                              cell={cell}
                                              readyOnly={true}
                                              columnWidths={columnWidths && columnWidths}
                                          />
                                      ))}
                                  </tr>
                              ))}
                    </tbody>
                </StyledSheetTable>
            </StyledSheetInner>
        </StyledSheet>
    );
};
const StyledSheet = styled.div<ColumnWidthProps>`
    display: inline-block;
    position: relative;
    margin: 1rem 0px 1rem 418px;
    margin-left: ${(props) =>
        props.colWidths
            ? `${props.colWidths.thirdColWidth + props.colWidths.secondColWidth + props.colWidths.firstColWidth}px`
            : '418px'};
`;
const StyledSheetInner = styled.div`
    border-radius: 0px 8px 0px 0px;
    overflow-y: visible;
    width: 580px;
    overflow-x: scroll;
`;
const StyledSheetTable = styled.table`
    border-spacing: 0px;
    width: 100%;
`;
const IndexTd = styled.td<ColumnWidthProps>`
    border: 1px solid #eaebee;
    background-color: #fff;
    padding: 0.8em 0.5em;
    white-space: normal;
    font-size: 12px;
    width: ${(props) => (props.colWidths ? `${props.colWidths.thirdColWidth}px` : '42px')};
    left: ${(props) =>
        props.colWidths
            ? `-${props.colWidths.firstColWidth + props.colWidths.secondColWidth + props.colWidths.thirdColWidth}px`
            : '-418px'};

    position: absolute;
    background-color: transparent;
    height: 52px;
`;

const StickyActionTd = styled.td`
    position: absolute;
    background-color: transparent;
    width: 92px;
    border: 0px;
    right: -92px;
    display: flex;
    align-items: center;
    border: 0px;
    padding: 0.8em 0.5em;
    white-space: normal;
    font-size: 12px;
    height: 52px;
`;

const IconPlaceholder = styled.div`
    display: inline-block;
    text-align: center;
    position: relative;
`;
const IconPlaceholderStyled = styled(IconPlaceholder)<ActionIconProps>`
    border: ${(props) => (props.isMarked || props.isDelete ? ' 1px solid #fff' : ' 1px solid #c6cbd4')};
    background-color: ${(props) => (props.isMarked && '#2ECC82') || (props.isDelete && '#E84C3D') || '#fff'};
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 36px;
    cursor: pointer;
    position: relative;
`;
const SharedBtnStyles = css`
    border: 0px;
    background-color: transparent;
    font-size: 12px;
    font-weight: 700;
    &:focus {
        outline: none;
    }
`;
const ActionBtn = styled.button`
    ${SharedBtnStyles}
`;
