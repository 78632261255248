import React, { Fragment } from 'react';
import styled from 'styled-components';
import { IcoMoon } from '../../../icons';
import { DataWithLabel } from '../../molecules';
import { TextDarkBlack, TextDarkGrey5 } from '../../../constants';

type GridDivProps = {
    disableGrid?: boolean;
    customGrid?: string;
    separator?: boolean;
};

type SubLineBlockProps = {
    marginType: string;
};

export const GridData = ({ data, sectionTitle, customGrid, separator }: ISection): JSX.Element => {
    return (
        <GridDiv
            disableGrid={data.length !== 0 && data[0].label === 'Fund Objective' ? true : false}
            id={`grid--${
                sectionTitle?.title !== undefined
                    ? sectionTitle.title.replace(/^[, ]+|[, ]+$|[, ]+/g, '-').toLowerCase()
                    : 'section'
            }`}
            separator={separator}
            customGrid={customGrid}
            data-testid={`grid--${
                sectionTitle?.title !== undefined
                    ? sectionTitle.title.replace(/^[, ]+|[, ]+$|[, ]+/g, '-').toLowerCase()
                    : 'section'
            }`}
        >
            {data.map(
                (
                    {
                        data,
                        label,
                        labelIcon,
                        dataIcon,
                        direction,
                        onClickData,
                        prefix,
                        subText,
                        multiLinks,
                        dataStyle,
                        labelStyle,
                    },
                    index,
                ) => {
                    return (
                        <Fragment key={`data${index}`}>
                            <DataWithLabel
                                data={data}
                                dataIcon={dataIcon}
                                direction={direction !== undefined ? direction : 'column'}
                                label={label}
                                labelIcon={labelIcon}
                                onClickData={onClickData}
                                prefix={prefix}
                                subText={subText}
                                multiLinks={multiLinks}
                                dataStyle={dataStyle}
                                labelStyle={labelStyle}
                            />
                            <div />
                        </Fragment>
                    );
                },
            )}
        </GridDiv>
    );
};

export const Section: React.FC<ISection | ISectionWithSubSections> = ({
    sectionTitle,
    data,
    subSection,
    customGrid,
    titleBanner,
    separator,
}: ISection | ISectionWithSubSections) => {
    return (
        <SectionElm isOnProp={subSection !== undefined} id={`${separator}-id`}>
            {titleBanner !== undefined ? (
                <TitleBanner>
                    {titleBanner.icon !== undefined ? (
                        <IcoMoon name={titleBanner.icon} size=".75rem" style={{ marginRight: '.625rem' }} />
                    ) : null}
                    <TextDarkBlack size="10px" lineHeight={'12px'}>
                        {titleBanner.title}
                    </TextDarkBlack>
                </TitleBanner>
            ) : null}
            {separator && <SubLineBlock marginType={'N-N-Y-N'} data-testid="subline-block" />}
            {sectionTitle?.title !== undefined ? (
                <SectionTitleWrapper>
                    <SectionTitle data-testid="section-title">
                        <SectionTitleLeft id="section-title-left" data-testid="section-title-left">
                            {sectionTitle.titleIcon !== undefined ? (
                                <IcoMoon
                                    name={sectionTitle.titleIcon}
                                    size="1.5rem"
                                    style={{ marginRight: '.625rem' }}
                                    color="002043"
                                />
                            ) : null}

                            <TextDarkBlack weight="700" lineHeight="24px">
                                {sectionTitle.title}
                            </TextDarkBlack>

                            {sectionTitle.badgeColor !== undefined && sectionTitle.badgeLabel !== undefined ? (
                                <StatusIndicator
                                    id="section-title-left-badge"
                                    color={sectionTitle.badgeColor}
                                    data-testid="section-title-left-badge"
                                    style={sectionTitle.badgeStyle}
                                >
                                    {sectionTitle.badgeLabel}
                                </StatusIndicator>
                            ) : (
                                <div />
                            )}
                        </SectionTitleLeft>
                        <LineBlock />
                        <SectionTitleRight id="section-title-right">
                            <TextDarkBlack weight="700">{sectionTitle.rightLabel}</TextDarkBlack>
                        </SectionTitleRight>
                    </SectionTitle>
                    {sectionTitle.bottomLabel && (
                        <SectionBottomLabel style={sectionTitle.bottomLabelStyle}>
                            <TextDarkGrey5 weight="700" size="12px" lineHeight="16px">
                                {sectionTitle.bottomLabel}
                            </TextDarkGrey5>
                        </SectionBottomLabel>
                    )}
                </SectionTitleWrapper>
            ) : null}

            {subSection
                ? subSection.map((sub, index) => {
                      return (
                          <SubSectionWrapper
                              data-testid="sub-section-wrapper"
                              id={sub.sectionTitle?.title ?? 'subsection' + index}
                              key={sub.sectionTitle?.title ?? 'subsection' + index}
                              style={sub.subSectionWrapperStyle}
                          >
                              {sub.separator && <LineDashedBlock />}
                              <SectionTitleWrapper>
                                  <SectionTitle>
                                      <SectionTitleLeft id="section-title-left" data-testid="section-title-left">
                                          {sub.sectionTitle?.titleIcon !== undefined ? (
                                              <IcoMoon
                                                  name={sub.sectionTitle.titleIcon}
                                                  size="1rem"
                                                  style={{ marginRight: '.625rem' }}
                                                  color="002043"
                                              />
                                          ) : null}

                                          <TextDarkBlack size="14px" weight="700">
                                              {sub.sectionTitle?.title}
                                          </TextDarkBlack>

                                          {sub.sectionTitle?.badgeColor !== undefined &&
                                          sub.sectionTitle.badgeLabel !== undefined ? (
                                              <StatusIndicator
                                                  id="section-title-left-badge"
                                                  color={sub.sectionTitle.badgeColor}
                                              >
                                                  {sub.sectionTitle.badgeLabel}
                                              </StatusIndicator>
                                          ) : (
                                              <div />
                                          )}
                                      </SectionTitleLeft>
                                      {sub.sectionTitle?.rightLabel && (
                                          <Fragment>
                                              <SubLineBlock data-testid="subline-b" marginType={'N-N-N-Y'} />
                                              <SectionTitleRight id="section-title-right">
                                                  <TextDarkBlack weight="700">
                                                      {sub.sectionTitle?.rightLabel}
                                                  </TextDarkBlack>
                                              </SectionTitleRight>
                                          </Fragment>
                                      )}
                                  </SectionTitle>
                              </SectionTitleWrapper>
                              <GridData
                                  data={sub.data}
                                  sectionTitle={sectionTitle}
                                  key={index}
                                  separator={sub.separator}
                                  customGrid={sub.customGrid}
                              ></GridData>
                          </SubSectionWrapper>
                      );
                  })
                : data && <GridData data={data} sectionTitle={sectionTitle} customGrid={customGrid} />}
        </SectionElm>
    );
};
const SectionElm = styled.div<IOnPropStyles>`
    display: flex;
    flex-direction: column;
    margin-bottom: ${(props) => (props.isOnProp ? '1rem' : '0')};
    &:last-child {
        margin-bottom: 0px;
    }
`;
const SubLineBlock = styled.div<SubLineBlockProps>`
    border: 1px solid #e7ebf1;
    margin: ${(props) => props.marginType.split('-').reduce((acc, cur) => `${acc} ${cur === 'Y' ? 1 : 0}rem`, '')};
    flex: 1 0 auto;
`;

const SectionTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const SectionTitleWrapper = styled.div`
    margin-bottom: 0.5rem;
`;
const SectionTitleLeft = styled.div`
    display: flex;
    align-items: center;
`;
const SectionBottomLabel = styled.div`
    margin-left: 2.125rem;
    margin-top: 4px;
`;
const SectionTitleRight = styled.div`
    display: flex;
    align-items: center;
    border-left: '2px solid #c61230';
    margin: '24px 1.5rem 0px';
`;

const SubSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
`;

const TitleBanner = styled.div`
    background: var(--accent-blue-2, #f2f4f6);
    margin: 8px -1.5rem 8px;
    display: flex;
    height: 24px;
    padding: 4px 24px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
`;
const LineBlock = styled.div`
    border: 1px solid #c6cbd4;
    margin: 0px 1rem 0px 1rem;
    flex: 1 0 auto;
`;

const LineDashedBlock = styled.div`
    border: 1px dashed #e7ebf1;
    margin: 1rem 0rem 1rem 0rem;
    flex: 1 0 auto;
`;

const GridDiv = styled.div<GridDivProps>`
    display: grid;
    grid-template-columns: ${(props) =>
        props.disableGrid ? 'none' : props.customGrid ? props.customGrid : '1.5fr 0.25fr  1.5fr 0.25fr 1.5fr 0.25fr'};
`;
const StatusIndicator = styled.div`
    padding: 2px 4px;
    line-height: 14px;
    font-size: 0.625rem;
    text-transform: capitalize;
    color: ${(props: { color: string }) => props.color};
    letter-spacing: 0.01em;
    border: ${(props: { color: string }) => `0.5px solid ${props.color}`};
    border-radius: 4px;
    margin-left: 8px;
`;
