import gql from 'graphql-tag';

export const updateTransactionReportStatus = /* GraphQL */ gql`
    mutation updateTransactionReportStatus($input: DownloadDailyInput) {
        updateTransactionReportStatus(input: $input) {
            data {
                result {
                    message
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
