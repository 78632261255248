import React, { FunctionComponent, Fragment } from 'react';
import { TextDarkBlack } from '../../../constants/styles/text';
import { IStyledToggleProps, IToggleProps } from './toggleTypes';

import styled from 'styled-components';

const StyledSwitch: React.FC<IStyledToggleProps> = ({
    children,
    disabled,
    selected,
    handleToggle,
    id,
    label,
}: IStyledToggleProps) => {
    const disabledStyle: React.CSSProperties = disabled === true ? { opacity: 0.5, pointerEvents: 'none' } : {};
    return (
        <ToggleLabel style={disabledStyle} data-testid="toggle">
            {handleToggle && (
                <input type="checkbox" onChange={handleToggle} style={{ width: '0px', visibility: 'hidden' }} id={id} />
            )}
            {selected === true ? (
                <SelectedSwitch data-testid="selected-switch">{children}</SelectedSwitch>
            ) : (
                <SwitchUnSelected data-testid="unselected-switch">{children}</SwitchUnSelected>
            )}
            {label !== '' && label !== undefined && (
                <TextDarkBlack
                    weight={selected ? '700' : '400'}
                    style={{ marginLeft: '0.875rem', display: 'inline-block' }}
                >
                    {label}
                </TextDarkBlack>
            )}
        </ToggleLabel>
    );
};

export const Toggle: FunctionComponent<IToggleProps> = ({
    disabled,
    selected,
    handleToggle,
    id,
    label,
}: IToggleProps) => {
    return (
        <Fragment>
            <StyledSwitch selected={selected} disabled={disabled} handleToggle={handleToggle} id={id} label={label}>
                <InsideFragment />
            </StyledSwitch>
        </Fragment>
    );
};

const SelectedSwitch = styled.div`
    display: inline-flex;
    background-color: #c61230;
    border-radius: 16px;
    height: 1rem;
    width: 2rem;
    justify-content: flex-end;
    padding-right: 0.1875rem;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`;

const SwitchUnSelected = styled.div`
    display: inline-flex;
    background-color: #a3a3a3;
    border-radius: 16px;
    height: 1rem;
    width: 2rem;
    align-items: center;
    padding-left: 0.1875rem;
    &:hover {
        cursor: pointer;
    }
`;

const InsideFragment = styled.div`
    background-color: #ffffff;
    border-radius: 100px;
    height: 0.625rem;
    width: 0.625rem;
`;

const ToggleLabel = styled.label`
    display: block-inline;
`;

export default Toggle;
