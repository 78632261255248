import React, { useState, useEffect } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { makeWebSocket } from '../../websocket';
import WebSocketContext from './';
import { IWebSocketContext } from './socket';

export interface WebSocketProviderProps {
    children: React.ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }: WebSocketProviderProps) => {
    const [url, setUrl] = useState<string>('');
    const [path, setPath] = useState<string>('');
    const [ws, setWebSocket] = useState<ReconnectingWebSocket>();
    const [isLoggedOut, setIsLoggedOut] = useState<boolean>(false);
    const [logoutSuccess, setLogoutSuccess] = useState<boolean>(false);

    useEffect(() => {
        if (url.length !== 0 && path.length !== 0) {
            const _ws = makeWebSocket(url, path);
            setWebSocket(_ws);
        }
    }, [url, path]);
    const ProviderValue: IWebSocketContext = {
        path,
        setPath,
        setUrl,
        url,
        ws,
        isLoggedOut,
        setIsLoggedOut,
        setWebSocket,
        logoutSuccess,
        setLogoutSuccess,
    };

    return <WebSocketContext.Provider value={ProviderValue}>{children}</WebSocketContext.Provider>;
};
