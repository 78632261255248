import { IMatchParams } from '../auth/CallBackRedirect';
export const getURLParams = (): IMatchParams => {
    const SSOObj: IMatchParams = {
        id_token: '',
        user: '',
        refresh_token: '',
    };

    const urlSearchParams = new URLSearchParams(window.location.search);
    SSOObj.id_token = urlSearchParams.get('id_token') as string;
    SSOObj.user = urlSearchParams.get('user') as string;
    SSOObj.refresh_token = urlSearchParams.get('user') as string;

    return SSOObj;
};
