import gql from 'graphql-tag';

export const createSettingMutation = gql`
    mutation createSettings($input: CreateSettingsInput) {
        createSettings(input: $input) {
            data {
                result {
                    status
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
