import gql from 'graphql-tag';

/**
 * @hqCeDashboard @query - HQ Dashboard
 * @description: TAB: Due date extension
 */

export const dueDateExtensionMutation = /* GraphQL */ gql`
    mutation targetDateResponse($input: TargetDateResponseInput) {
        targetDateResponse(input: $input) {
            data {
                result {
                    status
                    message
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
