/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { camelCaseToTitleCase } from './';
type FundDataObj = {
    label: string;
    data: any;
};

const fundSummaryLabels = [
    'fundCode',
    'fundCategory',
    'fundProcessingGroup',
    'fundDescription',
    'closedEnd',
    'fundType',
    'isSyariah',
    'fundClass',
    'trustee',
    'scProcessingGroup',
    'fundStartDate',
    'iopEndDate',
    'maturityDate',
    'commercialStartDate',
    'transactionsAfterIopAllow',
    'riskCategory',
    'nonBusinessDayFrom',
    'nonBusinessDayTo',
    'ageGroup',
    'lipperCode',
    'minimumBalanceHoldingUnits',
    'distributedBy',
    'felSharing',
    'isFea',
];
const ampSummaryLabels = [
    'fundCode',
    'fundCategory',
    'fundProcessingGroup',
    'fundDescription',
    'closedEnd',
    'fundType',
    'isSyariah',
    'fundClass',
    'trustee',
    'scProcessingGroup',
    'fundStartDate',
    'iopEndDate',
    'maturityDate',
    'commercialStartDate',
    'transactionsAfterIopAllow',
    'minimumBalanceHoldingUnits',
    'nonBusinessDayFrom',
    'nonBusinessDayTo',
    'riskCategory',
    'felSharing',
    'ageGroup',
    'lipperCode',
    'distributedBy',
    'landingFund',
];
const investmentSummaryLabels = [
    'instruction',
    'dividentTiming',
    'minimumDividentPayoutAmount',
    'dailyIncomeDistribution',
    'fundBaseCurrency',
    'fundClassCurrency',
    'isAipAllowed',
    'coolOffDays',
    'salesSettlementDay',
    'moneySightedFund',
    'differenceFundPrice',
    'dedicatedFund',
];
const ampInvestmentSummaryLabels = [
    'instruction',
    'dividentTiming',
    'minimumDividentPayoutAmount',
    'dailyIncomeDistribution',
    'fundBaseCurrency',
    'fundClassCurrency',
    'isAipAllowed',
    'coolOffDays',
    'moneySightedFund',
    'differenceFundPrice',
    'dedicatedFund',
];
const feesSummaryLabels = [
    'issuePrice',
    'annualManagementFee',
    'annualTrusteeFee',
    'minSalesFees',
    'maxSalesFees',
    'exitFeesCharge',
    'exitFeesChargeAmount',
    'ampFee',
    'gstParameter',
];
const redemptionSummaryLabels = [
    'redemption',
    'redemptionCoolOff',
    'redemptionType',
    'minRedemptionUnits',
    'redemptionSettleDate',
    'redemptionCutOffTime',
    'batchCutOffTime',
];
const switchingSummaryLabels = [
    'transactionsAfterIopSwitchIn',
    'transactionsAfterIopSwitchOut',
    'switchingType',
    'minSwitchOutUnits',
    'switchingFee',
    'switchingGroup',
    'switchingSettlementDate',
];
const ampSwitchingSummaryLabels = [
    'transactionsAfterIopSwitchIn',
    'transactionsAfterIopSwitchOut',
    'switchingType',
    'minSwitchOutUnits',
    'switchingSettlementDate',
    'switchingFee',
    'minSalesAmountEpf',
    'minTopUpEpf',
    'minSalesAmountNonEpf',
    'minTopUpNonEpf',
];
const salesSummaryLabels = [
    'transactionsAfterIopNewSales',
    'minNewSalesAmount',
    'maxNewSalesAmount',
    'minBalanceHoldingUnits',
    'salesCutOffTime',
    'batchCutOffTime',
];
const topUpSummaryLabels = ['transactionsAfterIopTopUpSales', 'minTopUpAmount', 'maxTopUpAmount'];
const transferSummaryLabels = ['transactionsAfterIopTransfer', 'minTransferOutUnits'];
const epfSummaryLabels = [
    'epfApproved',
    'epfStatus',
    'minSalesFee',
    'maxSalesFee',
    'minNewSalesAmountEpf',
    'maxNewSalesAmountEpf',
    'minTopupAmountEpf',
    'maxTopupAmountEpf',
    'exitFeesCharge',
    'exitFeesChargeAmount',
];
export const handleFundSummary = (result: IFundSummaryPayload): IFundDetails => {
    const mainObj: IFundDetails | any = {
        fundName: '',
        utmc: '',
        fundSummary: { title: 'Fund Summary', sections: [{ data: [] }] },
        investmentSummary: { title: 'Fund Investment Information', sections: [{ data: [] }] },
        feesSummary: { title: 'Fees, Charges & Expenses', sections: [{ data: [] }] },
        epfSummary: { title: 'EPF Information', sections: [{ data: [] }] },
        redemptionSummary: { title: 'Redemption', sections: [{ data: [] }] },
        switchingSummary: {
            title: 'Switching',
            sections: [{ data: [] }],
        },
        salesSummary: { title: 'New Sales', sections: [{ data: [] }] },
        topUpSummary: { title: 'Top Up', sections: [{ data: [] }] },
        transferSummary: { title: 'Transfer', sections: [{ data: [] }] },
        description: { title: 'Fund Description', sections: [{ data: [] }] },
    };

    Object.entries(result).map((elm: Record<string, any>) => {
        const tempSectionData: FundDataObj[] = [];
        // build fund details summary sections
        if (elm[0] !== 'fundName' && elm[0] !== 'utmc' && elm[0] !== 'description' && elm[0] !== 'filters') {
            let dataObj: FundDataObj = { label: '', data: null };

            if (elm[0] === 'fundSummary' && elm[1] !== null) {
                // push data to respective summary section
                fundSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'fundCode' &&
                                ((dataObj = { label: camelCaseToTitleCase(key), data: value }),
                                tempSectionData.push(dataObj));
                            key === 'fundCategory' &&
                                ((dataObj = { label: camelCaseToTitleCase(key), data: value }),
                                tempSectionData.push(dataObj));
                            key === 'fundProcessingGroup' &&
                                ((dataObj = { label: camelCaseToTitleCase(key), data: value }),
                                tempSectionData.push(dataObj));
                            key === 'fundDescription' &&
                                ((dataObj = { label: camelCaseToTitleCase(key), data: value }),
                                tempSectionData.push(dataObj));
                            key === 'closedEnd' &&
                                ((dataObj = { label: 'Fund Description - Closed-End', data: value ? 'Yes' : 'No' }),
                                tempSectionData.push(dataObj));
                            key === 'fundType' &&
                                ((dataObj = { label: 'SC Fund Type', data: value }), tempSectionData.push(dataObj));
                            key === 'isSyariah' &&
                                ((dataObj = { label: 'Type', data: value ? 'Shariah' : 'Conventional' }),
                                tempSectionData.push(dataObj));

                            key === 'fundClass' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'trustee' &&
                                ((dataObj = { label: camelCaseToTitleCase(key), data: value }),
                                tempSectionData.push(dataObj));
                            key === 'scProcessingGroup' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'fundStartDate' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: moment.unix(value as number).format('DD/MM/YYYY'),
                                }),
                                tempSectionData.push(dataObj));
                            key === 'iopEndDate' &&
                                ((dataObj = {
                                    label: 'IOP End Date',
                                    data: moment.unix(value as number).format('DD/MM/YYYY'),
                                }),
                                tempSectionData.push(dataObj));
                            key === 'maturityDate' &&
                                ((dataObj = {
                                    label: 'Maturity Date (Closed-End)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'commercialStartDate' &&
                                ((dataObj = {
                                    label: 'Commercial Start Date',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'transactionsAfterIopAllow' &&
                                ((dataObj = {
                                    label: 'Transactions After IOP - Allow',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'riskCategory' &&
                                ((dataObj = { label: camelCaseToTitleCase(key), data: value }),
                                tempSectionData.push(dataObj));
                            key === 'nonBusinessDayFrom' &&
                                ((dataObj = {
                                    label: 'Non Business Day - From',
                                    data: moment.unix(value as number).format('DD/MM/YYYY'),
                                }),
                                tempSectionData.push(dataObj));
                            key === 'nonBusinessDayTo' &&
                                ((dataObj = {
                                    label: 'Non Business Day - To',
                                    data: moment.unix(value as number).format('DD/MM/YYYY'),
                                }),
                                tempSectionData.push(dataObj));
                            key === 'ageGroup' &&
                                ((dataObj = {
                                    label: 'Default Asset Allocation - Age Group',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'lipperCode' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minimumBalanceHoldingUnits' &&
                                ((dataObj = { label: 'Min. Balance Holdings Units', data: value }),
                                tempSectionData.push(dataObj));
                            key === 'distributedBy' &&
                                ((dataObj = { label: 'Distributed', data: value !== '' ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'felSharing' &&
                                ((dataObj = { label: 'FEL Sharing', data: value }), tempSectionData.push(dataObj));
                            key === 'isFea' &&
                                ((dataObj = { label: 'FEA Tagging', data: value ? 'Yes' : 'No' }),
                                tempSectionData.push(dataObj));
                        }
                    }
                });
            }
            if (elm[0] === 'investmentSummary' && elm[1] !== null) {
                // push data to respective summary section
                investmentSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'instruction' &&
                                ((dataObj = {
                                    label: 'Income Distribution Instruction',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'dividentTiming' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minimumDividentPayoutAmount' &&
                                ((dataObj = { label: 'Min Dividend Amount Payout', data: `MYR ${value}` }),
                                tempSectionData.push(dataObj));
                            key === 'dailyIncomeDistribution' &&
                                ((dataObj = { label: camelCaseToTitleCase(key), data: value ? 'Yes' : 'No' }),
                                tempSectionData.push(dataObj));
                            key === 'fundBaseCurrency' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'fundClassCurrency' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'isAipAllowed' &&
                                ((dataObj = { label: 'Automatic Investment Plan (AIP)', data: value ? 'Yes' : 'No' }),
                                tempSectionData.push(dataObj));
                            key === 'coolOffDays' &&
                                ((dataObj = {
                                    label: 'No. of Cool Off Days',
                                    data: value,
                                }),
                                tempSectionData.push(dataObj));
                            key === 'salesSettlementDay' &&
                                ((dataObj = { label: camelCaseToTitleCase(key), data: value ? value : '-' }),
                                tempSectionData.push(dataObj));
                            key === 'moneySightedFund' &&
                                ((dataObj = { label: camelCaseToTitleCase(key), data: value ? 'Yes' : 'No' }),
                                tempSectionData.push(dataObj));
                            key === 'differenceFundPrice' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'dedicatedFund' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                        }
                    }
                });
            }
            if (elm[0] === 'feesSummary' && elm[1] !== null) {
                // push data to respective summary section
                feesSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'issuePrice' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'annualManagementFee' &&
                                ((dataObj = {
                                    label: 'Annual Management Fee (%)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'annualTrusteeFee' &&
                                ((dataObj = { label: 'Annual Trustee Fee (%)', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'minSalesFees' &&
                                ((dataObj = { label: 'Min. Sales Fee (%)', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'maxSalesFees' &&
                                ((dataObj = { label: 'Max. Sales Fee (%)', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'exitFeesCharge' &&
                                ((dataObj = { label: 'Exit Fees Charge (%)', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'exitFeesChargeAmount' &&
                                ((dataObj = {
                                    label: 'Exit Fees Charge (Amount)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'ampFee' &&
                                ((dataObj = {
                                    label: 'AMP Fee',
                                    data: value !== 'false' ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'gstParameter' &&
                                ((dataObj = { label: 'GST Parameter', data: value }), tempSectionData.push(dataObj));
                        }
                    }
                });
            }
            if (elm[0] === 'epfSummary' && elm[1] !== null) {
                // push data to respective summary section

                epfSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'epfApproved' &&
                                ((dataObj = {
                                    label: 'EPF Approved',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'epfStatus' &&
                                ((dataObj = {
                                    label: 'EPF Status',
                                    data: value,
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minSalesFee' &&
                                ((dataObj = {
                                    label: 'Min. Sales Fee - EPF (%)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'maxSalesFee' &&
                                ((dataObj = {
                                    label: 'Max. Sales Fee - EPF (%)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minNewSalesAmountEpf' &&
                                ((dataObj = {
                                    label: 'Min. New Sales Amt - EPF',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'maxNewSalesAmountEpf' &&
                                ((dataObj = {
                                    label: 'Max. New Sales Amt - EPF',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minTopupAmountEpf' &&
                                ((dataObj = { label: 'Min. Topup Amt - EPF', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'maxTopupAmountEpf' &&
                                ((dataObj = { label: 'Max. Topup Amt - EPF', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'exitFeesCharge' &&
                                ((dataObj = {
                                    label: 'Exit Fees Charge - EPF (%)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'exitFeesChargeAmount' &&
                                ((dataObj = {
                                    label: 'Exit Fees Charge - EPF (Amount)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                        }
                    }
                });
            }
            if (elm[0] === 'redemptionSummary' && elm[1] !== null) {
                // push data to respective summary section
                redemptionSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'redemption' &&
                                ((dataObj = {
                                    label: 'Transactions After IOP - Redemption',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'redemptionCoolOff' &&
                                ((dataObj = {
                                    label: 'Transactions After IOP - Redemption Cool Off',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'redemptionType' &&
                                ((dataObj = { label: 'Redemption Type', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'minRedemptionUnits' &&
                                ((dataObj = { label: 'Min. Redemption Units', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'redemptionSettleDate' &&
                                ((dataObj = { label: 'Redemption Settle Date', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'redemptionCutOffTime' &&
                                ((dataObj = {
                                    label: 'Redemption Cut Off Time',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'batchCutOffTime' &&
                                ((dataObj = { label: 'Batch Cut off Time', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                        }
                    }
                });
            }
            if (elm[0] === 'switchingSummary' && elm[1] !== null) {
                // push data to respective summary section

                switchingSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'transactionsAfterIopSwitchIn' &&
                                ((dataObj = {
                                    label: 'Transactions After IOP - Switch In',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'transactionsAfterIopSwitchOut' &&
                                ((dataObj = {
                                    label: 'Transactions After IOP - Switch Out',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'switchingType' &&
                                ((dataObj = { label: 'Switching Type', data: value }), tempSectionData.push(dataObj));
                            key === 'minSwitchOutUnits' &&
                                ((dataObj = { label: 'Min. Switch Out Units', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'switchingFee' &&
                                ((dataObj = { label: 'Switching Fee', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'switchingGroup' &&
                                ((dataObj = {
                                    label: 'Switching Group Settlement Day',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'switchingSettlementDate' &&
                                ((dataObj = { label: 'Switching Settle Date', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                        }
                    }
                });
            }
            if (elm[0] === 'salesSummary' && elm[1] !== null) {
                // push data to respective summary section
                salesSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'transactionsAfterIopNewSales' &&
                                ((dataObj = {
                                    label: 'Transactions After IOP - New Sales',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minNewSalesAmount' &&
                                ((dataObj = {
                                    label: 'Min. New Sales Amt - Individual',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'maxNewSalesAmount' &&
                                ((dataObj = {
                                    label: 'Max. New Sales Amt - Individual',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minBalanceHoldingUnits' &&
                                ((dataObj = {
                                    label: 'Min. Balance Holdings Units',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'salesCutOffTime' &&
                                ((dataObj = { label: 'Sales Cut Off Time', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'batchCutOffTime' &&
                                ((dataObj = { label: 'Batch Cut off Time', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                        }
                    }
                });
            }
            if (elm[0] === 'topUpSummary' && elm[1] !== null) {
                // push data to respective summary section

                topUpSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'transactionsAfterIopTopUpSales' &&
                                ((dataObj = {
                                    label: 'Transactions After IOP - Top Up Sales',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minTopUpAmount' &&
                                ((dataObj = {
                                    label: 'Min. Topup Amt - Individual',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'maxTopUpAmount' &&
                                ((dataObj = {
                                    label: 'Max. Topup Amt - Individual',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                        }
                    }
                });
            }
            if (elm[0] === 'transferSummary' && elm[1] !== null) {
                // push data to respective summary section

                transferSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'transactionsAfterIopTransfer' &&
                                ((dataObj = {
                                    label: 'Transactions After IOP - Transfer',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minTransferOutUnits' &&
                                ((dataObj = {
                                    label: 'Min. Transfer Out Units',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minTransferOutUnits' &&
                                ((dataObj = {
                                    label: 'Transfer Fee (PRS Provider)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                        }
                    }
                });
            }

            mainObj[elm[0]].sections[0].data = tempSectionData;
        } else if (elm[0] === 'description') {
            const descriptionObj: FundDataObj = { label: 'Fund Objective', data: elm[1] };
            mainObj[elm[0]].sections[0].data.push(descriptionObj);
        } else {
            mainObj[elm[0]] = elm[1];
        }
    });

    return mainObj;
};

export const handleAmpSummary = (result: IFundSummaryPayload): IFundDetails => {
    const mainObj: IFundDetails | any = {
        fundName: '',
        utmc: '',
        fundSummary: { title: 'AMP Summary', sections: [{ data: [] }] },
        investmentSummary: { title: 'AMP Investment Information', sections: [{ data: [] }] },
        feesSummary: { title: 'Fees, Charges & Expenses', sections: [{ data: [] }] },
        epfSummary: { title: 'EPF Information', sections: [{ data: [] }] },
        redemptionSummary: { title: 'Redemption', sections: [{ data: [] }] },
        switchingSummary: {
            title: 'Switching',
            sections: [{ data: [] }, { title: 'Min. Switch In Units', titleIcon: 'fund-performance', data: [] }],
        },
        salesSummary: { title: 'New Sales', sections: [{ data: [] }] },
        topUpSummary: { title: 'Top Up', sections: [{ data: [] }] },
        transferSummary: { title: 'Transfer', sections: [{ data: [] }] },
        description: { title: 'AMP Description', sections: [{ data: [] }] },
    };

    Object.entries(result).map((elm: Record<string, any>) => {
        const tempSectionData: FundDataObj[] = [];
        const _tempSubSectionData: FundDataObj[] = [];
        // build fund details summary sections
        if (elm[0] !== 'fundName' && elm[0] !== 'utmc' && elm[0] !== 'description' && elm[0] !== 'filters') {
            let dataObj: FundDataObj = { label: '', data: null };

            if (elm[0] === 'fundSummary' && elm[1] !== null) {
                // push data to respective summary section
                ampSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order

                        if (labelName === key) {
                            key === 'fundCode' &&
                                ((dataObj = { label: 'AMP Code', data: value ? value : '-' }),
                                tempSectionData.push(dataObj));
                            key === 'fundCategory' &&
                                ((dataObj = { label: 'AMP Category', data: value }), tempSectionData.push(dataObj));
                            key === 'fundProcessingGroup' &&
                                ((dataObj = {
                                    label: 'AMP Processing Group',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'fundDescription' &&
                                ((dataObj = { label: 'AMP Description', data: value }), tempSectionData.push(dataObj));
                            key === 'closedEnd' &&
                                ((dataObj = { label: 'AMP Description - Closed-End', data: value ? 'Yes' : 'No' }),
                                tempSectionData.push(dataObj));
                            key === 'fundType' &&
                                ((dataObj = { label: 'SC Fund Type', data: value }), tempSectionData.push(dataObj));
                            key === 'isSyariah' &&
                                ((dataObj = { label: 'Type', data: value ? 'Shariah' : 'Conventional' }),
                                tempSectionData.push(dataObj));
                            key === 'fundClass' &&
                                ((dataObj = {
                                    label: 'AMP Class',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'trustee' &&
                                ((dataObj = { label: camelCaseToTitleCase(key), data: value }),
                                tempSectionData.push(dataObj));
                            key === 'scProcessingGroup' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'fundStartDate' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: moment.unix(value as number).format('DD/MM/YYYY'),
                                }),
                                tempSectionData.push(dataObj));
                            key === 'iopEndDate' &&
                                ((dataObj = {
                                    label: 'IOP End Date',
                                    data: moment.unix(value as number).format('DD/MM/YYYY'),
                                }),
                                tempSectionData.push(dataObj));
                            key === 'maturityDate' &&
                                ((dataObj = {
                                    label: 'Maturity Date (Closed-End)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'commercialStartDate' &&
                                ((dataObj = {
                                    label: 'Commercial Start Date',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'transactionsAfterIopAllow' &&
                                ((dataObj = {
                                    label: 'Transactions After IOP - Allow',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'riskCategory' &&
                                ((dataObj = { label: camelCaseToTitleCase(key), data: value }),
                                tempSectionData.push(dataObj));
                            key === 'nonBusinessDayFrom' &&
                                ((dataObj = {
                                    label: 'Non Business Day - From',
                                    data: moment.unix(value as number).format('DD/MM/YYYY'),
                                }),
                                tempSectionData.push(dataObj));
                            key === 'nonBusinessDayTo' &&
                                ((dataObj = {
                                    label: 'Non Business Day - To',
                                    data: moment.unix(value as number).format('DD/MM/YYYY'),
                                }),
                                tempSectionData.push(dataObj));
                            key === 'ageGroup' &&
                                ((dataObj = {
                                    label: 'Default Asset Allocation - Age Group',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'lipperCode' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minimumBalanceHoldingUnits' &&
                                ((dataObj = { label: 'Min. Balance Holdings Units', data: value }),
                                tempSectionData.push(dataObj));
                            key === 'distributedBy' &&
                                ((dataObj = { label: 'Distributed', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'felSharing' &&
                                ((dataObj = { label: 'FEL Sharing', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));

                            key === 'landingFund' &&
                                ((dataObj = { label: 'Landing Fund', data: value ? 'Yes' : 'No' }),
                                tempSectionData.push(dataObj));
                        }
                    }
                });
            }
            if (elm[0] === 'investmentSummary' && elm[1] !== null) {
                // push data to respective summary section

                ampInvestmentSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'instruction' &&
                                ((dataObj = {
                                    label: 'Income Distribution Instruction',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'dividentTiming' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minimumDividentPayoutAmount' &&
                                ((dataObj = {
                                    label: 'Min Dividend Amount Payout',
                                    data: value !== '' ? `MYR ${value}` : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'dailyIncomeDistribution' &&
                                ((dataObj = { label: camelCaseToTitleCase(key), data: value ? 'Yes' : 'No' }),
                                tempSectionData.push(dataObj));
                            key === 'fundBaseCurrency' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'fundClassCurrency' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'isAipAllowed' &&
                                ((dataObj = { label: 'Automatic Investment Plan (AIP)', data: value ? 'Yes' : 'No' }),
                                tempSectionData.push(dataObj));
                            key === 'coolOffDays' &&
                                ((dataObj = {
                                    label: 'No. of Cool Off Days',
                                    data: value,
                                }),
                                tempSectionData.push(dataObj));

                            key === 'moneySightedFund' &&
                                ((dataObj = { label: camelCaseToTitleCase(key), data: value ? 'Yes' : 'No' }),
                                tempSectionData.push(dataObj));
                            key === 'differenceFundPrice' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'dedicatedFund' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                        }
                    }
                });
            }
            if (elm[0] === 'feesSummary' && elm[1] !== null) {
                // push data to respective summary section

                feesSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'issuePrice' &&
                                ((dataObj = {
                                    label: camelCaseToTitleCase(key),
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'annualManagementFee' &&
                                ((dataObj = {
                                    label: 'Annual Management Fee (%)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'annualTrusteeFee' &&
                                ((dataObj = {
                                    label: 'Annual Trustee Fee (%)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minSalesFees' &&
                                ((dataObj = {
                                    label: 'Sales Charge - Min. Sales Fee (%)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'maxSalesFees' &&
                                ((dataObj = {
                                    label: 'Sales Charge - Max. Sales Fee (%)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'exitFeesCharge' &&
                                ((dataObj = { label: 'Exit Fees Charge (%)', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'exitFeesChargeAmount' &&
                                ((dataObj = {
                                    label: 'Exit Fees Charge (Amount)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'ampFee' &&
                                ((dataObj = {
                                    label: 'AMP Fee',
                                    data: value !== 'false' ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'gstParameter' &&
                                ((dataObj = { label: 'GST Parameter (%)', data: value }),
                                tempSectionData.push(dataObj));
                        }
                    }
                });
            }
            if (elm[0] === 'epfSummary' && elm[1] !== null) {
                // push data to respective summary section

                epfSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'epfApproved' &&
                                ((dataObj = {
                                    label: 'EPF Approved',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'epfStatus' &&
                                ((dataObj = {
                                    label: 'EPF Status',
                                    data: value,
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minSalesFee' &&
                                ((dataObj = {
                                    label: 'Sales Charge - Min. Sales Fee - EPF (%)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'maxSalesFee' &&
                                ((dataObj = {
                                    label: 'Sales Charge - Max. Sales Fee - EPF (%)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minNewSalesAmountEpf' &&
                                ((dataObj = {
                                    label: 'Min. New Sales Amt - EPF',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'maxNewSalesAmountEpf' &&
                                ((dataObj = {
                                    label: 'Max. New Sales Amt - EPF',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minTopupAmountEpf' &&
                                ((dataObj = { label: 'Min. Topup Amt - EPF', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'maxTopupAmountEpf' &&
                                ((dataObj = { label: 'Max. Topup Amt - EPF', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'exitFeesCharge' &&
                                ((dataObj = {
                                    label: 'Exit Fees Charge - EPF (%)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'exitFeesChargeAmount' &&
                                ((dataObj = {
                                    label: 'Exit Fees Charge - EPF (Amount)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                        }
                    }
                });
            }
            if (elm[0] === 'redemptionSummary' && elm[1] !== null) {
                // push data to respective summary section

                redemptionSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'redemption' &&
                                ((dataObj = {
                                    label: 'Transactions After IOP - Redemption',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'redemptionCoolOff' &&
                                ((dataObj = {
                                    label: 'Transactions After IOP - Redemption Cool Off',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'redemptionType' &&
                                ((dataObj = { label: 'Redemption Type', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'minRedemptionUnits' &&
                                ((dataObj = { label: 'Min. Redemption Units', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'redemptionSettleDate' &&
                                ((dataObj = {
                                    label: 'Redemption Settle Date',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'redemptionCutOffTime' &&
                                ((dataObj = {
                                    label: 'Redemption Cut Off Time',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'batchCutOffTime' &&
                                ((dataObj = { label: 'Batch Cut off Time', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                        }
                    }
                });
            }
            if (elm[0] === 'switchingSummary' && elm[1] !== null) {
                // push data to respective summary section

                ampSwitchingSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'transactionsAfterIopSwitchIn' &&
                                ((dataObj = {
                                    label: 'Transactions After IOP - Switch In',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'transactionsAfterIopSwitchOut' &&
                                ((dataObj = {
                                    label: 'Transactions After IOP - Switch Out',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'switchingType' &&
                                ((dataObj = { label: 'Switching Type', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'minSwitchOutUnits' &&
                                ((dataObj = { label: 'Min. Switch Out Units', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'switchingSettlementDate' &&
                                ((dataObj = {
                                    label: 'Switching Settle Date',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'switchingFee' &&
                                ((dataObj = { label: 'Switching Fee', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'minSalesAmountEpf' &&
                                ((dataObj = {
                                    label: 'Min. New Sales (EPF) Amount',
                                    data: value ? value : 'Not Available',
                                }),
                                _tempSubSectionData.push(dataObj));
                            key === 'minTopUpEpf' &&
                                ((dataObj = {
                                    label: 'Min. Top Up (EPF) Amount',
                                    data: value ? value : 'Not Available',
                                }),
                                _tempSubSectionData.push(dataObj));
                            key === 'minSalesAmountNonEpf' &&
                                ((dataObj = {
                                    label: 'Min. New Sales (Non-EPF) Amount',
                                    data: value ? value : 'Not Available',
                                }),
                                _tempSubSectionData.push(dataObj));
                            key === 'minTopUpNonEpf' &&
                                ((dataObj = {
                                    label: 'Min. Topup (Non-EPF) Amount',
                                    data: value ? value : 'Not Available',
                                }),
                                _tempSubSectionData.push(dataObj));
                        }
                    }
                });
                mainObj[elm[0]].sections[1].data = _tempSubSectionData;
            }
            if (elm[0] === 'salesSummary' && elm[1] !== null) {
                // push data to respective summary section

                salesSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'transactionsAfterIopNewSales' &&
                                ((dataObj = {
                                    label: 'Transactions After IOP - New Sales',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minNewSalesAmount' &&
                                ((dataObj = {
                                    label: 'Min. New Sales Amt - Individual',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'maxNewSalesAmount' &&
                                ((dataObj = {
                                    label: 'Max. New Sales Amt - Individual',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minBalanceHoldingUnits' &&
                                ((dataObj = {
                                    label: 'Min. Balance Holdings Units',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'salesCutOffTime' &&
                                ((dataObj = { label: 'Sales Cut Off Time', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                            key === 'batchCutOffTime' &&
                                ((dataObj = { label: 'Batch Cut off Time', data: value ? value : 'Not Available' }),
                                tempSectionData.push(dataObj));
                        }
                    }
                });
            }
            if (elm[0] === 'topUpSummary' && elm[1] !== null) {
                // push data to respective summary section

                topUpSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'transactionsAfterIopTopUpSales' &&
                                ((dataObj = {
                                    label: 'Transactions After IOP - Top Up Sales',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minTopUpAmount' &&
                                ((dataObj = {
                                    label: 'Min. Topup Amt - Individual',
                                    data: value,
                                }),
                                tempSectionData.push(dataObj));
                            key === 'maxTopUpAmount' &&
                                ((dataObj = { label: 'Max. Topup Amt - Individual', data: value }),
                                tempSectionData.push(dataObj));
                        }
                    }
                });
            }
            if (elm[0] === 'transferSummary' && elm[1] !== null) {
                // push data to respective summary section

                transferSummaryLabels.map((labelName) => {
                    for (const [key, value] of Object.entries(elm[1])) {
                        //sort order
                        if (labelName === key) {
                            key === 'transactionsAfterIopTransfer' &&
                                ((dataObj = {
                                    label: 'Transactions After IOP - Transfer',
                                    data: value ? 'Yes' : 'No',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minTransferOutUnits' &&
                                ((dataObj = {
                                    label: 'Min. Transfer Out Units',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                            key === 'minTransferOutUnits' &&
                                ((dataObj = {
                                    label: 'Transfer Fee (PRS Provider)',
                                    data: value ? value : 'Not Available',
                                }),
                                tempSectionData.push(dataObj));
                        }
                    }
                });
            }

            mainObj[elm[0]].sections[0].data = tempSectionData;
        } else if (elm[0] === 'description') {
            const descriptionObj: FundDataObj = { label: 'Fund Objective', data: elm[1] };
            mainObj[elm[0]].sections[0].data.push(descriptionObj);
        } else {
            mainObj[elm[0]] = elm[1];
        }
    });

    return mainObj;
};
