import gql from 'graphql-tag';

export const newNotificationCount = /* GraphQL */ gql`
    query inboxDashboard($input: InboxInput) {
        inboxDashboard(input: $input) {
            data {
                result {
                    newMessageCount
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
