import React, { ChangeEvent, FC, Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { IcoMoon } from '../../../icons';
import { MiniSearchBarProps } from './MiniSearchbarTypes';
import { CustomSpacer } from '../../atoms';
import { LABEL, TextBlue6 } from '../../../constants';

type SelectDropdownInnerProps = {
    isExpand: boolean;
    isDisabled: boolean;
};
export const MiniSearchbar: FC<MiniSearchBarProps> = ({
    searchTitle,
    dropDownOptions,
    onItemClick,
    testId,
    isDisabled,
    handleSearchDropdown,
}) => {
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const dropDownRef = useRef<HTMLDivElement>(null);
    const showRef = useRef<boolean>(true);

    const handleOutsideClick = (event: MouseEvent): void => {
        event.preventDefault();
        if (dropDownRef.current?.contains(event.target as Node)) {
            // inside click
            return;
        }
        setShowDropdown(false);
        // outside click
    };

    const renderOptions = (selectOptions: Array<IDropdownItemV2> | undefined): JSX.Element => {
        return (
            <Fragment>
                {selectOptions?.map(
                    (result, index) =>
                        result.item && (
                            <ListOptionItem
                                onClick={() => onItemClick && onItemClick(result.item, result.value, searchTitle)}
                                key={index}
                                id={result.testId}
                            >
                                <SelectedValue>{result.item}</SelectedValue>
                            </ListOptionItem>
                        ),
                )}
            </Fragment>
        );
    };

    useEffect(() => {
        showRef.current = false;
    }, []);

    useEffect(() => {
        if (showDropdown) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showDropdown]);

    useEffect(() => {
        if (dropDownOptions.length === 0 && !showRef) {
            setShowDropdown(true);
        }
    }, [dropDownOptions]);

    return (
        <MiniSearchBarContainer id={testId} data-testid={testId}>
            <MiniSearchBarTop id="mini-search-bar-top">
                <MiniSearchBarH2>{searchTitle}</MiniSearchBarH2>
            </MiniSearchBarTop>
            <MiniSearchBarBottom id="mini-search-bar-bottom">
                <SearchInner ref={dropDownRef} isExpand={showDropdown} isDisabled={isDisabled ? isDisabled : false}>
                    <StyledInput
                        maxLength={255}
                        placeholder={`Search by ${searchTitle}`}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const length = handleSearchDropdown(event);
                            if (event.target.value.length > length) {
                                setShowDropdown(true);
                            } else {
                                setShowDropdown(false);
                            }
                        }}
                        disabled={isDisabled}
                    />

                    {showDropdown && dropDownOptions.length > 0 ? (
                        <ListOptions> {renderOptions(dropDownOptions)}</ListOptions>
                    ) : showDropdown ? (
                        <ListOptions>
                            <StyledDiv>
                                <IcoMoon name={'empty-results'} size={'9rem'} />
                                <CustomSpacer space={'1rem'} />
                                <TextBlue6 size={'14px'}>{LABEL.noResultsFound}</TextBlue6>
                            </StyledDiv>
                        </ListOptions>
                    ) : null}
                    <Span>
                        <IcoMoon name="search-dashboard" size="1.5rem" />
                    </Span>
                </SearchInner>
            </MiniSearchBarBottom>
        </MiniSearchBarContainer>
    );
};

const MiniSearchBarContainer = styled.div`
    width: 100%;
`;

const MiniSearchBarTop = styled.div`
    display: flex;
`;

const MiniSearchBarH2 = styled.h2`
    color: var(--Dark-Grey-6, #333);
    font-size: 0.8rem;
    font-weight: 700;
    display: flex;
    line-height: 15.6px;
    letter-spacing: -0.666667px;
    height: 2rem;
    align-items: center;
`;

const MiniSearchBarBottom = styled.div`
    display: flex;
    align-items: center;
`;

const SearchInner = styled.div<SelectDropdownInnerProps>`
    display: flex;
    position: relative;
    justify-content: space-between;
    background-color: ${(props) => (props.isDisabled ? '#f4f4f4' : '#fff')};
    border-radius: ${(props) => (props.isExpand ? ' 16px 16px 0 0' : '32px')};
    height: 48px;
    border: 1px solid #cecece;
    width: 100%;
    align-items: center;
    transition: all 0.2s ease;
    cursor: pointer;
    will-change: border-radius, box-shadow;
    box-shadow: ${(props) => (props.isExpand ? '0px 0px 0px 2px #002043 inset' : undefined)};
    /* &:focus-within {
        border: 2px solid #002043;
    } */
    /* &:hover {
        border: 2px solid #002043;
    } */
`;

const ListOptions = styled.ul`
    padding: 0px;
    list-style: none;
    position: absolute;
    background-color: #fff;
    border-radius: 0px 0px 16px 16px;
    width: 100%;
    box-shadow: 0px 0px 0px 2px rgb(0 32 67) inset;
    left: 0;
    top: 44px;
    padding: 1rem 0px;
    z-index: 9;
    margin: 0;
    max-height: 296px;
`;

const ListOptionItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 1.1rem;
    margin: 0 2px;
    user-select: none;
    &:hover {
        background-color: #f2f4f6;
    }
`;
const SelectedValue = styled.span`
    font-size: 1rem;
    font-weight: 700;
    opacity: 1;
`;
const Span = styled.span`
    position: absolute;
    align-self: center;
    right: 1rem;
    font-size: 1rem;
`;
const StyledInput = styled.input`
    margin-left: 1rem;
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
`;
const StyledDiv = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export default MiniSearchbar;
