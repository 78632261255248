export const getFileNameAndType = (fileName: string): Array<string> => {
    const _typeIndex = fileName.split(' ').findIndex((item) => item.includes('.'));
    const _fileName =
        _typeIndex === -1
            ? fileName.split(' ')[0]
            : fileName
                  .split(' ')
                  .filter((_item, index) => index !== _typeIndex)
                  .join(' ');
    const type = _typeIndex !== -1 ? fileName.split(' ')[_typeIndex] : fileName.split(' ')[1];
    return [_fileName, type];
};
