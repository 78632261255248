import gql from 'graphql-tag';
/**
 * @generateEddReportMonthlyQuery @query - Download EDD Status Report
 * @description: Generates monthly EDD report by given filters
 */

export const generateEddReportMonthlyQuery = gql`
    query generateEddReportMonthly($input: GenerateEddReportMonthlyInput) {
        generateEddReportMonthly(input: $input) {
            data {
                result {
                    message
                    url
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
