import gql from 'graphql-tag';
/**
 * @newCaseDefinitionQuery @query - EDD New Case Form API
 * @description: Retrieving Edd module (question template) for amla to create case
 */

export const getEddModuleQuery = gql`
    query getEddModule($input: GetEddModuleInput) {
        getEddModule(input: $input) {
            data {
                result {
                    title
                    description
                    options {
                        id
                        position
                        parent
                        title
                        description
                        type
                        hasRemark
                        multiSelection
                        autoHide
                        values
                        valuesDescription
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
