import { INITIAL_PROFILE } from '../pages';
import { emptyCheck } from './emptyCheck';

type staffProfile = {
    name: string;
    status: string;
    homeBranch: string;
    department: string;
    email: string;
    mobileNo: string;
};
export const handleStaffProfile = (profile: staffProfile): IStaffProfileDetails => {
    const temp: Array<IStaffProfileSection> = [];
    const staff: IStaffProfileDetails = INITIAL_PROFILE;
    const tempProfile = profile;
    const UserProfile: IStaffProfileSection = {
        title: 'User Profile',
        sections: [
            {
                data: [
                    { label: 'Home Branch', data: emptyCheck(tempProfile.homeBranch) },
                    { label: 'Department', data: emptyCheck(tempProfile.department) },
                ],
            },
        ],
    };
    const ContactDetails: IStaffProfileSection = {
        title: 'Contact Details',
        sections: [
            {
                data: [
                    { label: 'Email Address', data: emptyCheck(tempProfile.email) },
                    { label: 'Mobile Number', data: emptyCheck(tempProfile.mobileNo) },
                ],
            },
        ],
    };
    temp.push(UserProfile, ContactDetails);
    staff.name = emptyCheck(profile.name);
    staff.status = emptyCheck(profile.status);
    staff.details = temp;

    return staff;
};
