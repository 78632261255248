export const getInitials = (name: string): string | undefined => {
    const initials = name as string;
    return initials
        ?.match(/(^\S\S?|\b\S)?/g)
        ?.join('')
        ?.match(/(^\S|\S$)?/g)
        ?.join('')
        .toUpperCase();
};
export const getInitialsByFirstTwo = (name: string): string | undefined => {
    const initials = name as string;
    return initials
        ?.match(/(^\S\S?|\b\S)?/g)
        ?.join('')
        ?.match(/(^\S|\S$)?/g)
        ?.join('')
        .toUpperCase();
};
