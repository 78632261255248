import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FlexedDiv, RegulartTooltipTemplate, Tooltip } from '../components';
import { IcoMoon } from '../icons';
import AuthContext from '../context/AuthContext';
import styled from 'styled-components';
import moment from 'moment';

interface IDocumentColumnItem {
    name: string;
    url: string;
    documentType: string;
    isViewable: boolean;
    isValid: boolean;
}
export const DocumentColumnItem = ({ data, itemKey }: ITableCustomItem): JSX.Element => {
    const { userLoginContext } = useContext(AuthContext);
    const parsedPermission = JSON.parse(userLoginContext.permission);
    const { actions } = parsedPermission.hq.permission.productSettings.documentsTab;

    let docObj: IDocumentColumnItem = { documentType: '', name: '', url: '', isViewable: false, isValid: true };
    const toolTipMessage: Array<ITooltip> = [];
    //for each document in data check the doctype against column name to create docObj

    data.document.map((doc: IDocumentColumnItem) => {
        switch (itemKey[0].key) {
            case 'prospectusInformation':
                if (actions.canPreviewProspectus === 'maker') {
                    doc.documentType === 'PI' ? (docObj = { ...doc }) : (docObj.isValid = false);
                    doc.documentType === 'DD' ? (docObj = { ...doc }) : (docObj.isValid = false);
                    doc.documentType === 'IM' ? (docObj = { ...doc }) : (docObj.isValid = false);
                    docObj.isViewable = true;
                }
                break;
            case 'productHighlight':
                if (actions.canPreviewHighlight === 'maker') {
                    doc.documentType === 'PHS' ? (docObj = { ...doc }) : (docObj.isValid = false);
                    docObj.isViewable = true;
                }

                break;
            case 'annualReport':
                if (actions.canPreviewAnnual === 'maker') {
                    doc.documentType === 'AR' ? (docObj = { ...doc }) : (docObj.isValid = false);
                    docObj.isViewable = true;
                }

                break;
            case 'fundFact':
                if (actions.canPreviewMonthly === 'maker') {
                    doc.documentType === 'FFS' ? (docObj = { ...doc }) : (docObj.isValid = false);
                    docObj.isViewable = true;
                }
                break;
            default:
        }
    });
    !docObj.isValid && toolTipMessage.push({ label: 'Document not found' });
    !docObj.isViewable && toolTipMessage.push({ label: 'Unauthorized to view' });

    return (
        <Fragment>
            {docObj.url !== '' ? (
                <FlexedDiv alignItems="flex-start" direction="column">
                    <Link to={{ pathname: docObj && docObj.url }} target="_blank">
                        <IcoMoon name="file" size="1.3rem" />
                        <SubLabel>{moment(data.lastUpdated, 'x').format('DD/MM/YYYY')}</SubLabel>
                    </Link>
                </FlexedDiv>
            ) : (
                <Tooltip tooTipContent={<RegulartTooltipTemplate message={toolTipMessage} />} placement="Bottom">
                    <div>
                        <IcoMoon name="warning" color="#E89700" size="1.667vw" />
                    </div>
                </Tooltip>
            )}
        </Fragment>
    );
};

const SubLabel = styled.p`
    color: rgba(42, 54, 90, 0.8);
    font-size: 0.75rem;
    line-height: 1.5rem;
    margin-bottom: 0px;
`;
