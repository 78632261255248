import React, { ReactText } from 'react';
import styled from 'styled-components';
import { LABEL, TextDarkBlack } from '../../../constants';
import { IcoMoon } from '../../../icons';
import { CustomDropdown, IDropdownItem } from '../../molecules';
import { FlexedDiv } from '../../atoms/FlexDiv';

export const DashboardNavigationBar = ({
    disableResultLimit,
    resultLimit,
    maxPage,
    testId,
    handleNext,
    handlePrevious,
    page,
    limits,
    setCurrentPage,
    setResultLimit,
    resultInfoText,
    previousStyle,
    nextStyle,
}: IDashboardNavigationBarProps): JSX.Element => {
    /**
     * Function to handle page limit selection
     *@method handlePageLimit
     *@param value type of ReactText
     */
    const handlePageLimit = (value: ReactText) => {
        // const valueInt = parseInt(value.toString(), 10);
        value && setResultLimit(parseInt(value.toString(), 10));
    };

    /**
     * Function to generate page limits
     *@method generatePageLimits
     *@returns IDropdownItem[]
     */
    const generatePageLimits = (): IDropdownItem[] => {
        const _items: IDropdownItem[] = [];
        limits.map((result) =>
            _items.push({ item: result.toString(), handleItem: handlePageLimit, testId: `${result}-option` }),
        );

        return _items;
    };

    /**
     * Function to handle page number selection
     *@method handlePageNumber
     *@param value type of ReactText
     */
    const handlePageNumber = (value: ReactText) => {
        value && setCurrentPage(parseInt(value.toString()));
    };
    /**
     * Function to generate page numbers
     *@method generatePageNumbers
     *@returns IDropdownItem[]
     */
    const generatePageNumbers = (maxpages: number): IDropdownItem[] => {
        const _items: IDropdownItem[] = [];
        if (maxpages !== 0) {
            for (let i = 1; i <= maxpages; i++) {
                _items.push({ item: i.toString(), handleItem: handlePageNumber, testId: `${i}-option` });
            }
        }

        return _items;
    };
    return (
        <TableNav justifyContent={'space-between'} alignItems="center">
            {resultInfoText ? (
                <TextDarkBlack size="16px" weight="700">
                    {resultInfoText}
                </TextDarkBlack>
            ) : (
                <div></div>
            )}
            <FlexedDiv>
                <PaginationContainer>
                    <CustomDropdown items={generatePageLimits()} noMinWidth={true} disableDropdown={disableResultLimit}>
                        <DropDownInner>
                            <DropDownSpan>{LABEL.item}</DropDownSpan>
                            <DropDownSpan>{resultLimit}</DropDownSpan>

                            <IcoMoon name="caret-down" size="1.5rem" />
                        </DropDownInner>
                    </CustomDropdown>

                    <CustomDropdown
                        items={generatePageNumbers(maxPage ? maxPage : 0)}
                        noMinWidth={true}
                        disableDropdown={disableResultLimit}
                    >
                        <DropDownInner>
                            <DropDownSpan>{LABEL.page}</DropDownSpan>
                            <DropDownSpan>{page}</DropDownSpan>

                            <IcoMoon name="caret-down" size="1.5rem" />
                        </DropDownInner>
                    </CustomDropdown>

                    <MaxPageSpan>of {maxPage}</MaxPageSpan>

                    <FlexedDiv alignItems="center" style={{ opacity: page === 1 && maxPage === 1 ? 0.5 : 1 }}>
                        <CirclePrevBtn
                            className="card_buttons_back"
                            style={{
                                cursor: page === 1 ? 'default' : 'pointer',
                                opacity: page === 1 ? 0.5 : 1,
                                ...previousStyle,
                            }}
                            onClick={() => handlePrevious()}
                            id={`${testId}-prev-btn`}
                        >
                            <IcoMoon name="caret-left" size="1.5rem" />
                        </CirclePrevBtn>

                        <CircleNextBtn
                            className="card_buttons_next"
                            style={{
                                cursor: maxPage === page ? 'default' : 'pointer',
                                opacity: maxPage === page ? 0.5 : 1,
                                ...nextStyle,
                            }}
                            onClick={() => handleNext()}
                            id={`${testId}-next-btn`}
                        >
                            <IcoMoon name="caret-right" size="1.5rem" />
                        </CircleNextBtn>
                    </FlexedDiv>
                </PaginationContainer>
            </FlexedDiv>
        </TableNav>
    );
};

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
`;

const DropDownInner = styled.div`
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
`;
const DropDownSpan = styled.span`
    margin-right: 5px;
    :nth-child(2) {
        font-weight: bold;
    }
`;
const MaxPageSpan = styled.span`
    /* margin-left: 5px; */
    color: #878787;
`;
const CircleBtn = styled.div`
    margin: 0px 0.5rem 0 1rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    border: 1px solid #eaebee;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const CircleNextBtn = styled(CircleBtn)`
    margin: 0px 1.736vw 0px 4px;
`;
const CirclePrevBtn = styled(CircleBtn)`
    margin: 0px 4px 0px 1rem;
`;
const TableNav = styled((props) => <FlexedDiv {...props} />)`
    padding: 24px;
`;
