import gql from 'graphql-tag';

/**
 * @statusDropDown @query  Endpoint to get track order
 * @description Returns all orders for tracking for both hq and branch */
export const trackingDashboard = gql`
    query trackingDashboard($input: BranchDashboardInput) {
        trackingDashboard(input: $input) {
            data {
                result {
                    groupedTransactions {
                        grouping {
                            type
                            groupingData {
                                key
                                value
                            }
                            transactionData {
                                grouping {
                                    type
                                    groupingData {
                                        principalName
                                        principalNumber
                                        orderNumber: orderNo
                                        jointName
                                        jointNumber
                                        sameBranch: viewable
                                    }
                                    transactionData {
                                        data {
                                            requestId
                                            fundCode
                                            lastUpdated
                                            submittedOn
                                            createdOn
                                            orderNo
                                            transactionRef
                                            accountType
                                            clientName
                                            clientIdNum
                                            transactionType
                                            fundType
                                            paymentMethod
                                            fundCategory
                                            totalInvestment {
                                                fundCurrency
                                                investmentAmount
                                            }
                                            agentName
                                            agentCode
                                            status
                                            remarks {
                                                title
                                                content
                                            }
                                            cutOffTime
                                            batchTime
                                            targetDate
                                            aging
                                            approvalType
                                            utmc
                                            isSeen
                                            viewable
                                        }
                                    }
                                }
                            }
                        }
                    }
                    page
                    pages
                    totalResultCount
                    filters {
                        branchName
                        transactionType
                        branchStatus
                        batchTime
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
