import React, { CSSProperties, ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface IContainerProps {
    children: ReactNode;
    visibility: boolean;
    width?: string;
    style?: CSSProperties;
    testId?: string;
}

const Container: React.FC<IContainerProps> = ({ children, visibility, width, style, testId }: IContainerProps) => {
    return (
        <StyledContainerDiv show={visibility} style={{ width: width, ...style }} data-testid={`${testId}-container`}>
            {children}
        </StyledContainerDiv>
    );
};

interface IShowElement {
    show: boolean;
}

const StyledContainerDiv = styled.div`
    width: 100%;
    display: none;
    ${(props: IShowElement) =>
        props.show &&
        css`
            display: flex;
        `};
`;
export default Container;
