import styled from 'styled-components';

const black = '#333333';

export const TextBold12 = styled.div`
    color: ${black};
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5rem;
`;

export const TextBold16 = styled.div`
    color: ${black};
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5rem;
`;

export const TextNormal12 = styled.div`
    color: ${black};
    font-size: 12px;
    font-weight: normal;
    line-height: 1.5rem;
`;

export const TextBold18 = styled.div`
    color: ${black};
    font-size: 18px;
    font-weight: 700;
`;

export const TextBold24 = styled.text`
    color: ${black};
    font-size: 24px;
    font-weight: 700;
`;
export const TextNormal16 = styled.text`
    color: ${black};
    font-size: 16px;
    font-weight: normal;
`;
export const TextNormal18 = styled.text`
    color: ${black};
    font-size: 16px;
    font-weight: normal;
`;
export const PageDescription = styled.div`
    margin: 0.5rem 0rem 2rem 0rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #333333;
    letter-spacing: -0.05px;
`;
