interface IStatuses {
    tab: string;
    count: string;
}

export const makeStatusesTags = (statuses: IStatuses[], tags: IDashboardFilterTags[]): IStatusesTagsProps => {
    const temp: IStatusesTagsProps = {
        statusCount: [],
        statusTags: [...tags],
    };

    const tempStatusTags: IDashboardFilterTags[] = [];
    statuses.length !== 0
        ? statuses.map((status, index) => {
              const obj: IDashboardFilterTags = {
                  index: index,
                  title: status.tab,
                  active: false,
              };
              if (parseInt(status.count, 10)) {
                  let index = 0;
                  index < temp.statusCount.length;
                  index += 1;
                  tempStatusTags.push(obj);
                  temp.statusCount.push(parseInt(status.count));
              }
          })
        : null;

    // if (temp.statusTags.length < tempStatusTags.length) {
    if (temp.statusTags.length !== 0) {
        tempStatusTags.map((item, i) => {
            if (temp.statusTags[i] !== undefined && item.title !== undefined) {
                item.title !== temp.statusTags[i].title ? (temp.statusTags[i] = item) : null;
            } else {
                temp.statusTags.push(item);
            }
        });
    } else temp.statusTags = tempStatusTags;
    // }
    return temp;
};
