import React, { Fragment, useEffect, useRef } from 'react';
import { CustomButton } from '../..';
import { IcoMoon } from '../../../icons';

import styled from 'styled-components';
import ReactDOM from 'react-dom';

type ModalTopProps = {
    isCloseable?: boolean;
    alignment?: string;
};

type ModalBottomProps = {
    multipleBtns?: boolean;
};
export const Modal = ({
    modalActive,
    title,
    testId,
    primaryBtn,
    secondaryBtn,
    children,
    isCloseable,
    icon,
    contentAlignment,
    setModalActive,
    wrapperStyle,
}: IModalProps): JSX.Element => {
    const _root = document.querySelector('#modal') as Element;
    const inputEl = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        inputEl.current !== null ? inputEl.current.focus() : null;
    }, []);
    return (
        <Fragment>
            {ReactDOM.createPortal(
                <Fragment>
                    <ModalElm id={`${testId}-modal`} data-testid="custom-modal" style={wrapperStyle}>
                        <ModalInner id="modal-inner">
                            {isCloseable && modalActive && (
                                <Closeable>
                                    <div onClick={() => setModalActive(false)} data-testid="custom-modal-close">
                                        <IcoMoon
                                            color="#002043"
                                            name="close"
                                            size="2rem"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                </Closeable>
                            )}
                            <ModalTop id="modal-top" isCloseable={isCloseable} alignment={contentAlignment}>
                                {icon && (
                                    <IcoMoon name={icon} size="8rem" style={{ display: 'block', margin: '0 auto' }} />
                                )}
                                <ModalH1>{title}</ModalH1>
                            </ModalTop>
                            {children && <ModalContent>{children}</ModalContent>}

                            <ModalBottom
                                id="modal-bottom"
                                multipleBtns={secondaryBtn?.onClick && primaryBtn?.onClick ? true : false}
                            >
                                {secondaryBtn && secondaryBtn.onClick && (
                                    <CustomButton
                                        {...secondaryBtn}
                                        onClick={secondaryBtn.onClick}
                                        style={{ width: '212px', height: '48px', marginRight: '1rem' }}
                                        id={`${testId}-secondary-btn`}
                                        size={secondaryBtn.size}
                                    >
                                        {secondaryBtn.label}
                                    </CustomButton>
                                )}
                                {primaryBtn && primaryBtn.onClick && (
                                    <CustomButton
                                        {...primaryBtn}
                                        primary={primaryBtn.primary}
                                        onClick={primaryBtn.onClick}
                                        style={{ width: '212px', height: '48px' }}
                                        id={`${testId}-primary-btn`}
                                        size={primaryBtn.size}
                                        ref={inputEl}
                                        onKeyDown={(event) => {
                                            event.preventDefault();
                                        }}
                                    >
                                        {primaryBtn.label}
                                    </CustomButton>
                                )}
                            </ModalBottom>
                        </ModalInner>
                    </ModalElm>
                    <Backdrop id="back-drop"></Backdrop>
                </Fragment>,
                _root,
            )}
        </Fragment>
    );
};

Modal.displayName = 'Modal';
const ModalElm = styled.div`
    position: fixed;
    width: 100%;
    max-width: 536px;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
    z-index: 9999;
`;
const ModalInner = styled.div`
    background-color: #f2f4f6;
    border-radius: 8px;
`;
const ModalH1 = styled.h1`
    color: #002043;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0px;
`;
const ModalTop = styled.div<ModalTopProps>`
    padding: ${(props) => (props.isCloseable ? '0px 3rem 1.5rem 3rem' : '2.5rem  3rem 1.5rem')};
    text-align: ${(props) => (props.alignment ? props.alignment : 'left')};
`;
const ModalContent = styled.div`
    padding: 0px 3rem;
    margin-bottom: 2.5rem;
`;
const ModalBottom = styled.div<ModalBottomProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 3rem 1rem 3rem;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
`;
const Closeable = styled.div`
    display: flex;
    padding: 1.5rem;
    justify-content: flex-end;
`;
const Backdrop = styled.h2`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(0 0 0 / 70%);
    z-index: 999;
`;
