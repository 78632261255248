import React, { Dispatch } from 'react';

export interface AdvisersContextProps {
    adviserCount: number;
    adviserData: ITableData[];
    currentPage: number;
    filters: IColumnValue[];
    loading: boolean;
    maxPages: number;
    resultLimit: number;
    searchInput: ISearchInput;
    sortData: ISort_FilterInput;
    tab: number;
    adviserDataGrouped: TGroupingInfo[];
    memoizedDashboardInput: MemoizedAdviserDashboardInput;
    setAdviserCount: Dispatch<React.SetStateAction<number>>;
    setAdviserData: Dispatch<React.SetStateAction<ITableData[]>>;
    setCurrentPage: Dispatch<React.SetStateAction<number>>;
    setFilters: Dispatch<React.SetStateAction<IColumnValue[]>>;
    setMaxPages: Dispatch<React.SetStateAction<number>>;
    setResultLimit: Dispatch<React.SetStateAction<number>>;
    setSearchInput: Dispatch<React.SetStateAction<ISearchInput>>;
    setSortData: Dispatch<React.SetStateAction<ISort_FilterInput>>;
    setTab: Dispatch<React.SetStateAction<number>>;

    getDashboardCallBack: () => void;
    loadingHandler: () => void;
}

const AdvisersContext = React.createContext({} as AdvisersContextProps);

export default AdvisersContext;
