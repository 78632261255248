/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { lazy, Fragment, useContext, useEffect } from 'react';
import { Route, RouteProps, Switch, useHistory } from 'react-router-dom';
import * as ROUTES from '../../routes';

import {
    activityLogsRoutes,
    branchHQCERoutes,
    fundManagementRoutes,
    systemAdminRoutes,
    systemSettingRoutes,
    resourcesRoutes,
} from './routesHQConfig';

import { ActivityLogsProvider, SystemAdminProvider, AdviserAccessProvider } from '../../context/SystemAdminContext';
import { ProductSettingsProvider } from '../../context/ProductSettingsContext';
import { UploadsProvider } from '../../context/UploadsContext';
import { onRefreshFn } from '../../utils/authServices';
import { FlexedDiv, Modal } from '../../components';
import { LABEL } from '../../constants';
import WebSocketContext from '../../context/WebSocketContext';

// Shared imports
import AuthContext from '../../context/AuthContext';
import BranchOfficeProvider from '../../context/HQCEContext/branchOffice/BranchOfficeProvider';
import DashboardBranchProvider from '../../context/BranchContext/DashboardBranchProvider';
import HQCEProvider from '../../context/HQCEContext/HQCEProvider';
import AdvisersProvider from '../../context/AdviserContext/AdvisersProvider';
import { InboxProvider } from '../../context/InboxContext';
import TrackOrderProvider from '../../context/TrackOrderContext/TrackOrderProvider';
import PendingSubmissionProvider from '../../context/PendingSubmissionContext/PendingSubmissionProvider';
import InvestorsProvider from '../../context/InvestorContext/InvestorsProvider';
import ReportProvider from '../../context/ReportContext/ReportProvider';
import { IdleTimer } from '../../components/molecules/IdleTimer/IdleTimer';

const HQWrapper = lazy<any>(() => import('../../pages/Wrapper/HQWrapper'));
const Profile = lazy<any>(() => import('../../pages/Profile'));
const Inbox = lazy<any>(() => import('../../pages/Inbox/Inbox'));
const Error404 = lazy<any>(() => import('../../pages/ExceptionHandling/Error404'));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IHqRouteProps extends RouteProps {
    component?: any;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NewHqRoute = ({ component: Component, ...rest }: IHqRouteProps): JSX.Element => {
    const { userLoginContext, windowReloadFn, userLogoutFn } = useContext(AuthContext);
    const { isLoggedOut, setIsLoggedOut, logoutSuccess } = useContext(WebSocketContext);
    const history = useHistory();

    const checkPermission = (permission: any) => {
        const parsedPermission = JSON.parse(permission);
        if (parsedPermission.hq.grant === 'hq') {
            return true;
        }
        return false;
    };

    //EDD routes temporarily disabled for UAT

    //Fn to render system admin routes based on different privileges
    //Fn to render fund management routes based on privileges

    //Fn to render HQCE branch routes based on privileges

    //Fn to render EDD routes based on privileges

    const getPermissibleRoutes = (hq: IHqPermissions) => {
        const tempRoutes: JSX.Element[] = [];
        //CE
        if (hq.permission.ceDashboard?.isAll === 'true') {
            branchHQCERoutes.forEach((route) =>
                tempRoutes.push(
                    <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />,
                ),
            );
        }
        //EDD
        // if (hq.permission.eddCase?.isAll === 'true') {
        //     eddRoutes.forEach((route) =>
        //         tempRoutes.push(
        //             <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />,
        //         ),
        //     );
        // }
        //Fund Management
        if (hq.permission.productSettings?.isAll === 'true') {
            fundManagementRoutes.forEach((route) =>
                tempRoutes.push(
                    <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />,
                ),
            );
        }
        //System Admin User Management
        if (hq.permission.userManagement?.isAll === 'true') {
            systemAdminRoutes.forEach((route) =>
                tempRoutes.push(
                    <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />,
                ),
            );
        }
        //System Admin Activity Logs
        if (hq.permission.activityLogs?.isAll === 'true') {
            activityLogsRoutes.forEach((route) =>
                tempRoutes.push(
                    <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />,
                ),
            );
        }
        //System Admin System Settings
        if (hq.permission.systemSettings?.isAll === 'true') {
            systemSettingRoutes.forEach((route) =>
                tempRoutes.push(
                    <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />,
                ),
            );
        }
        //Resources-Reports
        if (hq.permission.reports?.isAll === 'true') {
            resourcesRoutes.forEach((route) =>
                tempRoutes.push(
                    <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />,
                ),
            );
        }
        return tempRoutes;
    };
    const renderRoutes = (permission: string): JSX.Element[] => {
        const parsedPermission: IPermissionObject = JSON.parse(permission);
        return getPermissibleRoutes(parsedPermission.hq);
    };
    useEffect(() => {
        windowReloadFn();
    }, [onRefreshFn]);

    return (
        <Fragment>
            {userLoginContext.isAuthenticated && checkPermission(userLoginContext.permission) ? (
                <HQWrapper>
                    <InboxProvider>
                        {renderRoutes(userLoginContext.permission).length !== 0 ? (
                            <HQCEProvider>
                                <SystemAdminProvider>
                                    <AdviserAccessProvider>
                                        <ActivityLogsProvider>
                                            <DashboardBranchProvider>
                                                <TrackOrderProvider>
                                                    <PendingSubmissionProvider>
                                                        <BranchOfficeProvider>
                                                            <AdvisersProvider>
                                                                <InvestorsProvider>
                                                                    <ProductSettingsProvider>
                                                                        <UploadsProvider>
                                                                            <ReportProvider>
                                                                                {isLoggedOut === false &&
                                                                                    logoutSuccess === false && (
                                                                                        <IdleTimer timeout={900000} />
                                                                                    )}
                                                                                <Switch>
                                                                                    {renderRoutes(
                                                                                        userLoginContext.permission,
                                                                                    )}
                                                                                    <Route
                                                                                        exact
                                                                                        path={ROUTES.hqInbox}
                                                                                        component={Inbox}
                                                                                    />
                                                                                    <Route
                                                                                        exact
                                                                                        path={ROUTES.hqProfile}
                                                                                        component={Profile}
                                                                                    />
                                                                                    <Route component={Error404} />
                                                                                </Switch>
                                                                            </ReportProvider>
                                                                        </UploadsProvider>
                                                                    </ProductSettingsProvider>
                                                                </InvestorsProvider>
                                                            </AdvisersProvider>
                                                        </BranchOfficeProvider>
                                                    </PendingSubmissionProvider>
                                                </TrackOrderProvider>
                                            </DashboardBranchProvider>
                                        </ActivityLogsProvider>
                                    </AdviserAccessProvider>
                                </SystemAdminProvider>
                            </HQCEProvider>
                        ) : null}
                    </InboxProvider>
                </HQWrapper>
            ) : null}
            {isLoggedOut ? (
                <Modal
                    title={'Duplicate log in'}
                    modalActive={isLoggedOut}
                    setModalActive={setIsLoggedOut}
                    primaryBtn={{
                        onClick: () => {
                            userLogoutFn();
                            history.push(ROUTES.signIn);
                            window.location.reload();
                            setIsLoggedOut(!isLoggedOut);
                        },
                        label: LABEL.okay,
                        primary: true,
                        size: 'large',
                    }}
                    icon="logout-modal-error"
                    contentAlignment="center"
                    testId="custom_modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {'Your account has been logged in from another device.'}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};
export default NewHqRoute;
