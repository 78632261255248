import gql from 'graphql-tag';

/**
 * @fundDaraDashboardQuery @query - Fund Data Dashboard
 * @description:  Returns all fund data
 */

export const fundDataDashboardQuery = gql`
    query fundDataDashboard($input: DashboardInput) {
        fundDataDashboard(input: $input) {
            data {
                result {
                    transactions {
                        fundName
                        fundClass
                        fundCurrency
                        lastUpdated
                        startDate
                        fundType
                        fundCategory
                        fundStatus
                        isSyariah
                        grossDistribution
                        netDistribution
                        unitSplit
                        riskCategory
                        fundId
                        issuingHouse
                        fundAbbr
                        closingDate
                        latestNavPerUnit
                        previousNavPerUnit
                        latestNavDate
                        previousNavDate
                        latestNetDate
                        latestGrossDate
                        unitClosingDate
                        document {
                            name
                            url
                            documentType
                        }
                    }
                    filters {
                        fundClass
                        fundCategory
                        fundCurrency
                        issuingHouse
                        fundStatus
                        fundType
                        riskCategory
                    }
                    allFundsCount
                    ampFundCount
                    page
                    pages
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
