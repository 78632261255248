import gql from 'graphql-tag';

/**
 * @getTransactionTracking @query - HQ Dashboard
 * @description: Returns transaction tracking list
 */

export const getTransactionTrackingQuery = /* GraphQL */ gql`
    query getTransactionTracking($input: TransactionTrackingInput) {
        getTransactionTracking(input: $input) {
            data {
                result {
                    filters {
                        levels
                    }
                    trackingSummary {
                        level
                        data {
                            createdOn
                            status
                            name
                            username
                            remarks {
                                title
                                content
                            }
                        }
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
