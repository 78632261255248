import React from 'react';

interface IIndicatorIconProps {
    finished: boolean;
}

export const IndicatorIcon = (props: IIndicatorIconProps): JSX.Element => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                fill={props.finished ? '#2ECC82' : '#C61230'}
            />
            {props.finished && (
                <>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.7755 5.22848C11.9449 5.4029 11.9449 5.68571 11.7755 5.86014L7.00589 10.7733C6.83655 10.9477 6.56201 10.9477 6.39268 10.7733L4.22466 8.54004C4.05532 8.36561 4.05532 8.0828 4.22466 7.90838C4.39399 7.73395 4.66853 7.73395 4.83787 7.90838L6.69928 9.8258L11.1623 5.22848C11.3317 5.05405 11.6062 5.05405 11.7755 5.22848Z"
                        fill="#FBFBFB"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.092 5.16047C11.2997 4.94651 11.638 4.94651 11.8457 5.16047C12.0514 5.37237 12.0514 5.71448 11.8457 5.92638L7.07607 10.8395C6.86836 11.0535 6.53002 11.0535 6.32231 10.8395L4.15429 8.60628C3.94857 8.39438 3.94857 8.05227 4.15429 7.84037C4.36199 7.62641 4.70034 7.62641 4.90804 7.84037L6.69919 9.6854L11.092 5.16047ZM11.7052 5.29472C11.5742 5.15982 11.3635 5.15982 11.2325 5.29472L6.76946 9.89204C6.75107 9.91099 6.7257 9.92169 6.69919 9.92169C6.67267 9.92169 6.6473 9.91099 6.62891 9.89204L4.76749 7.97462C4.63654 7.83972 4.42579 7.83972 4.29484 7.97462C4.16188 8.11157 4.16188 8.33508 4.29484 8.47203L6.46286 10.7053C6.59381 10.8402 6.80456 10.8402 6.93552 10.7053L11.7052 5.79213C11.8381 5.65518 11.8381 5.43167 11.7052 5.29472Z"
                        fill="#FBFBFB"
                    />
                </>
            )}
        </svg>
    );
};
