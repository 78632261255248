import { useState, useEffect } from 'react';
export const useDebounce = (value: ISearchInput, delay: number): ISearchInput => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        //set debounce vale after certain passed delay
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value]);

    return debouncedValue;
};
