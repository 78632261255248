import { useState } from 'react';

interface useComponentLoaderCb {
    loading: boolean;
    loadingHandler: () => void;
}

export const useComponentLoader = (): useComponentLoaderCb => {
    // State and setters for debounced value
    const [loading, setLoading] = useState<boolean>(false);

    const loadingHandler = () => {
        setLoading((prev) => !prev);
    };

    return { loading, loadingHandler };
};
