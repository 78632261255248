export const userColorPicker = (user: string): string => {
    let temp = '#A85846';
    switch (user) {
        case 'branch':
            temp = '#5A204A';
            break;
        case 'both':
        case 'hq':
            temp = '#132B53';
            break;
        case 'agent':
            temp = ' #A82252';
            break;
        case 'user':
        case 'investor':
        case 'client':
            temp = '#A85846';
            break;
    }
    return temp;
};
