import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { InputValidationErrorText } from '../../../constants';
import { IcoMoon } from '../../../icons';
import { FlexedDiv } from '../FlexDiv';
import Container from '../Container';

export const CustomInput: React.FC<CustomInputProps> = ({
    disabled,
    errorText,
    inputStyle,
    label,
    name,
    onChange,
    onLostFocus,
    placeHolder,
    style,
    value,
    validation,
    validationMessage,
    canClearContents,
    handleClearContents,
    ...rest
}: CustomInputProps) => {
    const [onFocus, setOnFocus] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setOnFocus(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef]);

    const handleClear = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
        handleClearContents && handleClearContents();
    };
    return (
        <Fragment>
            <ContainerInput style={style} ref={containerRef}>
                <StyledInputLabel>{label}</StyledInputLabel>
                <StyledInput
                    disabled={disabled}
                    style={{ ...inputStyle }}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onLostFocus}
                    placeholder={placeHolder}
                    accept={validation}
                    itemProp={validationMessage}
                    errorText={errorText}
                    onFocus={() => setOnFocus(true)}
                    ref={inputRef}
                    id={rest.id}
                    data-testid={rest.id}
                    {...rest}
                />
                {canClearContents && value.trim().length !== 0 && onFocus ? (
                    <ClearInputSpan onClick={() => handleClear()} data-testid="clear-all">
                        <IcoMoon name={'error-input'} style={{ fill: '#C6CBD4' }} size="1.5rem" />
                    </ClearInputSpan>
                ) : null}
                {errorText ? (
                    <Container visibility width="90%">
                        <FlexedDiv style={{ position: 'absolute', marginTop: '0.5rem' }}>
                            <IcoMoon
                                name="validation-error"
                                color="#E84C3D"
                                style={{ height: '1rem', width: '1.25rem' }}
                            />

                            <InputValidationErrorText>{errorText}</InputValidationErrorText>
                        </FlexedDiv>
                    </Container>
                ) : null}
            </ContainerInput>
        </Fragment>
    );
};

const ContainerInput = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;
const ClearInputSpan = styled.span`
    position: absolute;
    align-self: center;
    right: 12px;
    bottom: 10px;
    z-index: 100;
    font-size: 1rem;
    cursor: pointer;
`;
const StyledInput = styled.input<CustomInputProps>`
    padding: 0.75rem 1rem;
    background: ${(props) => (props.errorText !== undefined && props.errorText !== '' ? '#FDF1EF' : '#ffffff')};
    border: ${(props) =>
        props.errorText !== undefined && props.errorText !== '' ? '2px solid #E84C3D' : '1px solid #cecece'}!important;
    box-sizing: border-box;
    border-radius: 32px;
    min-width: 360px;
    font-size: 1rem;
    line-height: 1.5rem;
    max-height: 3rem;
    &:disabled {
        background: #f4f4f4;
        opacity: 0.6;
        pointer-events: none;
        color: #171717;
    }
    &:hover {
        border: ${(props) =>
            props.errorText !== undefined && props.errorText !== '' ? '' : '1px solid #66798e'} !important;
    }
    &:focus {
        outline: ${(props) => (props.errorText !== undefined && props.errorText !== '' ? 'none' : '')};
        box-shadow: ${(props) =>
            props.errorText !== undefined && props.errorText !== ''
                ? ''
                : '0px 2px 8px rgba(0, 32, 67, 0.06), 0px 0px 4px rgba(0, 32, 67, 0.02)'};
        color: #002043;
    }
    ::placeholder {
        font-weight: 400;
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
        -webkit-text-fill-color: inherit;
        box-shadow: '';
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
    }
`;

const StyledInputLabel = styled.div`
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 15.6px;
    color: #333333;
    flex: none;
    align-self: stretch;
    margin-bottom: 4px;
`;
