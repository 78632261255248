import { SYSTEM_ADMIN } from './labels';

export interface ISectionCollapsible {
    title: string;
    actions: string[];
    reviewApproval?: string[];
    reviewApprovalTitle?: string;
    keyName: string;
}

export interface UserTypes {
    general?: ISectionCollapsible[];
    maker?: ISectionCollapsible[];
    checker?: ISectionCollapsible[];
    autoAuthorizer?: ISectionCollapsible[];
}

interface CommonCollapsible {
    header?: string;
    title: string;
    subtitle?: string;
    data: UserTypes;
}

interface IBranchCollapsible {
    accountManagement: CommonCollapsible;
    ceTransaction: CommonCollapsible;
    adviser: CommonCollapsible;
    reports: CommonCollapsible;
}

export const BRANCH_ROLE: IBranchCollapsible = {
    accountManagement: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_LOGIN_ACCESS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_LOGIN_ACCESS_SUB,
        data: {
            general: [
                {
                    keyName: 'login',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_LOGIN_LOGOUT,
                    actions: [],
                    // SYSTEM_ADMIN.ADD_ROLE.LABEL_PASSWORD_RECOVERY
                },
                {
                    keyName: 'inbox',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_INBOX,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_READ_NOTIFICATION],
                },
                {
                    keyName: 'profile',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PROFILE,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_STAFF_DETAILS],
                },
            ],
        },
    },
    ceTransaction: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_CUSTOMER_EXP_TRANSACTIONS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_CUSTOMER_EXP_SUB,
        data: {
            maker: [
                {
                    keyName: 'pendingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PENDING,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_TRANSACTION_REPORT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_SUMMARY_REPORT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VERIFY_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS,
                    ],
                },
                {
                    keyName: 'upcomingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_UPCOMING,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'hardCopyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_WITH_HARD_COPY,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_RECEIVE_HARD_COPY,
                    ],
                },
                {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DUE_DATE_EXTENSION_REQUEST,
                    ],
                },
                {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_TRANSACTION_REPORT_PDF,
                    ],
                },
            ],
            checker: [
                {
                    keyName: 'pendingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PENDING,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_TRANSACTION_REPORT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_SUMMARY_REPORT,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VERIFY_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS_REQUEST,
                    ],
                },
                {
                    keyName: 'upcomingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_UPCOMING,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'hardCopyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_WITH_HARD_COPY,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_RECEIVE_HARD_COPY,
                    ],
                },
                {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_TRANSACTION_REPORT_PDF,
                    ],
                },
            ],
            autoAuthorizer: [
                {
                    keyName: 'pendingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PENDING,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_TRANSACTION_REPORT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_SUMMARY_REPORT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VERIFY_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS,
                    ],
                    reviewApproval: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VERIFY_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTE_TRANSACTIONS_REQUEST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_REJECT_TRANSACTIONS_REQUEST,
                    ],
                },
                {
                    keyName: 'upcomingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_UPCOMING,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'hardCopyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_WITH_HARD_COPY,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_RECEIVE_HARD_COPY,
                    ],
                },
                {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DUE_DATE_EXTENSION_REQUEST,
                    ],
                },
                {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_TRANSACTION_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_TRANSACTION_REPORT_PDF,
                    ],
                },
            ],
        },
    },
    adviser: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ADVISERS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_ADVISERS_SUB,
        data: {
            general: [
                {
                    keyName: 'advisersTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ADVISERS_TAB,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_ADVISER_LIST,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_VIEW_ADVISER_PROFILE,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_EXPORT_LIST,
                    ],
                },
                {
                    keyName: 'investorsTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_INVESTORS_TAB,
                    actions: [SYSTEM_ADMIN.ADD_ROLE.LABEL_INVESTOR_LIST],
                },
            ],
        },
    },
    reports: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REPORTS,
        header: SYSTEM_ADMIN.ADD_ROLE.LABEL_RESOURCES,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_REPORTS_SUB,
        data: {
            general: [
                {
                    keyName: 'operationalReport',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OPERATIONAL_REPORT,
                    actions: [
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_MSF_CTA,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_REGULAR_INVESTMENT,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_SWITCHING_SUBMISSION,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_REDEMPTION_SUBMISSION,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_BRANCH_CONTROL_SALES_SUBMISSION,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_SALES_SUBMISSION_STATISTIC_SUMMARY_AND_MONEY_MARKET,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_SALES_SUBMISSION_STATISTIC_DETAILS,
                        SYSTEM_ADMIN.ADD_ROLE.LABEL_DOWNLOAD_EPF_APPLICATION_STATUS,
                    ],
                },
            ],
        },
    },
};

export const BRANCH_ROLE_KEYS = {
    accountManagement: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_LOGIN_ACCESS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_LOGIN_ACCESS_SUB,
        data: {
            general: {
                login: {
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_LOGIN_LOGOUT,
                    actions: ['canDoPasswordRecovery'],
                },
                inbox: {
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_INBOX,
                    actions: ['canReadNotifications'],
                },
                profile: {
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PROFILE,
                    actions: ['canViewStaffDetails'],
                },
            },
        },
    },
    ceTransaction: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_CUSTOMER_EXP,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_CUSTOMER_EXP_SUB,
        data: {
            maker: {
                pendingTab: {
                    keyName: 'pendingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PENDING,
                    actions: [
                        'canViewTransactionList',
                        'canViewDetails',
                        'canExportList',
                        'canDownloadTransactionReport',
                        'canDownloadDailySummary',
                        'canVerifyTransactions',
                        'canReroute',
                        'canReject',
                    ],
                },
                upcomingTab: {
                    keyName: 'upcomingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_UPCOMING,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList'],
                },
                hardCopyTab: {
                    keyName: 'hardCopyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_WITH_HARD_COPY,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canReceiveHardCopy'],
                },
                reRoutedTab: {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canDoDueDateExtension'],
                },
                historyTab: {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        'canViewTransactionList',
                        'canViewDetails',
                        'canExportList',
                        'canDownloadTransactionReport',
                    ],
                },
            },
            checker: {
                pendingTab: {
                    keyName: 'pendingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PENDING,
                    actions: [
                        'canViewTransactionList',
                        'canViewDetails',
                        'canExportList',
                        'canDownloadTransactionReport',
                        'canDownloadDailySummary',
                    ],
                    reviewApproval: ['canApproveVerify', 'canApproveReRoute', 'canApproveReject'],
                },
                upcomingTab: {
                    keyName: 'upcomingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_UPCOMING,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList'],
                },
                hardCopyTab: {
                    keyName: 'hardCopyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_WITH_HARD_COPY,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canReceiveHardCopy'],
                },
                reRoutedTab: {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList'],
                },
                historyTab: {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        'canViewTransactionList',
                        'canViewDetails',
                        'canExportList',
                        'canDownloadTransactionReport',
                    ],
                },
            },
            autoAuthorizer: {
                pendingTab: {
                    keyName: 'pendingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_PENDING,
                    actions: [
                        'canViewTransactionList',
                        'canViewDetails',
                        'canExportList',
                        'canDownloadTransactionReport',
                        'canDownloadDailySummary',
                        'canVerifyTransactions',
                        'canReroute',
                        'canReject',
                    ],
                    reviewApproval: ['canApproveVerify', 'canApproveReRoute', 'canApproveReject'],
                },
                upcomingTab: {
                    keyName: 'upcomingTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_UPCOMING,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList'],
                },
                hardCopyTab: {
                    keyName: 'hardCopyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_WITH_HARD_COPY,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canReceiveHardCopy'],
                },
                reRoutedTab: {
                    keyName: 'reRoutedTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REROUTED_TAB,
                    actions: ['canViewTransactionList', 'canViewDetails', 'canExportList', 'canDoDueDateExtension'],
                },
                historyTab: {
                    keyName: 'historyTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_HISTORY_TAB,
                    actions: [
                        'canViewTransactionList',
                        'canViewDetails',
                        'canExportList',
                        'canDownloadTransactionReport',
                    ],
                },
            },
        },
    },
    adviser: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ADVISERS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_ADVISERS_SUB,
        data: {
            general: {
                advisersTab: {
                    keyName: 'advisersTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_ADVISERS_TAB,
                    actions: ['canViewAdviserList', 'canViewAdvisersProfile', 'canExportList'],
                },
                investorsTab: {
                    keyName: 'investorsTab',
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_INVESTORS_TAB,
                    actions: ['canViewInvestorsList'],
                },
            },
        },
    },
    reports: {
        title: SYSTEM_ADMIN.ADD_ROLE.LABEL_REPORTS,
        subtitle: SYSTEM_ADMIN.ADD_ROLE.LABEL_REPORTS_SUB,
        data: {
            general: {
                operationalReport: {
                    title: SYSTEM_ADMIN.ADD_ROLE.LABEL_OPERATIONAL_REPORT,
                    actions: [
                        'canDownloadMoneySightedCTAReport',
                        'canDownloadRegularInvestmentReport',
                        'canDownloadSwitchingSubmissionReport',
                        'canDownloadRedemptionSubmissionReport',
                        'canDownloadBranchControlSalesSubmissionSummaryReport',
                        'canDownloadSalesSubmissionStatisticSummaryMoneyMarketReport',
                        'canDownloadSalesSubmissionStatisticDetailsReport',
                        'canDownloadEpfApplicationStatusReport',
                    ],
                },
            },
        },
    },
};
