import gql from 'graphql-tag';

export const getRequestDetailsCEOthers = gql`
    query transactionRequest($input: TransactionRequestInput) {
        transactionRequest(input: $input) {
            data {
                result {
                    requestId
                    approval {
                        name
                        eventType
                        status
                        remarks
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
