import gql from 'graphql-tag';

export const staffProfileQuery = gql`
    query userProfile {
        userProfile {
            data {
                result {
                    name
                    status
                    homeBranch
                    department
                    email
                    mobileNo
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
