import gql from 'graphql-tag';

/**
 * @userUpdateStatus @mutation System admin Dashboard
 * @description mutation for modifying status of user
 */

export const getIdInformation = gql`
    query getIdInfo($input: IdInfoInput) {
        getIdInformation(input: $input) {
            data {
                result {
                    info
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;

export default getIdInformation;
