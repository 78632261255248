import React, { Fragment, useMemo, useState } from 'react';
import styled, { CSSProperties, css } from 'styled-components';
import { LABEL } from '../../../constants';
import { IcoMoon } from '../../../icons';
import { CustomDropdown, IDropdownItem, PaginationDropdown } from '../../molecules';
import { FlexedDiv } from '../../atoms';
import CounterTags from '../../atoms/CounterTags';
import StyledTab from '../../atoms/StyledTab';
import FilterPill from '../../molecules/FilterPills';

/**
 * IDashboardProps interface for Dashboard component
 * @interface
 * @property {Array<IDashboardTabs>}  tabs - array of IDashboardTabs.
 * @property {selectedTab}  selectedTab    - index of selected tab.
 * @property {function(index):undefined}  switchTabs    - fn of type (index: number) => void; to update current tab state.
 * @property {Array<number>}  orderCounter    - count of each tab's results.
 * @property {number}  selectedPill    - optional to show the selected pill
 * @property {IDashboardFilterTagsGroup} pills - optional if the dashboard needs to show filter pills.
 * @property {function(item:IDashboardFilterTags):void} onClickPills - optional fn of type (item: IDashboardFilterTags) => void; to trigger filter pills.
 * @property {number} page - optional to pass the page number.
 * @property {number} maxPage - optional to pass the max page number.
 * @property {function():void} handleNext - fn of type () => void; to handle next page button.
 * @property {function():void} handlePrevious - fn of type () => void; to handle previous page button.
 * @property {CSSProperties} nextStyle - optional to style next arrow button.
 * @property {CSSProperties} previousStyle - optional to style previous arrow button.
 * @property {function(limit:number):void} setResultLimit - fn of type (limit: number) => void; to set the result limit.
 * @property {function(page:number):void} setCurrentPage - fn of type (page: number) => void; to set the current page
 * @property {boolean} disablePagination - optional to hide pagination controls.
 */
interface IDashboardProps {
    tabs: Array<IDashboardTabs>;
    selectedTab: number;
    switchTabs: (index: number) => void;
    orderCounter: Array<number>;
    selectedPill?: number;
    pills?: IDashboardFilterTagsGroup;
    onClickPills?: (item: IDashboardFilterTags) => void;
    page?: number;
    maxPage?: number;
    handleNext: () => void;
    nextStyle?: CSSProperties;
    previousStyle?: CSSProperties;
    handlePrevious: () => void;
    setResultLimit: (limit: number) => void;
    setCurrentPage: (page: number) => void;
    resultLimit?: number;
    testId?: string;
    disableResultLimit: boolean;
    disablePagination?: boolean;
    adjustTabMargin?: boolean;
    pillCount?: Array<number>;
}

const limits = [10, 20, 50];
/**
 * Dashboard component to encompasses of other sub components and takes in props of interface {@link IDashboardProps}
 * @category Organisms
 * @component
 */

export const Dashboard: React.FC<IDashboardProps> = ({
    tabs,
    selectedTab,
    switchTabs,
    orderCounter,
    pills,
    onClickPills,
    handleNext,
    nextStyle,
    previousStyle,
    page,
    maxPage,
    handlePrevious,
    setResultLimit,
    setCurrentPage,
    testId,
    resultLimit,
    disableResultLimit,
    disablePagination,
    adjustTabMargin,
    pillCount,
}: IDashboardProps) => {
    const [previousLimit, setPreviousLimit] = useState(1);
    const [nextLimit, setNextLimit] = useState(10);
    const [pageNav, setPageNav] = useState<boolean[]>([false, false, false, false, false]);
    /**
     * Function to handle page limit selection
     *@method handlePageLimit
     *@param value type of ReactText
     */
    const handlePageLimit = (value: React.ReactText) => {
        // const valueInt = parseInt(value.toString(), 10);
        const intValue = parseInt(value.toString(), 10);
        value && setResultLimit(intValue);
        handlePageNumber(1);
        setPreviousLimit(1);
        setNextLimit(10);
    };
    /**
     * Function to handle page number selection
     *@method handlePageNumber
     *@param value type of ReactText
     */
    const handlePageNumber = (value: React.ReactText) => {
        value && setCurrentPage(parseInt(value.toString()));
    };
    /**
     * Function to generate page limits
     *@method generatePageLimits
     *@returns IDropdownItem[]
     */
    const generatePageLimits = (testId: string): IDropdownItem[] => {
        const _items: IDropdownItem[] = [];
        limits.map((result) =>
            _items.push({
                item: result.toString(),
                handleItem: handlePageLimit,
                testId: `${testId}-${result}-option`,
            }),
        );

        return _items;
    };

    /**
     * Function to generate page numbers when the maxPages is over 30 and fixed pagination flag is true
     *@method generatePageNumbers
     *@returns IPaginationDropdownItem[]
     */
    const generateFixedPageNumbers = (maxPages: number): IPaginationDropdownItem[] => {
        const _items: IPaginationDropdownItem[] = [];
        if (maxPages !== 0 && page !== undefined && maxPages > 30) {
            for (let i = previousLimit; i <= nextLimit; i++) {
                _items.push({
                    item: i.toString(),
                    handleItem: handlePageNumber,
                    testId: `${testId}-pagination-menu-${i}-option`,
                });
            }
        } else {
            for (let i = 1; i <= maxPages; i++) {
                _items.push({
                    item: i.toString(),
                    handleItem: handlePageNumber,
                    testId: `${testId}-pagination-menu-${i}-option`,
                });
            }
        }

        return _items;
    };
    /**
     * Function to handle the page navigation when the maxPages is over 30 and fixed pagination flag is true, here the state of the pageNav boolean array will be updated the element at position parameter will be changed to false
     *@method handlePageNav
     *@returns void
     */
    const handlePageNav = (position: number) => {
        const temp = [...pageNav];
        temp[position] = true;
        setPageNav(temp);
    };

    useMemo(() => {
        if (maxPage !== undefined && page !== undefined) {
            const index = pageNav.findIndex((item) => item === true);
            const temp = [false, false, false, false, false];
            const findPrevLimit = maxPage % 10;
            switch (index) {
                case 0:
                    setPreviousLimit(1);
                    setNextLimit(10);
                    setPageNav(temp);
                    break;
                case 1:
                    setPreviousLimit(findPrevLimit === 0 ? maxPage - 9 : maxPage + 1 - findPrevLimit);
                    setNextLimit(maxPage);
                    setPageNav(temp);
                    break;
                case 2:
                    setPreviousLimit((previousValue) => previousValue - 10);
                    setNextLimit((previousValue) =>
                        previousValue === maxPage ? previousValue - findPrevLimit : previousValue - 10,
                    );
                    setPageNav(temp);
                    break;
                case 3:
                    setPreviousLimit((previousValue) => previousValue + 10);
                    setNextLimit((previousValue) => (previousValue + 10 > maxPage ? maxPage : previousValue + 10));
                    setPageNav(temp);
                    break;
                case 4:
                    if (page > nextLimit) {
                        setPreviousLimit(page);
                        setNextLimit((previousValue) => previousValue + 10);
                        setPageNav(temp);
                    } else if (page < previousLimit) {
                        setPreviousLimit((previousValue) => previousValue - 10);
                        setNextLimit(page);
                        setPageNav(temp);
                    }
                    break;
            }
        }
    }, [pageNav, page]);

    return (
        <Fragment>
            <DashboardContainer data-testid="container_dashboard">
                <DashboardTop isOnProp={adjustTabMargin}>
                    <TabContainer id="tab-container">
                        {tabs &&
                            tabs.map(({ name, testId }, index: number) => (
                                <StyledTab
                                    active={selectedTab === index}
                                    onClick={() => switchTabs(index)}
                                    key={name}
                                    id={testId}
                                    data-testid={testId}
                                >
                                    {name}
                                    <CounterTags active={selectedTab === index}>{orderCounter[index]}</CounterTags>
                                </StyledTab>
                            ))}
                    </TabContainer>
                    {disablePagination === undefined && !disablePagination && (
                        <PaginationContainer id="pagination-container" data-testid="pagination-container">
                            <CustomDropdown
                                items={generatePageLimits(`${testId}`)}
                                noMinWidth={true}
                                disableDropdown={maxPage === 1}
                            >
                                <DropDownInner>
                                    <DropDownSpan>{LABEL.item}</DropDownSpan>
                                    <DropDownSpan>{resultLimit}</DropDownSpan>

                                    <IcoMoon name="caret-down" size="1.5rem" color={'#002043'} />
                                </DropDownInner>
                            </CustomDropdown>

                            <PaginationDropdown
                                items={generateFixedPageNumbers(maxPage ?? 0)}
                                disableDropdown={maxPage === 1}
                                selectedPage={page ?? 0}
                                lastPage={maxPage}
                                limit={30}
                                topLimit={previousLimit}
                                bottomLimit={nextLimit}
                                onClickJumpToFirst={() => {
                                    handlePageNumber(1);
                                    handlePageNav(0);
                                }}
                                onClickJumpToLast={() => {
                                    handlePageNumber(maxPage ?? 0);
                                    handlePageNav(1);
                                }}
                                onClickPageNav={handlePageNav}
                            />

                            <MaxPageSpan>of {maxPage}</MaxPageSpan>

                            <FlexedDiv alignItems="center" style={{ opacity: page === 1 && maxPage === 1 ? 0.5 : 1 }}>
                                <CirclePrevBtn
                                    className="card_buttons_back"
                                    disabled={page === 1}
                                    style={{
                                        cursor: page === 1 ? 'default' : 'pointer',
                                        opacity: page === 1 ? 0.5 : 1,
                                        ...previousStyle,
                                    }}
                                    onClick={() => {
                                        handlePrevious();
                                        handlePageNav(4);
                                    }}
                                    id={`${testId}-prev-btn`}
                                    data-testid={`${testId}-prev-btn`}
                                >
                                    <IcoMoon name="caret-left" size="1.5rem" />
                                </CirclePrevBtn>

                                <CircleNextBtn
                                    className="card_buttons_next"
                                    disabled={maxPage === page}
                                    style={{
                                        cursor: maxPage === page ? 'default' : 'pointer',
                                        opacity: maxPage === page ? 0.5 : 1,
                                        ...nextStyle,
                                    }}
                                    onClick={() => {
                                        handleNext();
                                        handlePageNav(4);
                                    }}
                                    id={`${testId}-next-btn`}
                                    data-testid={`${testId}-next-btn`}
                                >
                                    <IcoMoon
                                        name="caret-right"
                                        size="1.5rem"
                                        color={maxPage === page ? '#97A3B1' : '#002043'}
                                    />
                                </CircleNextBtn>
                            </FlexedDiv>
                        </PaginationContainer>
                    )}
                </DashboardTop>
                <DashboardBottom id="dashboard-bottom">
                    {pillCount !== undefined && pillCount !== null && pillCount.length !== 0 ? (
                        <QuickFilersContainer id="dashboard-pills">
                            <FlexedDiv alignItems="baseline">
                                {pills && pills.tab === selectedTab && onClickPills ? (
                                    <Fragment>
                                        <PillLabel>{pills.label}</PillLabel>
                                        {pills.tags.map((item, index) => (
                                            <FlexedDiv key={index + 1}>
                                                <FilterPill
                                                    active={item.active}
                                                    handleFilterPill={onClickPills}
                                                    item={item}
                                                    label={`${item.title} (${pillCount[index]})`}
                                                    testId={`${testId}-${item.testId}-pill-${item.index}`}
                                                    data-testid={`${testId}-${item.testId}-pill-${item.index}`}
                                                />
                                                {/* {selectedPill === index ? null : <PillBadge />} */}
                                            </FlexedDiv>
                                        ))}
                                    </Fragment>
                                ) : null}
                            </FlexedDiv>
                        </QuickFilersContainer>
                    ) : null}

                    {tabs &&
                        tabs.map(({ table }, index: number) =>
                            selectedTab === index ? <div key={index}>{table}</div> : null,
                        )}
                </DashboardBottom>
            </DashboardContainer>
        </Fragment>
    );
};
const DashboardContainer = styled.div`
    background-color: #fbfbfb;
    border-radius: 22px;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 24%);
    margin-bottom: 8.5rem;
    min-height: 50vh;
`;
const DashboardTop = styled.div<IOnPropStyles>`
    padding-top: ${(props) => (props.isOnProp ? '12.5rem' : '10rem')};
    border-bottom: 1px solid #eaebee;
    display: flex;
    justify-content: space-between;
`;
const DashboardBottom = styled.div``;

const QuickFilersContainer = styled.div`
    display: flex;
    padding: 12px 1.5rem;
    border-bottom: 1px solid #ececec;
`;
const PillLabel = styled.div`
    color: #4d4d4d;
    font-size: 0.75rem;
    margin-right: 0.5rem;
    text-transform: capitalize;
`;
const TabContainer = styled.div`
    display: flex;
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
`;

const DropDownInner = styled.div`
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
`;
const DropDownSpan = styled.span`
    margin-right: 5px;
    :nth-child(2) {
        font-weight: bold;
    }
`;
const MaxPageSpan = styled.span`
    /* margin-left: 5px; */
    color: #878787;
`;

const CircleBtn = styled.button<IOnPropStyles>`
    margin: 0px 0.5rem 0 1rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    border: 1px solid #002043;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ${(props: IOnPropStyles) =>
        props.disabled &&
        css`
            opacity : 0.4
            pointer-events: none;
        `}
`;

const CircleNextBtn = styled(CircleBtn)`
    margin: 0px 1.736vw 0px 4px;
`;
const CirclePrevBtn = styled(CircleBtn)`
    margin: 0px 4px 0px 1rem;
`;

/**
 * This event could be thrown by component in case
 * of some kind of unexpected behaviour.
 *
 * @category Organisms
 *
 */
export default Dashboard;
