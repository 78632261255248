/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { lazy, Fragment, useContext, useEffect } from 'react';
import { Route, RouteProps, Switch, useHistory } from 'react-router-dom';
import * as ROUTES from '../../routes';

import {
    activityLogsRoutes,
    branchHQCERoutes,
    eddRoutes,
    fundManagementRoutes,
    systemAdminRoutes,
    systemSettingRoutes,
} from './routesHQConfig';

import { ActivityLogsProvider, SystemAdminProvider, AdviserAccessProvider } from '../../context/SystemAdminContext';
import { ProductSettingsProvider } from '../../context/ProductSettingsContext';
import { UploadsProvider } from '../../context/UploadsContext';
import { onRefreshFn } from '../../utils/authServices';
import { FlexedDiv, Modal } from '../../components';
import { LABEL } from '../../constants';

import WebSocketContext from '../../context/WebSocketContext';

// Shared imports
import AuthContext from '../../context/AuthContext';
import BranchOfficeProvider from '../../context/HQCEContext/branchOffice/BranchOfficeProvider';
import DashboardBranchProvider from '../../context/BranchContext/DashboardBranchProvider';
import HQCEProvider from '../../context/HQCEContext/HQCEProvider';
import AdvisersProvider from '../../context/AdviserContext/AdvisersProvider';
import { InboxProvider } from '../../context/InboxContext';
import EDDProvider from '../../context/EDDContext/EDDProvider';
import TrackOrderProvider from '../../context/TrackOrderContext/TrackOrderProvider';
import PendingSubmissionProvider from '../../context/PendingSubmissionContext/PendingSubmissionProvider';
import InvestorsProvider from '../../context/InvestorContext/InvestorsProvider';
import { IdleTimer } from '../../components/molecules/IdleTimer/IdleTimer';

const HQWrapper = lazy<any>(() => import('../../pages/Wrapper/HQWrapper'));
const Profile = lazy<any>(() => import('../../pages/Profile'));
const Inbox = lazy<any>(() => import('../../pages/Inbox/Inbox'));
const Error404 = lazy<any>(() => import('../../pages/ExceptionHandling/Error404'));
export interface IHqRouteProps extends RouteProps {
    component?: any;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const HqRoute = ({ component: Component, ...rest }: IHqRouteProps): JSX.Element => {
    const { userLoginContext, windowReloadFn, userLogoutFn } = useContext(AuthContext);
    const { isLoggedOut, setIsLoggedOut, logoutSuccess } = useContext(WebSocketContext);
    const history = useHistory();

    const checkPermission = (permission: any) => {
        const parsedPermission = JSON.parse(permission);
        if (parsedPermission.hq.grant === 'hq') {
            return true;
        }
        return false;
    };

    const checkCeDashboard = (hq: IHqPermissions) => {
        if (hq.permission.ceDashboard?.isAll === 'true') {
            return true;
        }
        return false;
    };

    const checkFund = (hq: IHqPermissions) => {
        if (hq.permission.productSettings?.isAll || hq.permission.uploads?.isAll) {
            return true;
        }
        return false;
    };

    const checkSystemAdmin = (hq: IHqPermissions) => {
        if (hq.permission.userManagement?.isAll === 'true') {
            return true;
        }
        return false;
    };
    const checkActivityLogs = (hq: IHqPermissions) => {
        if (hq.permission.activityLogs?.isAll === 'true') {
            return true;
        }
        return false;
    };
    const checkSystemSettings = (hq: IHqPermissions) => {
        if (hq.permission.systemSettings?.isAll === 'true') {
            return true;
        }
        return false;
    };

    //EDD routes temporarily disabled for UAT
    const checkEDD = (hq: IHqPermissions) => {
        if (hq.permission.eddCase?.isAll === 'true') {
            return false;
        }
        return false;
    };

    //Fn to render system admin routes based on different privileges
    const renderSystemAdminRoutes = (permission: string): JSX.Element[] => {
        const parsedPermission: IPermissionObject = JSON.parse(permission);
        const _routes: JSX.Element[] = [];

        if (checkSystemAdmin(parsedPermission.hq)) {
            systemAdminRoutes.forEach((route) =>
                _routes.push(
                    <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />,
                ),
            );
        }
        if (checkActivityLogs(parsedPermission.hq)) {
            activityLogsRoutes.forEach((route) =>
                _routes.push(
                    <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />,
                ),
            );
        }
        if (checkSystemSettings(parsedPermission.hq)) {
            systemSettingRoutes.forEach((route) =>
                _routes.push(
                    <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />,
                ),
            );
        }

        return _routes;
    };
    //Fn to render fund management routes based on privileges
    const renderFundManagementRoutes = (permission: string): JSX.Element[] => {
        const parsedPermission: IPermissionObject = JSON.parse(permission);
        const _routes: JSX.Element[] = [];
        if (checkFund(parsedPermission.hq)) {
            fundManagementRoutes.forEach((route) =>
                _routes.push(
                    <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />,
                ),
            );
        }
        return _routes;
    };

    //Fn to render HQCE branch routes based on privileges
    const renderBranchHQCERoutes = (permission: string): JSX.Element[] => {
        const parsedPermission: IPermissionObject = JSON.parse(permission);
        const _routes: JSX.Element[] = [];
        if (checkCeDashboard(parsedPermission.hq)) {
            branchHQCERoutes.forEach((route) =>
                _routes.push(
                    <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />,
                ),
            );
        }
        return _routes;
    };

    //Fn to render EDD routes based on privileges

    const renderEDDRoutes = (permission: string): JSX.Element[] => {
        const parsedPermission: IPermissionObject = JSON.parse(permission);
        const _routes: JSX.Element[] = [];
        if (checkEDD(parsedPermission.hq)) {
            eddRoutes.forEach((route) =>
                _routes.push(
                    <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />,
                ),
            );
        }
        return _routes;
    };
    useEffect(() => {
        windowReloadFn();
    }, [onRefreshFn]);

    return (
        <Fragment>
            {userLoginContext.isAuthenticated && checkPermission(userLoginContext.permission) ? (
                <HQWrapper>
                    {isLoggedOut === false && logoutSuccess === false && <IdleTimer timeout={900000} />}
                    {renderSystemAdminRoutes(userLoginContext.permission).length !== 0 ? (
                        <InboxProvider>
                            <SystemAdminProvider>
                                <AdviserAccessProvider>
                                    <ActivityLogsProvider>
                                        <Switch>
                                            {renderSystemAdminRoutes(userLoginContext.permission)}
                                            <Route exact path={ROUTES.hqInbox} component={Inbox} />
                                            <Route exact path={ROUTES.hqProfile} component={Profile} />

                                            <Route component={Error404} />
                                        </Switch>
                                    </ActivityLogsProvider>
                                </AdviserAccessProvider>
                            </SystemAdminProvider>
                        </InboxProvider>
                    ) : null}

                    {renderBranchHQCERoutes(userLoginContext.permission).length !== 0 ? (
                        <InboxProvider>
                            <HQCEProvider>
                                <DashboardBranchProvider>
                                    <TrackOrderProvider>
                                        <PendingSubmissionProvider>
                                            <BranchOfficeProvider>
                                                <AdvisersProvider>
                                                    <InvestorsProvider>
                                                        <Switch>
                                                            {renderBranchHQCERoutes(userLoginContext.permission)}
                                                            <Route exact path={ROUTES.hqInbox} component={Inbox} />
                                                            <Route exact path={ROUTES.hqProfile} component={Profile} />
                                                            <Route component={Error404} />
                                                        </Switch>
                                                    </InvestorsProvider>
                                                </AdvisersProvider>
                                            </BranchOfficeProvider>
                                        </PendingSubmissionProvider>
                                    </TrackOrderProvider>
                                </DashboardBranchProvider>
                            </HQCEProvider>
                        </InboxProvider>
                    ) : null}

                    {renderFundManagementRoutes(userLoginContext.permission).length !== 0 ? (
                        <InboxProvider>
                            <ProductSettingsProvider>
                                <UploadsProvider>
                                    <Switch>
                                        {renderFundManagementRoutes(userLoginContext.permission)}
                                        <Route exact path={ROUTES.hqInbox} component={Inbox} />
                                        <Route exact path={ROUTES.hqProfile} component={Profile} />

                                        <Route component={Error404} />
                                    </Switch>
                                </UploadsProvider>
                            </ProductSettingsProvider>
                        </InboxProvider>
                    ) : null}
                    {renderEDDRoutes(userLoginContext.permission).length !== 0 ? (
                        <InboxProvider>
                            <EDDProvider>
                                <Switch>
                                    {renderEDDRoutes(userLoginContext.permission)}

                                    <Route exact path={ROUTES.hqInbox} component={Inbox} />
                                    <Route exact path={ROUTES.hqProfile} component={Profile} />
                                    <Route component={Error404} />
                                </Switch>
                            </EDDProvider>
                        </InboxProvider>
                    ) : null}
                </HQWrapper>
            ) : null}
            {isLoggedOut ? (
                <Modal
                    title={'Duplicate log in'}
                    modalActive={isLoggedOut}
                    setModalActive={setIsLoggedOut}
                    primaryBtn={{
                        onClick: () => {
                            userLogoutFn();
                            history.push(ROUTES.signIn);
                            window.location.reload();
                            setIsLoggedOut(!isLoggedOut);
                        },
                        label: LABEL.okay,
                        primary: true,
                        size: 'large',
                    }}
                    icon="logout-modal-error"
                    contentAlignment="center"
                    testId="custom_modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {'Your account has been logged in from another device.'}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};
export default HqRoute;
