import moment from 'moment';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FlexedDiv } from '../components';
import { subTitleStyle } from '../styles';

export const NAVDateAColumnItem = ({ data, itemKey, isSortedColumn }: ITableCustomItem): JSX.Element => {
    let unit = 0;
    let date = '';

    unit = data[itemKey[0].key] !== null && data[itemKey[0].key];
    date = data[itemKey[1].key] !== null ? moment(data[itemKey[1].key], 'x').format('DD/MM/YYYY') : '-';
    return (
        <Fragment>
            <FlexedDiv>
                <FlexedDiv direction="column">
                    <Day isBold={itemKey[0].key === isSortedColumn}>{unit}</Day>
                    <div style={{ ...subTitleStyle }}>{date}</div>
                </FlexedDiv>
            </FlexedDiv>
        </Fragment>
    );
};

const Day = styled.div`
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: -0.2px;
    color: #002043;
    font-weight: ${(props: IOnPropStyles) => (props.isBold ? 700 : 'normal')};
`;
