import React, { forwardRef, Ref } from 'react';
import styled, { css } from 'styled-components';
import { IcoMoon } from '../../../icons';
import { FlexedDiv } from '../FlexDiv';

function Tag(
    { children, onClose, index, maxWidth, disabled, ...rest }: ITagProps,
    ref: Ref<HTMLButtonElement>,
): JSX.Element {
    return (
        <StyledTag disabled={disabled} ref={ref} {...rest} data-testid="tag-container">
            <TagSpanContainer alignItems="center" data-testid="tag-span-container">
                <TagSpan data-testid="tag" maxWidth={maxWidth}>
                    {children}
                </TagSpan>
                <div
                    style={{ cursor: 'pointer', marginLeft: '4px' }}
                    onClick={() => onClose(index)}
                    data-testid="onClose"
                >
                    <IcoMoon name={'close'} size="0.75rem" />
                </div>
            </TagSpanContainer>
        </StyledTag>
    );
}
export default forwardRef(Tag);

const StyledTag = styled.button<IOnPropStyles>`
    border-radius: 24px;
    outline: none;
    background: #e7ebf1;
    border: none;
    margin: 0 3px 3px 0;
    &:hover {
        background: #ffffff;
    }
    ${(props: IOnPropStyles) =>
        props.disabled &&
        css`
            opacity: 0.6;
            pointer-events: none;
        `}
`;

const TagSpan = styled.div<IOnPropStyles>`
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: ${(props) => props.maxWidth};
    color: #002043;
`;
const TagSpanContainer = styled((props) => <FlexedDiv {...props} />)`
    height: 100%;
`;
