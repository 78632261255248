import React, { Fragment, forwardRef, Ref } from 'react';
import styled, { css } from 'styled-components';

import { IcoMoon } from '../../../icons';
import { CustomSpacer } from '../CustomSpacer';
import { FlexedDiv } from '../FlexDiv';

interface IStyledButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    primary?: boolean;
    primaryBackground?: string;
    secondary?: boolean;
    size?: 'normal' | 'large';
}

const StyledButton = styled.button<IStyledButtonProps>`
    border-radius: 24px;
    border: ${(p: IStyledButtonProps) =>
        p.primary ? 'transparent' : p.secondary ? '1px solid #002043' : '2px solid #C61230'};
    background: ${(p: IStyledButtonProps) => p.primaryBackground};
    color: ${(p: IStyledButtonProps) => (p.primary ? 'white' : '#002043')};
    padding: 0.5rem 1rem;
    min-width: 2.2vw;
    /* height: 3rem; */
    cursor: pointer;
    font-weight: 700;
    outline: none;
    font-size: 0.75rem;
    line-height: 15.6px;
    &:hover {
        background: rgba(112, 112, 112, 0.1);
    }
    :disabled {
        cursor: default;
        opacity: 0.6;
    }
    ${(props: IStyledButtonProps) =>
        props.primary &&
        css`
            background: ${props.primaryBackground};
            border: transparent !important;
            color: white !important;
            &:hover {
                background: ${props.primaryBackground} !important;
                opacity: 0.9;
            }
            :disabled {
                background: rgba(198, 18, 48, 0.5) !important;
                cursor: default;
                pointer-events: none;
                &:hover {
                    background: rgba(198, 18, 48, 0.5) !important;
                    opacity: 1;
                }
            }
        `}
    ${(props: IStyledButtonProps) =>
        props.size === 'large' &&
        css`
            font-size: 1rem;
            line-height: 23.68px;
            padding: 0.75rem 1.5rem;
            max-height: 3rem;
        `}
`;

export const CustomButton = forwardRef(
    (
        { prefixIcon, suffixIcon, primary, primaryBackground, children, secondary, ...rest }: ICustomButton,
        ref: Ref<HTMLButtonElement>,
    ): JSX.Element => {
        return (
            <Fragment>
                <StyledButton
                    primary={primary}
                    secondary={secondary}
                    primaryBackground={primary ? (primaryBackground ? primaryBackground : '#c61230') : 'transparent'}
                    ref={ref}
                    data-testid={rest.id}
                    {...rest}
                >
                    <FlexedDiv alignItems="center" justifyContent="center">
                        {prefixIcon !== undefined ? (
                            <Fragment>
                                {prefixIcon !== '' && (
                                    <IcoMoon name={prefixIcon} size="1rem" color={primary ? 'white' : ''} />
                                )}
                                <CustomSpacer horizontal space="0.5rem" />
                            </Fragment>
                        ) : null}
                        {children}
                        {suffixIcon !== undefined ? (
                            <Fragment>
                                <CustomSpacer horizontal space="0.5rem" />
                                <IcoMoon name={suffixIcon} size="1rem" color={primary ? 'white' : ''} />
                            </Fragment>
                        ) : null}
                    </FlexedDiv>
                </StyledButton>
            </Fragment>
        );
    },
);
CustomButton.displayName = 'CustomButton';
