import { initialUserLogin, IUserLoginContext } from '.';

export type AuthActionTypes =
    | { type: 'saveToken'; payload: string }
    | { type: 'deleteToken' }
    | { type: 'saveApiResponse'; payload: IUserLoginContext }
    | { type: 'saveIdToken'; payload: string | undefined }
    | { type: 'logOut' };

export const AuthReducer = (state: IUserLoginContext, action: AuthActionTypes): IUserLoginContext => {
    switch (action.type) {
        case 'saveToken':
            return { ...state, sessionToken: action.payload };
        case 'deleteToken':
            return { ...state, sessionToken: '' };
        case 'logOut':
            return { ...initialUserLogin };
        case 'saveApiResponse':
            return { ...state, ...action.payload };
        case 'saveIdToken':
            return { ...state, idToken: action.payload };
        default:
            return state;
    }
};
