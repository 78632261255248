/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useContext, useMemo, useCallback } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
//Context
import AuthContext from '../AuthContext';
import ErrorHandlingContext from '../ErrorHandling/ErrorHandlingContext';

//graphQL
import investorDashboardQuery from '../../_graphql/queries/investors/investorDashboard/investorDashboard';
import InvestorsContext from './InvestorsContext';
import { groupInvestorsByBranch, handleDashbordResults } from '../../pages/Investors/Utils/investorUtils';
import { useComponentLoader, useDebounce } from '../../customHooks';
import { buildSearchFiltersConfig, IMultiSelectElements } from '../../pages/Investors/dashboardFilterConfig';

interface IInvestorsProviderProps {
    children: React.ReactNode;
}

const InvestorsProvider: React.FC<IInvestorsProviderProps> = ({ children }: IInvestorsProviderProps) => {
    // Context
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //To check for jwt token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const parsedPermission: IPermissionObject = JSON.parse(userLoginContext.permission);
    //Data states
    const [investorsData, setInvestorsData] = useState<ITableData[]>([]);
    const [investorDataGrouped, setInvestorDataGrouped] = useState<TGroupingInfo[]>([]);
    const [tab, setTab] = useState(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [resultLimit, setResultLimit] = useState<number>(10);
    const [totalCount, setTotalCount] = useState(0);
    //Filters, sort and search states
    const [filterConfig, setFilterConfig] = useState<IMultiSelectElements[][]>([]);
    const [searchInput, setSearchInput] = useState({ value: '', column: 'all' });
    const [filters, setFilters] = useState<IColumnValue[]>([]);
    const [sortData, setSortData] = useState<IColumnValue[]>([{ column: 'clientName', value: 'ascending' }]);

    // custom hooks
    const debouncedSearchTerm = useDebounce(searchInput, 700);
    const { loading, loadingHandler } = useComponentLoader();
    //Utility states

    const initialRender = useRef<boolean>(true);

    const memoizedDashboardInput: MemoizedInvestorDashboardInput = useMemo(() => {
        return {
            tab,
            currentPage,
            resultLimit,
            filters,
            sortData,
            debouncedSearchTerm,
        };
    }, [tab, resultLimit, currentPage, filters, sortData, debouncedSearchTerm]);

    //Fn to get investor dashboard data
    const getDashboardCallBack = useCallback(() => {
        getInvestorDashboardData();
    }, [memoizedDashboardInput]);

    //Fn to call investor dashboard query
    const getInvestorDashboardData = async () => {
        loadingHandler();

        try {
            const response: any = await API.graphql(
                graphqlOperation(investorDashboardQuery, {
                    input: {
                        tab: 'daily',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: debouncedSearchTerm,
                        sort: sortData,
                        filter: filters,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = null || ((await response.data.investorDashboard) as StandardResponse);
            if (data) {
                if (data.result) {
                    const { pages, investors, page, investorsCount, filters }: IInvestorResponse =
                        data.result as IInvestorResponse;
                    // Check to group adivers
                    if (parsedPermission.hq.grant === 'hq') {
                        const _groupedData = groupInvestorsByBranch(investors);

                        setInvestorDataGrouped(_groupedData);
                    } else {
                        if (investors.length !== 0) {
                            setInvestorsData(handleDashbordResults(investors[0].investorList));
                        } else {
                            setInvestorsData([]);
                        }
                    }

                    if (initialRender.current) {
                        const formElements = buildSearchFiltersConfig(filters);

                        initialRender.current = false;
                        setFilterConfig(formElements);
                    }

                    setCurrentPage(page);
                    setTotalCount(investorsCount);
                    setMaxPages(pages);
                    loadingHandler();
                } else throw error;
            }
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: _error.errorCode,
                    message: _error.message,
                    title: 'Cannot Fetch Investors Data',
                    testId: 'investors-dashboard-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    const handleResultLimit = (limit: number) => {
        setResultLimit(limit);
    };
    const handleSetCurrentPage = (page: number) => {
        setCurrentPage(page);
    };

    const ProviderValue: IInvestorsContext = {
        maxPages,
        memoizedDashboardInput,
        investorsData,
        totalCount,
        searchInput,
        initialRender,
        filterConfig,
        investorDataGrouped,
        loading,
        setSearchInput,
        setTab,
        handleResultLimit,
        handleSetCurrentPage,
        getDashboardCallBack,
        setSortData,
        setFilters,
        setCurrentPage,
        filters,
        sortData,
        currentPage,
    };

    return <InvestorsContext.Provider value={ProviderValue}>{children}</InvestorsContext.Provider>;
};
export default InvestorsProvider;
