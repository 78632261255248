import React, { Fragment, ChangeEvent } from 'react';
import styled from 'styled-components';
import { IcoMoon } from '../../../icons';
interface ISearchInputProps {
    disabled?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
    placeHolder: string;
    handleSearch: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
    testId?: string;
    onClick?: React.MouseEventHandler<HTMLInputElement>;
}

const SearchInput = ({
    placeHolder,
    handleSearch,
    testId,
    disabled,
    onClick,
    inputRef,
}: ISearchInputProps): JSX.Element => {
    return (
        <Fragment>
            <Div>
                <Span>
                    <IcoMoon name="search-dashboard" size="1.5rem" />
                </Span>
                <StyledInput
                    ref={inputRef}
                    maxLength={255}
                    placeholder={placeHolder}
                    onChange={handleSearch}
                    id={`${testId}-search-input`}
                    data-testid={`${testId}-search-input`}
                    onClick={onClick}
                    disabled={disabled}
                />
            </Div>
        </Fragment>
    );
};

export default SearchInput;
const Div = styled.div`
    display: flex;
    position: relative;
    border-radius: 24px;
    height: 3rem;
    border: 1px solid #c9c9cd;
    width: 25vw;
    align-items: center;
    &:focus-within {
        border: 2px solid #002043;
    }
    /* &:hover {
        border: 2px solid #002043;
    } */
`;
const Span = styled.span`
    position: absolute;
    align-self: center;
    left: 1rem;
    z-index: 100;
    font-size: 1rem;
`;
const StyledInput = styled.input`
    margin-left: 2.75rem;
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
`;
