import React from 'react';
import { CSSProperties } from 'styled-components';
import { Fs10RegNeutralBlack, Fs16RegNeutralBlack } from '../../../constants';

interface LabeledTitleProps {
    label: string;
    title: string;
    style?: CSSProperties;
}

export const LabeledTitle = ({ label, title, style }: LabeledTitleProps): JSX.Element => (
    <div style={{ display: 'flex', flexDirection: 'column', ...style }}>
        <Fs10RegNeutralBlack>{label}</Fs10RegNeutralBlack>
        <Fs16RegNeutralBlack>{title}</Fs16RegNeutralBlack>
    </div>
);
