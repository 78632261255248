import React, { useState, useRef } from 'react';

import ReportContext from './ReportContext';
import moment from 'moment';

interface ReportProviderProps {
    children: React.ReactNode;
}

export const ReportProvider: React.FC<ReportProviderProps> = ({ children }: ReportProviderProps) => {
    //Context
    // To check for jwt token
    const initialMsfFilters: ISort_FilterInput = [
        {
            column: 'moneySighted',
            value: 'Yes',
        },
        {
            column: 'paymentMethod',
            value: 'CTA',
        },
        {
            column: 'fundCutOffTime',
            value: '9:30AM',
        },
        {
            column: 'completedAt',
            value: `${moment().startOf('day').valueOf()}~${moment().endOf('day').valueOf()}`,
        },
        {
            column: 'currency',
            value: '',
        },
    ];
    const initialBookingFilters: ISort_FilterInput = [
        { column: 'completedAt', value: `${moment().startOf('day').valueOf()}~${moment().valueOf()}` },
    ];
    //Data states
    const [reportData, setReportData] = useState<ITableData[]>([]);
    const [dropdownData, setDropdownData] = useState<IReportsDropdownOptions>({
        branchOptions: [],
        currencyOptions: [],
        fundCutOffTimeOptions: [],
        paymentMethodOptions: [],
        statusOptions: [],
        utmcOptions: [],
        adviserCodeOptions: [],
        bdmNameOptions: [],
        userGroupOptions: [],
    });

    const [inputValue, setInputValue] = useState<IReportInputValue>({
        detailsFrom: [],
        detailsContact: [],
    });
    const [dateRangeParams, setDateRangeParams] = useState<IDateRangeParams>({
        currentDate: '',
        dateRangeLimitInDay: '',
    });

    //Search states
    const [searchInput, setSearchInput] = useState<ISearchInput>({ value: '', column: 'all' });
    const [reportType, setReportType] = useState<string>('');
    const [filters, setFilters] = useState<IReportFilters>({
        msf: [...initialMsfFilters],
        regular: [],
        booking: [...initialBookingFilters],
        control: [],
        switching: [],
        epf: [],
        redemption: [],
        statisticSummary: [],
        statisticDetails: [],
        userAuditAccess: [],
        userAccountStatus: [],
    });

    const initialRender = useRef<boolean>(true);

    const handleDisabledDate = (current: moment.Moment): boolean => {
        let _disbale = false;
        _disbale = current.valueOf() > moment(parseInt(dateRangeParams.currentDate, 10)).valueOf();
        return _disbale;
    };

    const handleCancelDate = (reportType: TReportType) => {
        const tempFilter = [
            ...filters[reportType].filter((filterItem) => {
                return filterItem.column !== 'dateRange';
            }),
        ];
        setFilters({ ...filters, [reportType]: [...tempFilter] });
    };

    const ProviderValue: IReportContext = {
        reportData,
        searchInput,
        initialRender,
        setReportData,
        setSearchInput,
        reportType,
        setReportType,
        filters,
        setFilters,
        dropdownData,
        setDropdownData,
        inputValue,
        setInputValue,
        dateRangeParams,
        setDateRangeParams,
        handleDisabledDate,
        handleCancelDate,
    };
    return <ReportContext.Provider value={ProviderValue}>{children}</ReportContext.Provider>;
};
export default ReportProvider;
