import React from 'react';
import styled from 'styled-components';
import { LogoSpinner } from '../../atoms/LogoSpinner';

interface IComponentLoaderProps {
    position?: string;
    opacity?: number;
    backgroundColor?: string;
}

type BackDropProps = {
    position: string | null;
    opacity?: number;
    backgroundColor?: string | null;
};
export const ComponentLoader = ({ position, backgroundColor }: IComponentLoaderProps): JSX.Element => {
    return (
        <BackDrop
            position={position ? position : null}
            backgroundColor={backgroundColor ? backgroundColor : null}
            data-testid="component-loader"
        >
            <LogoSpinner />
        </BackDrop>
    );
};

//component styles
const BackDrop = styled.div<BackDropProps>`
    position: ${(props) => (props.position ? props.position : 'absolute')};
    z-index: 11;
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'rgb(255 255 255 / 91%)')};
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 22px;
    /* max-height: 100vh; */
`;
