/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { Fragment, FunctionComponent } from 'react';
import { IcoMoon } from '../../../icons';
import { FlexedDiv } from '../../atoms';
import { addUserModes } from '../../../constants/addUserModes';

import styled from 'styled-components';
import CustomCheckbox from '../../atoms/Checkbox';

export interface AdvanceTableRowProps {
    activeAccordion?: string[];
    activeDisabledRows?: IDisabledRows;
    columns: ITableColumn[];
    disabledRows?: IDisabledRows;
    groupByLabel?: string;
    index: string;
    item: ITableData;
    onRowSelect?: (record: ITableData) => void;
    RenderAccordion?: (record: ITableData, index: number) => JSX.Element;
    RenderGroupByLabel?: (props: ITableGroupBy) => JSX.Element;
    RenderItem?: (props: ITableCustomItem) => JSX.Element;
    RenderOptions?: (props: ITableOptions) => JSX.Element;
    rowId: string;
    rowSelection?: ITableData[];
    testId?: string;
}

type RowInnerProps = {
    opacityChange: boolean | undefined;
};

type RowCellInnerProps = {
    borderLeft: string;
};

export const AdvanceTableRow: FunctionComponent<AdvanceTableRowProps> = ({
    activeAccordion,
    activeDisabledRows,
    columns,
    disabledRows,
    groupByLabel,
    index,
    item,
    onRowSelect,
    RenderAccordion,
    RenderGroupByLabel,
    RenderItem,
    RenderOptions,
    rowId,
    rowSelection,
    testId,
}: AdvanceTableRowProps) => {
    let itemSelected = rowSelection !== undefined && rowSelection.indexOf(item) !== -1;
    let disabled = false;

    const handleSelectRow = () => {
        if (onRowSelect !== undefined) {
            onRowSelect(item);
        }
    };

    const rowData: IColumnItemAccordion = {
        index: index,
        rawData: item,
    };
    const handleDisableRow = ({
        key,
        value,
        activeDisabled,
        notValue,
        isMultipleColumns,
        multipleColumns,
    }: IDisabledRows) => {
        if (notValue === undefined || notValue === false) {
            if (value.includes(item[key].toLowerCase())) {
                itemSelected = activeDisabled ? true : false;
                disabled = true;
            }
        } else {
            const nonDisabledArray = [];
            value.includes(item[key].toLowerCase()) ? nonDisabledArray.push(true) : nonDisabledArray.push(false);
            if (isMultipleColumns) {
                multipleColumns &&
                    multipleColumns.map((ele) => {
                        const value = ele.value.map((ele) => (ele = ele.toLowerCase()));
                        value.includes(item[ele.key].toLowerCase())
                            ? nonDisabledArray.push(true)
                            : nonDisabledArray.push(false);
                    });
            }
            nonDisabledArray.every(Boolean) ? (disabled = false) : (disabled = true);
        }
    };
    const handleActiveDisableRows = ({ key, value }: IDisabledRows) => {
        if (value.includes(item[key])) {
            itemSelected = true;
            disabled = true;
        } else {
            itemSelected = false;
            disabled = true;
        }
    };
    disabledRows && handleDisableRow(disabledRows);
    activeDisabledRows && handleActiveDisableRows(activeDisabledRows);
    return (
        <Fragment>
            {groupByLabel && RenderGroupByLabel !== undefined ? (
                <Fragment>
                    <FlexedDiv style={{ margin: '.5rem 0rem', padding: '0rem 1rem' }}>
                        <RenderGroupByLabel groupBy={groupByLabel} data={item} />
                    </FlexedDiv>
                </Fragment>
            ) : null}
            <AdvanceTableRowElm id={`table-row-${testId}-${rowId}`}>
                {rowSelection === undefined ? null : (
                    <RowCheckbox>
                        <CustomCheckbox
                            handleCheckbox={handleSelectRow}
                            disabled={disabled}
                            checked={itemSelected}
                            id={`${testId}-checkbox-${rowId}`}
                        />
                    </RowCheckbox>
                )}
                <AdvanceTableRowInner opacityChange={disabled} id="row-inner" data-testid="advance-table-row-inner">
                    <Fragment>
                        {columns.map((column: ITableColumn, columnIndex: number) => {
                            const dataKey = column.key.map((key: ITableItemKey) => key);

                            const customStyle = column.itemTextStyle !== undefined ? column.itemTextStyle(rowData) : {};
                            const textStyle = {
                                ...column.textStyle,
                                ...customStyle,
                            };
                            const handleClickColumnItem = () => {
                                if (column.onClickItem !== undefined) {
                                    column.onClickItem(rowData, mode);
                                }
                            };
                            const itemBorderLeft =
                                columnIndex === 0 || column.noBorder !== undefined ? '' : '1px solid #F4F4F4';

                            const getItemIcon = column.getItem !== undefined ? column.getItem(rowData) : {};

                            const itemIcon: ITableIcon = {
                                ...column.itemIcon!,
                                ...getItemIcon,
                            };

                            const mode = itemIcon.name === 'eye-show' ? addUserModes.reviewUser : addUserModes.editUser;
                            const itemLabel: IColumnItem[] = dataKey.map((labelKey: ITableItemKey) => {
                                const itemPrefix: ITableItemPrefix[] | undefined =
                                    column.prefix !== undefined
                                        ? column.prefix.filter(
                                              (prefix: ITableItemPrefix) => prefix.targetKey === labelKey.key,
                                          )
                                        : undefined;
                                const prefixData =
                                    itemPrefix !== undefined && itemPrefix.length !== 0
                                        ? {
                                              prefix:
                                                  itemPrefix[0].value !== undefined
                                                      ? `${rowData.rawData[itemPrefix[0].value]}: ${
                                                            rowData.rawData[itemPrefix[0].key]
                                                        }`
                                                      : rowData.rawData[itemPrefix[0].key],
                                              prefixStyle: itemPrefix[0].textStyle,
                                          }
                                        : {};
                                const itemData: IColumnItem = {
                                    label: rowData.rawData[labelKey.key],
                                    textStyle: labelKey.textStyle,
                                    noDashOnNull: labelKey.noDashOnNull,
                                    ...prefixData,
                                };

                                const isBoolean = typeof itemData.label === 'boolean';

                                if (isBoolean) {
                                    itemData.label = 'Yes';
                                    if (!itemData.label) {
                                        itemData.label = 'No';
                                    }
                                }

                                return itemData;
                            });

                            return (
                                <RowCell
                                    key={`item${columnIndex}`}
                                    id={`${column.testId ? `${column.testId}-${rowId}` : ''}`}
                                    data-testid={'row-cell'}
                                >
                                    <RowCellInner
                                        style={{
                                            ...column.viewStyle,
                                            ...column.itemStyle,
                                        }}
                                        borderLeft={itemBorderLeft}
                                        id={
                                            `${column.testId}`
                                                ? `${column.testId}-${rowId}`
                                                : `${columnIndex}-row-cell-inner`
                                        }
                                    >
                                        {column.customItem === true ? (
                                            RenderItem !== undefined ? (
                                                <RenderItem data={item} itemKey={dataKey} />
                                            ) : null
                                        ) : (
                                            <div>
                                                {itemLabel.map((label: IColumnItem, labelIndex: number) => {
                                                    return (
                                                        <div key={labelIndex} style={{ display: 'flex' }}>
                                                            {label.prefix !== undefined && label.label !== undefined ? (
                                                                <Fragment>
                                                                    <div style={{ ...textStyle, ...label.prefixStyle }}>
                                                                        {label.prefix}&nbsp;
                                                                    </div>
                                                                </Fragment>
                                                            ) : null}
                                                            <div
                                                                style={{
                                                                    ...textStyle,
                                                                    ...label.textStyle,
                                                                    // textTransform: 'capitalize',
                                                                }}
                                                            >
                                                                {label.label
                                                                    ? label.label
                                                                    : label.noDashOnNull
                                                                    ? ''
                                                                    : '--'}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        {column.itemIcon !== undefined ||
                                        column.withAccordion === true ||
                                        column.getItem !== undefined ? (
                                            <div
                                                onClick={handleClickColumnItem}
                                                style={{ cursor: 'pointer' }}
                                                id={`${itemIcon.name}-icon-${rowId}`}
                                            >
                                                <IcoMoon {...itemIcon} size="24px" />
                                            </div>
                                        ) : null}
                                    </RowCellInner>
                                </RowCell>
                            );
                        })}

                        {RenderOptions !== undefined ? (
                            <Fragment>
                                <div
                                    style={{
                                        paddingLeft: '.55vw',
                                        display: 'flex',
                                        flexGrow: 1,
                                        alignItems: 'center',
                                        minWidth: '3.33vw',
                                    }}
                                    id={`${testId}-dropdown-options-${rowId}`}
                                >
                                    {disabled ? (
                                        <IcoMoon name="action-menu" size="1.5rem" style={{ opacity: 0.75 }} />
                                    ) : (
                                        <RenderOptions data={rowData} />
                                    )}
                                </div>
                            </Fragment>
                        ) : null}
                    </Fragment>
                    {RenderAccordion !== undefined && activeAccordion !== undefined ? (
                        <div>
                            {activeAccordion.includes(index) ? (
                                <Fragment>
                                    <div style={{}} />
                                    <div>
                                        <RenderAccordion {...item} index={index} />
                                    </div>
                                </Fragment>
                            ) : null}
                        </div>
                    ) : null}
                </AdvanceTableRowInner>
            </AdvanceTableRowElm>
        </Fragment>
    );
};

const AdvanceTableRowElm = styled.div`
    display: flex;
    margin: 8px 0px;
`;
const AdvanceTableRowInner = styled.div<RowInnerProps>`
    display: flex;
    background: #fff;
    border: 1px solid #eaebee;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    opacity: ${(props: RowInnerProps) => (props.opacityChange ? 0.75 : 1)};
`;
const RowCell = styled.div`
    display: flex;
`;
const RowCellInner = styled.div<RowCellInnerProps>`
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem;
    font-size: 0.875rem;
    color: #002043;
    line-height: 1.5rem;
    min-height: 64px;
    border-left: ${(props) => props.borderLeft};
`;

const RowCheckbox = styled.div`
    height: 5rem;
    display: flex;
    align-items: center;
    width: 3.333vw;
    padding-right: 1rem;
    justify-content: center;
`;
