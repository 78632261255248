/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useComponentLoader, useDebounce } from '../../customHooks';
import { API, graphqlOperation } from 'aws-amplify';
import AuthContext from '../AuthContext';
import AdvisersContext, { AdvisersContextProps } from './AdvisersContext';
import { adviserDashboardQuery } from '../../_graphql/queries/branchCE/adviserDashboard/adviserDashboard';
import ErrorHandlingContext from '../ErrorHandling/ErrorHandlingContext';
import { groubByAdvisersByBranch } from '../../pages/Advisers/adviserUtils';

export interface AdvisersProviderProps {
    children: React.ReactNode;
}

const initialAdviserFilters = [
    { column: 'createdOn', value: '' },
    { column: 'status', value: '' },
];
const AdvisersProvider: React.FC<AdvisersProviderProps> = ({ children }: AdvisersProviderProps) => {
    // Context
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    // const { branchesId } = useContext(BranchOfficeContext);
    const [tab, setTab] = useState(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [resultLimit, setResultLimit] = useState<number>(10);
    const [adviserCount, setAdviserCount] = useState<number>(0);
    const [adviserData, setAdviserData] = useState<ITableData[]>([]);
    const [adviserDataGrouped, setAdviserDataGrouped] = useState<TGroupingInfo[]>([]);
    const [searchInput, setSearchInput] = useState({ value: '', column: 'all' });
    const [sortData, setSortData] = useState<ISort_FilterInput>([{ column: 'agentName', value: 'ascending' }]);

    const [filters, setFilters] = useState<IColumnValue[]>(initialAdviserFilters);

    // custom hooks
    const { loading, loadingHandler } = useComponentLoader();
    const debouncedSearchTerm = useDebounce(searchInput, 700);

    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const parsedPermission: IPermissionObject = JSON.parse(userLoginContext.permission);

    //Memorized data states
    const memoizedDashboardInput: MemoizedAdviserDashboardInput = useMemo(() => {
        return {
            tab,
            filters,
            sortData,
            debouncedSearchTerm,
            currentPage,
            resultLimit,
        };
    }, [tab, filters, sortData, debouncedSearchTerm, currentPage, resultLimit]);

    //Fn to get adviser dashboard data
    const getDashboardCallBack = useCallback(() => {
        getAdviserBranchData();
    }, [memoizedDashboardInput]);

    //Fn to call adviser dashboard query
    const getAdviserBranchData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(adviserDashboardQuery, {
                    input: {
                        page: currentPage,
                        resultLimit: resultLimit,
                        sort: sortData,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        filter: [...filters],
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = null || ((await response.data.adviserDashboard) as StandardResponse);
            if (data) {
                if (data.result) {
                    const { advisers, adviserCount, page, pages }: IAdviserResponse = data.result as IAdviserResponse;
                    // Check to group adivers
                    if (parsedPermission.hq.grant === 'hq') {
                        const _groupedData = groubByAdvisersByBranch(advisers);
                        setAdviserDataGrouped(_groupedData);
                    } else {
                        if (advisers.length !== 0) {
                            setAdviserData(advisers[0].adviserList);
                        } else {
                            setAdviserData([]);
                        }
                    }

                    setAdviserCount(adviserCount);
                    setCurrentPage(page);
                    setMaxPages(pages);
                } else throw error;
            } else {
                if (Object.keys(error).includes('errorCode')) {
                    setErrorMessage({
                        ...errorMessage,
                        message: (error as IErrorHandling).message,
                        errorCode: (error as IErrorHandling).errorCode,
                        title: 'Cannot Fetch Adviser Data',
                        testId: 'adviserbranch-modal',
                    });
                    handleErrorHandler();
                } else {
                    setErrorMessage({
                        ...errorMessage,
                        message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                        title: 'Something Went Wrong',
                        testId: 'gql-error-modal',
                    });
                    handleErrorHandler();
                }
            }

            loadingHandler();
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Dashboard Data',
                    testId: 'adviserbranch-modal',
                });
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
            handleErrorHandler();
        }
    };

    const ProviderValue: AdvisersContextProps = {
        adviserCount,
        adviserData,
        currentPage,
        filters,
        sortData,
        tab,
        adviserDataGrouped,
        loading,
        maxPages,
        resultLimit,
        searchInput,
        memoizedDashboardInput,
        loadingHandler,
        setAdviserCount,
        setAdviserData,
        setCurrentPage,
        setFilters,
        setMaxPages,
        setResultLimit,
        setSearchInput,
        setSortData,
        setTab,
        getDashboardCallBack,
    };
    return <AdvisersContext.Provider value={ProviderValue}>{children}</AdvisersContext.Provider>;
};

export default AdvisersProvider;
