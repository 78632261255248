/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { IcoMoon } from '../../../icons';
import { CustomButton, FlexedDiv } from '../../atoms';
import Container from '../../atoms/Container';

interface IBannerProps {
    description?: string | JSX.Element;
    descriptionEmText?: string;
    disabled?: boolean;
    handleClose?: () => void;
    isRequestBanner?: boolean;
    maxWidth?: string;
    primaryButtonObject: IBannerPrimaryBtn;
    secondaryButtonObject?: IBannerSecondaryBtn | IBannerSecondaryBtn[];
    setBannerHeight?: (height: number) => void;
    shorthandActions?: JSX.Element | string;
    testId?: string;
    title: string;
    toggle: boolean;
}

export function Banner({
    description,
    descriptionEmText,
    disabled,
    handleClose,
    isRequestBanner,
    maxWidth,
    primaryButtonObject,
    secondaryButtonObject,
    setBannerHeight,
    shorthandActions,
    testId,
    title,
    toggle,
}: IBannerProps): JSX.Element {
    const bannerRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    useEffect(() => {
        if (bannerRef.current !== undefined && setBannerHeight !== undefined) {
            setBannerHeight(bannerRef.current.clientHeight);
        }
    }, []);

    return (
        <Container visibility={toggle} data-testid="container" testId={testId}>
            <Wrapper ref={bannerRef} maxWidth={maxWidth}>
                <FlexedDiv direction="column" data-testid="flexDiv">
                    <FlexedDiv>
                        <Title data-testid="title">{title}</Title>
                    </FlexedDiv>
                    <FlexedDiv alignItems="center">
                        {description !== undefined ? (
                            <Description isOnProp={isRequestBanner}>
                                {description}&nbsp;
                                <div style={{ fontWeight: isRequestBanner ? 400 : 700, color: '#333333' }}>
                                    {descriptionEmText !== undefined ? descriptionEmText : null}
                                </div>
                            </Description>
                        ) : null}
                        {shorthandActions !== undefined ? shorthandActions : null}
                    </FlexedDiv>
                </FlexedDiv>

                <FlexedDiv direction="row-reverse" style={{ minWidth: '33.5rem' }}>
                    <BannerButton
                        disabled={disabled}
                        primary
                        onClick={() => {
                            primaryButtonObject.handlePrimaryBtn();
                            // handleClose(); //should control modal outside
                        }}
                        data-testid={`${testId}-primary-btn`}
                        id={`${testId}-primary-btn`}
                        width={primaryButtonObject.width}
                    >
                        {primaryButtonObject.icon !== undefined ? (
                            <IcoMoon
                                name={primaryButtonObject.icon}
                                color="#FFFFFF"
                                size="23.68px"
                                style={{ marginRight: '.5rem' }}
                            />
                        ) : null}
                        {primaryButtonObject.label}
                    </BannerButton>

                    {secondaryButtonObject !== undefined ? (
                        Array.isArray(secondaryButtonObject) ? (
                            secondaryButtonObject.map((btn, index) => {
                                return (
                                    <BannerButton
                                        key={`banner-secondary-btn-${index + 1}`}
                                        onClick={() => {
                                            btn.handleSecondaryBtn();
                                            if (handleClose !== undefined) {
                                                handleClose(); //should control modal outside?
                                            }
                                        }}
                                        data-testid={`${testId}-secondary-btn-${index}`}
                                        id={`${testId}-secondary-btn-${index}`}
                                        color={btn.color}
                                        width={btn.width}
                                        disabled={btn.isDisabled}
                                    >
                                        {btn.icon !== undefined ? (
                                            <IcoMoon
                                                name={btn.icon}
                                                color={btn.color ?? '#333333'}
                                                size="23.68px"
                                                style={{ marginRight: '.5rem' }}
                                            />
                                        ) : null}
                                        {btn.label}
                                    </BannerButton>
                                );
                            })
                        ) : (
                            <BannerButton
                                onClick={() => {
                                    secondaryButtonObject.handleSecondaryBtn();
                                    if (handleClose !== undefined) {
                                        handleClose(); //should control modal outside?
                                    }
                                }}
                                data-testid={`${testId}-secondary-btn`}
                                id={`${testId}-secondary-btn`}
                                color={secondaryButtonObject.color}
                                width={secondaryButtonObject.width}
                                disabled={secondaryButtonObject.isDisabled}
                            >
                                {secondaryButtonObject.icon !== undefined ? (
                                    <IcoMoon
                                        name={secondaryButtonObject.icon}
                                        color={secondaryButtonObject.color ?? '#333333'}
                                        size="23.68px"
                                        style={{ marginRight: '.5rem' }}
                                    />
                                ) : null}
                                {secondaryButtonObject.label}
                            </BannerButton>
                        )
                    ) : null}
                </FlexedDiv>
            </Wrapper>
        </Container>
    );
}
interface IBannerWrapperProps {
    maxWidth?: string;
}
const Wrapper = styled.div<IBannerWrapperProps>`
    position: fixed;
    bottom: 0;
    width: 81.111vw;
    display: flex;
    background-color: #f6edd9;
    align-items: center;
    height: 7rem;
    border-top-left-radius: 1.375rem;
    border-top-right-radius: 1.375rem;
    padding: 1.5rem 2rem;
    z-index: 3;
    max-width: ${(props) => props.maxWidth}px;
    justify-content: space-between;
`;
const Title = styled.div`
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
`;
const Description = styled.div<IOnPropStyles>`
    font-size: 1rem;
    color: ${(props) => (props.isOnProp ? '#4D4D4D' : '#333333')};
    line-height: ${(props) => (props.isOnProp ? '21.6px' : '23.68px')};
    font-weight: 400;
    display: flex;
    white-space: pre;
`;

const BannerButton = styled(CustomButton)`
    height: 3rem;
    max-width: 292px;
    font-size: 1rem;
    width: ${(props) => (props.width ? `${props.width}` : `100%`)};
    margin-left: 1rem;
    border: 1px solid #c61230;
    border: ${(props) => (props.color ? `1px solid ${props.color}` : `1px solid #c61230`)};
    color: ${(props) => props.color};
    padding: 0.5rem 2.5rem;
`;

export default Banner;
