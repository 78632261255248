import React, { Fragment } from 'react';
import styled from 'styled-components';

interface ColumnSheetProps {
    columns: ISheetColumn[];
    isRowMarked?: boolean;
    handleMarkAll?: () => void;
    handleUndoAll?: () => void;
    readyOnly: boolean;
    hasExternalLinks?: boolean;
    columnWidths?: IFixedColumnWidths;
}
type StickyThProps = {
    colWidths: IFixedColumnWidths | undefined;
};
export const ColumnSheet: React.FC<ColumnSheetProps> = ({
    columns,
    isRowMarked,
    handleMarkAll,
    handleUndoAll,
    readyOnly,
    hasExternalLinks,
    columnWidths,
}: ColumnSheetProps) => {
    const th = (
        <Fragment>
            <StickyTh colWidths={columnWidths && columnWidths}></StickyTh>
            {columns.map((cloum, index) => (
                <StickyTh key={index} colWidths={columnWidths && columnWidths}>
                    {cloum.colName}
                </StickyTh>
            ))}
            {!isRowMarked ? (
                <StickyActionTh
                    onClick={() => (handleMarkAll ? handleMarkAll() : '')}
                    colWidths={columnWidths && columnWidths}
                    id="confirm-all-btn"
                >
                    Confirm All
                </StickyActionTh>
            ) : (
                <StickyActionTh
                    onClick={() => (handleUndoAll ? handleUndoAll() : '')}
                    colWidths={columnWidths && columnWidths}
                    id="undo-all-btn"
                >
                    Undo All
                </StickyActionTh>
            )}
        </Fragment>
    );
    const thReadOnly = (
        <Fragment>
            {hasExternalLinks ? (
                <StickyTh colWidths={columnWidths && columnWidths}>View</StickyTh>
            ) : (
                <StickyTh colWidths={columnWidths && columnWidths}></StickyTh>
            )}

            {columns.map((cloum, index) => (
                <StickyTh key={index} colWidths={columnWidths && columnWidths}>
                    {cloum.colName}
                </StickyTh>
            ))}
        </Fragment>
    );
    return <tr>{!readyOnly ? th : thReadOnly}</tr>;
};

const StickyTh = styled.th<StickyThProps>`
    border: 1px solid #eaebee;
    background-color: #fff;
    padding: 0.8em 0.8em;
    height: auto;
    font-size: 14px;
    /* max-width: 120px; */
    overflow: hidden;
    height: 52px;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:nth-child(1) {
        width: ${(props) => (props.colWidths ? `${props.colWidths.thirdColWidth}px` : '42px')};
        left: ${(props) =>
            props.colWidths
                ? `-${props.colWidths.firstColWidth + props.colWidths.secondColWidth + props.colWidths.thirdColWidth}px`
                : '-418px'};
        border-radius: 8px 0px 0px 0px;
        position: absolute;
        background-color: transparent;
        max-width: none;
    }
    &:nth-child(2) {
        width: ${(props) => (props.colWidths ? `${props.colWidths.secondColWidth}px` : '96px')};
        position: absolute;
        left: ${(props) =>
            props.colWidths ? `-${props.colWidths.firstColWidth + props.colWidths.secondColWidth}px` : '-376px'};
        background-color: transparent;
        max-width: none;
    }
    &:nth-child(3) {
        width: ${(props) => (props.colWidths ? `${props.colWidths.firstColWidth}px` : '280px')};
        position: absolute;
        left: ${(props) => (props.colWidths ? `-${props.colWidths.firstColWidth}px` : '-280px')};

        background-color: transparent;
        max-width: none;
    }
`;

const StickyActionTh = styled(StickyTh)`
    position: absolute;
    background-color: transparent;
    width: 100px;
    border: 0px;
    right: -100px;
    cursor: pointer;
`;
