import gql from 'graphql-tag';
/**
 * @allBranchQuery @query - HQ Dashboard
 * @description: TAB: All branches
 */

export const branchOfficesExportList = /* GraphQL */ gql`
    query dashboardExportList($input: DashboardExportInput) {
        dashboardExportList(input: $input) {
            data {
                result {
                    branchoffices {
                        name
                        code
                        state
                        agentCount
                        clientCount
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
