import gql from 'graphql-tag';
/**
 * @eddReportsPdfMutation @Mutation - Download Report with comments
 * @description: Download Report with comments
 */

export const eddReportsPdfMutation = gql`
    mutation eddReportsPdf($input: EddReportPdf) {
        eddReportsPdf(input: $input) {
            data {
                result {
                    status
                    message
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
