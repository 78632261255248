/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Dispatch, ReactText } from 'react';
import { IColumnValue } from '../../../pages/Advisers';

export interface BranchOfficeContextData {
    allBranchData: ITableData[];
    branchCounter: Array<number>;
    branchesId: string;
    disableResultLimit: boolean;
    expand: boolean;
    filters: IColumnValue[];
    getAllBranchData: () => Promise<void>;
    loading: boolean;
    loadingHandler: () => void;
    maxPages: number;
    page: number;
    resultLimit: number;
    searchInput: ISearchInput;
    setAllBranchData: Dispatch<React.SetStateAction<ITableData[]>>;
    setBranchCounter: Dispatch<React.SetStateAction<Array<number>>>;
    setBranchesId: Dispatch<React.SetStateAction<string>>;
    setExpand: Dispatch<React.SetStateAction<boolean>>;
    setFilters: Dispatch<React.SetStateAction<IColumnValue[]>>;
    setMaxPages: Dispatch<React.SetStateAction<number>>;
    setPage: Dispatch<React.SetStateAction<number>>;
    setResultLimit: Dispatch<React.SetStateAction<number>>;
    setSearchInput: Dispatch<React.SetStateAction<ISearchInput>>;
    setSortData: Dispatch<React.SetStateAction<ISort_FilterInput>>;
    setStateList: Dispatch<React.SetStateAction<string[]>>;
    setStates: Dispatch<React.SetStateAction<ReactText[]>>;
    setTab: Dispatch<React.SetStateAction<number>>;
    setTempFilters: React.Dispatch<React.SetStateAction<IColumnValue[]>>;
    sortData: ISort_FilterInput;
    stateList: string[];
    states: ReactText[];
    tab: number;
    tempFilters: IColumnValue[];
}

const BranchOfficeContext = React.createContext({} as BranchOfficeContextData);

export default BranchOfficeContext;
