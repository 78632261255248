// import moment from 'moment';

export function checkValid(column: string, value: string | Date): boolean {
    let returnVal = false;

    switch (column.toLowerCase()) {
        case 'fund start date':
        case 'iop end date':
        case 'non business day from date':
        case 'non business day to date':
        case 'value date':
            if (value instanceof Date) value instanceof Date ? (returnVal = true) : (returnVal = false);
            if (typeof value === 'string') {
                const ddmmyyyyVal = value
                    .toString()
                    .match(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/); //check dd/mm/yyyy

                ddmmyyyyVal ? (returnVal = true) : (returnVal = false);
            }
            break;
        default:
            returnVal = true;
    }

    return returnVal;
}

export function convertUtColumnsToKeys(columns: ISheetColumn[]): string[] {
    const tempColumns: string[] = [];
    columns.map((item, index) => {
        switch (item.colName.trim().toLowerCase()) {
            case 'fund code':
                tempColumns[index] = 'fundCode';
                break;
            case 'toms fund abbreviation':
                tempColumns[index] = 'tomsFundAbbreviation';
                break;
            case 'omni fund abbreviation':
                tempColumns[index] = 'omniFundAbbreviation';
                break;
            case 'fund name':
                tempColumns[index] = 'fundName';
                break;
            case 'fund status':
                tempColumns[index] = 'fundStatus';
                break;
            case 'fund category':
                tempColumns[index] = 'fundCategory';
                break;
            case 'fund processing group':
                tempColumns[index] = 'fundProcessingGroup';
                break;
            case 'retail fund / wholesale fund':
                tempColumns[index] = 'retailFundWholesaleFund';
                break;
            case 'closed-end (y/n)':
                tempColumns[index] = 'closedEnd';
                break;
            case 'sc fund type':
                tempColumns[index] = 'scFundType';
                break;
            case 'conventional / syariah':
                tempColumns[index] = 'conventionalSyariah';
                break;
            case 'fund class':
                tempColumns[index] = 'fundClass';
                break;
            case 'utmc':
                tempColumns[index] = 'utmc';
                break;
            case 'trustee':
                tempColumns[index] = 'trustee';
                break;
            case 'sc processing group':
                tempColumns[index] = 'scProcessingGroup';
                break;
            case 'epf approved (y/n)':
                tempColumns[index] = 'epfApproved';
                break;
            case 'epf status':
                tempColumns[index] = 'epfStatus';
                break;
            case 'fund start date':
                tempColumns[index] = 'fundStartDate';
                break;
            case 'iop end date':
                tempColumns[index] = 'iopEndDate';
                break;
            case 'maturity date (closed-end)':
                tempColumns[index] = 'maturityDate';
                break;
            case 'commercial start date':
                tempColumns[index] = 'commercialStartDate';
                break;
            case 'after iop - allow (y/n)':
                tempColumns[index] = 'allow';
                break;
            case 'after iop allow-new sales':
                tempColumns[index] = 'newSales';
                break;
            case 'after iop allow-top up sales':
                tempColumns[index] = 'topUpSales';
                break;
            case 'after iop allow-redemption':
                tempColumns[index] = 'redemption';
                break;
            case 'after iop allow-redemption cool off':
                tempColumns[index] = 'redemptionCoolOff';
                break;
            case 'after iop allow-switch in':
                tempColumns[index] = 'switchIn';
                break;
            case 'after iop allow-switch out':
                tempColumns[index] = 'switchOut';
                break;
            case 'after iop allow-transfer':
                tempColumns[index] = 'transfer';
                break;
            case 'income distribution instruction':
                tempColumns[index] = 'incomeDistributionInstruction';
                break;
            case 'dividend timing':
                tempColumns[index] = 'dividendTiming';
                break;
            case 'min dividend amount payout':
                tempColumns[index] = 'minDividendAmountPayout';
                break;

            case 'daily income distribution (y/n)':
                tempColumns[index] = 'dailyIncomeDistribution';
                break;
            case 'fund base currency':
                tempColumns[index] = 'fundBaseCurrency';
                break;
            case 'fund class currency':
                tempColumns[index] = 'fundClassCurrency';
                break;
            case 'issue price':
                tempColumns[index] = 'issuePrice';
                break;
            case 'annual management fee (%)':
                tempColumns[index] = 'annualManagementFee';
                break;
            case 'annual trustee fee (%)':
                tempColumns[index] = 'annualTrusteeFee';
                break;
            case 'min. sales fee (epf) (%)':
                tempColumns[index] = 'minSalesFeeEpf';
                break;
            case 'min. sales fee (non-epf) (%)':
                tempColumns[index] = 'minSalesFeeNonEpf';
                break;
            case 'max. sales fee (epf) (%)':
                tempColumns[index] = 'maxSalesFeeEpf';
                break;
            case 'max. sales fee (non-epf) (%)':
                tempColumns[index] = 'maxSalesFeeNonEpf';
                break;
            case 'aip allowed (y/n)':
                tempColumns[index] = 'aipAllowed';
                break;
            case 'min. new sales amt (epf)':
                tempColumns[index] = 'minNewSalesAmtEpf';
                break;
            case 'max. new sales amt (epf)':
                tempColumns[index] = 'maxNewSalesAmtEpf';
                break;
            case 'min. topup amt (epf)':
                tempColumns[index] = 'minTopupAmtEpf';
                break;
            case 'max. topup amt (epf)':
                tempColumns[index] = 'maxTopupAmtEpf';
                break;
            case 'min. new sales amt (non-epf)-individual':
                tempColumns[index] = 'minNewSalesAmtNonEpfIndividual';
                break;
            case 'max. new sales amt (non-epf)-individual':
                tempColumns[index] = 'maxNewSalesAmtNonEpfIndividual';
                break;
            case 'min. topup amt (non-epf)-individual':
                tempColumns[index] = 'minTopUpAmtNonEpfIndividual';
                break;
            case 'max. topup amt (non-epf)-individual':
                tempColumns[index] = 'maxTopUpAmtNonEpfIndividual';
                break;
            case 'no of cool of days':
                tempColumns[index] = 'noOfCoolOfDays';
                break;
            case 'exit fees charge - epf %':
                tempColumns[index] = 'exitFeesChargeEpfPercentage';
                break;
            case 'exit fees charge - epf amount':
                tempColumns[index] = 'exitFeesChargeEpfAmount';
                break;
            case 'exit fees charge - non-epf %':
                tempColumns[index] = 'exitFeesChargeNonEpfPercentage';
                break;
            case 'exit fees charge - non-epf amount':
                tempColumns[index] = 'exitFeesChargeNonEpfAmount';
                break;
            case 'redemption type':
                tempColumns[index] = 'redemptionType';
                break;
            case 'min. redemption units':
                tempColumns[index] = 'minRedemptionUnits';
                break;
            case 'switching type':
                tempColumns[index] = 'switchingType';
                break;
            case 'min switch out units':
                tempColumns[index] = 'minSwitchOutUnits';
                break;
            case 'switching fee':
                tempColumns[index] = 'switchingFee';
                break;
            case 'min. transfer of prs provider-out (units)':
                tempColumns[index] = 'minTransferOutUnits';
                break;
            case 'min. initial transfer of prs provider-in (amount)':
                tempColumns[index] = 'minInitialTransferInUnits';
                break;
            case 'min. topup transfer of prs provider-in (amount)':
                tempColumns[index] = 'minTopupTransferInUnits';
                break;
            case 'transfer fee (prs provider)':
                tempColumns[index] = 'transferFeePrsProvider';
                break;
            case 'min. balance holdings units':
                tempColumns[index] = 'minBalanceHoldingsUnits';
                break;
            case 'master cut off time':
                tempColumns[index] = 'salesCutOffTime';
                break;
            case 'redemption cut off time':
                tempColumns[index] = 'redemptionCutOffTime';
                break;
            case 'batch cut off time sales':
                tempColumns[index] = 'batchCutOffTimeSales';
                break;
            case 'batch cut off time redemption':
                tempColumns[index] = 'batchCutOffTimeRedemption';
                break;
            case 'gst parameter (%)':
            case 'gst parameter':
                tempColumns[index] = 'gstParameter';
                break;
            case 'risk category':
                tempColumns[index] = 'riskCategory';
                break;
            case 'non business day from date':
                tempColumns[index] = 'nonBusinessDayFromDate';
                break;
            case 'non business day to date':
                tempColumns[index] = 'nonBusinessDayToDate';
                break;
            case 'difference fund price':
                tempColumns[index] = 'differenceFundPrice';
                break;
            case 'sales settlement day':
                tempColumns[index] = 'salesSettlementDay';
                break;
            case 'redemption settle date':
                tempColumns[index] = 'redemptionSettleDate';
                break;
            case 'switching settle date':
                tempColumns[index] = 'switchingSettleDate';
                break;
            case 'switching group settlement day':
                tempColumns[index] = 'switchingGroupSettlementDay';
                break;
            case 'fund objective':
                tempColumns[index] = 'fundObjective';
                break;
            case 'dedicated fund':
                tempColumns[index] = 'dedicatedFund';
                break;
            case 'amp fee':
                tempColumns[index] = 'ampFee';
                break;
            case 'money sighted fund':
                tempColumns[index] = 'moneySightedFund';
                break;
            case 'fel sharing':
                tempColumns[index] = 'felSharing';
                break;
            case 'default asset allocation':
                tempColumns[index] = 'defaultAssetAllocation';
                break;
            case 'lipper code':
                tempColumns[index] = 'lipperCode';
                break;
            case 'distributed (kut / kibb)':
                tempColumns[index] = 'distributed';
                break;
            case 'fea tagging':
                tempColumns[index] = 'feaTagging';
                break;
            case 'prs scheme':
                tempColumns[index] = 'prsScheme';
                break;
            case 'switch out settle day to non-money sighted fund (differ utmc)':
                tempColumns[index] = 'switchOutSettleDayNonMoneySightedDifferUtmc';
                break;
            case 'switch out settle day to money sighted fund (differ utmc)':
                tempColumns[index] = 'switchOutSettleDayMoneySightedDifferUtmc';
                break;
            case 'switch out settle day to money sighted (same utmc)':
                tempColumns[index] = 'switchOutSettleDayMoneySightedSameUtmc';
                break;
            case 'switch out settle day to non-money sighted fund (same utmc)':
                tempColumns[index] = 'switchOutSettleDayNonMoneySightedSameUtmc';
                break;

            // NAV, distribution starts here
            case 'date':
            case 'value date':
                tempColumns[index] = 'date';
                break;
            case 'nav per unit':
                tempColumns[index] = 'navPerUnit';
                break;
            case 'gross distribution':
            case 'gross distribution per unit':
                tempColumns[index] = 'gross';
                break;
            case 'net distribution':
            case 'net distribution per unit':
                tempColumns[index] = 'net';
                break;
            case 'unit split / cancellation':
            case 'unit split/ capital consolidation (%)':
                tempColumns[index] = 'unit';
                break;
            default:
                tempColumns[index] = `${item.colName}-ERROR`;
                return;
        }
    });

    return tempColumns;
}
export function expandMasterFundCols(columns: Array<string>): string[] {
    const tempColumns: string[] = [];
    columns.map((item, index) => {
        switch (item) {
            case 'fundCode':
                tempColumns[index] = 'Fund Code';
                break;
            case 'tomsFundAbbreviation':
                tempColumns[index] = 'TOMS Fund Abbreviation';
                break;
            case 'omniFundAbbreviation':
                tempColumns[index] = 'OMNI Fund Abbreviation';
                break;
            case 'fundName':
                tempColumns[index] = 'Fund Name';
                break;
            case 'fundStatus':
                tempColumns[index] = 'Fund Status';
                break;
            case 'fundCategory':
                tempColumns[index] = 'Fund Category';
                break;
            case 'fundProcessingGroup':
                tempColumns[index] = 'Fund Processing Group';
                break;
            case 'retailFundWholesaleFund':
                tempColumns[index] = 'Retail Fund / Wholesale Fund';
                break;
            case 'closedEnd':
                tempColumns[index] = 'Closed-end (Y/N)';
                break;
            case 'scFundType':
                tempColumns[index] = 'SC Fund Type';
                break;
            case 'conventionalSyariah':
                tempColumns[index] = 'Conventional / Syariah';
                break;
            case 'fundClass':
                tempColumns[index] = 'Fund Class';
                break;
            case 'utmc':
                tempColumns[index] = 'UTMC';
                break;
            case 'trustee':
                tempColumns[index] = 'Trustee';
                break;
            case 'scProcessingGroup':
                tempColumns[index] = 'SC Processing Group';
                break;
            case 'epfApproved':
                tempColumns[index] = 'EPF Approved (Y/N)';
                break;
            case 'epfStatus':
                tempColumns[index] = 'EPF Status';
                break;
            case 'fundStartDate':
                tempColumns[index] = 'Fund Start Date';
                break;
            case 'iopEndDate':
                tempColumns[index] = 'IOP End Date';
                break;
            case 'maturityDate':
                tempColumns[index] = 'Maturity Date (Closed-end)';
                break;
            case 'commercialStartDate':
                tempColumns[index] = 'Commercial Start Date';
                break;
            case 'allow':
                tempColumns[index] = 'After IOP - Allow (Y/N)';
                break;
            case 'newSales':
                tempColumns[index] = 'After IOP Allow-New Sales';
                break;
            case 'topUpSales':
                tempColumns[index] = 'After IOP Allow-Top Up Sales';
                break;
            case 'redemption':
                tempColumns[index] = 'After IOP Allow-Redemption';
                break;
            case 'redemptionCoolOff':
                tempColumns[index] = 'After IOP Allow-Redemption Cool Off';
                break;
            case 'switchIn':
                tempColumns[index] = 'After IOP Allow-Switch IN';
                break;
            case 'switchOut':
                tempColumns[index] = 'After IOP Allow-Switch OUT';
                break;
            case 'transfer':
                tempColumns[index] = 'After IOP Allow-Transfer';
                break;
            case 'incomeDistributionInstruction':
                tempColumns[index] = 'Income Distribution Instruction';
                break;
            case 'dividendTiming':
                tempColumns[index] = 'Dividend timing';
                break;
            case 'minDividendAmountPayout':
                tempColumns[index] = 'Min Dividend Amount payout';
                break;
            case 'dailyIncomeDistribution':
                tempColumns[index] = 'Daily Income Distribution (Y/N)';
                break;
            case 'fundBaseCurrency':
                tempColumns[index] = 'Fund Base Currency';
                break;
            case 'fundClassCurrency':
                tempColumns[index] = 'Fund Class Currency';
                break;
            case 'issuePrice':
                tempColumns[index] = 'Issue Price';
                break;
            case 'annualManagementFee':
                tempColumns[index] = 'Annual Management Fee (%)';
                break;
            case 'annualTrusteeFee':
                tempColumns[index] = 'Annual Trustee Fee (%)';
                break;
            case 'minSalesFeeEpf':
                tempColumns[index] = 'Min. Sales Fee (EPF) (%)';
                break;
            case 'minSalesFeeNonEpf':
                tempColumns[index] = 'Min. Sales Fee (Non-EPF) (%)';
                break;
            case 'maxSalesFeeEpf':
                tempColumns[index] = 'Max. Sales Fee (EPF) (%)';
                break;
            case 'maxSalesFeeNonEpf':
                tempColumns[index] = 'Max. Sales Fee (Non-EPF) (%)';
                break;
            case 'aipAllowed':
                tempColumns[index] = 'AIP Allowed (Y/N)';
                break;
            case 'minNewSalesAmtEpf':
                tempColumns[index] = 'Min. New Sales Amt (EPF)';
                break;
            case 'maxNewSalesAmtEpf':
                tempColumns[index] = 'Max. New Sales Amt (EPF)';
                break;
            case 'minTopupAmtEpf':
                tempColumns[index] = 'Min. Topup Amt (EPF)';
                break;
            case 'maxTopupAmtEpf':
                tempColumns[index] = 'Max. Topup Amt (EPF)';
                break;
            case 'minNewSalesAmtNonEpfIndividual':
                tempColumns[index] = 'Min. New Sales Amt (NON-EPF)-Individual';
                break;
            case 'maxNewSalesAmtNonEpfIndividual':
                tempColumns[index] = 'Max. New Sales Amt (NON-EPF)-Individual';
                break;
            case 'minTopUpAmtNonEpfIndividual':
                tempColumns[index] = 'Min. Topup Amt (NON-EPF)-Individual';
                break;
            case 'maxTopUpAmtNonEpfIndividual':
                tempColumns[index] = 'Max. Topup Amt (NON-EPF)-Individual';
                break;
            case 'noOfCoolOfDays':
                tempColumns[index] = 'No Of Cool Of Days';
                break;
            case 'exitFeesChargeEpfPercentage':
                tempColumns[index] = 'Exit fees charge - EPF %';
                break;
            case 'exitFeesChargeEpfAmount':
                tempColumns[index] = 'Exit fees charge - EPF Amount';
                break;
            case 'exitFeesChargeNonEpfPercentage':
                tempColumns[index] = 'Exit fees charge - NON-EPF %';
                break;
            case 'exitFeesChargeNonEpfAmount':
                tempColumns[index] = 'Exit fees charge - NON-EPF Amount';
                break;
            case 'redemptionType':
                tempColumns[index] = 'Redemption Type';
                break;
            case 'minRedemptionUnits':
                tempColumns[index] = 'Min. Redemption Units';
                break;
            case 'switchingType':
                tempColumns[index] = 'Switching Type';
                break;
            case 'minSwitchOutUnits':
                tempColumns[index] = 'Min Switch out Units';
                break;
            case 'switchingFee':
                tempColumns[index] = 'Switching Fee';
                break;
            case 'minTransferOutUnits':
                tempColumns[index] = 'Min. Transfer of PRS Provider-OUT (Units)';
                break;
            case 'minInitialTransferInUnits':
                tempColumns[index] = 'Min. Initial Transfer of PRS Provider-IN (Amount)';
                break;
            case 'minTopupTransferInUnits':
                tempColumns[index] = 'Min. Topup Transfer of PRS Provider-IN (Amount)';
                break;
            case 'transferFeePrsProvider':
                tempColumns[index] = 'Transfer Fee (PRS Provider)';
                break;
            case 'minBalanceHoldingsUnits':
                tempColumns[index] = 'Min. Balance Holdings Units';
                break;
            case 'salesCutOffTime':
                tempColumns[index] = 'Master Cut off Time';
                break;
            case 'redemptionCutOffTime':
                tempColumns[index] = 'Redemption Cut off Time';
                break;
            case 'batchCutOffTimeSales':
                tempColumns[index] = 'Batch cut off time Sales';
                break;
            case 'batchCutOffTimeRedemption':
                tempColumns[index] = 'Batch cut off time Redemption';
                break;
            case 'gstParameter':
                tempColumns[index] = 'GST parameter';
                break;
            case 'riskCategory':
                tempColumns[index] = 'Risk Category';
                break;
            case 'nonBusinessDayFromDate':
                tempColumns[index] = 'Non Business Day From date';
                break;
            case 'nonBusinessDayToDate':
                tempColumns[index] = 'Non Business Day To date';
                break;
            case 'differenceFundPrice':
                tempColumns[index] = 'Difference Fund Price';
                break;
            case 'salesSettlementDay':
                tempColumns[index] = 'Sales Settlement Day';
                break;
            case 'redemptionSettleDate':
                tempColumns[index] = 'Redemption Settle Date';
                break;
            case 'switchingSettleDate':
                tempColumns[index] = 'Switching Settle Date';
                break;
            case 'switchingGroupSettlementDay':
                tempColumns[index] = 'Switching Group Settlement Day';
                break;
            case 'fundObjective':
                tempColumns[index] = 'Fund Objective';
                break;
            case 'dedicatedFund':
                tempColumns[index] = 'Dedicated Fund';
                break;
            case 'ampFee':
                tempColumns[index] = 'AMP Fee';
                break;
            case 'moneySightedFund':
                tempColumns[index] = 'Money Sighted Fund';
                break;
            case 'felSharing':
                tempColumns[index] = 'FEL sharing';
                break;
            case 'defaultAssetAllocation':
                tempColumns[index] = 'Default Asset Allocation';
                break;
            case 'lipperCode':
                tempColumns[index] = 'Lipper Code';
                break;
            case 'distributed':
                tempColumns[index] = 'Distributed (KUT / KIBB)';
                break;
            case 'feaTagging':
                tempColumns[index] = 'FEA Tagging';
                break;
            case 'prsScheme':
                tempColumns[index] = 'PRS Scheme';
                break;
            case 'switchOutSettleDayNonMoneySightedDifferUtmc':
                tempColumns[index] = 'Switch out settle day To NON-Money sighted fund (DIFFER UTMC)';
                break;
            case 'switchOutSettleDayMoneySightedDifferUtmc':
                tempColumns[index] = 'Switch out settle day To Money sighted fund (DIFFER UTMC)';
                break;
            case 'switchOutSettleDayMoneySightedSameUtmc':
                tempColumns[index] = 'Switch out settle day  To Money sighted (SAME UTMC)';
                break;
            case 'switchOutSettleDayNonMoneySightedSameUtmc':
                tempColumns[index] = 'Switch out settle day TO NON-Money sighted fund (SAME UTMC)';
                break;
            // NAV, distribution starts here
            case 'date':
                tempColumns[index] = 'Date';
                break;
            case 'value':
                tempColumns[index] = 'Value';
                break;
            case 'gross':
                tempColumns[index] = 'Gross Distribution';
                break;
            case 'net':
                tempColumns[index] = 'Net Distribution';
                break;
            case 'unit':
                tempColumns[index] = 'Unit Split/ Capital Consolidation (%)';
                break;
            case 'navPerUnit':
                tempColumns[index] = 'NAV Per Unit';
                break;
            default:
                tempColumns[index] = `${item}-ERROR`;
                return;
        }
    });
    return tempColumns;
}

export function expandBulkUserCols(columns: Array<string>): string[] {
    const tempColumns: string[] = [];
    columns.map((item, index) => {
        switch (item) {
            case 'name':
                tempColumns[index] = 'Name';
                break;
            case 'email':
                tempColumns[index] = 'Email';
                break;
            case 'username':
                tempColumns[index] = 'User Name';
                break;
            case 'branch_id':
                tempColumns[index] = 'Branch ID';
                break;
            case 'department_id':
                tempColumns[index] = 'Department ID';
                break;
            case 'sso_id':
                tempColumns[index] = 'SSO ID';
                break;
            case 'group_id':
                tempColumns[index] = 'Group ID';
                break;
            case 'mobile_no':
                tempColumns[index] = 'Mobile No';
                break;
            case 'description':
                tempColumns[index] = 'Description';
                break;
            default:
                tempColumns[index] = `${item}-ERROR`;
                return;
        }
    });
    return tempColumns;
}
export function checkFileType(fileType: string): string {
    let mimeType = '';
    switch (fileType) {
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            mimeType = 'application/vnd.ms-excel';
            break;
        case 'application/vnd.ms-excel':
            mimeType = 'text/csv';
            break;
        default:
            mimeType = fileType;
    }
    return mimeType;
}

export function moveItemInArray(from: number, to: number, array: ISheetColumn[]): ISheetColumn[] {
    const item = array.splice(from, 1)[0];
    array.splice(to, 0, item);
    return array;
}

export function getDocumentType(documentType: string): string {
    let docName = '';
    switch (documentType) {
        case 'AR':
            docName = 'Annual Report';
            break;
        case 'FFS':
            docName = 'Monthly Fund Fact Sheet';
            break;
        case 'PHS':
            docName = 'Product Highlight Sheet';
            break;
        case 'PI':
            docName = 'Prospectus Information';
            break;
        case 'IM':
            docName = 'Information Memorandum';
            break;
        case 'DD':
            docName = 'Disclosure Document';
            break;
        default:
            docName = 'DOCUMENT TYPE NOT IN SYSTEM';
    }
    return docName;
}

export function getFileSize(bytes: string): string {
    const _bytes = parseInt(bytes);
    let _tempValue = '';
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (_bytes == 0) return '0 Byte';

    const sizeIndex = Math.floor(Math.log(_bytes) / Math.log(1024));
    switch (sizeIndex) {
        case 1:
            _tempValue = Math.floor(_bytes / Math.pow(1024, sizeIndex)) + ' ' + sizes[sizeIndex];
            break;
        default:
            _tempValue = (_bytes / Math.pow(1024, sizeIndex)).toFixed(2) + ' ' + sizes[sizeIndex];
    }
    return _tempValue;
}

export function getSpecificFileSize(bytes: string, type: string): string {
    const _bytes = parseInt(bytes);
    let _tempValue = '';

    if (_bytes == 0) return `0 ${type}`;
    switch (type) {
        case 'KB':
            _tempValue = Math.floor(_bytes / Math.pow(1024, 1)) + ' ' + type;
            break;
        case 'MB':
            _tempValue = (_bytes / Math.pow(1024, 2)).toFixed(2) + ' ' + type;
    }
    return _tempValue;
}
