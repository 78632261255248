import gql from 'graphql-tag';
export const ceCheckerReviewRequest = gql`
    mutation transactionCheckerStatus($input: ApproveRejectInput) {
        transactionCheckerStatus(input: $input) {
            data {
                result {
                    status
                    message
                    transactionAffected
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
