import gql from 'graphql-tag';

export const downloadPPAQuery = gql`
    query ppaDownloadV2($input: TransactionRefArrayInput) {
        ppaDownloadV2(input: $input) {
            data {
                result {
                    message
                    link
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
