import gql from 'graphql-tag';

/**
 * @adviserDashboardQuery @query - Adviser Dashboard
 * @description:  Returns all advisers of user’s branch
 */
export const adviserDashboardQuery = /* GraphQL */ gql`
    query adviserDashboard($input: AdviserDashboardInput) {
        adviserDashboard(input: $input) {
            data {
                result {
                    advisers {
                        branchName
                        adviserList {
                            agentId
                            agentName
                            agentCode
                            bdmName
                            bdmCode
                            uplineName
                            uplineCode
                            status
                            createdOn
                            lastUpdated
                        }
                    }
                    adviserCount
                    page
                    pages
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
