import gql from 'graphql-tag';

/**
 * @createUser @mutation System admin Dashboard
 * @description mutation for creating new user
 */

export const DashboardUserExportList = gql`
    query dashboardExportList($input: DashboardExportInput) {
        dashboardExportList(input: $input) {
            data {
                result {
                    systemadmindashboard {
                        staffName
                        department
                        userGroup
                        lastLogin
                        timestamp
                        status
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
