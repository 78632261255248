type IGroupedTableData = {
    key: string;
    data: ITableData[];
};
/** Groups the transactions sent from hqceDashboard query as per  branchName  */
export const groupTransactionsByBranch = (data: ITableData[]): ITableData[] => {
    const temp: ITableData[] = [];
    const branchGroup: IGroupedTableData[] = [];
    const branchName: string[] = [];
    // Populates the different cutOffTimes into array
    data.map((item) => {
        if (branchName.includes(item['branchName'])) return;
        else branchName.push(item.branchName);
    });

    // Initialize the object with key as cutOff time and value as data
    for (let i = 0; i < branchName.length; i++) {
        branchGroup.push({ key: branchName[i], data: [] });
    }
    // Grouping the transactions under each available cutOff Time
    data.map((item) => {
        branchGroup.map((ele) => {
            ele.key === item.branchName ? ele.data.push(item) : null;
        });
    });
    // Pushes every grouped transactions into array to be returned.
    branchGroup.map((item) => {
        item.key &&
            item.data.map((ele, index) => {
                if (index === 0) {
                    ele.groupByLabel = ele.branchName;
                }
                temp.push(ele);
            });
    });
    return temp;
};
// const merge = (left: string[], right: string[]) => {
//     const result: string[] = [];
//     let iLeft = 0,
//         iRight = 0;

//     while (iLeft < left.length && iRight < right.length) {
//         const beginning = left[iLeft];
//         const end = right[iRight];

//         if (beginning < end) {
//             result.push(left[iLeft++]);
//         } else {
//             result.push(right[iRight++]);
//         }
//     }
//     return result.concat(left.slice(iLeft)).concat(right.slice(iRight));
// };
// export const mergeSort = (arr: string[]): string[] => {
//     if (arr.length < 2) {
//         return arr;
//     }

//     const middle = Math.floor(arr.length / 2),
//         left = arr.slice(0, middle),
//         right = arr.slice(middle);
//     return merge(mergeSort(left), mergeSort(right));
// };
