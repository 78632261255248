import gql from 'graphql-tag';

/**
 * @caseDetailsQuery @query - EDD Client Case Details API
 * @description: Amla officer can view the case details from edd dashboard
 */
export const caseDetailsQuery = /* GraphQL */ gql`
    query caseDetails($input: CaseDetailsInput) {
        caseDetails(input: $input) {
            data {
                result {
                    status
                    name
                    clientIdNum
                    idFile
                    idType
                    officerName
                    remark {
                        title
                        content
                    }
                    headings {
                        responseId
                        submittedOn
                        remarkOn
                    }
                    caseSummary {
                        caseDetails {
                            caseId
                            caseNo
                            createdOn
                            closedDate
                            eddTrigger {
                                title
                                content
                            }
                            eddReason {
                                title
                                content
                            }
                            satisfactoryStatus
                        }
                        personalDetails {
                            nationality
                            email
                            phone
                            occupation
                            employerName
                            grossIncome
                        }
                        accountSummary {
                            accountNo
                            accountCreatedOn
                            investorType
                            accountHolder
                            agentId
                            agentCode
                            receipt {
                                name
                                url
                                type
                            }
                            productType
                            fundOption
                        }
                    }
                    assignedAdviser {
                        personalDetails {
                            agentName
                            agentCode
                            nric
                            licenseCode
                            branch
                            region
                            channel
                            omniEnabled
                            bdmName
                            bdmCode
                            bdmEmail
                            uplineName
                            uplineCode
                            rank
                            agency
                        }
                        contactDetails {
                            email
                            mobileNo
                        }
                        addressInformation {
                            address
                            addrTown
                            country
                            postcode
                            state
                        }
                    }
                    response {
                        questionNo
                        questionId
                        title
                        answers
                        amlaRemark
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
