import React from 'react';
import styled from 'styled-components';

interface IDateCellProps {
    value: number;
    selected?: boolean;
    today: boolean;
    highlight?: number;
    disabled?: boolean;
    index: number;
    disableWeekends?: boolean;
    holidays: [string];
    formattedDate: string;
    disableHolidays: boolean;
    disableOldDates: boolean;
    isOldDate: string | number | boolean;
    disableCustomDates?: boolean;
    onClickHandler: (() => void) | undefined;
}
type DateCellDateProps = {
    isToday: boolean;
    selected: boolean;
};
export const DateCell: React.FC<IDateCellProps> = ({
    value,
    selected,
    today,
    highlight,
    index,
    disableWeekends,
    holidays,
    formattedDate,
    disableHolidays,
    disableOldDates,
    isOldDate,
    disableCustomDates,
    onClickHandler,
}: IDateCellProps) => {
    const hlight = highlight ? highlight : 0;
    const radr = index === 6 && hlight;
    const radl = index === 0 && hlight;
    let lr = highlight ? (highlight === 1 ? true : false) : false;
    let rr = highlight ? (highlight === 2 ? true : false) : false;
    lr = highlight === 5 ? true : lr;
    rr = highlight === 6 ? true : rr;
    const filteredDate = holidays.filter((_item, i) => {
        return holidays[i].split(':')[0] == formattedDate && value ? true : false;
    });
    const isHoliday = filteredDate.length ? true : false;
    const _disableOldDates = isOldDate > 0 && disableOldDates;
    // const handleDisable = (): string | undefined => {
    //     let _disable = 'auto';
    //     if (((index == 5 || index === 6) && disableWeekends) || (disableHolidays && isHoliday) || _disableOldDates) {
    //         _disable = 'none';
    //     }
    //     return _disable;
    // };

    return (
        <FlexRow2
            style={{
                pointerEvents:
                    ((index == 5 || index === 6) && disableWeekends) ||
                    (disableHolidays && isHoliday) ||
                    _disableOldDates ||
                    disableCustomDates
                        ? 'none'
                        : undefined,
                opacity:
                    ((index == 5 || index === 6) && disableWeekends) ||
                    (disableHolidays && isHoliday) ||
                    _disableOldDates ||
                    disableCustomDates
                        ? 0.7
                        : undefined,
            }}
        >
            {lr ? <div style={{ width: '6px' }} /> : undefined}
            <FlexRow2
                style={{
                    backgroundColor: hlight > 0 ? (lr || rr ? '#002043' : '#F2F4F6') : undefined,
                    borderTopLeftRadius: lr || radl ? 20 : undefined,
                    borderBottomLeftRadius: lr || radl ? 20 : undefined,
                    borderTopRightRadius: rr || radr ? 20 : undefined,
                    borderBottomRightRadius: rr || radr ? 20 : undefined,
                }}
                onClick={onClickHandler}
                id="date-cell-flex-row"
                data-testid="date-cell-flex-row"
            >
                {rr ? <div style={{ width: '6px' }} /> : undefined}
                <DateCellDate
                    isToday={today ? today : false}
                    selected={lr || rr || selected ? true : false}
                    data-testid="date-cell-date"
                >
                    {value > 0 ? value : null}
                </DateCellDate>
                {lr ? <div style={{ width: '6px' }} /> : undefined}
            </FlexRow2>
            {rr ? <div style={{ width: '6px' }} /> : undefined}
        </FlexRow2>
    );
};

const DateCellDate = styled.div<DateCellDateProps>`
    display: flex;
    width: 32px;
    height: 32px;
    font-weight: ${(props) => (props.selected || props.isToday ? '700' : '400')};
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.isToday || props.selected ? (props.selected ? '#FFFFFF' : '#333333') : '#pink')};
    background-color: ${(props) =>
        props.isToday || props.selected ? (props.selected ? '#002043' : '#F2F4F6') : '#transparent'};
    user-select: none;
    border-radius: ${(props) => (props.selected || props.isToday ? '50%' : '0px')};
    line-height: 32px;
    cursor: pointer;
`;

const FlexRow2 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;
