import gql from 'graphql-tag';
/**
 * @allBranchQuery @query - HQ Dashboard
 * @description: TAB: All branches
 */

export const allBranchQuery = /* GraphQL */ gql`
    query hqCeDashboard($input: DashboardInput) {
        hqCeDashboard(input: $input) {
            data {
                result {
                    branches {
                        branchId
                        name
                        code
                        state
                        dailyCount
                        rerouteCount
                        hardcopyCount
                    }
                    stateList
                    overview {
                        daily
                        reroute
                        extension
                        hardcopy
                    }
                    totalResultCount
                    branchCount
                    extensionCount
                    page
                    pages
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
