import gql from 'graphql-tag';

/**
 * @fundDocumentQuery @query - Fund Document Dashboard
 * @description:  Returns all master fund data
 */

export const allFundDocumentDashboardQuery = gql`
    query fundDocumentDashboard($input: DashboardInput) {
        fundDocumentDashboard(input: $input) {
            data {
                result {
                    transactions {
                        isSeen
                        fileName
                        lastUpdate
                        status
                        fileSize
                        remark
                        fileType
                        uploadedOn
                        totalDocuments
                        fundDocumentId
                    }
                    filters {
                        status
                    }
                    page
                    pages
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
