import React from 'react';

interface Props {
    grow?: number;
}

const FlexSpacer: React.FC<Props> = ({ grow }: Props) => {
    return <div data-testid="flex-spacer" style={{ flexGrow: grow !== undefined ? grow : 1 }} />;
};

export default FlexSpacer;
