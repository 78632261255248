import gql from 'graphql-tag';

/**
 * @FundCheckerApproveMutation @Mutation - Fund data Dashabord
 * @description: mutation to approve or reject fund data
 */

const fundCheckerApproveMutation = /* GraphQL */ gql`
    mutation fundStatus($input: FundStatusInput) {
        fundStatus(input: $input) {
            data {
                result {
                    message
                    status
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;

export default fundCheckerApproveMutation;
