import { IPermissionObject } from './permissionTypes';

export const getInboxSource = (permission: IPermissionObject): string[] => {
    let temp = ['ce'];
    if (permission.branch.grant !== undefined) {
        temp = ['ce'];
    } else {
        if (
            permission.hq.permission.userManagement !== undefined &&
            permission.hq.permission.userManagement.isAll === 'true'
        ) {
            temp = ['systemadmin'];
        }
        if (permission.hq.permission.activityLogs !== undefined && permission.hq.permission.activityLogs.isAll) {
            temp = ['systemadmin'];
        }
        if (permission.hq.permission.productSettings !== undefined && permission.hq.permission.productSettings.isAll) {
            temp = ['finance'];
        }
        if (permission.hq.permission.uploads !== undefined && permission.hq.permission.uploads.isAll) {
            temp = ['finance'];
        }
        if (
            permission.hq.permission.financeDashboard !== undefined &&
            permission.hq.permission.financeDashboard.isAll
        ) {
            temp = ['finance'];
        }
        if (permission.hq.permission.financeCases !== undefined && permission.hq.permission.financeCases.isAll) {
            temp = ['finance'];
        }
        if (permission.hq.permission.eddCase !== undefined && permission.hq.permission.eddCase.isAll) {
            temp = ['amla'];
        }
    }

    return temp;
};
