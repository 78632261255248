import gql from 'graphql-tag';

/**
 * @getOrderSummary @query - HQ Dashboard
 * @description: Returns order summary
 */

export const getRequestDetailsCEApprove = /* GraphQL */ gql`
    query getOrderSummaryV2($input: OrderSummaryInputV2) {
        getOrderSummaryV2(input: $input) {
            data {
                result {
                    requestId
                    approval {
                        name
                        eventType
                        status
                        remarks
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
