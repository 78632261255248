import React from 'react';
import styled from 'styled-components';

interface IContentWrapperWithTitleProps {
    title: string;
    subtitle?: string;
    children: React.ReactNode;
    padding?: string;
    marginTop?: string;
}

export const ContentWrapperWithTitle = (props: IContentWrapperWithTitleProps): JSX.Element => {
    return (
        <ContentWrapperWithTitleWrapper marginTopProp={props.marginTop}>
            <ContainerTitle>
                {props.title} {props.subtitle && <ContainerSubtitle>{props.subtitle}</ContainerSubtitle>}
            </ContainerTitle>

            <ContainerBody paddingAll={props.padding}>{props.children}</ContainerBody>
        </ContentWrapperWithTitleWrapper>
    );
};

const ContentWrapperWithTitleWrapper = styled.div<PaddingContainer>`
    border-radius: 12px;
    border: 1px solid transparent;
    margin-top: ${(props) => (props.marginTopProp ? props.marginTopProp : '2.5rem')};
    display: flex;
    flex-direction: column;
`;

const ContainerTitle = styled.div`
    background: #e7ebf1;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 148%;
    color: #171717;
    padding: 12px 32px;
    gap: 16px;
    /* height: 48px; */
    border-radius: 12px 12px 0px 0px;
`;
const ContainerSubtitle = styled.div`
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: var(--dark-grey-5, #4d4d4d);
`;

interface PaddingContainer extends React.CSSProperties {
    paddingAll?: string;
    marginTopProp?: string;
}

const ContainerBody = styled.div<PaddingContainer>`
    background: #ffffff;
    /* padding: 24px 32px; */
    padding: ${(props) => (props.paddingAll ? props.paddingAll : '24px 32px')};
    border-radius: 0px 0px 12px 12px;
`;
