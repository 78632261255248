import React, { useContext, useEffect } from 'react';
import { LABEL, TextDarkGrey5, TrackOrderSearchOptions } from '../../../constants';
import TrackOrderContext from '../../../context/TrackOrderContext/TrackOrderContext';
import { FilterSearch } from '../../molecules';
import { Modal } from '../Modal';

export const TrackOrderModal = ({
    trackOrder,
    closeTrackModal,
    trackOrderInput,
    setTrackOrderInput,
    trackSearchOptions,
    onTrackOrderSearch,
    ...rest
}: TrackOrderModalProps): JSX.Element => {
    const { setTrackOrderInput: setSearchInput, defaultSearchInput } = useContext(TrackOrderContext);
    useEffect(() => {
        setSearchInput(defaultSearchInput);
    }, []);
    return (
        <Modal
            {...rest}
            primaryBtn={{
                onClick: () => {
                    trackOrder();
                },
                label: LABEL.search,
                primary: true,
                disabled: trackOrderInput.value.trim() === '',
            }}
            secondaryBtn={{
                onClick: () => {
                    closeTrackModal();
                    setTrackOrderInput({ column: TrackOrderSearchOptions[5].value, value: '' });
                },
                label: LABEL.cancel,
            }}
            wrapperStyle={{ width: '820px', maxWidth: 'unset' }}
        >
            <>
                <TextDarkGrey5 style={{ marginBottom: '8px' }}>
                    Enter {TrackOrderSearchOptions.find((x) => x.value === trackOrderInput.column)?.item.toLowerCase()}{' '}
                    to get started.
                </TextDarkGrey5>
                <FilterSearch
                    options={trackSearchOptions}
                    placeHolder={`Search by ${
                        TrackOrderSearchOptions.find((x) => x.value === trackOrderInput.column)?.item
                    }`}
                    handleClearContents={() => {
                        setTrackOrderInput({ column: TrackOrderSearchOptions[5].value, value: '' });
                    }}
                    handleSearch={onTrackOrderSearch}
                    searchValue={trackOrderInput.value}
                    selectValue={trackOrderInput.column}
                    testId={'track-order-search'}
                />
            </>
        </Modal>
    );
};
