/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useRef } from 'react';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { setTimeout } from 'timers';
import { TooltipArrow } from './TooltipArrow';

interface ICustomTooltipProps extends ICustomTooltipStyleProps {
    /**
     * can be an html content or simple string to accomadate simple and complex tool tips
     */
    tooTipContent: JSX.Element | string;
    /**
     * the element over which tool tip wil display
     */
    children: React.ReactNode;
    /**
     * optional style to be applied to tool tip container
     */
    style?: React.CSSProperties;
}

/**
 * Custom tool tip with light and placement position option
 * @category Molecule
 * @param props - tool tip props
 * @returns  Tooltip element which will be wrapped as parent
 */
export const Tooltip = (props: ICustomTooltipProps): JSX.Element => {
    /**
     * Ref for child element
     */
    const childrenRef = useRef(null);
    const tooltipContentWrapperRef = useRef<HTMLSpanElement>(null);
    const toolTipArrowRef = useRef<SVGSVGElement>(null);
    const mouseDelayForShow: { current: NodeJS.Timeout | null } = useRef<NodeJS.Timeout>(null);
    const mouseDelayForHide: { current: NodeJS.Timeout | null } = useRef<NodeJS.Timeout>(null);
    const child = React.Children.only(props.children);
    const [showTooltip, setShowTooltip] = useState(false);
    const childRect = (childrenRef.current as unknown as HTMLElement)?.getBoundingClientRect();

    /**
     * helper function to set state to true to make tooltip visible
     */
    const mouseOnHover = () => {
        clearTimeout(mouseDelayForShow.current as NodeJS.Timeout);
        mouseDelayForShow.current = setTimeout(() => setShowTooltip(true), 150);
    };
    /**
     * helper function to set state to false to make tooltip hide
     */
    const mouseOut = () => {
        clearTimeout(mouseDelayForHide.current as NodeJS.Timeout);
        mouseDelayForHide.current = setTimeout(() => setShowTooltip(false), 150);
    };
    /**
     * function triggered on mousemove to tackle fast moving mouse, it checks if the mouse moved over is part of tooltip or not if not part of tool tip  @function mouseOut else @function mouseOnHover is called
     */
    const checkIfHoveringOverTooltipComponent = (e: any) => {
        if (childrenRef.current) {
            const mouseOver = (childrenRef.current as unknown as HTMLElement).contains(e.target);

            if (!showTooltip && mouseOver) {
                mouseOnHover;
            }

            if (showTooltip && !mouseOver) {
                mouseOut;
            }
        } else if (tooltipContentWrapperRef.current) {
            const mouseOver = tooltipContentWrapperRef.current.contains(e.target);

            if (!showTooltip && mouseOver) {
                mouseOnHover;
            }

            if (showTooltip && !mouseOver) {
                mouseOut;
            }
        } else if (toolTipArrowRef.current) {
            const mouseOver = toolTipArrowRef.current.contains(e.target);

            if (!showTooltip && mouseOver) {
                mouseOnHover;
            }

            if (showTooltip && !mouseOver) {
                mouseOut;
            }
        }
    };

    /**
     * tool_tip root placed outside the root document, this can be automatically created on useffect and destroyed when unmounted as of now it's placed in index.html in public folder
     */
    const tooltipDiv = document.getElementById('root');
    /**
     * based on the child elements bound dimensions adjust position of tool tip according to tooltip placement
     * @param placement - placement of tooltip
     */
    const toolTipContentPosition = (placement: TTooltipPosition): void => {
        if (childrenRef.current && tooltipContentWrapperRef.current) {
            const toolWrapper = tooltipContentWrapperRef.current;
            /**
             * getBound data from view port of the child element
             */
            const childRect = (childrenRef.current as unknown as HTMLElement)?.getBoundingClientRect();
            switch (placement) {
                case 'Top':
                    toolWrapper.style.transform = 'translateX(-50%)';
                    if (childRect) {
                        toolWrapper.style.left =
                            childRect.left + document.documentElement.scrollLeft + childRect.width / 2 + 'px';
                        toolWrapper.style.bottom = -(document.documentElement.scrollTop + childRect.top - 23) + 'px';
                    } else {
                        toolWrapper.style.left = '50%';
                        toolWrapper.style.bottom = '120%';
                    }
                    break;
                case 'Bottom':
                    toolWrapper.style.transform = 'translateX(-50%)';
                    if (childRect) {
                        toolWrapper.style.left =
                            childRect.left + document.documentElement.scrollLeft + childRect.width / 2 + 'px';
                        toolWrapper.style.top = childRect.bottom + document.documentElement.scrollTop - 9 + 'px';
                    } else {
                        toolWrapper.style.left = '50%';
                        toolWrapper.style.top = '120%';
                    }
                    break;
                case 'Left':
                    toolWrapper.style.transform = 'translateY(-50%)';
                    if (childRect) {
                        toolWrapper.style.top =
                            childRect.top + document.documentElement.scrollTop - 17 + childRect.height / 2 + 'px';
                        toolWrapper.style.right = -(childRect.left + document.documentElement.scrollLeft - 7) + 'px';
                    } else {
                        toolWrapper.style.top = '50%';
                        toolWrapper.style.right = '120%';
                    }
                    break;
                case 'Right':
                    toolWrapper.style.transform = 'translateY(-50%)';
                    if (childRect) {
                        toolWrapper.style.top =
                            childRect.top + document.documentElement.scrollTop - 17 + childRect.height / 2 + 'px';
                        toolWrapper.style.left = childRect.right + document.documentElement.scrollLeft + 7 + 'px';
                    } else {
                        toolWrapper.style.top = '50%';
                        toolWrapper.style.left = '120%';
                    }
                    break;
            }
            toolWrapper.style.display = 'block';
        }
    };

    useEffect(() => {
        if (childrenRef.current) {
            (childrenRef.current as unknown as HTMLElement).addEventListener('mouseover', mouseOnHover);
            (childrenRef.current as unknown as HTMLElement).addEventListener('mouseleave', mouseOut);
        }
        document.addEventListener('mousemove', checkIfHoveringOverTooltipComponent);
        toolTipContentPosition(props.placement || 'Bottom');
        return () => {
            (childrenRef.current as unknown as HTMLElement)?.removeEventListener('mouseover', mouseOnHover);
            (childrenRef.current as unknown as HTMLElement)?.removeEventListener('mouseleave', mouseOut);
            document.removeEventListener('mousemove', checkIfHoveringOverTooltipComponent);
            clearInterval(mouseDelayForShow.current as NodeJS.Timeout);
            clearInterval(mouseDelayForHide.current as NodeJS.Timeout);
        };
    }, [childRect, props.placement, showTooltip]);
    return (
        <>
            {React.cloneElement(child as ReactElement, {
                ref: (ref: any) => (childrenRef.current = ref),
            })}
            {/* helps put tooltip outside of div id= root, if you inspect the index-html element you can see a div with id tooltip_root */}
            {showTooltip &&
                ReactDOM.createPortal(
                    <CustomTooltipWrapper>
                        <CustomTootipContentWrapper
                            light={props.light}
                            placement={props.placement ?? 'Bottom'}
                            style={{ ...props.style }}
                            ref={tooltipContentWrapperRef}
                            onMouseOver={mouseOnHover}
                            onMouseLeave={mouseOut}
                        >
                            {props.tooTipContent}
                            <TooltipArrow
                                placement={props.placement ?? 'Bottom'}
                                light={props.light}
                                onMouseEnter={mouseOnHover}
                                onMouseLeave={mouseOut}
                                ref={toolTipArrowRef}
                            />
                        </CustomTootipContentWrapper>
                    </CustomTooltipWrapper>,
                    tooltipDiv as unknown as HTMLElement,
                )}
        </>
    );
};

const CustomTootipContentWrapper = styled.span<ICustomTooltipStyleProps>`
    display: none;
    opacity: 1;
    min-width: 146px;
    max-width: 312px;
    background-color: ${(props) => (props.light ? 'white' : '#171717')};
    color: ${(props) => (props.light ? '#333333' : '#FFFFFF')};
    ${(props) => (props.light ? `border: 1.5px solid #0089EC;` : ``)}
    padding: 16px;
    border-radius: 8px;
    position: absolute;
    z-index: 6;
    -webkit-transform: translateZ(0);
`;

const CustomTooltipWrapper = styled.div`
    position: relative;
    display: inline-block;
`;
