import gql from 'graphql-tag';
/**
 * @allBranchQuery @query - HQ Dashboard
 * @description: TAB: All branches
 */

export const hqceDashboardExtensionExportListCreatedOn = /* GraphQL */ gql`
    query dashboardExportList($input: DashboardExportInput) {
        dashboardExportList(input: $input) {
            data {
                result {
                    hqcedashboardExtension {
                        orderNo
                        transactionRef
                        accountType
                        clientName
                        clientIdNum
                        transactionType
                        paymentMethod
                        fundType
                        totalInvestment {
                            fundCurrency
                            investmentAmount
                        }
                        agentName
                        agentCode
                        createdOn
                        status
                        remarks {
                            title
                            content
                        }
                        targetDate
                        aging
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
