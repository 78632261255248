import React, { useContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import AuthContext from '../../../context/AuthContext/AuthContext';
import { Modal } from '../../organisms/Modal/Modal';
import moment from 'moment';
import { FlexedDiv } from '../../atoms/FlexDiv/FlexDiv';
import { CustomSpacer } from '../../atoms';
import { Fs16SemiBoldSecPrimaryBlack } from '../../../constants';
import * as ROUTES from '../../../routes';
import { useHistory } from 'react-router-dom';
import { signOutFn } from '../../../utils';

interface IIdleTimerProps {
    timeout: number;
}
export const IdleTimer: React.FC<IIdleTimerProps> = ({ timeout }: IIdleTimerProps) => {
    //Context
    const { userLoginContext, userLogoutFn } = useContext(AuthContext);
    const history = useHistory();

    //States
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [autoLogout, setAutoLogout] = useState<boolean>(false);

    const [remaining, setRemaining] = useState(timeout);
    // Commented BELOW CODE as its only for debugging
    // const [elapsed, setElapsed] = useState(0);
    // const [lastActive, setLastActive] = useState<Date | null>(new Date());
    const [isIdle, setIsIdle] = useState(false);

    const handleConfirmLogout = () => {
        userLogoutFn();
        history.push(ROUTES.signIn);
        window.location.reload();
    };

    const handleOnActive = () => {
        // console.log('On Active');
        if (userLoginContext.isAuthenticated) {
            setIsIdle(false);
        }
    };
    const handleOnIdle = () => {
        // console.log('On Idle');
        if (userLoginContext.isAuthenticated) {
            setIsIdle(true);
            handleCloseModal();
            setAutoLogout(true);
            signOutFn();
        }
    };
    const handleCrossTab = (data: string | number) => {
        // console.log('handleCrossTab', data);
        if (data === 'close-timer') {
            pause();
        } else if (data === 'renew-timer') {
            setShowConfirmationModal(false);
        }
    };

    const handlePrimaryButton = () => {
        message('renew-timer');
        handleActive();
        setShowConfirmationModal(false);
    };
    //Hooks
    const { pause, start, activate, message, getRemainingTime } = useIdleTimer({
        timeout,
        promptBeforeIdle: 30000,
        startManually: true,
        crossTab: true,
        syncTimers: 1,
        onActive: handleOnActive,
        onIdle: handleOnIdle,
        stopOnIdle: true,

        onPrompt: () => handleOnPrompt(),
        onMessage: (data) => handleCrossTab(data),
    });
    const handleStart = () => start();
    const handleActive = () => activate();

    // Fn to toggle confirmation modal onPrompt
    const handleOnPrompt = () => {
        // console.log('handleOnPrompt');
        setShowConfirmationModal(true);
    };
    const handleCloseModal = () => {
        // console.log('handleCloseModal');
        pause();
        setShowConfirmationModal(false);
    };
    // Fn to toggle confirmation modal

    useEffect(() => {
        // console.log(isIdle, 'isIdle');
    }, [isIdle]);
    useEffect(() => {
        if (userLoginContext.isAuthenticated) {
            handleStart();
            setRemaining(getRemainingTime());
            // Debugging Code BELOW
            // setLastActive(getLastActiveTime());
            // setElapsed(getElapsedTime());
            setInterval(() => {
                setRemaining(getRemainingTime());
                // Debugging Code BELOW
                // setLastActive(getLastActiveTime());
                // setElapsed(getElapsedTime());
            }, 1000);
        }
    }, [userLoginContext.isAuthenticated]);

    return (
        <div>
            {/* DEBUGGING
             <h1>Timeout: {timeout}ms</h1>
            <h1>Time Remaining: {remaining}</h1>
            <h1>Time Elapsed: {elapsed}</h1>
            <h1>Idle: {isIdle.toString()}</h1> */}

            {showConfirmationModal ? (
                <Modal
                    title={`Your session is about to expire`}
                    icon={`logout-confirmation`}
                    modalActive={showConfirmationModal}
                    setModalActive={handleCloseModal}
                    primaryBtn={{
                        onClick: () => handlePrimaryButton(),
                        label: `Yes, Continue`,
                        primary: true,
                    }}
                    secondaryBtn={{
                        onClick: () => {
                            handleCloseModal(), userLogoutFn(), history.push(ROUTES.signIn), window.location.reload();
                        },
                        label: `No, Logout`,
                    }}
                    contentAlignment="center"
                    testId="idle-timer-modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        <p>You will be logged out in {moment(remaining).unix()} seconds.</p>
                        <p>Do you want to stay signed in?</p>
                    </FlexedDiv>
                </Modal>
            ) : null}

            {autoLogout ? (
                <Modal
                    title={`Your session has expired due to inactivity`}
                    icon={`logout-confirmation`}
                    modalActive={autoLogout}
                    setModalActive={setAutoLogout}
                    primaryBtn={{
                        onClick: handleConfirmLogout,
                        label: `Proceed to Login`,
                        primary: true,
                    }}
                    contentAlignment="center"
                    testId="idle-timer-modal"
                >
                    <FlexedDiv direction="column" style={{ alignItems: 'center' }}>
                        <CustomSpacer space={'1rem'} />
                        <Fs16SemiBoldSecPrimaryBlack style={{ textAlign: 'center' }}>
                            {`Expired on ${moment().format('dddd DD/MM/YYYY, LTS')}`}
                        </Fs16SemiBoldSecPrimaryBlack>
                    </FlexedDiv>
                </Modal>
            ) : null}
        </div>
    );
};
