import gql from 'graphql-tag';

/**
 * @eddDashboardQuery @query - View Dashboard
 * @description: Returns new, reroute and closed cases related to amla
 */
export const eddDashboardQuery = /* GraphQL */ gql`
    query eddDashboard($input: DashboardInput) {
        eddDashboard(input: $input) {
            data {
                result {
                    cases {
                        isSeen
                        caseId
                        caseNo
                        accountType
                        clientIdNum
                        clientName
                        accountNo
                        agentName
                        agentCode
                        bdmName
                        bdmCode
                        status
                        remarks {
                            title
                            content
                        }
                        targetDate
                        lastUpdated
                        submittedOn
                        createdOn
                        aging
                    }
                    newCount
                    rerouteCount
                    closeCount
                    page
                    pages
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
