/* eslint-disable @typescript-eslint/no-explicit-any */
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
/**
 *
 * @param dataSet An array of objects that makes up the data set
 * @param fileName The file name for the xlsx file that is to be generated
 * @param fileExtension The extension for the downloaded file,if undefined default is .xlsx
 */

export function exportXLSX(dataSet: Array<any>, fileName: string, fileExtension?: XLSX.BookType): void {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const workSheet = XLSX.utils.json_to_sheet(dataSet);
    const workBook = { Sheets: { Sheet1: workSheet }, SheetNames: ['Sheet1'] };
    const excelBuffer = XLSX.write(workBook, {
        bookType: fileExtension != undefined ? fileExtension : 'xlsx',
        type: 'array',
    });
    const data = new Blob([excelBuffer], { type: fileType });
    if (fileExtension === undefined) fileExtension = 'xlsx';
    FileSaver.saveAs(data, fileName + '.' + fileExtension);
}
