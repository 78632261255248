import gql from 'graphql-tag';

export const getHolidaysQuery = gql`
    query getHolidays {
        getHolidays {
            data {
                result {
                    holidays
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
