import gql from 'graphql-tag';

export const getDropDownList = gql`
    query dropDownList {
        getDropDownList {
            data {
                result {
                    roles {
                        id
                        value
                    }
                    groups {
                        id
                        value
                    }
                    branches {
                        id
                        value
                    }
                    departments {
                        id
                        value
                    }
                    accessLevel {
                        id
                        value
                    }
                    agency {
                        id
                        value
                    }
                    channel {
                        id
                        value
                    }

                    eventTypes
                }
            }
            error {
                errorCode
                message
                statusCode
            }
        }
    }
`;
