/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useContext, useReducer } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { allFundDocumentDashboardQuery } from '../../_graphql/queries/fundData/documentDashboard/allFundDocument';
import { buildSearchFiltersConfig } from '../../pages/Upload';
import { useDebounce } from '../../customHooks';
import { toTitleCase } from '../../utils';

import AuthContext from '../AuthContext';
import ErrorHandlingContext from '../ErrorHandling/ErrorHandlingContext';
import UploadsContext from './';
import fundCheckerApproveMutation from '../../_graphql/mutations/fundData/checkerApprove';
import bulkUploadStatusMutation from '../../_graphql/mutations/fundData/bulkUploadStatus';

export interface UploadsProviderProps {
    children: React.ReactNode;
}

type Filters = {
    [key: string]: string[];
};
export const UploadsProvider: React.FC<UploadsProviderProps> = ({ children }: UploadsProviderProps) => {
    const _initStates: IUploadStates = {
        date: '',
        dateRange: '',
        status: '',
        sortData: { column: 'fileName', value: 'Ascending' },
        searchbarFilterTags: [],
    };
    // Context
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    //To check for jwt token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    // Dashboard functionality states
    const [tab, setTab] = useState(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [disableResultLimit, setDisableResultLimit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [resultLimit, setResultLimit] = useState(10);

    // Dashboard tab data states
    const [masterFundData, setMasterFundData] = useState<ITableData[]>([]);
    const [navPerUnitData, setNavPerUnitData] = useState<ITableData[]>([]);
    const [distributionData, setDistributionData] = useState<ITableData[]>([]);
    const [documentsData, setDocumentsData] = useState<ITableData[]>([]);
    const [filterData, setFilterData] = useState<IProductSettingsFilterData>();

    // Filter & sorting states
    const [dropDownFilters, setDropDownFilters] = useState<IDropDownFilters[][]>([]);
    const [searchInput, setSearchInput] = useState<ISearchInput>({ value: '', column: 'all' });
    // custom hooks
    const debouncedSearchTerm = useDebounce(searchInput, 700);

    const initialRender = useRef<boolean>(true);

    const handleTempFilter = (tempState: IUploadStates, action: TUploadInitState) => {
        const _tempFilter = { ...tempState };
        switch (action.type) {
            case 'date':
            case 'dateRange':
            case 'status':
                _tempFilter[action.type] = action.payload as string;
                break;
            case 'sortData':
                _tempFilter[action.type] = action.payload as ISortSettingValues;
                break;
            case 'searchbarFilterTags':
                _tempFilter[action.type] = action.payload as ISelectValue[];
                break;
            case 'reset':
                (_tempFilter.date = ''),
                    (_tempFilter.dateRange = ''),
                    (_tempFilter.status = ''),
                    (_tempFilter.sortData = { column: 'fileName', value: 'Ascending' }),
                    (_tempFilter.searchbarFilterTags = []);
                break;
        }
        return _tempFilter;
    };

    const [tempState, dispatch] = useReducer(handleTempFilter, {
        date: _initStates.date,
        dateRange: _initStates.dateRange,
        status: _initStates.status,
        sortData: _initStates.sortData,
        searchbarFilterTags: _initStates.searchbarFilterTags,
    });
    const loadingHandler = () => {
        setLoading((prev) => !prev);
    };
    // Text transform filter values
    const stylizeText = (filters: Filters): Filters => {
        const _filters: Filters = { ...filters };

        Object.keys(_filters).map((filterKey) => {
            switch (filterKey) {
                case 'status':
                    if (_filters[filterKey]) {
                        const _newArray = _filters[filterKey].map((value) => toTitleCase(value));
                        _filters[filterKey] = _newArray;
                    }

                    break;
            }
        });

        return _filters;
    };

    const handleInput = (column: string, value: string) => {
        if (value === '') {
            return undefined;
        }
        return { column, value };
    };

    const getData = () => {
        const filterInput = [];
        const dateInput = handleInput('date', tempState.date);
        let dateRangeInput;
        if (dateInput) {
            dateRangeInput = handleInput(tempState.date, tempState.dateRange);
            if (dateRangeInput) filterInput.push(dateRangeInput);
        }

        const statusInput = handleInput('status', tempState.status);
        if (statusInput) filterInput.push(statusInput);

        return filterInput;
    };

    const filterInput = getData();

    //call query to get all master fund
    const getAllMasterFundData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(allFundDocumentDashboardQuery, {
                    input: {
                        tab: 'masterfund',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: debouncedSearchTerm,
                        sort: [tempState.sortData],
                        filter: filterInput,
                    },
                }),
                idTokenHeader,
            );

            const { data, error } = await response.data.fundDocumentDashboard;

            if (!error) {
                const { pages, transactions, filters } = data.result;
                currentPage > pages && setCurrentPage(1);
                const _filters = stylizeText(filters);

                if (initialRender.current) {
                    const formElements = buildSearchFiltersConfig(_filters);
                    setDropDownFilters(formElements);
                }

                setFilterData(_filters);
                setMasterFundData(transactions);
                setDisableResultLimit(transactions.length === 0);
                setMaxPages(pages);
                setLoading(false);
            } else throw error;
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: _error.errorCode,
                    message: _error.message,
                    title: 'Cannot Fetch Masterfund Data',
                    testId: 'uploads-dashboard-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    //call query to get all NAV
    const getAllNAVPerUnitData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(allFundDocumentDashboardQuery, {
                    input: {
                        tab: 'navperunit',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: debouncedSearchTerm,
                        sort: [tempState.sortData],
                        filter: filterInput,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.fundDocumentDashboard;
            if (!error) {
                const { pages, transactions, filters } = data.result;
                currentPage > pages && setCurrentPage(1);
                const _filters = stylizeText(filters);
                if (initialRender.current) {
                    const formElements = buildSearchFiltersConfig(_filters);
                    setDropDownFilters(formElements);
                }
                setNavPerUnitData(transactions);
                setDisableResultLimit(transactions.length === 0);
                setMaxPages(pages);
                setLoading(false);
            } else throw error;
        } catch (error) {
            setLoading(false);
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: _error.errorCode,
                    message: _error.message,
                    title: 'Cannot Fetch NAV Per Unit Data',
                    testId: 'uploads-dashboard-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    //call query to get all distribution
    const getAllDistributionData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(allFundDocumentDashboardQuery, {
                    input: {
                        tab: 'distribution',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: debouncedSearchTerm,
                        sort: [tempState.sortData],
                        filter: filterInput,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.fundDocumentDashboard;
            if (!error) {
                const { pages, transactions, filters } = data.result;
                currentPage > pages && setCurrentPage(1);
                const _filters = stylizeText(filters);
                if (initialRender.current) {
                    const formElements = buildSearchFiltersConfig(_filters);
                    setDropDownFilters(formElements);
                }
                setDistributionData(transactions);
                setDisableResultLimit(transactions.length === 0);
                setMaxPages(pages);
                setLoading(false);
            } else throw error;
        } catch (error) {
            setLoading(false);
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: _error.errorCode,
                    message: _error.message,
                    title: 'Cannot Fetch Distribution Data',
                    testId: 'uploads-dashboard-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    //call query to get all documents
    const getAllDocumentsData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(allFundDocumentDashboardQuery, {
                    input: {
                        tab: 'documents',
                        page: currentPage,
                        resultLimit: resultLimit,
                        search: debouncedSearchTerm,
                        sort: [tempState.sortData],
                        filter: filterInput,
                    },
                }),
                idTokenHeader,
            );
            const { data, error } = await response.data.fundDocumentDashboard;

            if (!error) {
                const { pages, transactions, filters } = data.result;
                currentPage > pages && setCurrentPage(1);
                const _filters = stylizeText(filters);
                if (initialRender.current) {
                    const formElements = buildSearchFiltersConfig(_filters);
                    setDropDownFilters(formElements);
                }
                setDocumentsData(transactions);
                setDisableResultLimit(transactions.length === 0);
                setMaxPages(pages);
                setLoading(false);
            } else throw error;
        } catch (error) {
            setLoading(false);
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: _error.errorCode,
                    message: _error.message,
                    title: 'Cannot Fetch Documents Data',
                    testId: 'uploads-dashboard-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    //mutation function to approve or reject fund document data
    const fundStatusMutation = async (checkerModal: IFundApproval): Promise<boolean> => {
        try {
            const response: any = await API.graphql(
                graphqlOperation(fundCheckerApproveMutation, {
                    input: checkerModal,
                }),
                idTokenHeader,
            );
            const { error } = await response.data.fundStatus;
            if (error) throw error;
            else {
                // setLoading(false);
                return true;
            }
        } catch (error) {
            setLoading(false);
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: _error.errorCode,
                    message: _error.message,
                    title: `Approving ${checkerModal.tab} request failed`,
                    testId: 'review-import-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
            return true;
        }
    };

    //mutation function to approve or reject bulk documents
    const bulkDocumentsStatusMutation = async (checkerModal: IBulkDocumentsApproval): Promise<boolean> => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(bulkUploadStatusMutation, {
                    input: checkerModal,
                }),
                idTokenHeader,
            );
            const { error } = await response.data.bulkUploadStatus;
            if (!error) {
                setLoading(false);
                return true;
            } else throw error;
        } catch (error) {
            setLoading(false);
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    errorCode: _error.errorCode,
                    message: _error.message,
                    title: `Approving bulk documents request failed`,
                    testId: 'approve-bulkimport-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
            return false;
        }
    };

    const ProviderValue: IUplaodsContext = {
        tab,
        currentPage,
        maxPages,
        dropDownFilters,
        searchInput,
        masterFundData,
        navPerUnitData,
        distributionData,
        documentsData,
        filterData,
        initialRender,
        loading,
        debouncedSearchTerm,
        setTab,
        setCurrentPage,
        setDropDownFilters,
        setSearchInput,
        getAllMasterFundData,
        getAllNAVPerUnitData,
        getAllDistributionData,
        getAllDocumentsData,
        fundStatusMutation,
        bulkDocumentsStatusMutation,
        loadingHandler,
        disableResultLimit,
        tempState,
        dispatch,
        resultLimit,
        setResultLimit,
    };

    return <UploadsContext.Provider value={ProviderValue}>{children}</UploadsContext.Provider>;
};
