export const SearchColumnsProductSettings = {
    allFunds: [
        { item: 'All', value: 'all' },
        { item: 'Code', value: 'fundAbbr' },
        { item: 'Fund Name', value: 'fundName' },
        { item: 'UTMC', value: 'issuingHouse' },
    ],
    allAmp: [
        { item: 'All', value: 'all' },
        { item: 'Code', value: 'fundAbbr' },
        { item: 'AMP Name', value: 'fundName' },
        { item: 'UTMC', value: 'issuingHouse' },
    ],
};
