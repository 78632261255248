import gql from 'graphql-tag';

/**
 * @investorDashboard @query - Investor Dashboard
 * @description:  Returns all investor dashboard data
 */

const investorDashboardQuery = gql`
    query investorDashboard($input: DashboardInput) {
        investorDashboard(input: $input) {
            data {
                result {
                    investorsCount
                    investors {
                        branchName
                        investorList {
                            investmentSummary {
                                paymentMethod: accountFund
                            }
                            profile {
                                clientName: name
                                riskInfo {
                                    riskCategory
                                }
                            }
                            transactionDetails {
                                createdOn: registrationDate
                                agentName: servicingAdviserName
                                agentCode: servicingAdviserCode
                                accountType
                                accountNo
                            }
                            transactionRef
                            lastUpdated
                        }
                    }
                    filters {
                        paymentMethod
                        riskCategory
                    }
                    page
                    pages
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;

export default investorDashboardQuery;
