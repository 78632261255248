import gql from 'graphql-tag';

/**
 * @eddClientAccountsQuery @query - EDD Client Accounts API
 * @description: Amla able to search through list of client’s accounts to pick one for edd view/issue clientId will be obtained from caseSelection endpoint
 */
export const eddClientAccountsQuery = /* GraphQL */ gql`
    query eddClientAccounts($input: EddClientAccountsInput) {
        eddClientAccounts(input: $input) {
            data {
                result {
                    accounts {
                        utaId
                        accountNumber
                        clientId
                        clientName
                        clientIdNum
                        jointId
                        jointName
                        jointIdNum
                        registrationDate
                        servicingAdviserName
                        servicingAdviserCode
                        origin
                        hasEddCase
                    }
                    totalResultCount
                    page
                    pages
                }
            }
            error {
                errorCode
                message
                errorList
            }
        }
    }
`;
