import { SYSTEM_ADMIN } from './labels';

// export const MOCK_STAFF = {
//     staffName: 'Christine R.',
//     staffTitle: ['CE (Branch) Officer', 'CE (HQ) Officer', 'Admin', 'Amla/KYC Officer', 'Finance'],
//     staffId: '880808-08-8888',
// };

export const MOCK_EDD_QA = {
    Response1: [
        {
            Name: 'Alan Poe',
            Status: 'Read',
            title: 'What is the client’s main source of funds for the investments?',
            subtitle: 'To indicate the source of funds in detail. Attach evidence where applicable.',
            questions: [
                {
                    question: 'What is the client’s main source of funds for the investments?',
                    answers: [
                        {
                            is_right: true,
                            response: 'Response',
                            responseAnswer: 'Business',
                            remark: 'Remarks',
                            value: 'Client involved with family business.',
                            document: 'Document',
                            documentName: 'Business_Licences.pdf',
                        },
                        {
                            is_right: true,
                            response: 'Response',
                            responseAnswer: 'Investment',
                            remark: 'Remarks',
                            value: 'Client involved with family business.',
                            document: '',
                            documentName: '',
                        },
                        {
                            is_right: true,
                            response: 'Response',
                            responseAnswer: 'Investment',
                            remark: 'Remarks',
                            value: 'Client invested in a few shares.',
                            document: 'Document',
                            documentName: 'Business_Licences.pdf',
                        },
                    ],
                },
                {
                    question: 'How did you obtain information about the client’s source of funds?',
                    answers: [
                        {
                            is_right: true,
                            response: 'Response',
                            value: 'Client declaration',
                        },
                    ],
                },
                {
                    question: 'How did you obtain information about the client’s source of funds?',
                    answers: [
                        {
                            is_right: true,
                            response: 'Response',
                            responseAnswer: 'Savings',
                            remark: 'Estimated Value',
                            value: 'MYR 150,000',
                        },
                    ],
                },
                {
                    question: 'How did you obtain information about the client’s source of funds?',
                    answers: [
                        {
                            is_right: true,
                            response: 'Response',
                            responseAnswer: 'Savings',
                            remark: 'Estimated Value',
                            value: 'MYR 150,000',
                        },
                        {
                            is_right: true,
                            response: 'Response',
                            responseAnswer: 'Savings',
                            remark: 'Estimated Value',
                            value: 'MYR 150,000',
                        },
                    ],
                },
            ],
        },
    ],
    Response2: [
        {
            title: 'Quiz title',
            questions: [
                {
                    question: 'What is the first question?',
                    answers: [
                        {
                            is_right: true,
                            value: 'This one',
                        },
                        {
                            is_right: false,
                            value: 'The next one',
                        },
                    ],
                },
            ],
        },
    ],
};
export const MOCK_EDD_SATISFACTORY_QA = {
    Response1: [
        {
            Name: 'Alan Poe',
            Status: 'Read',
            Date: '1/07/2021,4:20',
            questions: [
                {
                    question: 'What is the client’s main source of funds for the investments?',
                    subtitle: 'To indicate the source of funds in detail. Attach evidence where applicable.',
                    answers: [
                        {
                            is_right: true,
                            response: 'Response',
                            responseAnswer: 'Business',
                            remark: 'Remarks',
                            value: 'Client involved with family business.',
                            document: 'Document',
                            documentName: 'Business_Licenses.pdf',
                        },
                        {
                            is_right: true,
                            response: 'Response',
                            responseAnswer: 'Investment',
                            remark: 'Remarks',
                            value: 'Client invested in a few shares.',
                            document: 'Document',
                            documentName: 'Business_Licenses.pdf',
                        },
                        {
                            is_right: true,
                            response: 'Response',
                            responseAnswer: 'Investment',
                            remark: 'Remarks',
                            value: 'Client invested in a few shares.',
                            document: 'Document',
                            documentName: 'Business_Licenses.pdf',
                        },
                        {
                            is_right: true,
                            response: 'Response',
                            responseAnswer: 'Other',
                            remark: 'Remarks',
                            value: 'Robbery.',
                            document: '',
                            documentName: '',
                        },
                    ],
                },
                {
                    question: 'How did you obtain information about the client’s source of funds?',
                    answers: [
                        {
                            is_right: false,
                            response: 'Response',
                            responseAnswer: 'Client declaration',
                            remark: 'Remarks',
                            value: 'Client invested in a few shares.',
                        },
                    ],
                },
                {
                    question: 'How did you obtain information about the client’s source of funds?',
                    answers: [
                        {
                            is_right: true,
                            response: 'Response',
                            responseAnswer: 'Savings',
                            remark: 'Estimated Value',
                            value: 'MYR 150,000',
                        },
                    ],
                },
                {
                    question: 'How did you obtain information about the client’s source of funds?',
                    answers: [
                        {
                            is_right: true,
                            response: 'Response',
                            responseAnswer: 'Savings',
                            remark: 'Estimated Value',
                            value: 'MYR 150,000',
                        },
                        {
                            is_right: true,
                            response: 'Response',
                            responseAnswer: 'Savings',
                            remark: 'Estimated Value',
                            value: 'MYR 150,000',
                        },
                    ],
                },
                {
                    question: 'Type of transactions involved:',
                    answers: [
                        {
                            is_right: true,
                            response: 'Response',
                            responseAnswer: 'Securities & Futures',
                            typeOfTransactions: 'Type Of Transactions',
                            typeOfTransactionsValue: [
                                {
                                    title: 'Short Term Investments',
                                    subTitle: '(Less than 1 year)',
                                },
                                {
                                    title: 'Medium Term Investments',
                                    subTitle: '(1-5 years)',
                                },
                            ],
                            remark: '',
                            value: '',
                        },
                        {
                            is_right: true,
                            response: 'Response',
                            responseAnswer: 'Savings',
                            remark: 'Remarks',
                            value: ['Sales/Top-up', 'Transfer'],
                        },
                    ],
                },
            ],
        },
    ],
    Response2: [
        {
            Name: 'Alan',
            Status: 'Read',
            Date: '1/08/2019',
            title: 'Quiz title',
            questions: [
                {
                    question: 'What is the first question?',
                    subtitle: 'To indicate the source of funds in detail. Attach evidence where applicable.',
                    answers: [
                        {
                            is_right: true,
                            value: 'This one',
                            response: 'Response',
                            responseAnswer: 'Savings',
                            remark: 'Remarks',
                        },
                        {
                            is_right: false,
                            value: 'The next one',
                            response: 'Response',
                            responseAnswer: 'Savings',
                            remark: 'Remarks',
                        },
                    ],
                },
            ],
        },
    ],
};

export const MOCK_ADVISER_PROFILE = {
    name: '',
    status: 'Active',
    code: '',
    nric: '',
    details: [
        {
            title: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_PERSONAL_DETAILS,
            sections: [
                {
                    data: [
                        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_HOME_BRANCH, data: '' },
                        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_REGION, data: '' },
                        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_ADVISER_CHANNEL, data: '' },
                        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_OMNI_ENABLED, data: '' },
                        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_ADVISER_RANK, data: '' },
                    ],
                },
                {
                    data: [
                        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_ADVISER_BDM, data: '' },
                        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_ADVISER_BDM_EMAIL, data: '' },
                        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_UPLINE_NAME_CODE, data: '' },
                        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_AGENCY, data: '' },
                    ],
                },
            ],
        },
        {
            title: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_CONTACT_DETAILS,
            sections: [
                {
                    data: [
                        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_EMAIL, data: '' },
                        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_MOBILE, data: '' },
                    ],
                },
            ],
        },
        {
            title: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_ADDRESS_INFO,
            sections: [
                {
                    data: [
                        {
                            label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_PERMANENT_ADDRESS,
                            data: '',
                        },
                        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_POSTCODE, data: '' },
                        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_TOWN, data: '' },
                        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_STATE, data: '' },
                        { label: SYSTEM_ADMIN.ADVISER_PROFILE.LABEL_COUNTRY, data: '' },
                    ],
                },
            ],
        },
    ],
};

export const MOCK_OP_CASES = {
    Reroute: [
        {
            verifiedOnDate: '22/06/2020',
            verifiedOnTime: '2:00:00 PM',
            investorName: 'Edgar Constantine',
            investorID: '880808-08-8888',
            transactionNo: '20AA0001',
            transactionType: 'Sales-AO',
            accountNo: 'S375393593953',
            product: 'UT',
            fundType: 'Cash',
            totalInvestment: '10,0000',
            fundCurrency: 'MYR',
            adviser: 'Alan Poe',
            adviserCode: '0000000001',
            status: 'HQ - Rerouted',
            dueDate: '30/06/2020',
            reasons: [
                {
                    label: 'Payment Not Found',
                    remarks: 'The payment for Kenanga Shariah Growth Opportunities Fund is invalid.',
                },
                {
                    label: 'Others',
                    remarks: 'The NRIC is blurred.',
                },
            ],
            accountType: 'principal',
        },
    ],
    History: [
        {
            lastUpdatedDate: '22/06/2020',
            lastUpdatedTime: '2:00:00 PM',
            investorName: 'Edgar Constantine',
            investorID: '880808-08-8888',
            transactionNo: '20AA0001',
            transactionType: 'Sales-AO5',
            accountNo: 'S375393593953',
            product: 'UT',
            fundType: 'Cash',
            totalInvestment: '10,0000',
            fundCurrency: 'MYR',
            adviser: 'Alan Poe',
            adviserCode: '0000000001',
            status: 'completed',
            dueDate: '30/06/2020',
            reasons: [
                {
                    label: 'Payment Not Found',
                    remarks: 'The payment for Kenanga Shariah Growth Opportunities Fund is invalid.',
                },
                {
                    label: 'Others',
                    remarks: 'The NRIC is blurred.',
                },
            ],
            accountType: 'principal',
        },
    ],
    Rejected: [
        {
            verifiedOnDate: '22/06/2020',
            verifiedOnTime: '2:00:00 PM',
            investorName: 'Edgar Constantine',
            investorID: '880808-08-8888',
            transactionNo: '20AA0001',
            transactionType: 'Sales-AO5',
            accountNo: 'S375393593953',
            product: 'UT',
            fundType: 'Cash',
            totalInvestment: '10,0000',
            fundCurrency: 'MYR',
            adviser: 'Alan Poe',
            adviserCode: '0000000001',
            status: 'rejected',
            dueDate: '30/06/2020',
            reasons: [
                {
                    label: 'Payment Not Found',
                    remarks: 'The payment for Kenanga Shariah Growth Opportunities Fund is invalid.',
                },
                {
                    label: 'Others',
                    remarks: 'The NRIC is blurred.',
                },
            ],
            accountType: 'principal',
        },
    ],
};

export const MOCK_FINACNE_DATA = {
    branchPills: {
        tab: 0,
        label: 'Branch',
        tags: [
            { title: 'All', index: 0 },
            { title: 'Batu Pahat – PW Office', index: 1 },
            { title: 'Damansara Uptown', index: 2 },
            { title: 'Ipoh', index: 3 },
        ],
    },
};
export const dummyTransactionsSummary: ITransactionsSummaryTrx = {
    status: '',
    orderNo: 'xxxxxxxx',
    transactionRef: 'xxxxxxxx',
    totalInvestment: [{ fundCurrency: '', investmentAmount: '' }],
    transactionDetails: {
        title: 'Transaction Details',
        sections: [
            {
                data: [{ label: '', data: '' }],
            },
        ],
    },
    investmentSummary: {
        title: 'Investment Summary',
        sections: [],
    },

    switchingSummary: [
        {
            sections: [],
        },
    ],
    redemptionTypeSummary: {
        sections: [
            {
                data: [],
            },
        ],
    },
    redemptionDetailsSummary: {
        sections: [],
    },

    proceedPayableSummary: {
        sections: [],
    },

    investorOverview: {
        title: 'Investor Overview',
        sections: [
            {
                data: [],
            },
        ],
    },
    // accountSummary: {
    //     title: 'Account Summary',
    //     sections: [
    //         {
    //             data: [
    //                 {
    //                     label: 'Registration Date',
    //                     data: '01/02/2022',
    //                 },
    //                 {
    //                     label: 'Account Type',
    //                     data: 'Joint',
    //                 },
    //                 {
    //                     label: 'Account Number',
    //                     data: '123456',
    //                 },
    //                 {
    //                     label: 'Risk Profile',
    //                     data: 'Low',
    //                 },
    //                 {
    //                     label: 'Servicing Adviser Name & Code',
    //                     data: 'A',
    //                 },
    //                 {
    //                     label: 'BDM Name',
    //                     data: 'Christy Ng',
    //                 },
    //                 {
    //                     label: 'Authorised Signatory',
    //                     data: 'Principal',
    //                 },
    //             ],
    //         },
    //     ],
    // },
    paymentSummary: {
        title: 'Payment Summary',
        sections: [
            {
                data: [{ label: '', data: '' }],
            },
            {
                data: [{ label: '', data: '' }],
            },
        ],
    },
    idNumberInvestor: '',
};

export const mockCROrderSummary = {
    status: '',
    orderNo: 'xxxxxxxx',
    transactionRef: 'xxxxxxxx',
    transactionDetails: {
        title: 'Transaction Details',
        sections: [
            {
                data: [{ label: '', data: '' }],
            },
        ],
    },
    investorOverview: {
        title: 'Investment Summary',
        sections: [
            {
                title: 'fund 1',
                subTitle: '',
                fundCurrency: '',
                amount: '',
                data: [{ label: '', data: '' }],
            },
            {
                title: 'fund 2',
                subTitle: '',
                fundCurrency: '',
                amount: '',
                data: [{ label: '', data: '' }],
            },
        ],
    },
    contactDetails: {
        title: 'Account Summary',
        sections: [
            {
                data: [
                    {
                        label: 'Registration Date',
                        data: '01/02/2022',
                    },
                    {
                        label: 'Account Type',
                        data: 'Joint',
                    },
                    {
                        label: 'Account Number',
                        data: '123456',
                    },
                    {
                        label: 'Risk Profile',
                        data: 'Low',
                    },
                    {
                        label: 'Adviser Name & Code',
                        data: 'A',
                    },
                    {
                        label: 'BDM Name',
                        data: 'Christy Ng',
                    },
                    {
                        label: 'Authorised Signatory',
                        data: 'Principal',
                    },
                ],
            },
        ],
    },
    riskAssesment: {
        title: 'Payment Summary',
        sections: [
            {
                title: 'payment 1',
                data: [{ label: '', data: '' }],
            },
            {
                title: 'payment 2',
                data: [{ label: '', data: '' }],
            },
        ],
    },
    FATCADeclaration: {
        title: 'Payment Summary',
        sections: [
            {
                title: 'FATCADeclaration 1',
                data: [{ label: '', data: '' }],
            },
            {
                title: 'FATCADeclaration 2',
                data: [{ label: '', data: '' }],
            },
        ],
    },
    CRSDeclaration: {
        title: 'Common Reporting Standard (CRS) Declaration',
        sections: [
            {
                sectionTitle: { title: 'CRSDeclaration 1' },
                data: [{ label: '', data: '' }],
            },
            {
                sectionTitle: { title: 'CRSDeclaration 2' },
                data: [{ label: '', data: '' }],
            },
        ],
    },
};

export const dummyDocumentSummary = {
    accountType: '',
    softcopy: {
        required: false,
        documents: [
            {
                mainHeader: '',
                subHeader: '',
                documents: [
                    {
                        title: '',
                        name: '',
                        url: '',
                        type: '',
                        label: '',
                    },
                ],
            },
        ],
    },
    hardcopy: {
        required: false,
        utmcDocs: [
            {
                mainHeader: '',
                subHeader: '',
                documents: [
                    {
                        title: '',
                        name: '',
                        url: '',
                        type: '',
                        label: '',
                    },
                ],
            },
        ],
        accDocs: [
            {
                mainHeader: '',
                subHeader: '',
                documents: [
                    {
                        title: '',
                        name: '',
                        url: '',
                        type: '',
                        label: '',
                    },
                ],
            },
        ],
    },
};
export const errorHandlerInitial: IErrorHandling = {
    errorCode: '',
    title: '',
    message: '',
};

export const MOCK_TRACK_TABLE_DATA: ITableData[] = [
    {
        date: '22/06/2020',
        loginID: 'E-2010-007',
        remarks: [{ title: 'Low Taek Jho', content: ['aaaaaaaaaaaa,\n aaaaaaaaaaaaa aaaaaaaaaaaaa \naaaaaaaaaa'] }],
        status: 'Pending',
    },
    {
        loginID: '015xxxxxx',
        date: '22/06/2020',
        status: 'Submitted',
        remarks: null,
    },
    {
        date: '25/02/2022',
        status: 'Submitted',
        remarks: null,
    },
    {
        date: '22/09/2021',
        loginID: 'E-2017-017',
        remarks: [{ title: 'Low Franc Jho', content: ['aaaaaadddddddddsssss'] }],
        status: 'Overdue-1',
    },
];

export const MOCK_TRACKING_INFO: IStepperTimelineProps[] = [
    {
        title: 'CE (Branch)',
        steps: [
            {
                stepName: 'Completed',
                subText: '01/03/2022',
                finished: false,
            },
            {
                stepName: 'Pending Approval',
                subText: '28/02/2022',
                finished: true,
            },
            {
                stepName: 'In Review',
                subText: '24/02/2022',
                finished: true,
            },
        ],
    },
    {
        title: 'Adviser',
        steps: [
            {
                stepName: 'Completed',
                subText: '01/03/2022',
                finished: true,
            },
        ],
    },
    {
        title: 'CE (HQ)',
        steps: [
            {
                stepName: 'Completed',
                subText: '01/03/2022',
                finished: true,
            },
        ],
    },
    {
        title: 'CE (Branch)',
        steps: [
            {
                stepName: 'Pending Due Date',
                subText: '01/03/2022',
                finished: true,
            },
            {
                stepName: 'Pending Approval',
                subText: '28/02/2022',
                finished: true,
            },
            {
                stepName: 'In Review',
                subText: '24/02/2022',
                finished: true,
            },
            {
                stepName: 'Pending',
                subText: '24/02/2022',
                finished: true,
            },
            {
                stepName: 'BR-Rerouted',
                subText: '24/02/2022',
                finished: true,
            },
            {
                stepName: 'Upcoming',
                subText: '24/02/2022',
                finished: true,
            },
        ],
    },
    {
        title: 'Adviser',
        steps: [
            {
                stepName: 'Submitted',
                subText: '01/03/2022',
                finished: true,
            },
            {
                stepName: 'Pending Approval',
                subText: '28/02/2022',
                finished: true,
            },
            {
                stepName: 'In Review',
                subText: '24/02/2022',
                finished: true,
            },
            {
                stepName: 'Pending Doc & Payment',
                subText: '24/02/2022',
                finished: true,
            },
        ],
    },
];

declare interface ITransactionData {
    transactions: ITransactionWithGrouping | ITransactionWithoutGrouping;
}

declare interface ITransactionWithGrouping {
    /**
     * Information on grouping, if this key is not undefined it means there is further grouping in table before we reach row data
     */
    grouping: TGroupingInfo[];
    /**
     * will be an object containg all data required to full fill a table row/transaction, if there is grouping key the the object won't have data
     */
    data?: undefined;
}
declare interface ITransactionWithoutGrouping {
    /**
     * Information on grouping, if this key is not undefined it means there is further grouping in table before we reach row data
     */
    grouping?: undefined;
    /**
     * will be an object containg all data required to full fill a table row/transaction, if there is grouping key the the object won't have data
     */
    data: ITableData[];
}

declare interface IGroupingInfo {
    /**
     * type of grouping, like grouping by branch,order, cut-off etc
     */
    type: string;
    /**
     * the info on grouping data, it will be based on what type of grouping type it belongs to what kind of data should be show to make relevant ui and functionality
     */
    groupingData: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
    /**
     * transaction data which indicates if there is further grouping or we can show  data, data means row data here
     */
    transactionData: ITransactionWithGrouping | ITransactionWithoutGrouping;
}
declare interface IGroupingOrder {
    type: 'order';
    groupingData: IOrderClassificationData;
    transactionData: ITransactionWithGrouping | ITransactionWithoutGrouping;
}

declare interface IGroupingBranch {
    type: 'branch';
    groupingData: IBranchClassificationData;
    transactionData: ITransactionWithGrouping | ITransactionWithoutGrouping;
}

declare interface IGroupingCutOff {
    type: 'cut-off';
    groupingData: IFundCutOffData;
    transactionData: ITransactionWithGrouping | ITransactionWithoutGrouping;
}

declare type TGroupingInfo = IGroupingBranch | IGroupingCutOff | IGroupingOrder | IGroupingInfo;

declare interface IFundCutOffData {
    time: string;
    suffixString: string;
}

declare interface IBranchClassificationData {
    branch: string;
    branchId?: string;
    style?: React.CSSProperties;
}

declare interface IPrincipal {
    id: string;
    name: string;
}

export const MOCK_TRACKING_TABLE_DATA: ITransactionData = {
    transactions: {
        grouping: [
            {
                type: 'branch',
                groupingData: {
                    branch: 'Batu Pahat – PW Office',
                },
                transactionData: {
                    grouping: [
                        {
                            type: 'cut-off',
                            groupingData: {
                                time: '11:00 AM',
                                suffixString: 'Fund-Cutoff time',
                            },
                            transactionData: {
                                grouping: [
                                    {
                                        type: 'order',
                                        groupingData: {
                                            principal: [
                                                {
                                                    name: 'Edgar Constantine',
                                                    id: '1234',
                                                },
                                                {
                                                    name: 'Jane Constantine',
                                                    id: '1234',
                                                },
                                            ],
                                            orderNumber: '21AA0001',
                                            acNumber: 'R100001',
                                            orders: 2,
                                            sameBranch: true,
                                        },
                                        transactionData: {
                                            data: [
                                                {
                                                    lastUpdated: '1646876240100',
                                                    createdOn: '1646871239003',
                                                    orderNo: 'S0101A001',
                                                    transactionRef: '21AA0001',
                                                    transactionType: 'Redeem',
                                                    fundCode: [
                                                        { fundAbbr: 'KSGOF' },
                                                        { fundAbbr: 'KSG' },
                                                        { fundAbbr: 'KBG' },
                                                    ],
                                                    totalInvestment: [
                                                        {
                                                            fundCurrency: 'AUD',
                                                            investmentAmount: '5,000.00',
                                                        },
                                                        {
                                                            fundCurrency: 'MYR',
                                                            investmentAmount: '1,000.00',
                                                        },
                                                        {
                                                            fundCurrency: 'USD',
                                                            investmentAmount: '1,000.00',
                                                        },
                                                    ],
                                                    agentName: 'Anoop Poe',
                                                    agentCode: '000000000001',
                                                    bdmName: 'Steven Venton',
                                                    status: 'Completed',
                                                    viewable: true,
                                                },
                                            ],
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
            {
                type: 'branch',
                groupingData: {
                    branch: 'Damansara Uptown',
                },
                transactionData: {
                    grouping: [
                        {
                            type: 'cut-off',
                            groupingData: {
                                time: '11:00 AM',
                                suffixString: 'Fund-Cutoff time',
                            },
                            transactionData: {
                                grouping: [
                                    {
                                        type: 'order',
                                        groupingData: {
                                            principal: [
                                                {
                                                    name: 'Mohamed Roslin Albukhary bin Fuzi',
                                                    id: '1234',
                                                },
                                            ],
                                            orderNumber: '21AA0001',
                                            acNumber: 'R100001',
                                            orders: 2,
                                            sameBranch: false,
                                        },
                                        transactionData: {
                                            data: [
                                                {
                                                    lastUpdated: '1646878241214',
                                                    createdOn: '1646878239003',
                                                    orderNo: null,
                                                    transactionRef: null,
                                                    transactionType: 'Redeem',
                                                    fundCode: [{ fundAbbr: 'KSGOF' }],
                                                    totalInvestment: [
                                                        {
                                                            fundCurrency: 'AUD',
                                                            investmentAmount: '5,000.00',
                                                        },
                                                    ],
                                                    agentName: 'Alan Poe',
                                                    agentCode: '0091234655',
                                                    bdmName: 'Steven Venton',
                                                    status: 'Completed',
                                                    viewable: false,
                                                },
                                                {
                                                    lastUpdated: '1646878241214',
                                                    createdOn: '1646878231003',
                                                    orderNo: 'S0101A001',
                                                    transactionRef: '21AA0001',
                                                    transactionType: 'Redeem',
                                                    fundCode: [{ fundAbbr: 'KSGOF' }],
                                                    totalInvestment: [
                                                        {
                                                            fundCurrency: 'AUD',
                                                            investmentAmount: '5,000.00',
                                                        },
                                                    ],
                                                    agentName: 'Alan Poe',
                                                    agentCode: '009988776655',
                                                    bdmName: 'Steven Venton',
                                                    status: 'Completed',
                                                    viewable: false,
                                                },
                                                {
                                                    lastUpdated: '1646878241214',
                                                    createdOn: '1646878239003',
                                                    orderNo: 'S0101A001',
                                                    transactionRef: '21AA0001',
                                                    transactionType: 'Redeem',
                                                    fundCode: [{ fundAbbr: 'KSGOF' }],
                                                    totalInvestment: [
                                                        {
                                                            fundCurrency: 'AUD',
                                                            investmentAmount: '5,000.00',
                                                        },
                                                    ],
                                                    agentName: 'Alan Poe',
                                                    agentCode: '009988776655',
                                                    bdmName: 'Steven Venton',
                                                    status: 'In Review',
                                                    viewable: false,
                                                },
                                                {
                                                    lastUpdated: '1646855241214',
                                                    createdOn: '1646843039003',
                                                    orderNo: '21AA0001',
                                                    transactionRef: 'S0101A001',
                                                    transactionType: 'Redeem',
                                                    fundCode: [{ fundAbbr: 'KSGOF' }],
                                                    totalInvestment: [
                                                        {
                                                            fundCurrency: 'AUD',
                                                            investmentAmount: '5,000.00',
                                                        },
                                                    ],
                                                    agentName: 'Alan Poe',
                                                    agentCode: '009988776655',
                                                    bdmName: 'Steven Venton',
                                                    status: 'Pending Payment',
                                                    viewable: false,
                                                    newTransaction: true,
                                                },
                                            ],
                                        },
                                    },
                                    {
                                        type: 'order',
                                        groupingData: {
                                            principal: [
                                                {
                                                    name: 'Mohamed Roslin Albukhary bin Fuzi',
                                                    id: '1234',
                                                },
                                            ],
                                            orderNumber: '21AA0001',
                                            acNumber: 'R100001',
                                            orders: 2,
                                            sameBranch: false,
                                        },
                                        transactionData: {
                                            data: [
                                                {
                                                    lastUpdated: '1646862241214',
                                                    createdOn: '1646860239003',
                                                    orderNo: 'S0101A001',
                                                    transactionRef: '21AA0001',
                                                    transactionType: 'Redeem',
                                                    fundCode: [{ fundAbbr: 'KSGOF' }],
                                                    totalInvestment: [
                                                        {
                                                            fundCurrency: 'AUD',
                                                            investmentAmount: '5,000.00',
                                                        },
                                                    ],
                                                    agentName: 'Alan Poe',
                                                    agentCode: '009988776655',
                                                    bdmName: 'Steven Venton',
                                                    status: 'Pending Doc & Payment',
                                                    viewable: false,
                                                },
                                                {
                                                    lastUpdated: '1646877241214',
                                                    createdOn: '1646874239003',
                                                    orderNo: 'S0101A001',
                                                    transactionRef: '21AA0001',
                                                    transactionType: 'Redeem',
                                                    fundCode: [{ fundAbbr: 'KSGOF' }],
                                                    totalInvestment: [
                                                        {
                                                            fundCurrency: 'AUD',
                                                            investmentAmount: '5,000.00',
                                                        },
                                                    ],
                                                    agentName: 'Alan Poe',
                                                    agentCode: '123458776655',
                                                    bdmName: 'Steven Venton',
                                                    status: 'Pending Payment',
                                                    viewable: false,
                                                    newTransaction: true,
                                                },
                                            ],
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
        ],
    },
};

export const MOCK_TRACK_ORDERS = {
    data: {
        branchDashboardV2: {
            data: {
                result: {
                    transactions: {
                        grouping: [
                            {
                                type: 'cutOffTime',
                                groupingData: {
                                    key: 'cutOffTime',
                                    value: '04:00PM',
                                },
                                transactionData: {
                                    grouping: [
                                        {
                                            type: 'orderNo',
                                            groupingData: {
                                                principalName: 'Mahani',
                                                principalNumber: '800701457678',
                                                orderNo: '22AA0038',
                                            },
                                            transactionData: {
                                                data: [
                                                    {
                                                        requestId: null,
                                                        lastUpdated: '1644924177112',
                                                        submittedOn: '1644829472571',
                                                        createdOn: '1642410641404',
                                                        orderNo: '22AA0038',
                                                        transactionRef: 'S0118A001',
                                                        accountType: 'JOINT',
                                                        clientName: 'Mahani',
                                                        clientIdNum: '800701457678',
                                                        transactionType: 'Sales-AO',
                                                        fundType: 'AMP',
                                                        paymentMethod: 'CASH',
                                                        fundCategory: 'Conservative',
                                                        totalInvestment: [
                                                            {
                                                                fundCurrency: 'MYR',
                                                                investmentAmount: '5,000.00',
                                                            },
                                                        ],
                                                        agentName: 'Agent H',
                                                        agentCode: 'P1000000, 1000000',
                                                        status: 'In review',
                                                        remarks: [
                                                            {
                                                                title: 'Payment',
                                                                content: ['Payment slip image not clear'],
                                                            },
                                                        ],
                                                        cutOffTime: '04:00PM',
                                                        batchTime: null,
                                                        targetDate: null,
                                                        aging: '29 days',
                                                        approvalType: null,
                                                        utmc: 'KENANGA INVESTORS BERHAD',
                                                        isSeen: true,
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            type: 'orderNo',
                                            groupingData: {
                                                principalName: 'Julia Lee',
                                                principalNumber: '890901168765',
                                                orderNo: '22AA0026',
                                            },
                                            transactionData: {
                                                data: [
                                                    {
                                                        requestId: null,
                                                        lastUpdated: '1643354723591',
                                                        submittedOn: '1643355025965',
                                                        createdOn: '1642389723759',
                                                        orderNo: '22AA0026',
                                                        transactionRef: 'S0119A005',
                                                        accountType: 'INDIVIDUAL',
                                                        clientName: 'Julia Lee',
                                                        clientIdNum: '890901168765',
                                                        transactionType: 'Sales-AO',
                                                        fundType: 'AMP',
                                                        paymentMethod: 'EPF',
                                                        fundCategory: 'Moderate',
                                                        totalInvestment: [
                                                            {
                                                                fundCurrency: 'MYR',
                                                                investmentAmount: '1,000.00',
                                                            },
                                                        ],
                                                        agentName: 'Agent H',
                                                        agentCode: 'P1000000, 1000000',
                                                        status: 'Pending',
                                                        remarks: [
                                                            {
                                                                title: 'Document',
                                                                content: [
                                                                    'NRIC image not clear',
                                                                    'Passport image not clear',
                                                                ],
                                                            },
                                                        ],
                                                        cutOffTime: '04:00PM',
                                                        batchTime: null,
                                                        targetDate: null,
                                                        aging: '47 days',
                                                        approvalType: null,
                                                        utmc: 'KENANGA INVESTORS BERHAD',
                                                        isSeen: true,
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            type: 'orderNo',
                                            groupingData: {
                                                principalName: 'Kaloi',
                                                principalNumber: '53783838',
                                                orderNo: '22AA0037',
                                            },
                                            transactionData: {
                                                data: [
                                                    {
                                                        requestId: 508,
                                                        lastUpdated: '1644818210742',
                                                        submittedOn: '1642565688578',
                                                        createdOn: '1642409320097',
                                                        orderNo: '22AA0037',
                                                        transactionRef: 'S0120A010',
                                                        accountType: 'INDIVIDUAL',
                                                        clientName: 'Kaloi',
                                                        clientIdNum: '53783838',
                                                        transactionType: 'Sales-AO',
                                                        fundType: 'PRS',
                                                        paymentMethod: 'CASH',
                                                        fundCategory: 'GROWTH',
                                                        totalInvestment: [
                                                            {
                                                                fundCurrency: 'MYR',
                                                                investmentAmount: '20,000.00',
                                                            },
                                                        ],
                                                        agentName: 'Agent H',
                                                        agentCode: 'P1000000, 1000000',
                                                        status: 'Pending Approval',
                                                        remarks: null,
                                                        cutOffTime: '04:00PM',
                                                        batchTime: null,
                                                        targetDate: null,
                                                        aging: '30 days',
                                                        approvalType: 'reject',
                                                        utmc: 'KENANGA INVESTORS BERHAD',
                                                        isSeen: true,
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            type: 'orderNo',
                                            groupingData: {
                                                principalName: 'Kejora',
                                                principalNumber: '990909124566',
                                                orderNo: '22AA0070',
                                            },
                                            transactionData: {
                                                data: [
                                                    {
                                                        requestId: null,
                                                        lastUpdated: '1643352281993',
                                                        submittedOn: '1643352446480',
                                                        createdOn: '1642634826998',
                                                        orderNo: '22AA0070',
                                                        transactionRef: 'S0120A030',
                                                        accountType: 'INDIVIDUAL',
                                                        clientName: 'Kejora',
                                                        clientIdNum: '990909124566',
                                                        transactionType: 'Sales-AO',
                                                        fundType: 'PRS',
                                                        paymentMethod: 'CASH',
                                                        fundCategory: 'EQUITY',
                                                        totalInvestment: [
                                                            {
                                                                fundCurrency: 'MYR',
                                                                investmentAmount: '1,000.00',
                                                            },
                                                        ],
                                                        agentName: 'Agent H',
                                                        agentCode: 'P1000000, 1000000',
                                                        status: 'Pending',
                                                        remarks: [
                                                            {
                                                                title: 'Payment',
                                                                content: ['Payment slip image not clear'],
                                                            },
                                                            {
                                                                title: 'Document',
                                                                content: ['Passport image not clear'],
                                                            },
                                                        ],
                                                        cutOffTime: '04:00PM',
                                                        batchTime: null,
                                                        targetDate: null,
                                                        aging: '47 days',
                                                        approvalType: null,
                                                        utmc: 'KENANGA INVESTORS BERHAD',
                                                        isSeen: true,
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            type: 'orderNo',
                                            groupingData: {
                                                principalName: 'Bavarian Filled',
                                                principalNumber: '900607111111',
                                                orderNo: '22AA0083',
                                            },
                                            transactionData: {
                                                data: [
                                                    {
                                                        requestId: 525,
                                                        lastUpdated: '1644826743059',
                                                        submittedOn: '1642672135837',
                                                        createdOn: '1642672045516',
                                                        orderNo: '22AA0083',
                                                        transactionRef: 'S0121A007',
                                                        accountType: 'INDIVIDUAL',
                                                        clientName: 'Bavarian Filled',
                                                        clientIdNum: '900607111111',
                                                        transactionType: 'Sales-AO',
                                                        fundType: 'PRS',
                                                        paymentMethod: 'CASH',
                                                        fundCategory: 'GROWTH',
                                                        totalInvestment: [
                                                            {
                                                                fundCurrency: 'MYR',
                                                                investmentAmount: '5,000.00',
                                                            },
                                                        ],
                                                        agentName: 'Agent J',
                                                        agentCode: 'P1000000, 1000000',
                                                        status: 'Pending Approval',
                                                        remarks: null,
                                                        cutOffTime: '04:00PM',
                                                        batchTime: null,
                                                        targetDate: null,
                                                        aging: '30 days',
                                                        approvalType: 'approve',
                                                        utmc: 'KENANGA INVESTORS BERHAD',
                                                        isSeen: true,
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            type: 'orderNo',
                                            groupingData: {
                                                principalName: 'Earl Ashur',
                                                principalNumber: '970601010101',
                                                orderNo: '22AA0094',
                                            },
                                            transactionData: {
                                                data: [
                                                    {
                                                        requestId: null,
                                                        lastUpdated: '1644927000660',
                                                        submittedOn: '1645065411583',
                                                        createdOn: '1642992428162',
                                                        orderNo: '22AA0094',
                                                        transactionRef: 'S0124A014',
                                                        accountType: 'INDIVIDUAL',
                                                        clientName: 'Earl Ashur',
                                                        clientIdNum: '970601010101',
                                                        transactionType: 'Sales-AO',
                                                        fundType: 'AMP',
                                                        paymentMethod: 'CASH',
                                                        fundCategory: 'Moderate',
                                                        totalInvestment: [
                                                            {
                                                                fundCurrency: 'MYR',
                                                                investmentAmount: '5,000.00',
                                                            },
                                                        ],
                                                        agentName: 'Agent J',
                                                        agentCode: 'P1000000, 1000000',
                                                        status: 'Pending',
                                                        remarks: [
                                                            {
                                                                title: 'Others',
                                                                content: ['22AA0089'],
                                                            },
                                                        ],
                                                        cutOffTime: '04:00PM',
                                                        batchTime: null,
                                                        targetDate: null,
                                                        aging: '29 days',
                                                        approvalType: null,
                                                        utmc: 'KENANGA INVESTORS BERHAD',
                                                        isSeen: true,
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            type: 'orderNo',
                                            groupingData: {
                                                principalName: 'Gerald',
                                                principalNumber: '880901126678',
                                                orderNo: '22AA0118',
                                            },
                                            transactionData: {
                                                data: [
                                                    {
                                                        requestId: 792,
                                                        lastUpdated: '1645665335243',
                                                        submittedOn: '1643014977644',
                                                        createdOn: '1643012039419',
                                                        orderNo: '22AA0118',
                                                        transactionRef: 'S0125A013',
                                                        accountType: 'INDIVIDUAL',
                                                        clientName: 'Gerald',
                                                        clientIdNum: '880901126678',
                                                        transactionType: 'Sales-AO',
                                                        fundType: 'UT',
                                                        paymentMethod: 'CASH',
                                                        fundCategory: 'EQUITY',
                                                        totalInvestment: [
                                                            {
                                                                fundCurrency: 'MYR',
                                                                investmentAmount: '1,000.00',
                                                            },
                                                        ],
                                                        agentName: 'Agent H',
                                                        agentCode: 'P1000000, 1000000',
                                                        status: 'Pending Approval',
                                                        remarks: null,
                                                        cutOffTime: '04:00PM',
                                                        batchTime: null,
                                                        targetDate: null,
                                                        aging: '20 days',
                                                        approvalType: 'reject',
                                                        utmc: 'KENANGA INVESTORS BERHAD',
                                                        isSeen: true,
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            type: 'orderNo',
                                            groupingData: {
                                                principalName: 'Hamzah',
                                                principalNumber: '880807112788',
                                                orderNo: '22AA0139',
                                            },
                                            transactionData: {
                                                data: [
                                                    {
                                                        requestId: 611,
                                                        lastUpdated: '1644928107018',
                                                        submittedOn: '1643088411584',
                                                        createdOn: '1643086248087',
                                                        orderNo: '22AA0139',
                                                        transactionRef: 'S0125A030',
                                                        accountType: 'INDIVIDUAL',
                                                        clientName: 'Hamzah',
                                                        clientIdNum: '880807112788',
                                                        transactionType: 'Sales-AO',
                                                        fundType: 'UT',
                                                        paymentMethod: 'EPF',
                                                        fundCategory: 'EQUITY',
                                                        totalInvestment: [
                                                            {
                                                                fundCurrency: 'MYR',
                                                                investmentAmount: '1,000.00',
                                                            },
                                                        ],
                                                        agentName: 'Agent H',
                                                        agentCode: 'P1000000, 1000000',
                                                        status: 'Pending Approval',
                                                        remarks: null,
                                                        cutOffTime: '04:00PM',
                                                        batchTime: null,
                                                        targetDate: null,
                                                        aging: '29 days',
                                                        approvalType: 'approve',
                                                        utmc: 'KENANGA INVESTORS BERHAD',
                                                        isSeen: true,
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            type: 'orderNo',
                                            groupingData: {
                                                principalName: 'Hujan',
                                                principalNumber: '880801096557',
                                                orderNo: '22AA0144',
                                            },
                                            transactionData: {
                                                data: [
                                                    {
                                                        requestId: null,
                                                        lastUpdated: '1643358385092',
                                                        submittedOn: '1643359221627',
                                                        createdOn: '1643095109678',
                                                        orderNo: '22AA0144',
                                                        transactionRef: 'S0125A032',
                                                        accountType: 'INDIVIDUAL',
                                                        clientName: 'Hujan',
                                                        clientIdNum: '880801096557',
                                                        transactionType: 'Sales-AO',
                                                        fundType: 'PRS',
                                                        paymentMethod: 'CASH',
                                                        fundCategory: 'EQUITY',
                                                        totalInvestment: [
                                                            {
                                                                fundCurrency: 'MYR',
                                                                investmentAmount: '1,000.00',
                                                            },
                                                        ],
                                                        agentName: 'Agent H',
                                                        agentCode: 'P1000000, 1000000',
                                                        status: 'Pending',
                                                        remarks: [
                                                            {
                                                                title: 'Payment',
                                                                content: ['Payment slip image not clear'],
                                                            },
                                                            {
                                                                title: 'Document',
                                                                content: ['NRIC image not clear'],
                                                            },
                                                        ],
                                                        cutOffTime: '04:00PM',
                                                        batchTime: null,
                                                        targetDate: null,
                                                        aging: '47 days',
                                                        approvalType: null,
                                                        utmc: 'KENANGA INVESTORS BERHAD',
                                                        isSeen: true,
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'cutOffTime',
                                groupingData: {
                                    key: 'cutOffTime',
                                    value: '03:00PM',
                                },
                                transactionData: {
                                    grouping: [
                                        {
                                            type: 'orderNo',
                                            groupingData: {
                                                principalName: 'Joe',
                                                principalNumber: 'S678899H',
                                                orderNo: '22AA0160',
                                            },
                                            transactionData: {
                                                data: [
                                                    {
                                                        requestId: 526,
                                                        lastUpdated: '1644826743059',
                                                        submittedOn: '1643166571408',
                                                        createdOn: '1643166173886',
                                                        orderNo: '22AA0160',
                                                        transactionRef: 'S0126A022',
                                                        accountType: 'INDIVIDUAL',
                                                        clientName: 'Joe',
                                                        clientIdNum: 'S678899H',
                                                        transactionType: 'Sales-AO',
                                                        fundType: 'UT',
                                                        paymentMethod: 'CASH',
                                                        fundCategory: 'FIXED INCOME',
                                                        totalInvestment: [
                                                            {
                                                                fundCurrency: 'MYR',
                                                                investmentAmount: '200,000.00',
                                                            },
                                                            {
                                                                fundCurrency: 'MYR',
                                                                investmentAmount: '1,900.00',
                                                            },
                                                            {
                                                                fundCurrency: 'USD',
                                                                investmentAmount: '5,000.00',
                                                            },
                                                        ],
                                                        agentName: 'Agent K',
                                                        agentCode: 'P1000000, 1000000',
                                                        status: 'Pending Approval',
                                                        remarks: null,
                                                        cutOffTime: '03:00PM',
                                                        batchTime: null,
                                                        targetDate: null,
                                                        aging: '30 days',
                                                        approvalType: 'approve',
                                                        utmc: 'KENANGA INVESTORS BERHAD',
                                                        isSeen: true,
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    dailyCount: 289,
                    upcomingCount: 0,
                    hardcopyCount: 3,
                    rerouteCount: 0,
                    historyCount: 382,
                    page: 1,
                    pages: 29,
                    totalResultCount: 289,
                    batchCount: [],
                    typeCount: [0, 289],
                    typeSeen: [true, false],
                },
            },
            error: null,
        },
    },
};

export const MOCK_ORDERSUMMARY_DOCUMENT_DATA = {
    data: {
        getOrderSummary: {
            data: {
                result: {
                    status: 'Pending Doc',
                    orderNumber: '22AA0174',
                    remark: null,
                    extensionRemark: null,
                    documentSummary: {
                        accountType: 'Individual',
                        softcopy: {
                            required: true,
                            documents: [
                                {
                                    mainHeader: 'Miriam Wexler',
                                    subHeader: 'Principal Account Holder',
                                    documents: [
                                        {
                                            label: 'Front of NRIC',
                                            data: 'IMG_0007.HEIC',
                                            onClickData:
                                                'https://test-omni-documents.s3.ap-southeast-1.amazonaws.com/clients/76e7ff70-ae5d-11ec-a63d-87e3661d9826/IDENTIFICATION/nric-28032022-021104.jpeg?AWSAccessKeyId=AKIA5Y3PSOVV275XSTHE&Expires=1648540056&Signature=RrkjRlZW6ZDJGZx0v%2BwmCFQMxcw%3D',
                                        },
                                        {
                                            label: 'Back of NRIC',
                                            data: 'IMG_0007.HEIC',
                                            onClickData:
                                                'https://test-omni-documents.s3.ap-southeast-1.amazonaws.com/clients/76e7ff70-ae5d-11ec-a63d-87e3661d9826/IDENTIFICATION/nric-28032022-021104.jpeg?AWSAccessKeyId=AKIA5Y3PSOVV275XSTHE&Expires=1648540056&Signature=RrkjRlZW6ZDJGZx0v%2BwmCFQMxcw%3D',
                                        },
                                        {
                                            label: 'Certificate of Loss of Nationality',
                                            data: '-',
                                            onClickData: '-',
                                        },
                                    ],
                                },
                            ],
                        },
                        hardcopy: {
                            required: true,
                            utmcDocs: [],
                            accDocs: [
                                {
                                    mainHeader: 'Miriam Wexler',
                                    subHeader: 'Principal Account Holder',
                                    documents: [
                                        {
                                            label: 'W-8BEN Form',
                                            data: '-',
                                            onClickData: '',
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
            },
        },
    },
};

export const MOCK_TRANSACTION_TRACKING = {
    getTransactionTracking: {
        data: {
            result: {
                trackingSummary: [
                    {
                        createdOn: '1642740054471',
                        status: 'Submitted',
                        level: 'Adviser',
                        loginId: null,
                        remark: [],
                    },
                    {
                        createdOn: '1642739906953',
                        status: 'BR - Rerouted',
                        level: 'CE (Branch)',
                        loginId: {
                            name: 'Web User G',
                            username: 'bh.aa',
                        },
                        remark: [
                            {
                                label: 'Payment',
                                remark: ['Total payment amount less than investment amount'],
                            },
                        ],
                    },
                    {
                        createdOn: '1642739897693',
                        status: 'In review',
                        level: 'CE (Branch)',
                        loginId: {
                            name: 'Web User G',
                            username: 'bh.aa',
                        },
                        remark: [],
                    },
                    {
                        createdOn: '1642739800322',
                        status: 'Submitted',
                        level: 'Adviser',
                        loginId: null,
                        remark: [],
                    },
                    {
                        createdOn: '1642739791376',
                        status: 'Pending Payment',
                        level: 'Adviser',
                        loginId: null,
                        remark: [],
                    },
                    {
                        createdOn: '1642739739457',
                        status: 'Pending Payment',
                        level: 'Adviser',
                        loginId: null,
                        remark: [],
                    },
                ],
                statusOverview: [
                    {
                        createdOn: '1642740054471',
                        status: 'Submitted',
                        level: 'Adviser',
                    },
                    {
                        createdOn: '1642739906953',
                        status: 'BR - Rerouted',
                        level: 'CE (Branch)',
                    },
                    {
                        createdOn: '1642739897693',
                        status: 'In review',
                        level: 'CE (Branch)',
                    },
                    {
                        createdOn: '1642739800322',
                        status: 'Submitted',
                        level: 'Adviser',
                    },
                    {
                        createdOn: '1642739791376',
                        status: 'Pending Payment',
                        level: 'Adviser',
                    },
                    {
                        createdOn: '1642739739457',
                        status: 'Pending Payment',
                        level: 'Adviser',
                    },
                ],
            },
        },
        error: null,
    },
};

export const MOCK_INVESTOR_ACC_INFO: IAccountInformation = {
    accountType: 'Principal',
    accountSummary: {
        title: 'Account Summary',
        sections: [
            {
                data: [],
            },
        ],
    },
    addressInformation: {
        title: 'Correspondence Address',
        sections: [
            {
                data: [],
            },
        ],
    },
    bankInformation: {
        title: 'Bank Information',
        sections: [
            {
                sectionTitle: {
                    title: 'Local Bank',
                    titleIcon: 'bank',
                },
                data: [],
            },
            {
                sectionTitle: {
                    title: 'Foreign Bank',
                    titleIcon: 'bank',
                },
                data: [],
            },
        ],
    },
    investorOverview: {
        title: 'Investor Overview',
        sections: [
            {
                sectionTitle: {
                    title: 'Principal Holder',
                    titleIcon: 'avatar',
                },
                data: [
                    {
                        data: 'Edgar Constantine',
                        label: 'Investor Name',
                    },
                ],
            },
        ],
    },
};
