import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { FlexedDiv, RegulartTooltipTemplate, Tooltip } from '../components';
import { LABEL } from '../constants';

interface ITotalInvestmentColumnItem {
    fundCurrency: string;
    investmentAmount: string;
}

/**
 * @TotalInvestmentColumnItem
 */

export const TotalInvestmentColumnItem = ({ data, isSortedColumn }: ITableCustomItem): JSX.Element => {
    const investor: Array<ITotalInvestmentColumnItem> = data.totalInvestment ?? [
        { fundCurrency: '', investmentAmount: '-' },
    ];

    const amount: Array<string> = [];

    for (let index = 0; index < investor.length; index++) {
        const temp = `${investor[index].fundCurrency} ${investor[index].investmentAmount}`;
        amount.push(temp);
    }

    const tooltipMessage = () => {
        const tempMessage: Array<ITooltip> = [];
        amount.map((item) => {
            const tempObject = { label: '' };
            tempObject.label = item;
            tempMessage.push(tempObject);
        });
        return tempMessage;
    };

    return (
        <Fragment>
            <FlexedDiv direction="column" justifyContent="center" alignItems="flex-end" flex={1}>
                {investor.map((item, index) => {
                    return (
                        <Fragment key={`${index}_${data.orderNumber}`}>
                            {index <= 2 ? (
                                <FlexedDiv>
                                    <Currency isBold={isSortedColumn === 'totalInvestment'}>
                                        {item.fundCurrency}
                                    </Currency>{' '}
                                    &nbsp;
                                    <Amount>{item.investmentAmount}</Amount>
                                </FlexedDiv>
                            ) : null}
                        </Fragment>
                    );
                })}
                {investor.length > 3 ? (
                    <Fragment>
                        <Tooltip tooTipContent={<RegulartTooltipTemplate message={tooltipMessage()} />}>
                            <ShowAll>{LABEL.showAll}</ShowAll>
                        </Tooltip>
                    </Fragment>
                ) : null}
            </FlexedDiv>
        </Fragment>
    );
};

const Amount = styled.div`
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: -0.2px;
    color: #002043;
    word-break: break-all;
`;

const Currency = styled.div`
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: -0.2px;
    color: #002043;
    ${(props: IOnPropStyles) =>
        props.isBold &&
        css`
            font-weight: 700;
        `}
`;
const ShowAll = styled.div`
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #0089ec;
`;
