export const titleStyle: React.CSSProperties = {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.388889px',
    color: '#253052',
};

export const subTitleStyle: React.CSSProperties = {
    fontSize: '.75rem',
    lineHeight: '15.6px',
    letterSpacing: '-0.333333px',
    color: '#4D637B',
    mixBlendMode: 'normal',
    fontWeight: 'normal',
};
export const datePickerPopupContainerStyle: React.CSSProperties = {
    minWidth: '22.5rem',
    borderRadius: '31px',
    height: '24rem',
    maxWidth: '50%',
};
export const dateRenderStyle: React.CSSProperties = {
    width: '3rem',
    height: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    padding: '0 .25rem',
};
export const sectionSubTextStyle: React.CSSProperties = {
    color: '#333333',
    fontWeight: 600,
    fontSize: '.875rem',
    lineHeight: '1rem',
    whiteSpace: 'break-spaces',
};
export const investorAmountStyle: React.CSSProperties = {
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.5rem',
    color: 'black',
};
export const investorColumnItemAvatarStyle: React.CSSProperties = {
    borderRadius: '88px',
    background: 'rgba(0,32,67,0.1)',
    padding: '0rem 0.25rem',
    width: '1.667vw',
    height: '1.667vw',
    marginRight: '.5rem',
};
export const investorColumnItemNameStyle: React.CSSProperties = {
    color: '#002043',
    letterSpacing: '-0.2px',
    fontSize: '.875rem',
    lineHeight: '1rem',
};
export const investorColumnItemIdStyle: React.CSSProperties = {
    color: 'rgba(42, 54, 90, 0.8)',
    fontSize: '.75rem',
    lineHeight: '1rem',
};
export const dropDownMenuItemTextStyle: React.CSSProperties = {
    marginLeft: '.5rem',
    fontSize: '.75rem',
    lineHeight: '1rem',
    letterSpacing: '-0.444444px',
    padding: '.5rem',
    color: '#002043',
};
export const investorsContainerPaginationStyle: React.CSSProperties = {
    margin: '0px 0.5rem 0rem 1rem',
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '100%',
    border: '1px solid #eaebee',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};
export const reasonsPageButtons: React.CSSProperties = {
    maxWidth: '16.667vw',
    width: '240px',
    minWidth: '15rem',
    marginRight: '1rem',
    border: '2px solid #C61230',
};
export const datePickerWrapper: React.CSSProperties = {
    width: '22.5rem',
    borderRadius: '31px',
    marginBottom: '3.5rem',
};
export const filterTooltipColor = '#EAF3F0';
export const pd8: React.CSSProperties = { padding: '.5rem' };
export const sh16 = '1.111vw';
export const sh18 = '1.250vw';
export const sh20 = '1.389vw';
export const sh24 = '1.667vw';
export const sh25 = '1.736vw';
export const sh30 = '2.083vw';
export const sh32 = '2.222vw';
export const sh38 = '2.639vw';
export const sh40 = '2.778vw';
export const sh43 = '2.986vw';
export const sh45 = '3.194vw';
export const sh48 = '3.333vw';
export const sh50 = '3.542vw';
export const sh53 = '3.750vw';
export const sh56 = '3.889vw';
export const sh58 = '4.028vw';
export const sh60 = '4.166vw';
export const sh62 = '4.306vw';
export const sh64 = '4.444vw';
export const sh66 = '4.548vw';
export const sh67 = '4.583vw';
export const sh69 = '4.722vw';
export const sh70 = '4.861vw';
export const sh72 = '5vw';
export const sh73 = '5.069vw';
export const sh74 = '5.104vw';
export const sh77 = '5.417vw';
export const sh80 = '5.556vw';
export const sh82 = '5.625vw';
export const sh85 = '5.903vw';
export const sh88 = '6.111vw';
export const sh90 = '6.180vw';
export const sh91 = '6.250vw';
export const sh92 = '6.389vw';
export const sh94 = '6.528vw';
export const sh95 = '6.632vw';
export const sh96 = '6.667vw';
export const sh98 = '6.736vw';
export const sh99 = '6.806vw';
export const sh100 = '6.944vw';
export const sh101 = '7.188vw';
export const sh104 = '7.222vw';
export const sh105 = '7.292vw';
export const sh108 = '7.500vw';
export const sh111 = '7.708vw';
export const sh112 = '7.778vw';
export const sh114 = '7.917vw';
export const sh118 = '7.986vw';
export const sh120 = '8.333vw';
export const sh122 = '8.472vw';
export const sh125 = '8.542vw';
export const sh124 = '8.611vw';
export const sh128 = '8.889vw';
export const sh130 = '9.028vw';
export const sh131 = '9.097vw';
export const sh132 = '9.166vw';
export const sh133 = '9.236vw';
export const sh136 = '9.444vw';
export const sh138 = '9.583vw';
export const sh140 = '9.722vw';
export const sh144 = '10vw';
export const sh148 = '10.278vw';
export const sh150 = '10.417vw';
export const sh152 = '10.556vw';
export const sh156 = '10.833vw';
export const sh158 = '10.972vw';
export const sh160 = '11.111vw';
export const sh164 = '11.389vw';
export const sh168 = '11.667vw';
export const sh170 = '11.806vw';
export const sh174 = '12.083vw';
export const sh176 = '12.222vw';
export const sh182 = '12.639vw';
export const sh184 = '12.778vw';
export const sh187 = '12.986vw';
export const sh188 = '13.056vw';
export const sh191 = '13.264vw';
export const sh192 = '13.333vw';
export const sh200 = '13.889vw';
export const sh205 = '14.236vw';
export const sh208 = '14.444vw';
export const sh210 = '14.583vw';
export const sh216 = '15vw';
export const sh220 = '15.278vw';
export const sh224 = '15.556vw';
export const sh237 = '16.458vw';
export const sh239 = '16.597vw';
export const sh240 = '16.667vw';
export const sh247 = '17.153vw';
export const sh264 = '18.333vw';
export const sh272 = '18.889vw';
export const sh288 = '20vw';
export const sh296 = '20.556vw';
export const sh453 = '31.458vw';
export const sh784 = '54.444vw';
