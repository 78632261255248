import gql from 'graphql-tag';

/**
 * @transactionStatus @query - Branch Dashboard
 * @description: After transaction is reviewed, branch can approve, reject or reroute
 */
export const transactionStatusMutation = /* GraphQL */ gql`
    mutation transactionStatus($input: TransactionStatusInput) {
        transactionStatus(input: $input) {
            data {
                result {
                    status
                    message
                    transactionAffected
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;

export default transactionStatusMutation;
