import React, { useState, Fragment, useEffect, useRef } from 'react';
import { FlexedDiv, Modal } from '../../../components';
import { getFileSize } from '../../../utils';
import { IcoMoon } from '../../../icons';
import styled from 'styled-components';
import { LABEL } from '../../../constants';
import { getFileNameAndType } from '../../../utils/getFilename';

type ProgressProps = {
    width: number;
};
type FileItem = {
    percentage: number;
    fileName: string;
    fileSize: number;
    isValid: boolean;
    errorMessage: string;
};

type singleFile = {
    fileName: string;
    fileSize: number;
    isValid: boolean;
    errorMessage: string;
};
type CheckMarkProps = {
    isFileNameExist: boolean;
};
interface DocumentUploaderProps {
    uploadedFileHandle?: (file: File) => void;
    uploadBulkFileHandle?: (fileList: File[]) => void;
    isMultiple: boolean;
    isFileNameExist?: boolean;
    handleUploadReset?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const DocumentUploader: React.FC<DocumentUploaderProps> = ({
    uploadedFileHandle,
    uploadBulkFileHandle,
    isMultiple,
    isFileNameExist,
    handleUploadReset,
}: DocumentUploaderProps) => {
    const [progressWidth, setProgressWidth] = useState(0);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [selectedSingleFile, setSelectedSingleFile] = useState<singleFile>({
        fileName: '',
        fileSize: 0,
        isValid: true,
        errorMessage: '',
    });
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [progressInfos, setProgressInfos] = useState<FileItem[]>([]);
    const [showModal, setShowModal] = useState(false);
    const reader = new FileReader();
    const singleFileInputRef = useRef<HTMLInputElement>(null);
    //Fn to handle file select
    const handleFileChange = (e: React.FormEvent<HTMLInputElement>): void => {
        handleUploadReset && handleUploadReset(false);
        const file = (e.target as HTMLInputElement).files;

        if (file != null) {
            let fileSizeCheck = false;
            let fileType = false;
            fileType =
                file[0].type === 'application/vnd.ms-excel' ||
                file[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                file[0].type === 'text/csv'
                    ? true
                    : false;
            fileSizeCheck = file[0].size <= 5242880 ? true : false;
            // check uploaded file type
            if (fileType) {
                // check uploaded file size
                if (fileSizeCheck) {
                    setProgressWidth(0);

                    setSelectedSingleFile({
                        ...selectedSingleFile,
                        fileName: file[0].name,
                        fileSize: file[0].size,
                        isValid: true,
                    });

                    reader.addEventListener(
                        'progress',
                        (e) => {
                            const percentage = (e.loaded / file[0].size) * 100;
                            setIsUploading(true);
                            setProgressWidth(percentage);
                        },
                        false,
                    );
                    reader.addEventListener(
                        'loadend',
                        () => {
                            setIsUploading(false);
                            setIsUploaded(true);

                            uploadedFileHandle && uploadedFileHandle(file[0]);
                        },
                        false,
                    );
                    if (file.length > 0) {
                        reader.readAsDataURL(file[0]);
                    }
                } else {
                    setSelectedSingleFile({
                        ...selectedSingleFile,
                        isValid: false,
                        errorMessage: 'File size is too big. Please try again.',
                    });
                }
            } else {
                setSelectedSingleFile({
                    ...selectedSingleFile,
                    isValid: false,
                    errorMessage: 'Uploaded an invalid file type. Please try again.',
                });
            }
        }
    };
    //Fn to handle multiple file select
    const handleMultipleFiles = (e: React.FormEvent<HTMLInputElement>): void => {
        e.preventDefault();
        const files: FileList | null = (e.target as HTMLInputElement).files;
        if (files !== null) {
            // get files info ito an array from input filelist

            if (files.length <= 30) {
                const _progressInfos: FileItem[] = [];
                const _files: File[] = [];
                Object.values(files).forEach((file) => {
                    let fileSizeCheck = false;
                    let fileType = false;
                    fileType = file.type === 'application/pdf' ? true : false;
                    fileSizeCheck = file.size <= 5242880 ? true : false;

                    //check file type validation
                    if (fileType) {
                        //check file type and size validation
                        if (fileSizeCheck) {
                            const _fileName = getFileNameAndType(file.name)[0];
                            const documentType = getFileNameAndType(file.name)[1];
                            //check fund document name macthes format
                            if (documentType && documentType.includes('.pdf') && _fileName !== undefined) {
                                _progressInfos.push({
                                    percentage: 0,
                                    fileName: file.name,
                                    fileSize: file.size,
                                    isValid: true,
                                    errorMessage: '',
                                });
                                _files.push(file);
                            } else {
                                _progressInfos.push({
                                    percentage: 0,
                                    fileName: file.name,
                                    fileSize: file.size,
                                    isValid: false,
                                    errorMessage: `${file.name} should be a valid fund document`,
                                });
                            }
                        } else {
                            _progressInfos.push({
                                percentage: 0,
                                fileName: file.name,
                                fileSize: file.size,
                                isValid: false,
                                errorMessage: `${file.name} file size is too big. Please try again.`,
                            });
                        }
                    } else {
                        _progressInfos.push({
                            percentage: 0,
                            fileName: file.name,
                            fileSize: file.size,
                            isValid: false,
                            errorMessage: `${file.name} is an invalid file type. Please try again.`,
                        });
                    }
                });
                setSelectedFiles(_files);
                setProgressInfos(_progressInfos);
            } else {
                handleShowConfirmation();
            }
        }
    };
    //start reading each file
    const readMultipleFileHanlder = (file: File, index: number) => {
        const reader = new FileReader();
        const _progressInfos = [...progressInfos];

        try {
            if (file) {
                progressInfos.map((progressItem, progressIndex) => {
                    if (progressItem.fileName === file.name) {
                        reader.readAsDataURL(file);

                        reader.addEventListener(
                            'progress',
                            (e) => {
                                const percentage = (e.loaded / file.size) * 100;
                                _progressInfos[progressIndex].percentage = percentage;

                                setProgressInfos(_progressInfos);
                            },
                            false,
                        );

                        reader.onloadend = () => {
                            if (index + 1 === selectedFiles.length) {
                                uploadBulkFileHandle && selectedFiles && uploadBulkFileHandle(selectedFiles);
                            }
                        };
                    }
                });
            }
        } catch (err) {}
    };
    //reset component
    const resetFileuplaoder = () => {
        if (singleFileInputRef.current) singleFileInputRef.current.value = '';
        setIsUploaded(false);
        handleUploadReset && handleUploadReset(true);
        setSelectedSingleFile({ ...selectedSingleFile, fileName: '', fileSize: 0, isValid: true, errorMessage: '' });
    };
    useEffect(() => {
        // read each file in filelist
        selectedFiles && Object.values(selectedFiles).forEach((file, index) => readMultipleFileHanlder(file, index));
    }, [selectedFiles]);
    const handleShowConfirmation = () => {
        setShowModal(!showModal);
    };
    return (
        <Fragment>
            <DocumentWrapper data-testid="docs-wrapper">
                <DocumentInner>
                    <FlexedDiv direction="row" justifyContent="space-between" alignItems="center">
                        <Group>
                            <IconPlaceholder data-testid="icon-placeholder">
                                <IcoMoon name="file" size="28px" />
                                {isUploaded && (
                                    <CheckMark
                                        isFileNameExist={isFileNameExist !== undefined ? isFileNameExist : false}
                                    >
                                        <IcoMoon
                                            name={isFileNameExist !== undefined && isFileNameExist ? 'close' : 'check'}
                                            size="10px"
                                            color="#fff"
                                            data-testid="icon-icon"
                                        />
                                    </CheckMark>
                                )}
                            </IconPlaceholder>
                            {isUploaded ? (
                                <Title>{isMultiple ? 'Please select files' : selectedSingleFile.fileName}</Title>
                            ) : (
                                <Title>{isMultiple ? 'Please select files' : 'Please select a file'}</Title>
                            )}
                        </Group>
                        <Group style={{ alignItems: 'center' }}>
                            {!isFileNameExist && isUploaded && <Badge>Uploaded</Badge>}
                            <IconPlaceholderStyled>
                                <form data-testid="form">
                                    {isMultiple ? (
                                        <input
                                            type="file"
                                            multiple
                                            onChange={(e) => handleMultipleFiles(e)}
                                            data-testid="form-input"
                                            accept=".pdf"
                                            id="multiple-upload-btn"
                                        />
                                    ) : (
                                        <input
                                            type="file"
                                            onChange={(e) => handleFileChange(e)}
                                            data-testid="form-input"
                                            id="single-upload-btn"
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            ref={singleFileInputRef}
                                        />
                                    )}
                                    {isUploaded ? (
                                        <ResetIcon onClick={resetFileuplaoder} id="delete-file-icon">
                                            <IcoMoon name="trash" size="28px" />
                                        </ResetIcon>
                                    ) : (
                                        <IconPlaceholder>
                                            <IcoMoon name="upload" size="28px" />
                                        </IconPlaceholder>
                                    )}
                                </form>
                            </IconPlaceholderStyled>
                        </Group>
                    </FlexedDiv>
                    <FlexedDiv direction="column">
                        {isMultiple ? (
                            <Fragment>
                                {progressInfos &&
                                    progressInfos.map((file: FileItem, index) => (
                                        <FileItem key={index}>
                                            <FlexedDiv justifyContent="space-between">
                                                <SubTitle>
                                                    {file.fileName !== undefined
                                                        ? `${file.fileName} - ${getFileSize(file.fileSize.toString())}`
                                                        : 'No file uploaded - Max file size: 5MB'}
                                                </SubTitle>
                                                <Percentage>{file.percentage.toFixed(0)}%</Percentage>
                                            </FlexedDiv>
                                            <ProgressBarStyles id="progress">
                                                <ProgressBar>
                                                    <Progress width={file.percentage}></Progress>
                                                </ProgressBar>
                                            </ProgressBarStyles>
                                            {!file.isValid && (
                                                <Error>
                                                    <IcoMoon
                                                        name="clear"
                                                        size="21px"
                                                        color="#E84C3D"
                                                        data-testid="icon-icon"
                                                    />
                                                    <ErrorMessage>{file.errorMessage}</ErrorMessage>
                                                </Error>
                                            )}
                                        </FileItem>
                                    ))}
                            </Fragment>
                        ) : (
                            <FileItem>
                                <FlexedDiv justifyContent="space-between">
                                    <SubTitle>
                                        {selectedSingleFile.fileName !== ''
                                            ? `${selectedSingleFile.fileName} - ${getFileSize(
                                                  selectedSingleFile.fileSize.toString(),
                                              )}`
                                            : 'No file uploaded - Max file size: 5MB'}
                                    </SubTitle>
                                    {isUploading && <Percentage>{progressWidth.toFixed(0)}%</Percentage>}
                                </FlexedDiv>
                                {isUploading && (
                                    <ProgressBarStyles id="progress">
                                        <ProgressBar>
                                            <Progress width={progressWidth}></Progress>
                                        </ProgressBar>
                                    </ProgressBarStyles>
                                )}
                            </FileItem>
                        )}
                    </FlexedDiv>
                </DocumentInner>
            </DocumentWrapper>
            {!selectedSingleFile.isValid && (
                <Error>
                    <IcoMoon name="clear" size="21px" color="#E84C3D" data-testid="icon-icon" />
                    <ErrorMessage>{selectedSingleFile.errorMessage}</ErrorMessage>
                </Error>
            )}

            {showModal ? (
                <Modal
                    title={'Can’t bulk upload 100 files to OMNI'}
                    modalActive={showModal}
                    setModalActive={setShowModal}
                    primaryBtn={{
                        onClick: () => {
                            setShowModal(!showModal);
                        },
                        label: LABEL.okay,
                        primary: true,
                        size: 'large',
                    }}
                    icon="reject-illustration"
                    contentAlignment="center"
                    testId="custom_modal"
                >
                    <FlexedDiv direction="column" style={{ textAlign: 'center' }}>
                        {'Please reduce the number of uploaded files to 30 or below.'}
                    </FlexedDiv>
                </Modal>
            ) : null}
        </Fragment>
    );
};
const ResetIcon = styled.div`
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
`;
const CheckMark = styled.div<CheckMarkProps>`
    border-radius: 50%;
    background-color: ${(props) => (props.isFileNameExist ? 'rgb(232, 76, 61)' : 'rgb(46, 204, 130)')};
    position: absolute;
    width: 14px;
    height: 14px;
    bottom: 0px;
    display: flex;
    right: 0px;
    justify-content: center;
    align-items: center;
`;
const Badge = styled.span`
    border-radius: 6px;
    padding: 4px 8px;
    background-color: rgb(167 167 167 / 10%);
    margin-right: 1rem;
    display: flex;
    font-weight: 600;
    font-size: 0.8rem;
`;
const ErrorMessage = styled.span`
    color: #e84c3d;
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    word-break: break-word;
`;
const Error = styled.div`
    width: 100%;
    padding: 8px 0px;
`;
const FileItem = styled.div`
    width: 100%;
    padding: 0px 3rem 0px 35px;
    margin: 0.5rem 0px;
`;

const DocumentInner = styled.div`
    width: 100%;
`;
const ProgressBarStyles = styled.div`
    width: 100%;
`;
const ProgressBar = styled.div`
    border-radius: 10px;
    background-color: #eaebee;
    margin-top: 12px;
`;
const Progress = styled.div<ProgressProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 10px;
    border-radius: 10px;
    background-color: #2ecc82;
    transition: width 0.3s ease;
    width: ${(props) => props.width}%;
`;
const DocumentWrapper = styled.div`
    padding: 1rem 1.2rem;
    background-color: #fff;
    border-radius: 8px;
    max-width: 680px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 2px 8px rgba(0, 32, 67, 0.04), 6px 6px 16px rgba(0, 32, 67, 0.04);
`;
const Group = styled.div`
    display: flex;
    align-items: center;
`;

const IconPlaceholder = styled.div`
    display: inline-block;
    text-align: center;
    position: relative;
    cursor: pointer;
`;
const IconPlaceholderStyled = styled(IconPlaceholder)`
    border: 1px solid #c6cbd4;
    height: 40px;
    width: 40px;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    border-radius: 36px;
    position: relative;
    input {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        top: 0;
        left: 0;
    }
`;

const Title = styled.h1`
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0px;
    font-weight: 700;
    padding-left: 8px;
`;
const SubTitle = styled.span`
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: normal;
    color: rgba(42, 54, 90, 0.8);
    font-size: 0.75rem;
    max-width: 320px;
`;
const Percentage = styled.span`
    font-weight: normal;
    color: rgba(42, 54, 90, 0.8);
    font-size: 0.75rem;
`;

export default DocumentUploader;
