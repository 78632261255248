import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { IcoMoon } from '../../../icons';
import { CustomSpacer } from '../../atoms';
import { LABEL } from '../../../constants';
import { Tooltip } from '../../molecules';

interface ICustomDropdownProps {
    children: ReactNode;
    data?: ITableData;
    disableDropdown?: boolean;
    handleReset?: () => void;
    hideReset?: boolean;
    items: Array<IDropdownItemV2>;
    maxWidth?: string;
    noMinWidth?: boolean;
    resetAllLabel?: string;
    resetPosition?: 'top' | 'bottom';
    selected?: string | string[];
    selectIcon?: boolean;
    showDivider?: boolean;
    size?: 'normal' | 'large';
    iconPosition?: 'before' | 'after';
}

export interface IDropdownItemV2 {
    color?: string;
    danger?: boolean;
    disabled?: boolean;
    handleItem: (item: string, rawData: ITableData | undefined, value: string | undefined) => void;
    icon?: string;
    item: string;
    rawData?: ITableData;
    testId?: string;
    tooltipMessage?: string;
    value?: string;
}

export const CustomDropdownV2 = ({
    children,
    data,
    disableDropdown,
    handleReset,
    hideReset,
    items,
    maxWidth,
    noMinWidth,
    resetAllLabel,
    resetPosition,
    selected,
    showDivider,
    size,
    iconPosition,
}: ICustomDropdownProps): JSX.Element => {
    const [show, setShow] = useState<boolean>(false);
    const node = useRef<HTMLDivElement>(null);
    const _showDivider = showDivider == undefined ? true : showDivider;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickOutside = (e: any) => {
        if (node.current?.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
        setShow(false);
    };

    useEffect(() => {
        if (show) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [show]);

    const showReset = hideReset ? false : true;
    const menuItem = ({ icon, item, danger, handleItem, color, disabled, testId, value }: IDropdownItemV2) => {
        const iconName = icon !== undefined ? icon : 'check';
        const currentColor = color !== undefined ? color : '#002043';
        return (
            <DropdownItem
                isTrue={disabled ? disabled : false}
                onClick={() => (disabled ? undefined : handleItem(item, data, value))}
                id={testId}
            >
                {icon !== undefined && (iconPosition !== 'after' || iconPosition === undefined) ? (
                    <IcoMoon
                        style={{ marginRight: '0.5rem' }}
                        name={iconName}
                        size={size === 'large' ? '1.5rem' : '1rem'}
                        color={danger ? '#e84c3d' : currentColor}
                    />
                ) : null}
                <DropdownItemText size={size} isTrue={danger !== undefined ? danger : false}>
                    {item}
                </DropdownItemText>
                {icon !== undefined && iconPosition === 'after' ? (
                    <IcoMoon
                        style={{ marginRight: '0.5rem' }}
                        name={iconName}
                        size={size === 'large' ? '1.5rem' : '1rem'}
                        color={danger ? '#e84c3d' : currentColor}
                    />
                ) : (
                    <CustomSpacer style={{ flexGrow: 1 }} horizontal={true} space={'.375rem'} />
                )}

                {value !== undefined ? (
                    selected && selected[0] === item && selected[1] === value ? (
                        <IcoMoon name={'success-green'} size="1.5rem" />
                    ) : (
                        ''
                    )
                ) : selected === item ? (
                    <IcoMoon name={'success-green'} size="1.5rem" />
                ) : (
                    ''
                )}
            </DropdownItem>
        );
    };
    const topOffset = node.current?.clientHeight !== undefined ? node.current?.clientHeight : 0;
    return (
        <Fragment>
            <DropdownContainer
                onClick={() => setShow(!show)}
                ref={node}
                disabled={disableDropdown}
                id="setShowBtn"
                data-testid="setShowBtn"
            >
                {children}

                <DropdownMenu
                    isTrue={show}
                    topOffset={topOffset}
                    noMinWidth={noMinWidth ? noMinWidth : false}
                    maxWidth={maxWidth}
                    data-testid="visible-menu"
                    id={`dropdown-menu-${show ? 'visible' : 'hidden'}`}
                >
                    {showReset && resetPosition === 'top' ? (
                        <DropdownItem
                            isTrue={false}
                            onClick={handleReset}
                            data-testid="handleResetAllBtn"
                            id="handleResetAllBtn"
                            style={{
                                boxShadow: 'inset 0px -1px 0px #f2f4f6',
                            }}
                        >
                            <DropdownItemText isTrue={false}>
                                {resetAllLabel !== undefined ? resetAllLabel : LABEL.resetAll}
                            </DropdownItemText>
                            <CustomSpacer style={{ flexGrow: 1 }} horizontal={true} space={'.375rem'} />
                            {selected === '' ? <IcoMoon name={'success-green'} size="1.5rem" /> : null}
                        </DropdownItem>
                    ) : (
                        <HiddenDiv data-testid="handleResetAllBtn-does-not-exist" />
                    )}
                    {items.map(
                        ({ icon, item, danger, handleItem, color, disabled, tooltipMessage, testId, value }, index) => {
                            return (
                                <div
                                    key={`$item_${index}`}
                                    style={{
                                        boxShadow:
                                            _showDivider && index !== items.length - 1
                                                ? 'inset 0px -1px 0px #f2f4f6'
                                                : '',
                                    }}
                                >
                                    {tooltipMessage !== undefined ? (
                                        <Tooltip
                                            placement="Left"
                                            tooTipContent={tooltipMessage}
                                            style={{ zIndex: 100 }}
                                        >
                                            <div>
                                                {menuItem({
                                                    icon,
                                                    item,
                                                    danger,
                                                    handleItem,
                                                    color,
                                                    value,
                                                    disabled,
                                                    testId,
                                                })}
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        <Fragment>
                                            {menuItem({
                                                icon,
                                                item,
                                                danger,
                                                handleItem,
                                                value,
                                                color,
                                                testId,
                                                disabled,
                                            })}
                                        </Fragment>
                                    )}
                                </div>
                            );
                        },
                    )}
                    {showReset && resetPosition === 'bottom' ? (
                        <DropdownItem
                            isTrue={false}
                            onClick={handleReset}
                            data-testid="handleResetAllBtn"
                            id="handleResetAllBtn"
                        >
                            <DropdownItemText isTrue={false}>
                                {resetAllLabel !== undefined ? resetAllLabel : LABEL.resetAll}
                            </DropdownItemText>
                            <CustomSpacer style={{ flexGrow: 1 }} horizontal={true} space={'.375rem'} />
                            <IcoMoon name={'success-green'} size="1.5rem" />
                        </DropdownItem>
                    ) : (
                        <HiddenDiv data-testid="handleResetAllBtn-does-not-exist" />
                    )}
                </DropdownMenu>
            </DropdownContainer>
        </Fragment>
    );
};
interface renderStyle {
    isTrue: boolean;
    size?: 'normal' | 'large';
}
interface renderDropDownMenuStyle {
    isTrue: boolean;
    topOffset: number;
    noMinWidth: boolean;
    maxWidth?: string;
}
const DropdownMenu = styled.div<renderDropDownMenuStyle>`
    visibility: hidden;
    /* transition: all 0.5s ease-in; */
    position: absolute;
    top: ${(props) => `${props.topOffset}px`};
    right: 0;
    z-index: 100;
    border: 1px solid #f2f4f6;
    box-sizing: border-box;
    box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: #fff;
    min-width: ${(props) => (props.noMinWidth ? '9rem' : '10.5rem')};
    max-height: 20rem;
    overflow-y: auto;
    width: max-content;
    padding: 0.5rem 0rem;
    ${(props) =>
        props.isTrue &&
        css`
            visibility: visible;
        `}
    ${(props) =>
        props.maxWidth !== undefined &&
        css`
            max-width: ${props.maxWidth};
            width: 100%;
        `}
`;
const DropdownItem = styled.div`
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    display: flex;
    align-items: center;
    &:hover {
        background: #f2f4f6;
        cursor: pointer;
    }
    ${(props: renderStyle) =>
        props.isTrue &&
        css`
            opacity: 0.6;
            &:hover {
                background: #fff;
                cursor: default;
            }
        `}
    justify-content: space-between;
    height: 2rem;
`;

const DropdownItemText = styled.div`
    color: #002043;
    font-size: ${(props: renderStyle) => (props.size === 'large' ? '1rem' : '0.75rem')};
    line-height: ${(props: renderStyle) => (props.size === 'large' ? '1.5rem' : '1rem')};
    font-weight: 700;
    ${(props: renderStyle) =>
        props.isTrue &&
        css`
            color: #e84c3d;
        `}
`;
const DropdownContainer = styled.div<IOnPropStyles>`
    position: relative;
    display: flex;
    cursor: pointer;
    ${(props: IOnPropStyles) =>
        props.disabled &&
        css`
            opacity: 0.7;
            pointer-events: none;
        `}
`;
const HiddenDiv = styled.div`
    visibility: hidden;
`;
export default CustomDropdownV2;
