import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FlexedDiv } from '../components';
import { LinkButton } from '../components/atoms/LinkButton';
import Tag from '../components/atoms/Tag';
import { LABEL, TextNavyBlue } from '../constants';

interface ISearchBarFilterTags {
    disabled?: boolean;
    handleClearAll: () => void;
    handleRemoveTag: (item: ISelectValue, index: number) => void;
    handleShowAll: () => void;
    showAll: boolean;
    tags: Array<ISelectValue>;
    testId?: string;
}

export const SearchBarFilterTags = ({
    showAll,
    handleShowAll,
    disabled,
    handleClearAll,
    handleRemoveTag,
    testId,
    tags,
}: ISearchBarFilterTags): JSX.Element => {
    const filterTagsRef = useRef<HTMLDivElement>(null);
    const tagRefs = useRef<Array<HTMLButtonElement | null>>([]);
    const [rowWidth, setRowWidth] = useState<number>(0);
    const [widths, setWidths] = useState<Array<number>>([]);
    const [breakpointWidth, setBreakPointWidth] = useState<number>(0);
    useEffect(() => {
        const resizeObserverRow = new ResizeObserver((entries) => {
            setRowWidth(entries[0].contentRect.width);
        });
        filterTagsRef.current !== null && filterTagsRef.current !== undefined
            ? resizeObserverRow.observe(filterTagsRef.current)
            : null;
        const temp: number[] = [];
        for (let i = 0; i < tags.length; i++) {
            tagRefs.current = tagRefs.current.slice(0, tags.length);
            const tempWidth = tagRefs.current[i]?.clientWidth;
            temp.push(tempWidth !== undefined ? tempWidth : 0);
            setWidths(temp);
        }
    }, [tagRefs, filterTagsRef, tags]);
    const condition = useMemo(() => {
        let breakPoint = 0;
        let total = 54;
        for (let i = 0; i < widths.length; i++) {
            total = total + widths[i] + 5;
            if (total >= rowWidth - 20) {
                breakPoint = i;
                rowWidth + 46 < total ? setBreakPointWidth(rowWidth) : setBreakPointWidth(0);
                break;
            }
        }
        const condition = breakPoint !== 0 ? true : false;
        return condition;
    }, [tags, rowWidth, widths]);
    return (
        <SearchBarTagContainer id={`${testId}-search-bar-filterTag-row`}>
            <SearchBarTagRow ref={filterTagsRef} isOnProp={showAll}>
                {tags.map((item, index) => (
                    <Tag
                        key={index + 1}
                        onClose={() => handleRemoveTag(item, index)}
                        style={{ cursor: 'default', marginBottom: '0.75rem' }}
                        disabled={disabled}
                        ref={(ref) => (tagRefs.current[index] = ref)}
                        id={`${testId}-search-bar-filterTag-${item.label}}`}
                    >
                        <FlexedDiv>
                            <Normal>{item.label}&nbsp;</Normal>
                            {item.value}
                        </FlexedDiv>
                    </Tag>
                ))}
                {showAll || condition === false ? (
                    <LinkButton
                        buttonColor="#0089EC"
                        disabled={disabled}
                        onClick={() => handleClearAll()}
                        id={`${testId}-search-bar-filterTag-clearAll`}
                    >
                        {LABEL.clearAll}
                    </LinkButton>
                ) : (
                    <div />
                )}
            </SearchBarTagRow>
            {condition && showAll === false ? (
                <ClearAllPositionWrapper isOnProp={breakpointWidth !== 0} maxWidth={breakpointWidth.toString()}>
                    <LinkButton
                        buttonColor="#0089EC"
                        disabled={disabled}
                        onClick={() => handleClearAll()}
                        id={`${testId}-search-bar-filterTag-clearAll`}
                    >
                        {LABEL.clearAll}
                    </LinkButton>
                </ClearAllPositionWrapper>
            ) : null}
            {condition ? (
                <TextNavyBlue
                    weight="700"
                    size="12px"
                    style={{ cursor: 'pointer', position: 'absolute', right: '1.5rem' }}
                    onClick={handleShowAll}
                    id={`${testId}-search-bar-filterTag-row-${showAll ? 'hide' : 'showAll'}`}
                >
                    {showAll ? LABEL.hide : LABEL.showAll}
                </TextNavyBlue>
            ) : null}
        </SearchBarTagContainer>
    );
};

const SearchBarTagContainer = styled.div`
    display: flex;
    align-items: baseline;
`;
const SearchBarTagRow = styled.div<IOnPropStyles>`
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    height: ${(props) => (props.isOnProp ? 'auto' : '2rem')};
    align-items: baseline;
    width: 80%;
    column-gap: 5px;
    min-width: 752px;
    max-width: 1056px;
`;
const Normal = styled.div`
    font-weight: 400;
`;
const ClearAllPositionWrapper = styled.div<IOnPropStyles>`
    width: 54px;
    ${(props) =>
        props.isOnProp &&
        css`
            position: absolute;
            left: ${props.maxWidth}px;
        `}
`;
