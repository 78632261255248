/**
 * @downloadDaily @mutation - Branch Dashboard
 * @description: Downloads report and status changed to In Review
 */

export const transactionReportZip = /* GraphQL */ `
    mutation downloadDaily($input: DownloadDailyInput) {
        downloadDaily(input: $input) {
            data {
                result {
                    message
                    link
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
