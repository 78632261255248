import gql from 'graphql-tag';

export const eddDashboardExportListCreatedOn = /* GraphQL */ gql`
    query dashboardExportList($input: DashboardExportInput) {
        dashboardExportList(input: $input) {
            data {
                result {
                    edddashboard {
                        caseId
                        caseNo
                        accountType
                        clientIdNum
                        clientName
                        accountNo
                        agentName
                        agentCode
                        bdmName
                        bdmCode
                        status
                        remarks {
                            title
                            content
                        }
                        targetDate
                        lastUpdated
                        createdOn
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
