import React from 'react';

declare interface ITransactionWithGrouping {
    grouping: TGroupingInfo[];
    data?: undefined;
}
declare interface ITransactionWithoutGrouping {
    grouping?: undefined;
    data: ITableData[];
}

declare interface IGroupingOrder {
    type: 'orderNo';
    groupingData: IOrderClassificationData;
    transactionData: ITransactionWithGrouping | ITransactionWithoutGrouping;
}

declare interface IGroupingBranch {
    type: 'branchName';
    groupingData: IBranchClassificationData;
    transactionData: ITransactionWithGrouping | ITransactionWithoutGrouping;
}

declare interface IGroupingCutOff {
    type: 'cutOffTime';
    groupingData: IFundCutOffData;
    transactionData: ITransactionWithGrouping | ITransactionWithoutGrouping;
}
declare interface IGroupingInfo {
    /**
     * type of grouping, like grouping by branch,order, cut-off etc
     */
    type: string;
    /**
     * the info on grouping data, it will be based on what type of grouping type it belongs to what kind of data should be show to make relevant ui and functionality
     */
    groupingData: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
    /**
     * transaction data which indicates if there is further grouping or we can show  data, data means row data here
     */
    transactionData: ITransactionWithGrouping | ITransactionWithoutGrouping;
}

declare type TGroupingInfo = IGroupingBranch | IGroupingCutOff | IGroupingOrder | IGroupingInfo;

declare interface IFundCutOffData {
    value: string;
    suffixString?: string;
}

declare interface IBranchClassificationData {
    value: string;
    branchId?: string;
    // style?: React.CSSProperties;
}

/**
 * to get the styling for each individual grouping
 * @param type - what type of grouping is there
 * @returns - style for specified type of grouping applied in the advanced table
 */
export const getGroupStyling = (type: 'branchName' | 'cutOffTime' | 'orderNo' | string): React.CSSProperties => {
    switch (type) {
        case 'branchName':
            return {
                width: 'calc(100% + 48px)',
                margin: '16px 0px 16px -24px',
            };
        case 'cutOffTime':
            return {};
        case 'orderNo':
            return {
                marginBottom: '8px',
            };
        default:
            return {};
    }
};

/**
 * get required info based on type of grouping for advanced table
 * @param info - info from backend that needs to be changed
 * @returns - required data to fullfil ui for the specified grouping
 */
export const getGroupingTemplateInfo = (
    info: TGroupingInfo,
    noTransactionIconOnOrder?: boolean,
): IOrderClassification | IBranchClassification | IFundCutOffClassification => {
    let data:
        | IOrderClassification
        | IBranchClassification
        | IFundCutOffClassification
        | {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              [key: string]: any;
          } = {};
    switch (info.type) {
        case 'branchName':
            data = {
                branch: info.groupingData.value,
                branchId: info.groupingData.branchId,
            };
            break;
        case 'cutOffTime':
            data = {
                value: info.groupingData.value,
                suffixString: 'Fund Cutoff time',
            };
            break;
        case 'orderNo':
            const principal = [
                {
                    principalName: info.groupingData.principalName,
                    principalNumber: info.groupingData.principalNumber ?? info.groupingData.jointNumber,
                },
            ];
            info.groupingData.jointName
                ? principal.push({
                      principalName: info.groupingData.jointName,
                      principalNumber: info.groupingData.jointNumber,
                  })
                : null;
            data = {
                ...info.groupingData,
                sameBranch: info.groupingData.sameBranch == 'true' || info.groupingData.sameBranch === null,
                principal: principal,
                noTransactionOverviewIcon: noTransactionIconOnOrder,
                noTransactions: info.groupingData.noTransactions,
            };
            break;
    }
    return data as IOrderClassification | IBranchClassification | IFundCutOffClassification;
};

/**
 * Applying grouped data format compatible for Advanced table from grouped Backend data
 * @param grouping - grouped data
 * @param GroupingTemplate - template to be used for grouping data
 * @param getGroupStyling - styling to be used for each grouping
 * @param noTransactionIconOnOrder - optional field for order template to not show order overview icon
 * @returns - grouped data to be plugged into advanced table
 */
export const getGroupingFromData = (
    grouping: TGroupingInfo[],
    GroupingTemplate: (props: ITableGroupingProps) => JSX.Element,
    getGroupStyling: (type: 'branchName' | 'cutOffTime' | 'orderNo' | string) => React.CSSProperties,
    noTransactionIconOnOrder?: boolean,
): (IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[] => {
    let groupData: (IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData)[] = [];
    groupData = grouping.map((group): IGroupedWithSubGroupTabledData | IGroupedWithoutSubGroupTableData => {
        if (group.transactionData.grouping) {
            return {
                mainGrouping: GroupingTemplate({
                    type: group.type,
                    data: getGroupingTemplateInfo(group, noTransactionIconOnOrder),
                    style: getGroupStyling(group.type),
                }),
                subGrouping: getGroupingFromData(
                    group.transactionData.grouping,
                    GroupingTemplate,
                    getGroupStyling,
                    noTransactionIconOnOrder,
                ),
            };
        } else {
            return {
                mainGrouping: GroupingTemplate({
                    type: group.type,
                    data: getGroupingTemplateInfo(group, noTransactionIconOnOrder),
                    style: getGroupStyling(group.type),
                }),
                data: group.transactionData.data,
            };
        }
    });
    return groupData;
};
