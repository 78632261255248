import gql from 'graphql-tag';

/**
 * @hqCeDashboard @query - HQ Dashboard
 * @description: TAB: Due date extension
 */

export const dueDateExtensionQuery = /* GraphQL */ gql`
    query hqCeDashboard($input: DashboardInput) {
        hqCeDashboard(input: $input) {
            data {
                result {
                    transactions {
                        isSeen
                        orderNo
                        transactionRef
                        requestId
                        accountType
                        clientName
                        clientIdNum
                        transactionType
                        paymentMethod
                        fundType
                        totalInvestment {
                            fundCurrency
                            investmentAmount
                        }
                        agentName
                        agentCode
                        lastUpdated
                        submittedOn
                        createdOn
                        status
                        remarks {
                            title
                            content
                        }
                        targetDate
                        requestedBy
                        aging
                        branchName
                    }
                    overview {
                        daily
                        reroute
                        extension
                        hardcopy
                    }
                    totalResultCount
                    branchCount
                    extensionCount
                    page
                    pages
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
