import gql from 'graphql-tag';
/**
 * @branchDashboard @query - Transaction Dashboard
 * @description: Returns all tabs in a dashboard with default ‘Daily’ tab
 */

const dailyQueryV2 = /* GraphQL */ gql`
    query branchDashboardV2($input: BranchDashboardInput) {
        branchDashboardV2(input: $input) {
            data {
                result {
                    batchCount {
                        batchTime
                        count
                    }
                    changeRequestsCount
                    cutOffCount
                    dailyCount
                    enableBulkVerify
                    filters {
                        transactionType
                        fundType
                        paymentMethod
                        accountType
                        branchStatus
                        cutOffTime
                    }
                    groupedTransactions {
                        grouping {
                            type
                            groupingData {
                                key
                                value
                            }
                            transactionData {
                                grouping {
                                    type
                                    groupingData {
                                        principalName
                                        principalNumber
                                        orderNo
                                        jointName
                                        jointNumber
                                        sameBranch: viewable
                                        accountNo
                                        noTransactions
                                    }
                                    transactionData {
                                        data {
                                            requestId
                                            isVerified
                                            fundCode
                                            lastUpdated
                                            submittedOn
                                            createdOn
                                            orderNo
                                            transactionRef
                                            accountType
                                            clientName
                                            clientIdNum
                                            jointName
                                            jointNumber
                                            transactionType
                                            fundType
                                            paymentMethod
                                            fundCategory
                                            isPhysicalDocRequired
                                            totalInvestment {
                                                fundCurrency
                                                investmentAmount
                                            }
                                            totalUnits
                                            agentName
                                            agentCode
                                            status
                                            remarks {
                                                title
                                                content
                                            }
                                            cutOffTime
                                            batchTime
                                            targetDate
                                            aging
                                            approvalType
                                            utmc
                                            isSeen
                                        }
                                    }
                                }
                            }
                        }
                    }
                    hardcopyCount
                    historyCount
                    page
                    pages
                    rerouteCount
                    transactionsCount
                    typeCount
                    typeSeen
                    upcomingCount
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;

export default dailyQueryV2;
