import moment from 'moment';
import { emptyCheck } from './emptyCheck';
import { LABEL } from '../constants';
import { formatFatca, hyphenateNric } from './formatIds';
import { getSummaryTitle, getTotalInvestmentAmt, getTotalLabel } from '../pages/OrderDetails/Utils/orderSummaryUtils';
import { getDeclarationAddress } from './getDeclarationAddress';
import { toTitleCase } from './toTitleCase';

// Modules
import { redemptionDataStructring } from './OrderSummary/Redemption';

export const handleOrderSummary = (
    orderSummary: IOrderSummaryResult,
    handleAccountLinks?: string,
    locationState?: IOrderSummaryRouteProps, //remove
): IOrderSummaryProps => {
    const paymentSummaryObj: ISection[] = [];

    const _index = orderSummary.profile.findIndex((item) => item.principal === true);
    const jointIndex = orderSummary.profile.findIndex((item) => item.principal === false);
    const principalIndex = _index !== -1 ? _index : 0;
    const hideinvestorOverview = () => {
        if (orderSummary?.transactionType.toLowerCase() === 'sales-ao' && !orderSummary.isEtb) {
            const investorArray = orderSummary?.profile.map((item, index) => {
                return item.isEtb === false ? index : null;
            });
            const etbIndex = investorArray.findIndex((item) => item === null);
            return etbIndex !== -1 ? false : true;
        } else return false;
    };
    //Investor overview section
    const _investorOverviewSection: IProfileSection = {
        title: 'Investor Overview',
        sections: [{ data: [] }],
        hidden: hideinvestorOverview(),
    };
    if (orderSummary.profile.length > 1) {
        const _principalSection: ISection = {
            sectionTitle: {
                title: 'Principal Holder',
                titleIcon: 'single-avatar',
            },
            data: [],
        };
        const _jointSection: ISection = {
            sectionTitle: {
                title: 'Joint Holder',
                titleIcon: 'joint-avatar',
            },
            data: [],
        };
        const _idTypePrincipal = toTitleCase(orderSummary.profile[principalIndex].idType);
        const _idTypeJoint = toTitleCase(orderSummary.profile[jointIndex].idType);
        let _ntbClient = -1;
        orderSummary.profile.map((item, index) => {
            if (item.isEtb !== true && item !== null) _ntbClient = index;
        });
        _principalSection.data.push(
            {
                label: 'Investor Name',
                data: emptyCheck(orderSummary.profile[principalIndex].name),
            },
            {
                label:
                    orderSummary.profile[principalIndex].idType === 'NRIC'
                        ? `Investor NRIC`
                        : `Investor ${_idTypePrincipal}`,
                data:
                    emptyCheck(orderSummary.profile[principalIndex].idType).toLowerCase() === 'nric'
                        ? hyphenateNric(emptyCheck(orderSummary.profile[principalIndex].idNumber))
                        : emptyCheck(orderSummary.profile[principalIndex].idNumber),
                dataIcon: 'profile-card-link',
                onClickData:
                    orderSummary.profile[principalIndex].idFile !== null &&
                    orderSummary.profile[principalIndex].idFile !== ''
                        ? orderSummary.profile[principalIndex].idFile
                        : undefined,
            },
            {
                label: 'Risk Profile',
                data: emptyCheck(orderSummary.profile[principalIndex].personalDetails.riskProfile),
            },
        );
        _jointSection.data.push(
            {
                label: 'Investor Name',
                data: emptyCheck(orderSummary.profile[jointIndex].name),
            },
            {
                label:
                    orderSummary.profile[jointIndex].idType === 'NRIC' ? `Investor NRIC` : `Investor ${_idTypeJoint}`,
                data:
                    emptyCheck(orderSummary.profile[jointIndex].idType).toLowerCase() === 'nric'
                        ? hyphenateNric(emptyCheck(orderSummary.profile[jointIndex].idNumber))
                        : emptyCheck(orderSummary.profile[jointIndex].idNumber),
                dataIcon: 'profile-card-link',
                onClickData:
                    orderSummary.profile[jointIndex].idFile !== null && orderSummary.profile[jointIndex].idFile !== ''
                        ? orderSummary.profile[jointIndex].idFile
                        : undefined,
            },
        );
        orderSummary.profile[jointIndex].personalDetails.riskProfile !== null &&
        orderSummary.profile[jointIndex].personalDetails.riskProfile !== undefined
            ? _jointSection.data.push({
                  label: 'Risk Profile',
                  data: emptyCheck(orderSummary.profile[jointIndex].personalDetails.riskProfile),
              })
            : null;
        if (_ntbClient === principalIndex) {
            if (_principalSection.sectionTitle) {
                _principalSection.sectionTitle.badgeColor = '#243547';
                _principalSection.sectionTitle.badgeLabel = 'New Investor';
            }
        } else if (_ntbClient === jointIndex) {
            if (_jointSection.sectionTitle) {
                _jointSection.sectionTitle.badgeColor = '#243547';
                _jointSection.sectionTitle.badgeLabel = 'New Investor';
            }
        }

        _investorOverviewSection.sections.push(_principalSection);
        _investorOverviewSection.sections.push(_jointSection);
    } else {
        const _idType = toTitleCase(orderSummary.profile[principalIndex].idType);
        _investorOverviewSection.sections[0].data.push(
            {
                label: 'Investor Name',
                data: emptyCheck(orderSummary.profile[principalIndex].name),
            },
            {
                label: orderSummary.profile[principalIndex].idType === 'NRIC' ? `Investor NRIC` : `Investor ${_idType}`,
                data:
                    emptyCheck(orderSummary.profile[principalIndex].idType).toLowerCase() === 'nric'
                        ? hyphenateNric(emptyCheck(orderSummary.profile[principalIndex].idNumber))
                        : emptyCheck(orderSummary.profile[principalIndex].idNumber),
                dataIcon: 'profile-card-link',
                onClickData:
                    orderSummary.profile[principalIndex].idFile !== null &&
                    orderSummary.profile[principalIndex].idFile !== ''
                        ? orderSummary.profile[principalIndex].idFile
                        : undefined,
            },
            {
                label: 'Risk Profile',
                data: emptyCheck(orderSummary.profile[principalIndex].personalDetails.riskProfile),
            },
        );
    }

    //Investment summary section
    const _investmentSummary: ISectionWithSubSections[] = [];

    orderSummary.investmentSummary.map((ele) => {
        const fundSummaryObj: ISectionWithSubSections = {
            sectionTitle: {
                title: ele.fundName,
                titleIcon: 'fund',
                rightLabel: emptyCheck(`${ele.fundCurrency ?? ''} ${ele.investmentAmount ?? ''}`),
                bottomLabel: ele.utmc,
            },
            subSection: [],
            subSectionWrapperStyle: { margin: '5px 0px 0px 0px' },
        };

        const tempObjFundInfo: ISection = {
            sectionTitle: {
                title: 'Fund Information',
                titleIcon: 'fundInfo',
                rightLabel: ' ',
            },
            data: [
                { label: 'Fund Code', data: emptyCheck(ele.fundCode) },
                { label: 'Product Type', data: emptyCheck(ele.productType) },
                { label: 'Type', data: emptyCheck(ele.investmentType) },
                { label: 'Class', data: emptyCheck(ele.fundClass) },
                { label: 'Currency', data: emptyCheck(ele.fundCurrency) },
            ],
            subSectionWrapperStyle: { margin: '5px 0px 0px 0px' },
        };
        const tempObjInvestment: ISection = {
            sectionTitle: {
                title: 'Investment',
                titleIcon: 'investmentInfo',
                rightLabel: ' ',
            },
            data: [
                { label: 'Funding Option', data: emptyCheck(ele.accountFund) },
                { label: 'Sales Charge', data: emptyCheck(ele.salesCharge) },

                {
                    label: 'Payment Term',
                    data:
                        emptyCheck(ele.recurring) !== '-'
                            ? emptyCheck(ele.recurring).toLowerCase() === 'no'
                                ? 'One-time payment'
                                : 'Recurring'
                            : '-',
                },
                { label: 'Mode of Income Distribution', data: emptyCheck(ele.distributionInstructions) },
                // { label: 'Recurring', data: emptyCheck(ele.recurring) },
            ],
            subSectionWrapperStyle: { margin: '5px 0px 0px 0px' },
        };
        //show new fund badge and mode of income for new funds when isTopUp is false
        if (ele.isTopup === false) {
            fundSummaryObj.sectionTitle.badgeLabel = LABEL.newFund;
            fundSummaryObj.sectionTitle.badgeColor = '#4D4D4D';
        }

        fundSummaryObj.subSection.push(tempObjFundInfo, tempObjInvestment);
        _investmentSummary.push(fundSummaryObj);
        // fundSummaryObj.push(tempObj);
    });

    /********************************Redemption Section************************************/
    const [_redemptionType, _redemptionDetailsGroup, _proceedPayableDetailsGroup] =
        redemptionDataStructring(orderSummary);

    // Switching Summary section
    const switchingGroup: IInvestmentSummarySection[] = [];
    orderSummary.switchingSummary.map((ele, index) => {
        const tempGroup: IInvestmentSummarySection = {
            title: `Switching Group ${orderSummary.switchingSummary.length > 1 ? index + 1 : ''}`,
            sections: [],
        };

        const switchOutFundInfo: ISectionWithSubSections = {
            sectionTitle: {
                title: ele.switchOutInfo.fundName,
                bottomLabel: ele.switchOutInfo.utmc,
                rightLabel: `${emptyCheck(ele.switchOutInfo.switchOutUnits)} units`,
                titleIcon: 'fund',
            },
            titleBanner: {
                title: 'Switch Out',
                icon: 'switch-out',
            },
            subSection: [],
        };
        const tempObjSwitchOutFundInfo: ISection = {
            data: [
                { label: 'Fund Code', data: emptyCheck(ele.switchOutInfo.fundCode) },
                { label: 'Class', data: emptyCheck(ele.switchOutInfo.fundClass) },
                { label: 'Currency', data: emptyCheck(ele.switchOutInfo.currency) },
                { label: 'Available Units', data: emptyCheck(ele.switchOutInfo.availableUnits) },
            ],
        };
        switchOutFundInfo.subSection.push(tempObjSwitchOutFundInfo);
        // _switchingSummary.push(switchOutFundInfo);
        tempGroup.sections.push(switchOutFundInfo);
        ele.switchInInfo.map((switchInFund, index) => {
            const switchInFundInfo: ISectionWithSubSections = {
                sectionTitle: {
                    title: switchInFund.fundName,
                    bottomLabel: switchInFund.utmc,
                    titleIcon: 'fund',
                    rightLabel: `${emptyCheck(switchInFund.units)} units`,
                },
                subSection: [],
                titleBanner:
                    index === 0
                        ? {
                              title: 'Switch In',
                              icon: 'switch-in',
                          }
                        : undefined,
            };
            if (switchInFund.isNewFund) {
                switchInFundInfo.sectionTitle.badgeLabel = LABEL.newFund;
                switchInFundInfo.sectionTitle.badgeColor = '#4D4D4D';
            }

            const tempObjSwitchInFundInfo: ISection = {
                data: [
                    { label: 'Fund Code', data: emptyCheck(switchInFund.fundCode) },
                    { label: 'Class', data: emptyCheck(switchInFund.fundClass) },
                    { label: 'Currency', data: emptyCheck(switchInFund.currency) },
                    { label: 'Sales Charge', data: emptyCheck(switchInFund.salesCharge) },
                    { label: 'Mode of Income Distribution', data: emptyCheck(switchInFund.modeIncomeDistribution) },
                ],
            };

            switchInFundInfo.subSection.push(tempObjSwitchInFundInfo);
            tempGroup.sections.push(switchInFundInfo);
        });

        switchingGroup.push(tempGroup);
    });
    orderSummary.paymentSummary?.map((ele, index) => {
        const tempObj: ISection = {
            // title: ele.paymentMethod.toLowerCase() === 'recurring' ? `Recurring Details` : `Payment ${index + 1}`,
            sectionTitle: {
                title: ele.paymentMethod.toLowerCase() === 'recurring' ? `Recurring Details` : `Payment ${index + 1}`,
                titleIcon: 'transaction',
                bottomLabel: ele.surplusNote,
                rightLabel:
                    ele.fundCurrency || ele.investmentAmount
                        ? emptyCheck(ele.fundCurrency) + ' ' + emptyCheck(ele.investmentAmount)
                        : '-',
                // badgeColor:
                //     ele.status !== undefined && ele.status !== null ? statusBadgeColorOnly(ele.status) : undefined,
                // badgeLabel: ele.status !== undefined && ele.status !== null ? ele.status : undefined,
            },

            data: [],
        };
        // if (ele.investmentAmount !== undefined && ele.investmentAmount !== null) {
        //     tempObj.data.push({ label: 'Amount', data: emptyCheck(ele.investmentAmount), prefix: ele.fundCurrency });
        // }
        if (
            ele.paymentMethod !== undefined &&
            ele.paymentMethod !== null &&
            ele.paymentMethod.toLowerCase() !== 'recurring'
        ) {
            tempObj.data.push({ label: 'Payment Method', data: emptyCheck(ele.paymentMethod) });
        }
        if (ele.epfAccountNumber !== undefined && ele.epfAccountNumber !== null) {
            tempObj.data.push({ label: 'EPF Account No.', data: emptyCheck(ele.epfAccountNumber) });
        }
        if (ele.epfReferenceNo !== undefined && ele.epfAccountNumber !== null) {
            tempObj.data.push({ label: 'EPF Reference No.', data: emptyCheck(ele.epfReferenceNo) });
        }
        if (ele.bankName !== undefined && ele.bankName !== null) {
            tempObj.data.push({
                label: 'Bank Name',
                data: emptyCheck(ele.bankName),
            });
        }
        if (
            ele.referenceNumber !== undefined &&
            ele.referenceNumber !== null &&
            ele.paymentMethod.toLowerCase() !== 'client trust account (cta)'
        ) {
            tempObj.data.push({ label: 'Payment Reference Number', data: emptyCheck(ele.referenceNumber) });
        }

        if (ele.paymentOn !== null && ele.paymentOn !== undefined) {
            tempObj.data.push({
                label: 'Transaction Date',
                data: emptyCheck(ele.paymentOn !== null ? moment(ele.paymentOn, 'x').format('DD/MM/YYYY') : ''),
            });
        }
        if (ele.kibBankName !== undefined && ele.kibBankName !== null) {
            tempObj.data.push({
                label: 'KIB Bank Account Number',
                data: emptyCheck(ele.kibBankName),
                subText: emptyCheck(ele.kibBankAccountNumber),
            });
        }

        if (ele.fundCurrency !== undefined && ele.fundCurrency !== null) {
            tempObj.data.push({ label: 'KIB Bank Currency', data: emptyCheck(ele.fundCurrency) });
        }
        if (ele.checkNumber !== undefined && ele.checkNumber !== null) {
            tempObj.data.push({ label: 'Cheque Number', data: emptyCheck(ele.checkNumber) });
        }
        if (ele.clientName !== undefined && ele.clientName !== null) {
            tempObj.data.push({ label: 'Client Name', data: emptyCheck(ele.clientName) });
        }
        if (ele.clientTrustAccountNumber !== undefined && ele.clientTrustAccountNumber !== null) {
            tempObj.data.push({ label: 'Client Trust Account Number', data: emptyCheck(ele.clientTrustAccountNumber) });
        }
        if (ele.recurringType !== undefined && ele.recurringType !== null) {
            tempObj.data.push({ label: 'Recurring Type', data: emptyCheck(ele.recurringType) });
        }
        if (ele.bankAccountName !== undefined && ele.bankAccountName !== null) {
            tempObj.data.push({
                label: 'Account Holder’s Name',
                data: emptyCheck(ele.bankAccountName),
                subText: ele.isCombined ? 'Combined' : '',
            });
        }
        if (ele.bankAccountNumber !== undefined && ele.bankAccountNumber !== null) {
            tempObj.data.push({
                label: 'Bank Account No.',
                data: emptyCheck(ele.bankAccountNumber),
            });
        }
        if (ele.recurringBank !== undefined && ele.recurringBank !== null) {
            tempObj.data.push({ label: 'Recurring Bank', data: emptyCheck(ele.recurringBank) });
        }

        if (ele.frequency !== undefined && ele.frequency !== null) {
            tempObj.data.push({ label: 'Frequency', data: emptyCheck(ele.frequency) });
        }
        tempObj.data.push({ label: 'Remarks', data: emptyCheck(ele.remark) });
        if (ele.proofOfPayment !== null && ele.proofOfPayment !== undefined) {
            tempObj.data.splice(tempObj.data.length - 1, 0, {
                label: 'Proof of Payment ',
                data: ele.proofOfPayment !== null ? emptyCheck(ele.proofOfPayment.name) : '-',
                dataIcon: 'document-link',
                onClickData: ele.proofOfPayment !== null ? emptyCheck(ele.proofOfPayment.url) : '-',
            });
        }
        paymentSummaryObj.push(tempObj);
    });

    const documentSummary: IDocumentSummary = orderSummary.documentSummary;
    const transactionSummary: ITransactionsSummary | ITransactionsSummaryTrx = {
        status: orderSummary.status,
        remarks: orderSummary.remarks,
        approval: orderSummary.approval,
        extensionRemarks: orderSummary.extensionRemarks,
        orderNo: orderSummary.orderNo,
        transactionRef: emptyCheck(orderSummary.transactionRef),
        totalInvestment: orderSummary.totalInvestment,
        investorOverview: _investorOverviewSection,
        transactionDetails: {
            title: `${getSummaryTitle(locationState, orderSummary.status)} Details`,
            sections: [
                {
                    data: [
                        {
                            label: LABEL.transactionNo,
                            data:
                                getSummaryTitle(locationState, orderSummary.status) === LABEL.order
                                    ? emptyCheck(orderSummary.transactionRef)
                                    : `${emptyCheck(orderSummary.orderNo)}${emptyCheck(orderSummary.transactionRef)}`,
                        },
                        {
                            label: LABEL.orderNumber,
                            data: emptyCheck(orderSummary.orderNo),
                        },

                        {
                            label: LABEL.transactionDate,
                            data:
                                orderSummary.transactionDetails[0].registrationDate !== null
                                    ? moment(orderSummary.transactionDetails[0].registrationDate, 'x').format(
                                          'DD/MM/YYYY',
                                      )
                                    : '-',
                        },
                        {
                            label: LABEL.transactionTypeLong,
                            data: emptyCheck(orderSummary.transactionType),
                        },
                        {
                            label: 'Adviser Name & Code',
                            data: emptyCheck(orderSummary.transactionDetails[0].servicingAdviserName),
                            subText: emptyCheck(orderSummary.transactionDetails[0].servicingAdviserCode),
                            subTextStyle: { fontWeight: 400, fontSize: '14px' },
                        },

                        {
                            label: 'KIB Processing Branch',
                            data: emptyCheck(orderSummary.transactionDetails[0].kibProcessingBranch),
                        },
                        {
                            label: 'Consent for Ongoing Services',
                            data: 'Yes',
                        },
                    ],
                },
                {
                    separator: true,
                    data: [
                        {
                            label: 'Account Type',
                            data: emptyCheck(orderSummary.transactionDetails[0].accountType),
                        },
                        {
                            label: getTotalLabel(orderSummary),
                            data:
                                orderSummary.transactionType?.toLowerCase() === 'switch'
                                    ? emptyCheck(orderSummary.totalSwitchOutUnits)
                                    : orderSummary.transactionType?.toLowerCase() === 'redeem'
                                    ? emptyCheck(
                                          orderSummary.totalRedeemUnits ||
                                              `${orderSummary.totalRedeemAmount[0].fundCurrency} ${orderSummary.totalRedeemAmount[0].redeemAmount}`,
                                      )
                                    : getTotalInvestmentAmt(orderSummary.totalInvestment),
                        },
                    ],
                },
            ],
        },
        accountSummary: {
            title: 'Account Summary',
            sections: [
                {
                    data: [
                        {
                            label: 'Registration Date',
                            data:
                                orderSummary.transactionDetails[0].registrationDate !== undefined ||
                                orderSummary.transactionDetails[0].registrationDate !== null
                                    ? moment(orderSummary.transactionDetails[0].registrationDate, 'x').format(
                                          'DD/MM/YYYY',
                                      )
                                    : '-',
                        },
                        {
                            label: 'Account Type',
                            data: emptyCheck(orderSummary.transactionDetails[0].accountType),
                        },
                        {
                            label: 'Account Number',
                            data: emptyCheck(orderSummary.transactionDetails[0].accountNo),
                        },
                        {
                            label: 'Risk Profile',
                            data: emptyCheck(orderSummary.profile[principalIndex].personalDetails.riskProfile),
                        },
                        {
                            label: 'Adviser Name & Code',
                            data: emptyCheck(orderSummary.transactionDetails[0].servicingAdviserName),
                            subText: emptyCheck(orderSummary.transactionDetails[0].servicingAdviserCode),
                            subTextStyle: { fontWeight: 400, fontSize: '14px' },
                        },
                        {
                            label: 'BDM Name',
                            data: emptyCheck(orderSummary.transactionDetails[0].bdmName),
                        },
                        {
                            label: 'Authorised Signatory',
                            data: emptyCheck(orderSummary.transactionDetails[0].accountOperationMode),
                        },
                    ],
                },
            ],
        },
        investmentSummary: {
            title: 'Investment Summary',
            sections: _investmentSummary,
        },
        idNumberInvestor: orderSummary.profile[principalIndex].idNumber,
        idNumberInvestorJoint: jointIndex !== -1 ? orderSummary.profile[jointIndex].idNumber : undefined,
        ...(orderSummary.transactionType?.toLowerCase() === 'redeem' && {
            redemptionTypeSummary: _redemptionType as IProfileSection,
            redemptionSummary: {
                title: 'Fund Redemption',
                sections: _redemptionDetailsGroup as ISectionWithSubSections[],
            },
            proceedPayableSummary: {
                title: 'Proceed Payable',
                sections: _proceedPayableDetailsGroup as ISectionWithSubSections[],
            },
        }),

        switchingSummary: switchingGroup,
        paymentSummary: {
            title: 'Payment Summary',
            sections: [],
        },
        riskAssessment: {
            title: 'Risk  Assessment',
            sections: [
                {
                    data: [],
                },
            ],
        },
    };

    const links: linkTypes[] = [];
    //Hide account number for Sales-AO NTB transactions/orders
    if (orderSummary?.transactionType.toLowerCase() !== 'sales-ao' && orderSummary.isEtb) {
        if (
            orderSummary.transactionDetails[0].accountNo !== null &&
            orderSummary.transactionDetails[0].accountNo !== undefined
        ) {
            orderSummary.transactionDetails.forEach((result: TransactionDetails) => {
                links.push({
                    value: result.accountNo,
                    linkText: 'View Details',
                    linkIcon: 'arrow-right',
                    link: handleAccountLinks,
                });
            });
        }

        const accountObj: IDataWithLabel = {
            label: 'Account Number',
            multiLinks: links.length === 0 ? undefined : links,
            data: links.length === 0 ? '-' : '',
        };
        transactionSummary.transactionDetails.sections[1].data.splice(0, 0, accountObj);
    }

    // Risk Assessment Section

    const riskAssessment: IDataWithLabel[] = [];
    if (orderSummary.riskSubmitted !== null) {
        riskAssessment.push(
            {
                label: 'Risk Appetite',
                data: emptyCheck(orderSummary.riskSubmitted.riskCategory),
            },
            {
                label: 'Expected Range of Return',
                data: emptyCheck(orderSummary.riskSubmitted.rangeOfReturn),
            },
            {
                label: 'Type',
                data: emptyCheck(orderSummary.riskSubmitted.typeOfFunds),
            },
            {
                label: 'Risk Profile',
                data: emptyCheck(orderSummary.riskSubmitted.message),
            },
        );
    }
    if (riskAssessment.length > 0) {
        riskAssessment.forEach((item) => transactionSummary.riskAssessment?.sections[0].data.push(item));
    } else {
        transactionSummary.riskAssessment = undefined;
    }
    if (['switch', 'redeem'].includes(orderSummary.transactionType.toLowerCase())) {
        transactionSummary.investmentSummary = undefined;
    }

    if (paymentSummaryObj.length > 0) {
        paymentSummaryObj.forEach((item) => transactionSummary.paymentSummary?.sections.push(item));
    } else {
        transactionSummary.paymentSummary = undefined;
    }

    const profile: TOrderSummaryProfileSections[] = [];
    // const _bankInfo: IBankInformation = {
    //     title: 'Banking Details',
    //     sections: [],
    // };
    orderSummary.profile.map((item) => {
        //Profile details
        const _identification: ISummarySection = {
            title: 'Identification',
            sections: [{ data: [] }],
        };
        //Push main profile details in identification section
        _identification.sections[0].data.push(
            { label: 'Name', data: emptyCheck(item.name) },
            { label: `${item.idType} ${handleIDtype(item.idType)}`, data: emptyCheck(item.idNumber) },
            { label: 'Date of Birth', data: emptyCheck(item.personalDetails.dateOfBirth) },
        );
        if (item.idType === 'Passport' || item.idType === 'passport') {
            _identification.sections[0].data.push(
                { label: 'Country', data: emptyCheck(item.personalDetails.nationality) },
                {
                    label: 'Passport Expiration Date',
                    data: emptyCheck(
                        item.personalDetails.expirationDate !== null
                            ? moment(item.personalDetails.expirationDate, 'x').format('DD/MM/YYYY')
                            : '',
                    ),
                },
            );
        }
        //Sub section in Identification section
        const _subSectionIdentification: ISection = {
            sectionTitle: { title: 'Personal Details', titleIcon: 'single-avatar' },
            data: [],
        };
        //Push sub section data
        _subSectionIdentification.data.push(
            { label: 'Salutation', data: emptyCheck(item.personalDetails.salutation) },
            { label: 'Gender', data: emptyCheck(item.personalDetails.gender) },
            { label: 'Place of Birth', data: emptyCheck(item.personalDetails.placeOfBirth) },
            { label: 'Country of Birth', data: emptyCheck(item.personalDetails.countryOfBirth) },
        );

        if (item.personalDetails.nationality === 'Malaysia') {
            _subSectionIdentification.data.push(
                {
                    label: 'Bumiputera',
                    data:
                        item.personalDetails.bumiputera !== undefined
                            ? emptyCheck(item.personalDetails.bumiputera)
                            : '-',
                },
                {
                    label: 'Race',
                    data: item.personalDetails.race !== undefined ? emptyCheck(item.personalDetails.race) : '-',
                },
            );
        } else {
            _subSectionIdentification.data.splice(2, 0, {
                label: 'Nationality',
                data: emptyCheck(item.personalDetails.nationality),
            });
        }
        _subSectionIdentification.data.push(
            { label: `Mother's Maiden Name`, data: emptyCheck(item.personalDetails.mothersMaidenName) },
            { label: 'Marital Status', data: emptyCheck(item.personalDetails.maritalStatus) },
            { label: 'Education Level', data: emptyCheck(item.personalDetails.educationLevel) },
        );
        if (item.epfDetails?.epfMemberNumber !== undefined && item.epfDetails?.epfMemberNumber !== null) {
            _subSectionIdentification.data.push({
                label: 'EPF Account Number',
                data: emptyCheck(item.epfDetails?.epfMemberNumber),
            });
        }
        if (item.epfDetails?.epfAccountType !== undefined && item.epfDetails?.epfAccountType !== null) {
            _subSectionIdentification.data.push({
                label: 'EPF Account Type',
                data: emptyCheck(item.epfDetails?.epfAccountType),
            });
        }
        //Push sub section
        _identification.sections.push(_subSectionIdentification);

        //Contact section
        const _contact: ISummarySection = {
            title: 'Contact',
            sections: [{ data: [] }],
        };
        _contact.sections[0].data.push(
            {
                label: `Email Address`,
                data: emptyCheck(item.contactDetails?.email),
            },
            {
                label: `Mobile Number`,
                data: emptyCheck(item.contactDetails?.mobileNumber),
            },
        );
        item.contactDetails?.homeNumber !== null
            ? _contact.sections[0].data.push({
                  label: `Home Number`,
                  data: emptyCheck(item.contactDetails?.homeNumber),
              })
            : null;
        item.contactDetails?.officeNumber !== null
            ? _contact.sections[0].data.push({
                  label: `Office Number`,
                  data: emptyCheck(item.contactDetails?.officeNumber),
              })
            : null;
        item.contactDetails?.faxNumber !== null
            ? _contact.sections[0].data.push({
                  label: `Fax Number`,
                  data: emptyCheck(item.contactDetails?.faxNumber),
              })
            : null;

        //Permanent address section
        const _permanentAddress: ISummarySection = {
            title: 'Permanent Address',
            sections: [{ data: [] }],
        };
        _permanentAddress.sections[0].data.push(
            {
                label: `Permanent Address 1`,
                data: emptyCheck(item.addressInformation?.permanentAddress?.address?.line1),
            },
            {
                label: `Permanent Address 2`,
                data: emptyCheck(item.addressInformation?.permanentAddress?.address?.line2),
            },
            {
                label: `Permanent Address 3`,
                data: emptyCheck(item.addressInformation?.permanentAddress?.address?.line3),
            },
            // {
            //     label: `Permanent Address 4`,
            //     data: emptyCheck(item.addressInformation?.permanentAddress?.address?.line4),
            // },
            {
                label: `Postcode`,
                data: emptyCheck(item.addressInformation?.permanentAddress?.postCode),
            },
            {
                label: `City / Town`,
                data: emptyCheck(item.addressInformation?.permanentAddress?.city),
            },
            {
                label: `State`,
                data: emptyCheck(item.addressInformation?.permanentAddress?.state),
            },
            {
                label: `Country`,
                data: emptyCheck(item.addressInformation?.permanentAddress?.country),
            },
        );
        //Employment section
        const _employment: ISummarySection = {
            title: 'Employment',
            sections: [{ data: [] }],
        };
        _employment.sections[0].data.push(
            {
                label: 'Occupation',
                data: emptyCheck(item.employmentInformation?.occupation),
            },
            {
                label: 'Business Field',
                data: emptyCheck(item.employmentInformation?.natureOfBusiness),
            },
            {
                label: 'Annual Income (MYR)',
                data: emptyCheck(item.employmentInformation?.annualIncome),
            },
            {
                label: 'Monthly Household Income (MYR)',
                data: emptyCheck(item.personalDetails?.monthlyHouseholdIncome),
            },
            {
                label: 'Employer Name',
                data: emptyCheck(item.employmentInformation?.nameOfEmployer),
            },
        );
        const _subSectionEmployment: ISection = {
            sectionTitle: { title: 'Employer’s Address', titleIcon: 'location' },
            data: [],
        };
        _subSectionEmployment.data.push(
            {
                label: 'Employer’s Address 1',
                data: emptyCheck(item.employmentInformation?.address?.address?.line1),
            },
            {
                label: 'Employer’s Address 2',
                data: emptyCheck(item.employmentInformation?.address?.address?.line2),
            },
            {
                label: 'Employer’s Address 3',
                data: emptyCheck(item.employmentInformation?.address?.address?.line3),
            },
            // {
            //     label: 'Employer’s Address 4',
            //     data: emptyCheck(item.employmentInformation?.address?.address?.line4),
            // },
            {
                label: 'Postcode',
                data: emptyCheck(item.employmentInformation?.address?.postCode),
            },
            {
                label: 'City / Town',
                data: emptyCheck(item.employmentInformation?.address?.city),
            },
            {
                label: 'State',
                data: emptyCheck(item.employmentInformation?.address?.state),
            },
            {
                label: 'Country',
                data: emptyCheck(item.employmentInformation?.address?.country),
            },
        );
        //Push sub section
        _employment.sections.push(_subSectionEmployment);

        //FATCA section
        const _fatca: ISummarySection = {
            title: 'Foreign Account Tax Compliance Act (FATCA) Declaration',
            sections: [{ data: [] }],
        };
        _fatca.sections[0].data.push({
            label: `U.S. Citizenship`,
            data: formatFatca(emptyCheck(item.fatcaDeclaration?.usCitizen).toLowerCase()),
        });
        if (emptyCheck(item.fatcaDeclaration?.usCitizen).toLowerCase() !== 'true') {
            _fatca.sections[0].data.push({
                label: `Born in the U.S.`,
                data: formatFatca(emptyCheck(item.fatcaDeclaration?.usBorn?.toLowerCase())),
            });
        }
        //Push additional FATCA info if investor is US Born
        if (emptyCheck(item.fatcaDeclaration?.usBorn).toLowerCase() === 'true') {
            _fatca.sections[0].data.push({
                label: `Certificate of Loss of Nationality`,
                data:
                    item?.fatcaDeclaration?.certificate && item.fatcaDeclaration?.certificate?.name !== null
                        ? 'Yes'
                        : 'No',
            });
            if (item?.fatcaDeclaration?.certificate === null || item.fatcaDeclaration?.certificate?.name === null) {
                _fatca.sections[0].data.push({
                    label: `Reason for Certificate Not Available`,
                    data: emptyCheck(item.fatcaDeclaration?.reason),
                });
            }
            if (item.fatcaDeclaration?.confirmAddress) {
                _fatca.sections[0].data.push({
                    label: `Correspondence Declaration`,
                    data: formatFatca(emptyCheck(item.fatcaDeclaration?.confirmAddress).toLowerCase()),
                });
            }

            if (emptyCheck(item.fatcaDeclaration?.confirmAddress).toLowerCase() === 'true')
                _fatca.sections[0].data.push({
                    label: 'Malaysian Address',
                    data: `${getDeclarationAddress(
                        item.addressInformation ? item.addressInformation.mailingAddress?.address : null,
                    )}, ${item.addressInformation?.mailingAddress.postCode}, ${
                        item.addressInformation?.mailingAddress.city
                    }, ${item.addressInformation?.mailingAddress.state}, ${
                        item.addressInformation?.mailingAddress.country
                    }`,
                });

            if (item.fatcaDeclaration?.formW8Ben ?? false) {
                _fatca.sections[0].data.push({
                    label: 'W-8BEN Form',
                    data: item.fatcaDeclaration?.formW8Ben?.name !== null ? 'Yes' : 'No',
                });
            }
        }
        if (item.fatcaDeclaration?.formW9 ?? false) {
            _fatca.sections[0].data.push({
                label: 'W-9 Form',
                data: item.fatcaDeclaration?.formW9?.name !== null ? 'Yes' : 'No',
            });
        }
        if (item.fatcaDeclaration?.pendingDoc ?? false) {
            _fatca.sections[0].data.push({
                label: 'Physical Document to Submit',
                data: item.fatcaDeclaration?.pendingDoc ?? '-',
            });
        }
        //CRS section
        const _crsDeclaration: ISummarySection = {
            title: 'Common Reporting Standard (CRS) Declaration',
            sections: [{ data: [] }],
        };
        _crsDeclaration.sections[0].data.push({
            label: `Country / Jurisdiction of Residence Declaration`,
            data: emptyCheck(item.crsDeclaration?.taxResident),
        });

        if (item.crsDeclaration?.tin[0]?.country) {
            item.crsDeclaration.tin.map((ele, index) => {
                //Sub section in Identification section
                const _subSectionTin: ISection = {
                    sectionTitle: { title: `Taxpayer Identification ${index + 1}`, titleIcon: 'profile-card-2' },
                    data: [],
                };
                // const _tinData: { label: string; data: string }[] = [];
                _subSectionTin.data.push(
                    {
                        label: `TIN Residence Country`,
                        data: emptyCheck(ele.country),
                    },
                    {
                        label: `Tax Identification Number (TIN)`,
                        data: emptyCheck(ele.tinNumber),
                    },
                    {
                        label: `TIN Reasons`,
                        data: emptyCheck(ele.reason),
                    },
                );
                //Push subsection
                _crsDeclaration.sections.push(_subSectionTin);
            });
        }

        let tempProfile: TOrderSummaryProfileSections = {
            name: item.name,
            id: hyphenateNric(item.idNumber),
            idType: item.idType,
            idProof: item.idFile,
            principal: item.principal,
            personalDetails: _identification,
            isEtb: item.isEtb ?? false,
        };
        if (_contact.sections[0].data.length > 0) {
            tempProfile = {
                ...tempProfile,
                contactDetails: _contact,
            };
        }
        if (_permanentAddress.sections[0].data.length > 0) {
            tempProfile = {
                ...tempProfile,
                addressInformation: _permanentAddress,
            };
        }

        if (_employment.sections[0].data.length > 0) {
            tempProfile = {
                ...tempProfile,
                employmentInformation: _employment,
            };
        }

        if (_fatca.sections[0].data.length > 0) {
            tempProfile = {
                ...tempProfile,
                fatcaDeclaration: _fatca,
            };
        }
        if (_crsDeclaration.sections[0].data.length > 0) {
            tempProfile = {
                ...tempProfile,
                crsDeclaration: _crsDeclaration,
            };
        }

        profile.push(tempProfile);
    });

    // Account Tab
    const accountSummary: IAccountSummary = {};

    //Correspondence address section push data only is Sales AO and ETB

    if (orderSummary.transactionType.toLowerCase() === 'sales-ao' && !orderSummary.isEtb) {
        const _correspondenceAddress: IProfileSection = { title: 'Correspondence Address', sections: [] };
        orderSummary.profile.map((item) => {
            const _dataArray: IDataWithLabel[] = [];
            _dataArray.push(
                {
                    label: 'Correspondence Address 1',
                    data: emptyCheck(item.addressInformation?.mailingAddress?.address.line1),
                },
                {
                    label: 'Postcode',
                    data: emptyCheck(item.addressInformation?.mailingAddress?.postCode),
                },
                {
                    label: 'City / Town',
                    data: emptyCheck(item.addressInformation?.mailingAddress?.city),
                },
                {
                    label: 'State',
                    data: emptyCheck(item.addressInformation?.mailingAddress?.state),
                },
                {
                    label: 'Country',
                    data: emptyCheck(item.addressInformation?.mailingAddress?.country),
                },
            );
            emptyCheck(item.addressInformation?.mailingAddress?.address.line2) !== '-'
                ? _dataArray.splice(1, 0, {
                      label: 'Correspondence Address 2',
                      data: emptyCheck(item.addressInformation?.mailingAddress?.address.line2),
                  })
                : null;
            emptyCheck(item.addressInformation?.mailingAddress?.address.line3) !== '-'
                ? _dataArray.splice(2, 0, {
                      label: 'Correspondence Address 3',
                      data: emptyCheck(item.addressInformation?.mailingAddress?.address.line3),
                  })
                : null;
            emptyCheck(item.addressInformation?.mailingAddress?.address.line4) !== '-'
                ? _dataArray.splice(3, 0, {
                      label: 'Correspondence Address 4',
                      data: emptyCheck(item.addressInformation?.mailingAddress?.address.line4),
                  })
                : null;
            const _sectionTemp: ISection = { sectionTitle: {}, data: [] };
            if (orderSummary.profile.length === 2) {
                if (item.principal && (item.isEtb === false || item.isEtb === null)) {
                    if (_sectionTemp.sectionTitle) {
                        _sectionTemp.sectionTitle.title = LABEL.principalHolder;
                        _sectionTemp.sectionTitle.titleIcon = 'single-avatar';
                        _sectionTemp.sectionTitle.badgeColor = '#4D4D4D';
                        _sectionTemp.sectionTitle.badgeStyle = {
                            border: 'none',
                            fontSize: '0.75rem',
                            lineHeight: '1rem',
                        };
                        _sectionTemp.sectionTitle.badgeLabel = item.name;
                        _sectionTemp.data = [..._dataArray];
                    }
                }
                if (!item.principal && (item.isEtb === false || item.isEtb === null)) {
                    if (_sectionTemp.sectionTitle) {
                        _sectionTemp.sectionTitle.title = LABEL.jointHolder;
                        _sectionTemp.sectionTitle.titleIcon = 'joint-avatar';
                        _sectionTemp.sectionTitle.badgeColor = '#4D4D4D';
                        _sectionTemp.sectionTitle.badgeStyle = {
                            border: 'none',
                            fontSize: '0.75rem',
                            lineHeight: '1rem',
                        };
                        _sectionTemp.sectionTitle.badgeLabel = item.name;
                    }
                    _sectionTemp.data = [..._dataArray];
                }

                _correspondenceAddress.sections.push(_sectionTemp);
            } else {
                const tempSection: ISection = { data: [..._dataArray] };
                _correspondenceAddress.sections.push(tempSection);
            }
        });

        accountSummary.correspondenceAddress = _correspondenceAddress;
    }

    //Account settings  section push data
    if (!['switch', 'redeem'].includes(orderSummary.transactionType.toLowerCase())) {
        const _settings: IProfileSection = { title: 'Account Settings', sections: [{ data: [] }] };
        _settings.sections[0].data.push({
            label: 'Income Distribution',
            data: emptyCheck(orderSummary?.investmentSummary[0]?.distributionInstructions),
        });

        orderSummary.transactionDetails[0].accountOperationMode !== null &&
        orderSummary.transactionDetails[0].accountOperationMode !== undefined
            ? _settings.sections[0].data.push({
                  label: 'Authorised Signatory',
                  data: emptyCheck(orderSummary.transactionDetails[0].accountOperationMode),
              })
            : null;
        orderSummary.profile[principalIndex].personalDetails.relationship !== null &&
        orderSummary.profile[principalIndex].personalDetails.relationship !== undefined
            ? _settings.sections[0].data.push({
                  label: 'Relationship with Joint Holder',
                  data: emptyCheck(orderSummary.profile[principalIndex].personalDetails.relationship),
              })
            : null;
        accountSummary.settings = _settings;
    }

    //Banking details section
    const _bankInformation: IProfileSection = { title: 'Banking Details', sections: [] };
    if (orderSummary.profile.length > 0 && orderSummary.profile[principalIndex].bankInformation !== null) {
        orderSummary.profile[principalIndex].bankInformation.localBank !== null &&
        orderSummary.profile[principalIndex].bankInformation.localBank !== undefined
            ? orderSummary.profile[principalIndex].bankInformation.localBank.forEach((bank, index) => {
                  const tempObj: ISection = {
                      sectionTitle: { title: `Local Bank ${index !== 0 ? index + 1 : ''}`, titleIcon: 'bank1' },
                      data: [],
                  };
                  tempObj.data.push({ label: LABEL.currency, data: emptyCheck(bank.currency.toString()) });
                  tempObj.data.push({ label: 'Bank Name', data: emptyCheck(bank.bankName) });
                  tempObj.data.push({ label: 'Account Holder’s Name', data: emptyCheck(bank.bankAccountName) });
                  tempObj.data.push({ label: 'Bank Account Number', data: emptyCheck(bank.bankAccountNumber) });
                  bank.bankSwiftCode !== undefined && bank.bankSwiftCode !== null
                      ? tempObj.data.push({ label: 'Bank Swift Code', data: emptyCheck(bank.bankSwiftCode) })
                      : null;
                  _bankInformation.sections.push(tempObj);
              })
            : null;
        orderSummary.profile[principalIndex].bankInformation.foreignBank !== null &&
        orderSummary.profile[principalIndex].bankInformation.foreignBank !== undefined
            ? orderSummary.profile[principalIndex].bankInformation.foreignBank.forEach((bank, index) => {
                  const tempObj: ISection = {
                      sectionTitle: { title: `Foreign Bank ${index + 1}`, titleIcon: 'bank1' },
                      data: [],
                  };
                  tempObj.data.push({ label: LABEL.currency, data: emptyCheck(bank.currency.toString()) });
                  tempObj.data.push({ label: 'Bank Name', data: emptyCheck(bank.bankName) });
                  tempObj.data.push({ label: 'Account Holder’s Name', data: emptyCheck(bank.bankAccountName) });
                  tempObj.data.push({ label: 'Bank Account Number', data: emptyCheck(bank.bankAccountNumber) });
                  tempObj.data.push({ label: 'Bank Swift Code', data: emptyCheck(bank.bankSwiftCode) });
                  tempObj.data.push({ label: 'Bank Location', data: emptyCheck(bank.bankLocation) });
                  _bankInformation.sections.push(tempObj);
              })
            : null;

        _bankInformation.sections.length > 0 && (accountSummary.bankInformation = _bankInformation);
    }

    const orderSummaryRemapped: IOrderSummaryProps = {
        transactionSummary: transactionSummary,
        profile: showProfileTab(
            orderSummary.isEtb,
            orderSummary.transactionType.toLowerCase(),
            orderSummary.profile,
            principalIndex,
            profile,
        ),
        documentSummary: documentSummary,
        account: showAccountTab(orderSummary.transactionType.toLowerCase(), orderSummary.profile[principalIndex])
            ? accountSummary
            : undefined,
    };
    return orderSummaryRemapped;
};

const showProfileTab = (
    isEtb: boolean | undefined,
    transactionType: string,
    profile: TOrderSummaryProfile[],
    principalIndex: number,
    profileTabData: TOrderSummaryProfileSections[],
): undefined | TOrderSummaryProfileSections[] => {
    if (!isEtb && transactionType === 'sales-ao') {
        const temp: number[] = [];
        profileTabData.map((item, index) => {
            if (item.isEtb === false || item.isEtb === null) temp.push(index);
        });
        const isNtbAoJoint = temp.length === 2 ? true : false;
        if (!isNtbAoJoint) {
            let ntbProfile = -1;
            profile.map((item, index) => {
                if (item.isEtb === false || item.isEtb === null) ntbProfile = index;
            });
            return [profileTabData[ntbProfile]];
        } else return profileTabData;
    } else if (isEtb && transactionType === 'sales') {
        if (profile[principalIndex].epfDetails !== null && profile[principalIndex].epfDetails?.epfMemberNumber) {
            return undefined;
        } else return undefined;
    }
};

const showAccountTab = (transactionType: string, profile: TOrderSummaryProfile): undefined | boolean => {
    // Account Opening
    if (transactionType === 'sales-ao') return true;
    // Switching and Sales
    else {
        if (profile.bankInformation)
            return profile.bankInformation?.foreignBank.length > 0 || profile.bankInformation?.localBank.length > 0;
        else return undefined;
    }
};

const handleIDtype = (idType: string) => {
    switch (idType.toLowerCase()) {
        case 'nric':
        case 'passport':
            return 'Number';
        default:
            return 'ID Number';
    }
};
