import gql from 'graphql-tag';

/**
 * @eddDashboard @mutation - EDD Dashboard
 * @description: case status cancelRequest
 */

export const cancelCaseMutation = /* GraphQL */ gql`
    mutation caseStatus($input: CaseStatusInput) {
        caseStatus(input: $input) {
            data {
                result {
                    status
                    message
                }
            }
            error {
                errorCode
                message
                statusCode
            }
        }
    }
`;
