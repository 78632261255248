import gql from 'graphql-tag';

export const allPendingSubmissionBranchQuery = gql`
    query allPendingDashboard($input: BranchDashboardInput) {
        allPendingDashboard(input: $input) {
            data {
                result {
                    filters {
                        transactionType
                        accountType
                    }
                    statuses {
                        tab
                        count
                    }
                    groupedTransactions {
                        grouping {
                            type
                            groupingData {
                                key
                                value
                            }
                            transactionData {
                                grouping {
                                    type
                                    groupingData {
                                        principalName
                                        principalNumber
                                        orderNo
                                        jointName
                                        jointNumber
                                        sameBranch: viewable
                                    }
                                    transactionData {
                                        data {
                                            createdOn
                                            lastUpdated
                                            orderNo
                                            accountType
                                            clientName
                                            clientIdNum
                                            transactionType
                                            fundType
                                            paymentMethod
                                            totalInvestment {
                                                fundCurrency
                                                investmentAmount
                                            }
                                            totalUnits
                                            agentName
                                            agentCode
                                            status
                                            aging
                                        }
                                    }
                                }
                            }
                        }
                    }
                    statuses {
                        tab
                        count
                    }
                    page
                    pages
                    totalResultCount
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
