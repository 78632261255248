import React, { FunctionComponent } from 'react';
import { LABEL } from '../../../constants';
import { IcoMoon } from '../../../icons';
import { sh48 } from '../../../styles';
import { FlexedDiv } from '../../atoms';
import styled from 'styled-components';
import CustomCheckbox from '../../atoms/Checkbox';

interface TableHeaderProps {
    actionsLabel?: string;
    columns: ITableColumn[];
    data: ITableData[];
    handleSelectHeaderCheck?: () => void;
    hasHeaderCheckbox?: boolean;
    headerCheckbox?: boolean;
    headerCheckboxDisabled?: boolean;
    headerCheckboxInter?: boolean;
    rowSelectionLabel?: string;
    testId?: string;
    withActions: boolean;
    headerBorder?: boolean;
}
type TableHeaderWrapProps = {
    borderBottom: boolean;
};
export const TableHeader: FunctionComponent<TableHeaderProps> = ({
    actionsLabel,
    columns,
    data,
    handleSelectHeaderCheck,
    hasHeaderCheckbox,
    headerCheckbox,
    headerCheckboxDisabled,
    headerCheckboxInter,
    rowSelectionLabel,
    testId,
    withActions,
    headerBorder,
}: TableHeaderProps) => {
    return (
        <TableHeaderWrap
            id="table-header"
            data-testid={`${testId}`}
            borderBottom={headerBorder !== undefined ? headerBorder : true}
        >
            {rowSelectionLabel !== undefined ? (
                <div
                    style={{
                        width: sh48,
                        flexDirection: 'row',
                        display: 'inline',
                        fontSize: '0.75rem',
                        textAlign: 'center',
                        color: 'rgba(42, 54, 90, 0.8)',
                        mixBlendMode: 'normal',
                    }}
                >
                    <div style={{ letterSpacing: -0.33 }} data-testid="row-selection-label">
                        {rowSelectionLabel}
                    </div>
                </div>
            ) : null}
            {hasHeaderCheckbox ? (
                <FlexedDiv
                    direction="row"
                    justifyContent="center"
                    style={{
                        paddingRight: '1rem',
                        width: sh48,
                        mixBlendMode: 'normal',
                    }}
                    id="flexDiv"
                >
                    <CustomCheckbox
                        handleCheckbox={handleSelectHeaderCheck}
                        checked={headerCheckbox ?? false}
                        disabled={headerCheckboxDisabled}
                        id={`${testId}-checkbox-all`}
                        indeterminate={headerCheckboxInter}
                    />
                </FlexedDiv>
            ) : null}
            <TableHeaderInner>
                {columns.map((item: ITableColumn, index: number) => {
                    return (
                        <ColumnItem
                            style={{ ...item.viewStyle }}
                            key={index}
                            id={`${item.title.replace(/ +/g, '').toLowerCase()}${
                                item.subtitle !== undefined ? item.subtitle.replace(/ +/g, '').toLowerCase() : ''
                            }-column-btn`}
                        >
                            <ColumnItemText style={{ ...item.titleStyle }}>
                                <div>{item.title}</div>
                                {item.subtitle !== undefined ? (
                                    <div
                                        style={{
                                            fontWeight: 400,
                                            fontSize: '0.625rem',
                                            lineHeight: '.75rem',
                                            color: '#66798E',
                                        }}
                                    >
                                        {item.subtitle}
                                    </div>
                                ) : null}
                            </ColumnItemText>
                            {item.icon === undefined ? null : item.RenderHeaderMenu !== undefined ? (
                                <item.RenderHeaderMenu icon={item.icon} data={data} data-testid="header-menu" />
                            ) : (
                                <div
                                    style={{ display: 'flex', paddingLeft: '4px', cursor: 'pointer' }}
                                    onClick={item.onPressHeader}
                                    id="colum-sort-filter-icon"
                                >
                                    <IcoMoon
                                        name={item.icon.name}
                                        size={item.icon.size ? item.icon.size : '1rem'}
                                        style={{ ...item.icon.style }}
                                    />
                                </div>
                            )}
                        </ColumnItem>
                    );
                })}
                {withActions ? (
                    <div
                        style={{
                            minWidth: '3.33vw',
                            display: 'flex',
                            fontSize: '0.75rem',
                            textAlign: 'left',
                            color: 'rgba(42, 54, 90, 0.8)',
                            mixBlendMode: 'normal',
                        }}
                    >
                        <div style={{ letterSpacing: -0.33 }}>
                            {actionsLabel && actionsLabel ? actionsLabel : LABEL.actions}
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </TableHeaderInner>
        </TableHeaderWrap>
    );
};

const TableHeaderWrap = styled.div<TableHeaderWrapProps>`
    flex-direction: row;
    align-items: flex-start;
    max-height: 32px;
    display: flex;
    margin-top: 1.5rem;
    border-bottom: ${(props) => (props.borderBottom ? `1px solid #ececec` : `0px`)};
    padding: 0px 1.5rem;
`;
const TableHeaderInner = styled.div`
    display: flex;
`;
const ColumnItem = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 0.55vw;
    align-items: flex-start;
    min-height: 32px;
`;
const ColumnItemText = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    line-height: 14px;
    border: 1px solid transparent;
    color: #002043;
    mix-blend-mode: normal;
    letter-spacing: -0.33;
`;
