export const systemAdminDashboard = /* GraphQL */ `
    query sysAdDashboard($input: DashboardInput) {
        systemAdminDashboard(input: $input) {
            data {
                result {
                    overview {
                        totalSuspendedUsers
                        totalActiveUsers
                        totalActiveGroups
                        totalActiveRoles
                        totalEditUserRequest
                        totalBulkImportRequest
                        totalCreatedUserRequest
                        totalSuspendedUserRequest
                        totalReactivateUserRequest
                        totalTerminateUserRequest
                        totalGroupRequest
                        totalEditGroupRequest
                        totalSuspendedGroupRequest
                        totalReactivateGroupRequest
                        totalDeleteGroupRequest
                        totalRoleRequest
                        totalEditRoleRequest
                        totalSuspendedRoleRequest
                        totalReactivateRoleRequest
                        totalDeleteRoleRequest
                    }
                    bulkId
                    totalUserCount
                    totalGroupCount
                    totalRoleCount
                    transactions {
                        isSeen
                        userId
                        staffName
                        loginId
                        department
                        userGroup
                        lastLogin
                        # GROUP TAB
                        groupId
                        groupName
                        branch
                        userCount
                        roleCount
                        # ROLE TAB
                        roleId
                        roleName
                        description
                        accessLevel
                        # GENERIC
                        requestId
                        timestamp
                        status
                        source
                        lastUpdated
                    }
                    pages
                    page
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
