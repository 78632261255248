import { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
const HOME = '/';
const SIGNIN = '/home/signin';
export const usePreventBackNavigation = (): null => {
    const history = useHistory();
    const currentPathname = useRef<string>('');
    currentPathname.current = window.location.pathname;

    useEffect(() => {
        const listen = history.listen((location, action) => {
            if (action === 'POP') {
                if (
                    currentPathname.current !== location.pathname &&
                    location.pathname !== HOME &&
                    location.pathname !== SIGNIN &&
                    currentPathname.current !== HOME &&
                    currentPathname.current !== SIGNIN
                ) {
                    currentPathname.current = location.pathname;
                } else {
                    history.push({
                        pathname: currentPathname.current,
                        state: location.state,
                    });
                }
            } else {
                currentPathname.current = location.pathname;
            }
        });
        return () => {
            listen();
        };
    }, [history]);
    return null;
};
