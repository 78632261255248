import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FlexedDiv } from '../components';
import { IcoMoon } from '../icons';
import { investorColumnItemAvatarStyle, investorColumnItemIdStyle, investorColumnItemNameStyle } from '../styles';

interface IInvestorColumnItem {
    name: string;
    accountType: string;
    id: string;
}

export const InvestorColumnItem = ({ data, itemKey, type, isSortedColumn }: ITableCustomItem): JSX.Element => {
    const investor: IInvestorColumnItem = { name: '', accountType: '', id: '' };
    itemKey.map((item) => {
        const iKey = item.key;
        if (iKey === 'accountType') {
            investor.accountType = data[iKey];
        } else if (iKey === 'clientName') {
            investor.name = data[iKey];
        } else {
            investor.id = data[iKey];
        }
    });

    return (
        <Fragment>
            <FlexedDiv>
                {type === undefined ? (
                    <FlexedDiv alignItems="center" justifyContent="center" style={investorColumnItemAvatarStyle}>
                        {investor.accountType !== null ? (
                            <IcoMoon
                                name={investor.accountType.toLowerCase() === 'individual' ? 'avatar' : 'joint'}
                                size="1.15rem"
                            />
                        ) : (
                            <IcoMoon name={'avatar'} size="1.15rem" />
                        )}
                    </FlexedDiv>
                ) : null}

                <FlexedDiv direction="column">
                    <div style={investorColumnItemNameStyle}>
                        <span style={{ fontWeight: isSortedColumn === 'clientName' ? 700 : 'normal' }}>
                            {investor.name}
                        </span>
                        {data.isSeen ? null : <Badge />}
                    </div>
                    <div style={investorColumnItemIdStyle}>{investor.id}</div>
                </FlexedDiv>
            </FlexedDiv>
        </Fragment>
    );
};

const Badge = styled.div`
    width: 0.5rem;
    height: 0.5rem;
    background: #c61230;
    border-radius: 50%;
    margin-left: 0.25rem;
    margin-bottom: 0.5rem !important;
    display: inline-block;
`;
