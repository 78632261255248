import gql from 'graphql-tag';

/**
 * @systemSettingsDashboard @query - System Settings Dashboard
 * @description:  Returns all EPF data
 */

export const systemSettingsDashboardQuery = gql`
    query systemSettingsDashboard($input: DashboardInput) {
        systemSettingsDashboard(input: $input) {
            data {
                result {
                    settings {
                        settingsId
                        displayName
                        description
                        status
                        currentStatus
                        requestStatus
                        requestId
                        effectiveDate
                        lastUpdated
                        isSeen
                        requestEffectiveDate
                    }
                    totalResultCount
                    page
                    pages
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
