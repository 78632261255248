import React, { Fragment } from 'react';
import { FlexedDiv, Section } from '../components';
import { LABEL, TextNavyBlue } from '../constants';
import { IcoMoon } from '../icons';

interface multiSectionProps {
    sections: ISection[] | ISectionWithSubSections[];
    noSectionImage?: string;
    noShowLabel?: boolean;
    customGrid?: string;
}
export const MultiSection: React.FC<multiSectionProps> = ({
    sections,
    noSectionImage,
    noShowLabel,
}: multiSectionProps) => {
    return (
        <Fragment>
            {sections &&
                sections.map(({ data, sectionTitle, subSection, titleBanner, customGrid, separator }, index) => (
                    <Fragment key={`section${index}`}>
                        {data ? (
                            <Section
                                sectionTitle={sectionTitle}
                                data={data}
                                customGrid={customGrid}
                                titleBanner={titleBanner}
                                {...(separator && { separator })}
                            />
                        ) : (
                            <Section
                                sectionTitle={sectionTitle}
                                subSection={subSection}
                                customGrid={customGrid}
                                titleBanner={titleBanner}
                                {...(separator && { separator })}
                            />
                        )}
                        {/* {sections.length !== index + 1 ? <Divider /> : null} */}
                    </Fragment>
                ))}
            {sections.length === 0 && !noShowLabel && (
                <FlexedDiv direction="column" alignItems={'center'} margin={'32px 0 40px 0'}>
                    <IcoMoon name={noSectionImage ?? ''} size={'160px'} />
                    <TextNavyBlue weight="700">{LABEL.noInformation}</TextNavyBlue>
                </FlexedDiv>
            )}
        </Fragment>
    );
};
