import React, { Fragment } from 'react';
import styled from 'styled-components';
import { FlexedDiv } from '../components';

export const StaffNameColumnItem = ({ data, isSortedColumn }: ITableCustomItem): JSX.Element => {
    const isBoldCondition =
        isSortedColumn === 'name' || isSortedColumn === 'groupName' || isSortedColumn === 'roleName';
    return (
        <Fragment>
            <FlexedDiv direction="column" justifyContent="center" style={{ padding: '0.5rem', width: '100%' }}>
                <Title isBold={isBoldCondition}>
                    {data.name !== undefined && data.name}
                    {data.groupName !== undefined && data.groupName}
                    {data.roleName !== undefined && data.roleName}
                    {data.isSeen ? null : <Badge />}
                </Title>
                <SubTitle> {data.code}</SubTitle>
            </FlexedDiv>
        </Fragment>
    );
};

const Title = styled.div<IOnPropStyles>`
    font-size: 0.875rem;
    line-height: 1.2rem;
    letter-spacing: -0.2px;
    color: #002043;
    font-weight: ${(props) => (props.isBold ? 700 : 400)};
    width: 98%;
    word-break: break-word;
`;
const SubTitle = styled.p`
    font-size: 0.75rem;
    line-height: 1.2;
    letter-spacing: -0.333333px;
    color: #2a365a;
    opacity: 0.8;
    margin: 0px;
`;
const Badge = styled.div`
    width: 0.5rem;
    height: 0.5rem;
    background: #c61230;
    border-radius: 50%;
    /* margin-left: 0.25rem;
    margin-bottom: 0.3rem; */
    display: inline-block;
    vertical-align: top;
`;
