export const SearchOptionsBranch = {
    daily: [
        { item: 'All Categories', value: 'all' },
        { item: 'Account Number', value: 'accountNo' },
        { item: 'Adviser Name', value: 'agentName' },
        { item: 'Adviser Code', value: 'agentCode' },
        { item: 'Branch Name', value: 'branchName' },
        { item: 'Investor Name', value: 'clientName' },
        { item: 'Investor ID', value: 'clientIdNum' },
        { item: 'Order Number', value: 'orderNo' },
        { item: 'Transaction Number', value: 'transactionRef' },
    ],
    upcoming: [
        { item: 'All', value: 'all' },
        { item: 'Adviser Name', value: 'agentName' },
        { item: 'Adviser Code', value: 'agentCode' },
        { item: 'Investor Name', value: 'clientName' },
        { item: 'Investor ID', value: 'clientIdNum' },
        { item: 'Order Number', value: 'orderNo' },
        { item: 'User ID', value: 'userId' },
    ],
    pending: [
        { item: 'All Categories', value: 'all' },
        { item: 'Adviser Name', value: 'agentName' },
        { item: 'Adviser Code', value: 'agentCode' },
        { item: 'Branch Name', value: 'branchName' },
        { item: 'Investor Name', value: 'clientName' },
        { item: 'Investor ID', value: 'clientIdNum' },
        { item: 'Order Number', value: 'orderNo' },
    ],
};
export const SearchOptionsSystemAdmin = [
    [
        { item: 'All', value: 'all' },
        { item: 'User Name', value: 'name' },
        { item: 'User Id', value: 'userId' },
        { item: 'Department', value: 'department' },
        { item: 'User Group', value: 'groupName' },
        { item: 'Email', value: 'email' },
    ],
    [{ item: 'User Group', value: 'groupName' }],
    [{ item: 'Role Name', value: 'roleName' }],
];
export const SearchOptionsEddInvestors = {
    investors: [
        { item: 'All', value: 'all' },
        { item: 'Investors Name', value: 'clientName' },
        { item: 'Id', value: 'clientIdNum' },
    ],
};

export const SearchOptionsEddDashboard = {
    new: [
        { item: 'All', value: 'all' },
        { item: 'Adviser Name', value: 'agentName' },
        { item: 'Adviser Code', value: 'agentCode' },
        { item: 'Investor Name', value: 'clientName' },
        { item: 'Investor ID', value: 'clientIdNum' },
        { item: 'BDM Name', value: 'bdmName' },
        { item: 'EDD Case ID', value: 'caseNo' },
    ],
};

export const TrackOrderSearchOptions = [
    { item: 'Account Number', value: 'accountNo' },
    { item: 'Adviser Code', value: 'agentCode' },
    { item: 'Adviser Name', value: 'agentName' },
    { item: 'Investor ID', value: 'clientIdNum' },
    { item: 'Investor Name', value: 'clientName' },
    { item: 'Order Number', value: 'orderNo' },
    { item: 'Transaction Number', value: 'transactionNo' },
];
