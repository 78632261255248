import React, { Fragment, useState, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { CustomDropdown, FlexedDiv, IDropdownItem, PaginationDropdown } from '../../components';
import { LABEL } from '../../constants';
import { IcoMoon } from '../../icons';
import CounterTags from '../../components/atoms/CounterTags';
import StyledTab from '../../components/atoms/StyledTab';
import FilterPill from '../../components/molecules/FilterPills';

const TransactionsDashboard: React.FC<ITransactionsDashboardProps> = ({
    disableResultLimit,
    handleNext,
    handlePillFundType,
    handlePrevious,
    orderCounter,
    page,
    pages,
    pillFundType,
    resultLimit,
    selectedTab,
    setResultLimit,
    switchTabs,
    tabs,
    testId,
    typeCount,
    setPage,
    adjustTabMargin,
    pillOrderType,
    handlePillOrderType,
    orderTypeCount,
}: ITransactionsDashboardProps) => {
    const [previousLimit, setPreviousLimit] = useState(1);
    const [nextLimit, setNextLimit] = useState(10);
    const [pageNav, setPageNav] = useState<boolean[]>([false, false, false, false, false]);
    /**
     * Function to handle page limit selection
     *@method handlePageLimit
     *@param value type of string
     */
    const handlePageLimit = (value: string) => {
        // const valueInt = parseInt(value.toString(), 10);
        value && setResultLimit(parseInt(value.toString(), 10));
        handlePageNumber(1);
        setPreviousLimit(1);
        setNextLimit(10);
    };
    /**
     * Function to handle page number selection
     *@method handlePageNumber
     *@param value type of ReactText
     */
    const handlePageNumber = (value: React.ReactText) => {
        value && setPage(parseInt(value.toString()));
    };
    /**
     * Function to generate page limits
     *@method generatePageLimits
     *@returns IDropdownItem[]
     */
    const generatePageLimits = (): IDropdownItem[] => {
        const _items: IDropdownItem[] = [];
        limits.map((result) =>
            _items.push({ item: result.toString(), handleItem: handlePageLimit, testId: `${result}-option` }),
        );

        return _items;
    };
    /**
     * Function to generate page numbers when the maxPages is over 30 and fixed pagination flag is true
     *@method generatePageNumbers
     *@returns IPaginationDropdownItem[]
     */
    const generateFixedPageNumbers = (maxPages: number): IPaginationDropdownItem[] => {
        const _items: IPaginationDropdownItem[] = [];
        if (maxPages !== 0 && page !== undefined && maxPages > 30) {
            for (let i = previousLimit; i <= nextLimit; i++) {
                _items.push({
                    item: i.toString(),
                    handleItem: handlePageNumber,
                    testId: `${testId}-pagination-menu-${i}-option`,
                });
            }
        } else {
            for (let i = 1; i <= maxPages; i++) {
                _items.push({
                    item: i.toString(),
                    handleItem: handlePageNumber,
                    testId: `${testId}-pagination-menu-${i}-option`,
                });
            }
        }

        return _items;
    };

    /**
     * Function to handle the page navigation when the maxPages is over 30 and fixed pagination flag is true, here the state of the pageNav boolean array will be updated the element at position parameter will be changed to false
     *@method handlePageNav
     *@returns void
     */
    const handlePageNav = (position: number) => {
        const temp = [...pageNav];
        temp[position] = true;
        setPageNav(temp);
    };

    useMemo(() => {
        if (pages !== undefined && page !== undefined) {
            const index = pageNav.findIndex((item) => item === true);
            const temp = [false, false, false, false, false];
            const findPrevLimit = pages % 10;
            switch (index) {
                case 0:
                    setPreviousLimit(1);
                    setNextLimit(10);
                    setPageNav(temp);
                    break;
                case 1:
                    setPreviousLimit(findPrevLimit === 0 ? pages - 9 : pages + 1 - findPrevLimit);
                    setNextLimit(pages);
                    setPageNav(temp);
                    break;
                case 2:
                    setPreviousLimit((previousValue) => previousValue - 10);
                    setNextLimit((previousValue) =>
                        previousValue === pages ? previousValue - findPrevLimit : previousValue - 10,
                    );
                    setPageNav(temp);
                    break;
                case 3:
                    setPreviousLimit((previousValue) => previousValue + 10);
                    setNextLimit((previousValue) => (previousValue + 10 > pages ? pages : previousValue + 10));
                    setPageNav(temp);
                    break;
                case 4:
                    if (page > nextLimit) {
                        setPreviousLimit(page);
                        setNextLimit((previousValue) => previousValue + 10);
                        setPageNav(temp);
                    } else if (page < previousLimit) {
                        setPreviousLimit((previousValue) => previousValue - 10);
                        setNextLimit(page);
                        setPageNav(temp);
                    }
                    break;
            }
        }
    }, [pageNav, page]);

    return (
        <Fragment>
            <DashboardContainer data-testid="container_dashboard">
                <DashboardTop isOnProp={adjustTabMargin}>
                    {/* Dashboard tabs render Daily, Upcoming, Physical Doc, Rerouted, History */}
                    <TabContainer id="tab-container">
                        {tabs.map(({ name, testId }, index: number) => (
                            <StyledTab
                                active={selectedTab === index}
                                onClick={() => switchTabs(index)}
                                key={name}
                                id={testId}
                            >
                                {name}
                                <CounterTags active={selectedTab === index}>{orderCounter[index]}</CounterTags>
                            </StyledTab>
                        ))}
                    </TabContainer>
                    {/* Dashboard tabs render ends here */}
                    {/* Dashboard pagination starts */}
                    <PaginationContainer>
                        <CustomDropdown
                            items={generatePageLimits()}
                            noMinWidth={true}
                            disableDropdown={disableResultLimit}
                            // role={'button'}
                        >
                            <DropDownInner>
                                <DropDownSpan>{LABEL.item}</DropDownSpan>
                                <DropDownSpan>{resultLimit}</DropDownSpan>

                                <IcoMoon name="caret-down" size="1.5rem" color={'#002043'} />
                            </DropDownInner>
                        </CustomDropdown>

                        <PaginationDropdown
                            items={generateFixedPageNumbers(pages ?? 0)}
                            disableDropdown={pages === 1}
                            selectedPage={page ?? 0}
                            lastPage={pages}
                            limit={30}
                            topLimit={previousLimit}
                            bottomLimit={nextLimit}
                            onClickJumpToFirst={() => {
                                handlePageNumber(1);
                                handlePageNav(0);
                            }}
                            onClickJumpToLast={() => {
                                handlePageNumber(pages ?? 0);
                                handlePageNav(1);
                            }}
                            onClickPageNav={handlePageNav}
                        />

                        <MaxPageSpan>of {pages}</MaxPageSpan>

                        <FlexedDiv alignItems="center" style={{ opacity: page === 1 && pages === 1 ? 0.5 : 1 }}>
                            <CirclePrevBtn
                                className="card_buttons_back"
                                disabled={page === 1}
                                style={{
                                    cursor: page === 1 ? 'default' : 'pointer',
                                    opacity: page === 1 ? 0.5 : 1,
                                }}
                                onClick={() => {
                                    handlePrevious();
                                    handlePageNav(4);
                                }}
                                id={`${testId}-prev-btn`}
                            >
                                <IcoMoon name="caret-left" size="1.5rem" />
                            </CirclePrevBtn>

                            <CircleNextBtn
                                className="card_buttons_next"
                                disabled={pages === page}
                                style={{
                                    cursor: pages === page ? 'default' : 'pointer',
                                    opacity: pages === page ? 0.5 : 1,
                                }}
                                onClick={() => {
                                    handleNext();
                                    handlePageNav(4);
                                }}
                                id={`${testId}-next-btn`}
                            >
                                <IcoMoon
                                    color={pages === page ? '#97A3B1' : '#002043'}
                                    name="caret-right"
                                    size="1.5rem"
                                />
                            </CircleNextBtn>
                        </FlexedDiv>
                    </PaginationContainer>
                    {/* Dashboard pagination ends */}
                </DashboardTop>
                <DashboardBottom id="dashboard-bottom">
                    {/* Order Types and Fund Types filters stars */}
                    <QuickFilersContainer>
                        <FlexedDiv alignItems="center">
                            <FlexedDiv style={{ marginRight: '2.5rem' }} alignItems="baseline">
                                {pillOrderType?.tab === selectedTab ? (
                                    <Fragment>
                                        <PillLabel>{pillOrderType.label}</PillLabel>
                                        {pillOrderType.tags.map((item, index) => (
                                            <FilterPill
                                                active={item.active}
                                                key={index + 1}
                                                handleFilterPill={
                                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                                    handlePillOrderType || ((_item: IDashboardFilterTags) => null)
                                                }
                                                item={item}
                                                label={`${item.title} (${orderTypeCount[index]})`}
                                                testId={`${testId}-${item.testId}-pill-${item.index}`}
                                            />
                                        ))}
                                    </Fragment>
                                ) : null}
                            </FlexedDiv>
                            {pillFundType?.tab === selectedTab ? (
                                <Fragment>
                                    <PillLabel>{pillFundType.label}</PillLabel>
                                    {pillFundType.tags.map((item, index) => (
                                        <FilterPill
                                            active={item.active}
                                            key={index + 1}
                                            handleFilterPill={handlePillFundType}
                                            item={item}
                                            label={`${item.title} (${typeCount[index]})`}
                                            testId={`${testId}-${item.testId}-pill-${item.index}`}
                                        />
                                    ))}
                                </Fragment>
                            ) : null}
                        </FlexedDiv>
                        {/* {batchCutOffCount !== undefined &&
                        batchCutOffCount !== null &&
                        batchCutOffCount.length !== 0 ? (
                            <FlexedDiv style={{ marginLeft: '4rem' }} alignItems="baseline">
                                {pillCutOffTime?.tab === selectedTab ? (
                                    <Fragment>
                                        <PillLabel>{pillCutOffTime.label}</PillLabel>
                                        {pillCutOffTime.tags.map((item, index) => (
                                            <Fragment key={index + 1}>
                                                <FilterPill
                                                    active={item.active}
                                                    handleFilterPill={handlePillCutOffTime}
                                                    item={item}
                                                    label={`${item.title} (${batchCutOffCount[index]})`}
                                                    testId={`${testId}-${item.testId}-pill-${item.index}`}
                                                />
                                            </Fragment>
                                        ))}
                                    </Fragment>
                                ) : null}
                            </FlexedDiv>
                        ) : null} */}
                    </QuickFilersContainer>
                    {/* Order Types and Fund Types filters ends */}

                    {/* Transaction or Orders table */}
                    {tabs.map(({ table }, index: number) =>
                        selectedTab === index ? <div key={index}>{table}</div> : null,
                    )}
                </DashboardBottom>
            </DashboardContainer>
        </Fragment>
    );
};
export default TransactionsDashboard;

const limits = [10, 20, 50];
const PillLabel = styled.div`
    color: #4d4d4d;
    font-size: 0.75rem;
    margin-right: 0.5rem;
    text-transform: capitalize;
`;
const DashboardContainer = styled.div`
    background-color: #fbfbfb;
    border-radius: 22px;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 24%);
    margin-bottom: 8.5rem;
    min-height: 50vh;
`;
const DashboardTop = styled.div<IOnPropStyles>`
    padding-top: ${(props) => (props.isOnProp ? '12.5rem' : '10rem')};
    border-bottom: 1px solid #eaebee;
    display: flex;
    justify-content: space-between;
`;

const DashboardBottom = styled.div``;

const QuickFilersContainer = styled.div`
    display: flex;
    padding: 12px 1.5rem;
    border-bottom: 1px solid #ececec;
`;

const TabContainer = styled.div`
    display: flex;
`;
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
`;

const DropDownInner = styled.div`
    cursor: pointer;
    padding: 10px 12px;
    display: flex;
    align-items: center;
`;
const DropDownSpan = styled.span`
    margin-right: 5px;
    :nth-child(2) {
        font-weight: bold;
    }
`;
const MaxPageSpan = styled.span`
    color: #878787;
`;

const CircleBtn = styled.button<IOnPropStyles>`
    margin: 0px 0.25rem 0 1rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    border: 1px solid #002043;

    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ${(props: IOnPropStyles) =>
        props.disabled &&
        css`
           opacity : 0.4
            pointer-events: none;
        `}
`;

const CircleNextBtn = styled(CircleBtn)`
    margin: 0px 1.736vw 0px 4px;
`;
const CirclePrevBtn = styled(CircleBtn)`
    margin: 0px 4px 0px 1rem;
`;
