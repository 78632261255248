import React, { Fragment } from 'react';
import styled from 'styled-components';
import { IcoMoon } from '../../../icons';
import { FlexedDiv } from '../FlexDiv';

export const LinkButton = ({
    buttonColor,
    children,
    noFill,
    prefixIcon,
    suffixIcon,
    ...rest
}: ILinkButton): JSX.Element => {
    return (
        <Fragment>
            <StyledButton noFill={noFill} buttonColor={buttonColor} {...rest} data-testid="button">
                <FlexedDiv alignItems="center" justifyContent="space-between">
                    {prefixIcon !== undefined ? (
                        <Icon noFill={noFill} buttonColor={buttonColor}>
                            <IcoMoon name={prefixIcon} size="1rem" style={rest.iconStyle} />
                        </Icon>
                    ) : null}
                    {children}
                    {suffixIcon !== undefined ? (
                        <Icon noFill={noFill} buttonColor={buttonColor}>
                            <IcoMoon name={suffixIcon} size="1rem" style={rest.iconStyle} />
                        </Icon>
                    ) : null}
                </FlexedDiv>
            </StyledButton>
        </Fragment>
    );
};

const StyledButton = styled.button<ILinkButton>`
    color: ${(props) => props.buttonColor ?? '#002043'};
    box-shadow: ${(props) =>
        props.buttonColor && (props.noFill === undefined || props.noFill === false)
            ? `inset 0px -2px 0px ${props.buttonColor}`
            : 'none'};
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 15.6px;
    letter-spacing: 0em;
    text-align: center;
    background: rgba(255, 255, 255, 0.01);
    outline: none;
    border: none;
    padding: 4px 2px;
    cursor: pointer;
    &:hover {
        color: ${(props) =>
            props.noFill === undefined || props.noFill === false
                ? '#ffffff'
                : `linear-gradient(0deg, ${props.buttonColor}, #ffff)`};
        background: ${(props) =>
            props.noFill === undefined || props.noFill === false ? props.buttonColor : `inherit`};
    }
    &:focus {
        color: ${(props) =>
            props.noFill === undefined || props.noFill === false
                ? '#ffffff'
                : `linear-gradient(0deg, ${props.buttonColor}, #ffff)`};
        background: ${(props) =>
            props.noFill === undefined || props.noFill === false ? props.buttonColor : `inherit`};
    }
    &:disabled {
        opacity: 0.6;
        pointer-events: none;
    }
`;
const Icon = styled.div<ILinkButton>`
    cursor: pointer;
    fill: ${(props) => props.buttonColor};
    &:hover {
        fill: ${(props) =>
            props.noFill === undefined || props.noFill === false
                ? '#ffffff'
                : `linear-gradient(0deg, ${props.buttonColor}, ${props.buttonColor})`};
    }
`;
