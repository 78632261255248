import React, { CSSProperties, ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { InputValidationErrorText } from '../../../constants';
import { TextBold12 } from '../../../constants/textStyles';
import { IcoMoon } from '../../../icons';

import styled from 'styled-components';
import Container from '../../atoms/Container';
import { FlexedDiv } from '../..';

export interface NestedDropdownItemsProps {
    value: string;
    onClick?: () => void;
    options?: NestedDropdownItemsProps[];
}

interface NestedDropdownProps {
    placeholder?: string;
    suffixIcon?: ReactNode;
    data: NestedDropdownItemsProps[];
    value?: string;
    onChange?: (value: string) => void;
    style?: CSSProperties;
    label?: string;
    openStyle?: CSSProperties;
    dropDownBoxStyle?: CSSProperties;
    isSecondaryBlue?: boolean;
    errorMessage?: string;
}
interface INextedDropDownOptionProps {
    item: NestedDropdownItemsProps;
    onClick?: () => void;
    style?: CSSProperties;
    itemIndex: number;
    activeItem: number;
    setActiveItem: () => void;
    onChange?: (value: string) => void;
    value?: string;
    left?: number;
    parentHeight?: number;
    isSecondaryBlue?: boolean;
}
interface NextedDropDownListProps {
    data?: NestedDropdownItemsProps[];
    onChange?: (value: string) => void;
    value?: string;
    nested?: boolean;
    left?: number;
    top: number;
    parentHeight?: number;
    style?: CSSProperties;
    isSecondaryBlue?: boolean;
    directChild?: boolean;
}

type NestedDropDownListElmProps = {
    height: string;
    nested: boolean;
    top: string;
    left: string;
    borderColor: string;
    borderRadius: string;
};
type NextedOptionElmProps = {
    top: string;
    left: string;
};
type NextedOptionInnerProps = {
    backgroundColor: string;
};
const NextedDropDownOption = ({
    activeItem,
    itemIndex,
    style,
    item,
    onClick,
    setActiveItem,
    onChange,
    value,
    left,
    parentHeight,
    isSecondaryBlue,
}: INextedDropDownOptionProps) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [hovering, setHovering] = useState(false);

    const leftVal = left ? left : 0;
    const handleClick = () => {
        setActiveItem();
        item.options ? setOpenDropdown(true) : onChange ? onChange(item.value) : undefined;
        onClick ? onClick() : undefined;
    };

    return (
        <NextedOptionElm
            id="nested-drop-down-option"
            left={left !== undefined ? `${left.toString()}px` : 'auto'}
            top={(itemIndex * 40 + 20).toString()}
        >
            <NextedOptionInner
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                backgroundColor={hovering ? '#eee' : '#fff'}
                onClick={() => handleClick()}
            >
                {isSecondaryBlue ? (
                    <OptionInner>
                        <OptionValue>{item.value}</OptionValue>
                    </OptionInner>
                ) : (
                    <OptionInner>
                        <OptionValue>{item.value}</OptionValue>
                        {value && value.toLocaleLowerCase().trim() === item.value.toLowerCase().trim() ? (
                            <GreenCircle>
                                <IcoMoon name={'check'} size="0.9rem" color="#fff" />
                            </GreenCircle>
                        ) : null}
                    </OptionInner>
                )}
                {item.options ? (
                    <div style={{ marginLeft: 'auto' }}>
                        <IcoMoon name={'caret-right'} size="1.2rem" />
                    </div>
                ) : null}
            </NextedOptionInner>
            {openDropdown && itemIndex === activeItem ? (
                <NestedDropDownList
                    data={item.options}
                    value={value}
                    onChange={onChange}
                    nested
                    left={leftVal + 356}
                    // top={0}
                    top={parentHeight ? parentHeight - 24 : 0} //24 is 20 padding + 2*2 borderWidth
                    parentHeight={parentHeight}
                    style={{ borderColor: style?.borderColor }}
                />
            ) : null}
        </NextedOptionElm>
    );
};

export const NestedDropDownList = ({
    data,
    onChange,
    value,
    nested,
    left,
    top,
    parentHeight,
    style,
    isSecondaryBlue,
    directChild,
}: NextedDropDownListProps): JSX.Element => {
    const [activeItem, setActiveItem] = useState(-1);
    const handleActiveItem = (index: number) => {
        setActiveItem(index);
    };
    const height = data ? data.length * 40 + 2 * 20 : 0;

    return (
        <NestedDropDownListElm
            id="nexted-drop-down-list"
            height={height.toString()}
            borderColor={style?.borderColor ? style.borderColor : '#cbcbcb'}
            nested={nested ? nested : false}
            top={nested ? (top - 40 - 20).toString() : 'auto'}
            left={left ? `${left.toString()}px` : 'auto'}
            borderRadius={directChild ? '0px 0px 16px 16px' : '16px'}
        >
            <div style={{ position: 'relative' }}>
                {data &&
                    data.map((item, index) => (
                        <NextedDropDownOption
                            key={index}
                            item={item}
                            itemIndex={index}
                            activeItem={activeItem}
                            setActiveItem={() => handleActiveItem(index)}
                            onClick={item.onClick}
                            onChange={onChange}
                            value={value}
                            left={0}
                            parentHeight={parentHeight}
                            style={style}
                            isSecondaryBlue={isSecondaryBlue}
                        />
                    ))}
            </div>
        </NestedDropDownListElm>
    );
};

export const NestedDropdown = ({
    data,
    placeholder,
    suffixIcon,
    value,
    onChange,
    style,
    label,
    openStyle,
    dropDownBoxStyle,
    isSecondaryBlue, //textcolor
    errorMessage,
}: NestedDropdownProps): JSX.Element => {
    const [hovering, setHovering] = useState(false);
    const [hasFocus, setHasFocus] = useState(false);
    const [open1, setOpen1] = useState(false);
    const NDRef = useRef<HTMLDivElement>(null);

    // This useEffect detects the click outside of the component to reset opened dropdowns
    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function handleClick(e: any) {
            if (NDRef && NDRef.current) {
                const ref = NDRef.current as HTMLDivElement;
                if (!ref.contains(e.target)) {
                    setOpen1(false);
                    setHasFocus(false);
                }
            }
        }
    }, []);

    const handleClick = () => {
        setOpen1(!open1);
        setHasFocus(true);
    };

    let borderColor = style?.borderColor ? style?.borderColor : 'transparent';
    if (hovering || hasFocus) {
        borderColor = '#002043';
    }

    const handleChange = (newValue: string) => {
        onChange && onChange(newValue);
        setOpen1(false);
    };
    let offsetTop = 0;
    let componentHeight = 0;
    if (NDRef && NDRef.current) {
        offsetTop = NDRef.current.offsetTop + NDRef.current.clientHeight;
        componentHeight = NDRef.current.clientHeight;
    }

    return (
        <div ref={NDRef} style={{ width: 'fit-content', boxSizing: 'border-box', ...style }} id="nested-drop-down">
            {label ? <TextBold12>{label}</TextBold12> : null}
            <div
                onMouseDown={() => handleClick()}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '360px',
                    height: '40px',
                    borderTopRightRadius: '20px',
                    borderTopLeftRadius: '20px',
                    borderBottomRightRadius: open1 ? 0 : '20px',
                    borderBottomLeftRadius: open1 ? 0 : '20px',
                    backgroundColor: 'white',
                    borderWidth: open1 ? dropDownBoxStyle?.borderWidth || 1 : 1,
                    borderColor: open1 ? dropDownBoxStyle?.borderColor || borderColor : borderColor,
                    borderStyle: 'solid',
                    padding: '0 16px',
                    alignItems: 'center',
                    // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                    transition: 'all 0.2s ease',
                    outline: 'none',
                    boxSizing: 'border-box',
                }}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
            >
                {value ? (
                    <SelectedValue>{value}</SelectedValue>
                ) : placeholder ? (
                    <div style={{ color: '#C9C9CD' }}>{placeholder}</div>
                ) : null}
                <div
                    style={{
                        marginLeft: 'auto',
                        transform: open1 ? 'rotate(180deg)' : '',
                        transition: 'all 0.2s ease',
                    }}
                >
                    {suffixIcon}
                </div>
            </div>
            {open1 ? (
                <NestedDropDownList
                    data={data}
                    onChange={(value) => handleChange(value)}
                    value={value}
                    top={offsetTop}
                    parentHeight={componentHeight}
                    style={openStyle}
                    isSecondaryBlue={isSecondaryBlue}
                    directChild={true}
                />
            ) : null}
            {errorMessage !== undefined && errorMessage !== '' && !open1 ? (
                <Container visibility width="90%" style={{ marginBottom: '2rem' }}>
                    <FlexedDiv style={{ position: 'absolute', marginTop: '0.5rem', maxWidth: '336px' }}>
                        <IcoMoon name="validation-error" color="#E84C3D" style={{ height: '1rem', width: '1.25rem' }} />
                        <InputValidationErrorText>{errorMessage}</InputValidationErrorText>
                    </FlexedDiv>
                </Container>
            ) : null}
        </div>
    );
};
const NestedDropDownListElm = styled.div<NestedDropDownListElmProps>`
    height: ${(props) => props.height}px;
    background-color: 'white';
    border-color: ${(props) => props.borderColor};
    border-width: 2px;
    border-style: solid;
    box-sizing: border-box;
    position: absolute;
    left: ${(props) => props.left};
    width: 360px;
    top: ${(props) => `${props.top}px`};
    z-index: 5;
    border-radius: ${(props) => `${props.borderRadius}`};
    background-color: #fff;
`;
const NextedOptionElm = styled.div<NextedOptionElmProps>`
    position: absolute;
    boxsizing: border-box;
    width: 100%;
    left: ${(props) => props.left};
    top: ${(props) => `${props.top}px`};
`;
const NextedOptionInner = styled.div<NextedOptionInnerProps>`
    width: 356px;
    height: 40px;
    transition: all 300ms;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    outline: none;
    box-sizing: border-box;
    background-color: ${(props) => props.backgroundColor};
    cursor: pointer;
`;
const OptionInner = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const OptionValue = styled.span`
    font-size: 1rem;
    font-weight: 700;
`;
const GreenCircle = styled.div`
    width: 18px;
    height: 18px;
    background-color: #2ecc82;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
`;
const SelectedValue = styled.span`
    font-size: 1rem;
    font-weight: 700;
`;
