import React from 'react';
import { Fragment } from 'react';
import styled, { css } from 'styled-components';

interface IPillProps {
    active: boolean;
    disabled?: boolean;
}

const FilterPill = ({ active, label, handleFilterPill, item, testId, disable }: IFilterPillProps): JSX.Element => {
    return (
        <Fragment>
            <PillStyled
                active={active}
                disabled={disable}
                onClick={() => handleFilterPill(item)}
                data-testid={testId}
                id={testId}
            >
                {label}
            </PillStyled>
        </Fragment>
    );
};

const PillStyled = styled.button<IPillProps>`
    background: #f4f4f4;
    margin: 0rem 0.5rem 0rem 0rem;
    border: none;
    border-radius: 24px;
    min-height: 1.5rem;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem !important;
    line-height: 1rem;
    font-weight: 600;
    color: #002043;
    text-transform: capitalize;
    cursor: pointer;
    outline: none;
    ${(props) => (props.disabled ? 'opacity:0.6;' : '')}
    ${(props) => (props.disabled ? 'pointer-events:none;' : '')}
    &:hover {
        background: #eaebee;
    }
    ${(props: IPillProps) =>
        props.active &&
        css`
            color: #ffff;
            background: #002043;

            &:hover {
                background: rgba(0, 32, 67, 0.85);
            }
        `}
`;
export default FilterPill;
