import React, { ReactText } from 'react';
import { useState } from 'react';
import TrackOrderContext from './TrackOrderContext';
import { TrackOrderSearchOptions } from '../../constants';
import {
    branchTypesMultiSelect,
    statusTypesMultiSelect,
    transactionTypesMultiSelect,
} from '../../pages/DashboardBranch/helpers/filterDropdownsBranch';

interface ITrackOrderProviderProps {
    children: React.ReactNode;
}

export const TrackOrderProvider: React.FC<ITrackOrderProviderProps> = ({ children }: ITrackOrderProviderProps) => {
    const _initTrackFilters: ITrackFilters = {
        date: 'createdOn',
        dateRange: '',
        transactionType: [],
        status: [],
        branch: [],
        batchCutOff: [],
    };
    const defaultSearchInput: ISearchInput = {
        value: '',
        column: TrackOrderSearchOptions[5].value,
    };
    const [trackOrderInput, setTrackOrderInput] = useState<ISearchInput>(defaultSearchInput);

    const [filters, setFilters] = useState<ITrackFilters>(_initTrackFilters);
    const [sortData, setSortData] = useState<ISort_FilterInput>([{ column: 'lastUpdated', value: 'ascending' }]); //sort data for table

    //navigation states
    const [resultNumber, setResultNumber] = useState(0);
    const [resultLimit, setResultLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [createdOrUpdated, setCreatedOrUpdated] = useState('Last Updated');
    const [tempTargetDate, setTempTarget] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);

    //filter option state
    const [branchFilters, setBranchFilters] = useState<ReactText[]>(branchTypesMultiSelect);
    const [transactionTypeOptions, setTransactionTypeOptions] = useState<ReactText[]>(transactionTypesMultiSelect);
    const [statusOptions, setStatusOptions] = useState<ReactText[]>(statusTypesMultiSelect);
    const [batchCutOffOptions, setBatchCutOffOptions] = useState<ReactText[]>([]);

    const [previousPageURL, setPreviousPageURL] = useState<string>('');

    /**
     * function to run when input value changes
     */
    const onTrackOrderSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTrackOrderInput({ ...trackOrderInput, value: event.target.value });
    };

    const trackSearchOptions = (): IDropdownItemV2[] => {
        let _options: IDropdownItemV2[] = [];
        _options = TrackOrderSearchOptions.map((option) => {
            return {
                item: option.item,
                value: option.value,
                handleItem: () => setTrackOrderInput({ ...trackOrderInput, column: option.value }),
                testId: `track-order-search-filter-${option.item.split(' ').join('-').toLowerCase()}`,
            };
        });
        return _options;
    };

    const dateSorting: ISelectValue[] = [
        { label: 'Created On', value: 'createdOn' },
        { label: 'Last Updated', value: 'lastUpdated' },
    ];

    const ProviderValue: ITrackOrderContextProps = {
        trackOrderInput,
        defaultSearchInput,
        setTrackOrderInput,
        onTrackOrderSearch,
        trackSearchOptions,
        filters,
        setFilters,
        sortData,
        setSortData,
        initialFilter: _initTrackFilters,
        dateSorting,
        resultLimit,
        setResultLimit,
        resultNumber,
        setResultNumber,
        page,
        setPage,
        createdOrUpdated,
        setCreatedOrUpdated,
        tempTargetDate,
        setTempTarget,
        previousPageURL,
        setPreviousPageURL,
        branchFilters,
        batchCutOffOptions,
        transactionTypeOptions,
        statusOptions,
        setBranchFilters,
        setStatusOptions,
        setTransactionTypeOptions,
        setBatchCutOffOptions,
    };

    return <TrackOrderContext.Provider value={ProviderValue}>{children}</TrackOrderContext.Provider>;
};

export default TrackOrderProvider;
