/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { Fragment, ReactText, SetStateAction } from 'react';

import CustomCheckbox from '../../atoms/Checkbox';
import MultiSelect from '../../molecules/MultiSelect/MultiSelect';

interface ICheckboxWithMultiSelectProps extends ICustomCheckboxProps {
    data?: Array<ReactText>;
    handleData?: (index: SetStateAction<ReactText[]>) => void;
    placeholder: string;
    hasSelectAll?: boolean;
    selectLabel: string;
    multiSelectState: Array<ReactText>;
    handleMultiSelectDropdown: (item: Array<ReactText>, fn: string, id: number) => void;
}

const CheckboxWithMultiSelect = ({
    data,
    checked,
    id,
    label,
    value,
    placeholder,
    className,
    handleCheckbox,
    hasSelectAll,
    selectLabel,
    multiSelectState,
    handleMultiSelectDropdown,
}: ICheckboxWithMultiSelectProps): JSX.Element => {
    return (
        <Fragment>
            <div className={className}>
                <CustomCheckbox checked={checked} label={label} id={id} value={value} handleCheckbox={handleCheckbox} />
                <div style={{ marginTop: '0.75rem' }}>
                    {checked ? (
                        <MultiSelect
                            label={selectLabel}
                            placeHolder={placeholder}
                            options={data !== undefined ? data : ['']}
                            noOverlay
                            selectId={parseInt(id ?? '0')}
                            hasSelectAll={hasSelectAll}
                            handleMultiSelect={handleMultiSelectDropdown}
                            checkedState={multiSelectState}
                        />
                    ) : null}
                </div>
            </div>
        </Fragment>
    );
};
export default CheckboxWithMultiSelect;
