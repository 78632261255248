/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useReducer, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { ActivityLogsQuery } from '../../../_graphql/queries/systemadmin/activityLogs';
import { LABEL } from '../../../constants';

import ActivityLogsContext from './ActivityLogsContext';
import AuthContext from '../../AuthContext';
import ErrorHandlingContext from '../../ErrorHandling/ErrorHandlingContext';

interface IActivityLogsProviderProps {
    children: React.ReactNode;
}

export const ActivityLogsProvider: React.FC<IActivityLogsProviderProps> = ({
    children,
}: IActivityLogsProviderProps) => {
    const _initStates: IActivityLogsStates = {
        createdOn: '',
        roles:[],
        eventTypes: [],
        status: '',
        sortData: { column: 'createdOn', value: 'descending' },
        searchbarFilterTags: [],
    };

    // Dashboard functionality states
    const [tab, setTab] = useState(0);
    const [disableResultLimit, setDisableResultLimit] = useState<boolean>(false);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [logCounter, setLogCounter] = useState<Array<number>>([1, 1]);
    const [searchInput, setSearchInput] = useState({ value: '', column: 'all' });
    const [rolesFilter, setRolesFilter] = useState<string>(LABEL.roles);
    const [eventTypesFilter, setEventTypesFilter] = useState<string>(LABEL.eventType);
    const [statusFilter, setStatusFilter] = useState<string>(LABEL.status);
    const [resultLimit, setResultLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);



    // Dashboard tab data states
    const [allActivityData, setAllActivityData] = useState<ITableData[]>([]);
    const [allReportsData, setAllReportsData] = useState<ITableData[]>([]);
    
    // UI states
    const [loading, setLoading] = useState<boolean>(false);
    const { userLoginContext } = useContext(AuthContext);
   
    //to check for JWT token
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
   
    // Error handling
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);

    //handleFilter
    const handleTempFilter = (tempState: IActivityLogsStates, action: TActivityLogsInitState) => {
        const _tempFilter = { ...tempState };
        switch (action.type) {
            case 'createdOn':
            case 'status':
                _tempFilter[action.type] = action.payload as string;
                break;
            case 'roles':
            case 'eventTypes':
                _tempFilter[action.type] = action.payload as unknown as string[];
                break;
            case 'sortData':
                _tempFilter[action.type] = action.payload as IColumnValue;
                break;
            case 'searchbarFilterTags':
                _tempFilter[action.type] = action.payload as ISelectValue[];
                break;
            case 'reset':
                (_tempFilter.createdOn = ''),
                    (_tempFilter.roles = []),
                    (_tempFilter.eventTypes = []),
                    (_tempFilter.status = ''),
                    (_tempFilter.sortData = { column: 'createdOn', value: 'descending' }),
                    (_tempFilter.searchbarFilterTags = []);
                break;
        }
        return _tempFilter;
    };

    const [tempState, dispatch] = useReducer(handleTempFilter, {
        createdOn: _initStates.createdOn,
        roles: _initStates.roles,
        eventTypes: _initStates.eventTypes,
        status: _initStates.status,
        sortData: _initStates.sortData,
        searchbarFilterTags: _initStates.searchbarFilterTags,
    });

    //fn to handle loading
    const loadingHandler = () => {
        setLoading((prev) => !prev);
    };

    const handleInput = (column: string, value: string) => {
        if (value === '') {
          return undefined;
        }
        return {column, value};
      };
      
      const getData = () => {
        const filterInput = [];
        const dateInput = handleInput('createdOn', tempState.createdOn);
        const statusInput = handleInput('status', tempState.status);
        if (dateInput) filterInput.push(dateInput);
        tempState.roles.forEach((value) => {
          if (value) {
            filterInput.push({ column: 'roles', value });
          }
        });
        tempState.eventTypes.forEach((value) => {
            if (value) {
              filterInput.push({ column: 'eventType', value });
            }
          });
        if (statusInput) filterInput.push(statusInput);
        return filterInput;
      };

    //call query to get all activity log data
    const getActivityLogData = async () => {
        const filterInput = getData();
        loadingHandler();
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await API.graphql(
                graphqlOperation(ActivityLogsQuery, {
                    input: {
                        tab: tab === 0 ? 'userActivity' : 'reports',
                        page: currentPage,
                        resultLimit: resultLimit,
                        sort: tempState.sortData,
                        search: {
                            column: searchInput.column,
                            value: searchInput.value,
                        },
                        filter: filterInput,
                    },
                }),
                idTokenHeader,
            );

            if (response.data.activityLogDashboard.data !== null) {
                const { result } = response.data.activityLogDashboard.data;

                setData(result.logs);
                setDisableResultLimit(result.logs.length === 0);
                if (currentPage > result.pages) {
                    setCurrentPage(1)
                }
                setMaxPages(result.pages);
                setLogCounter([result.activityCount, result.reportCount]);
            } else throw response.data.activityLogDashboard.error;
            loadingHandler();
        } catch (error) {
            loadingHandler();
            const _error = error as IErrorHandling;
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Dashboard Data',
                    testId: 'activitylogs-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };
    //Fn to set data from query to states
    const setData = (data: ITableData[]) => {
        if (tab === 0) {
            setAllActivityData(data);
        } else {
            setAllReportsData(data);
        }
    };
    const ProviderValue: IActivityLogsContext = {
        allActivityData,
        allReportsData,
        disableResultLimit,
        eventTypesFilter,
        getActivityLogData,
        loading,
        loadingHandler,
        logCounter,
        maxPages,
        rolesFilter,
        searchInput,
        setEventTypesFilter,
        setLogCounter,
        setRolesFilter,
        setSearchInput,
        setStatusFilter,
        setTab,
        statusFilter,
        tab,
        tempState,
        dispatch,
        currentPage,
        setCurrentPage,
        resultLimit,
        setResultLimit,
    };
    return <ActivityLogsContext.Provider value={ProviderValue}>{children}</ActivityLogsContext.Provider>;
};
