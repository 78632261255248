import gql from 'graphql-tag';

export const ceCrQuery = /* GraphQL */ gql`
    query branchDashboardV2($input: BranchDashboardInput) {
        branchDashboardV2(input: $input) {
            data {
                result {
                    changeRequestsCount
                    dailyCount
                    filters {
                        transactionType
                        fundType
                        paymentMethod
                        accountType
                        branchStatus
                        riskCategory
                    }
                    hardcopyCount
                    historyCount
                    page
                    pages
                    rerouteCount
                    enableBulkVerify
                    transactions {
                        orderNo
                        transactionRef
                        accountType
                        accountNo
                        clientName
                        clientIdNum
                        transactionType
                        agentName
                        agentCode
                        lastUpdated
                        submittedOn
                        createdOn
                        status
                        remarks {
                            title
                            content
                        }
                        targetDate
                        aging
                        approvalType
                        riskCategory
                        isSeen
                        isVerified
                        requestId
                    }
                    transactionsCount
                    upcomingCount
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
