import styled, { css } from 'styled-components';
interface IStyledTabProps {
    active: boolean;
}
const StyledTab = styled.div`
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        cursor: pointer;
    }
    text-transform: capitalize;
    color: #66798e;
    font-size: 1rem;
    line-height: 23.68px;
    letter-spacing: 0em;
    font-weight: 600;
    ${(props: IStyledTabProps) =>
        props.active &&
        css`
            color: #002043;
            box-shadow: inset 0px -2px 0px #c61230;
        `}
`;
export default StyledTab;
