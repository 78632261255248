/* eslint-disable @typescript-eslint/no-explicit-any */
import { lazy } from 'react';
import * as ROUTES from '../../routes';

// System admin components
const SystemAdminDashboard = lazy<any>(() => import('../../pages/SystemAdmin/Dashboard/Dashboard'));
const AddUser = lazy<any>(() => import('../../pages/SystemAdmin/AddUser'));
const AddRoles = lazy<any>(() => import('../../pages/SystemAdmin/RoleSettings/AddRoles'));
const EditRoles = lazy<any>(() => import('../../pages/SystemAdmin/RoleSettings/EditRoles'));
const AddUserGroup = lazy<any>(() => import('../../pages/SystemAdmin/UserGroup/AddUserGroup/AddUserGroup'));
const EditUserGroup = lazy<any>(() => import('../../pages/SystemAdmin/UserGroup/EditUserGroup'));
const ImportUsers = lazy<any>(() => import('../../pages/SystemAdmin/BulkImportUsers/ImportUsers'));
const ViewImportUsers = lazy<any>(() => import('../../pages/SystemAdmin/BulkImportUsers/ViewImportUsers'));
const ReviewBulkImportUsers = lazy<any>(() => import('../../pages/SystemAdmin/BulkImportUsers/ReviewBulkImportUsers'));
const RejectBulkImportUsers = lazy<any>(() => import('../../pages/SystemAdmin/BulkImportUsers/RejectBulkImportUsers'));
const AdviserAccessDashboard = lazy<any>(() => import('../../pages/SystemAdmin/AdviserAccess/Dashboard'));
const SystemAdminAdviserProfile = lazy<any>(
    () => import('../../pages/SystemAdmin/AdviserProfile/SystemAdminAdviserProfile'),
);
const UserGroupViewInbox = lazy<any>(() => import('../../pages/Inbox/SystemAdminPages/UserGroupViewInbox'));
const RolesViewInbox = lazy<any>(() => import('../../pages/Inbox/SystemAdminPages/RolesViewInbox'));
const UserViewInbox = lazy<any>(() => import('../../pages/Inbox/SystemAdminPages/UserViewInbox'));

// Activity log components
const ActivityLogsDashboard = lazy<any>(() => import('../../pages/SystemAdmin/ActivityLogs/Dashboard'));
const UserGroupActivity = lazy<any>(
    () => import('../../pages/SystemAdmin/ActivityLogs/Redirections/CreateUserGroupActvitiy'),
);
const DueDateActivity = lazy<any>(() => import('../../pages/SystemAdmin/ActivityLogs/Redirections/DueDateActivity'));
const RerouteActivity = lazy<any>(() => import('../../pages/SystemAdmin/ActivityLogs/Redirections/RerouteActivity'));
const ReviewImportActivity = lazy<any>(
    () => import('../../pages/SystemAdmin/ActivityLogs/Redirections/ReviewImportActivity'),
);
const RolesActivity = lazy<any>(() => import('../../pages/SystemAdmin/ActivityLogs/Redirections/RolesActivity'));
const CreateUserActivity = lazy<any>(
    () => import('../../pages/SystemAdmin/ActivityLogs/Redirections/CreateUserActivity'),
);
const AdviserActivity = lazy<any>(() => import('../../pages/SystemAdmin/ActivityLogs/Redirections/AdviserActivity'));
const BulkImportActivity = lazy<any>(
    () => import('../../pages/SystemAdmin/ActivityLogs/Redirections/BulkImportActivity'),
);
const BulkUploadActivity = lazy<any>(
    () => import('../../pages/SystemAdmin/ActivityLogs/Redirections/BulkUploadActivity'),
);
const EditEPFActivity = lazy<any>(() => import('../../pages/SystemAdmin/ActivityLogs/Redirections/EditEPFActivity'));
const OrderSummaryActivity = lazy<any>(
    () => import('../../pages/SystemAdmin/ActivityLogs/Redirections/OrderDetails/OrderSummaryActivity'),
);
const RejectActivity = lazy<any>(() => import('../../pages/SystemAdmin/ActivityLogs/Redirections/RejectActivity'));
//System settings components
const EditEPF = lazy<any>(() => import('../../pages/SystemAdmin/SystemSettings/EPF/Pages/EditEPF'));
const RejectRemarkSettings = lazy<any>(
    () => import('../../pages/SystemAdmin/SystemSettings/EPF/Pages/RejectRemarkSettings'),
);

const SystemSettingsDashboard = lazy<any>(
    () => import('../../pages/SystemAdmin/SystemSettings/SystemSettingsDashboard'),
);
const SettingsViewInbox = lazy<any>(() => import('../../pages/SystemAdmin/SystemSettings/EPF'));

// Fund management routes
const BulkUploadDocuments = lazy<any>(() => import('../../pages/Import/BulkUploadDocuments'));
const Import = lazy<any>(() => import('../../pages/Import/Import'));
const RejectImport = lazy<any>(() => import('../../pages/Import/RejectImport'));
const ReviewBulkImport = lazy<any>(() => import('../../pages/Import/ReviewBulkImport'));
const ReviewImport = lazy<any>(() => import('../../pages/Import/ReviewImport'));
const ViewBulkUpload = lazy<any>(() => import('../../pages/Import/ViewBulkUpload'));
const ViewImport = lazy<any>(() => import('../../pages/Import/ViewImport'));
const DetailsPage = lazy<any>(() => import('../../pages/ProductSettings/DetailsPage/DetailsPage'));
const ProductSettings = lazy<any>(() => import('../../pages/ProductSettings/ProductSettings'));
const UploadsDashboard = lazy<any>(() => import('../../pages/Upload/UploadsDashboard'));

// HQ CE branch routes
const HQCEDashboard = lazy<any>(() => import('../../pages/DashboardHQCE/DashboardHQCE'));
const HQCEViewBranch = lazy<any>(() => import('../../pages/DashboardHQCE/AllBranches/ViewBranch'));
const OrderSummaryNew = lazy<any>(() => import('../../pages/OrderDetails/OrderSummaryChangeRequest'));
const AllSubmittedCEDashboard = lazy<any>(() => import('../../pages/DashboardBranch/AllSubmittedDashboard'));
const ExtendTargetDateBranch = lazy<any>(
    () => import('../../pages/DashboardBranch/ExtendTargetDate/ExtendTargetDateBranch'),
);
const OrderDetails = lazy<any>(() => import('../../pages/OrderDetails/OrderSummary'));
const RejectRemarks = lazy<any>(() => import('../../templates/RejectRemarks'));
const TrackOrder = lazy<any>(() => import('../../pages/TrackOrder/TrackOrder'));
const DueDateExtension = lazy<any>(() => import('../../pages/ExtendTargetDate'));
const RejectTransaction = lazy<any>(() => import('../../pages/RejectTransaction/RejectTransaction'));
const RerouteTransaction = lazy<any>(() => import('../../pages/RerouteTransaction/RerouteTransactions'));
const CEExtendDueDateRequest = lazy<any>(() => import('../../pages/Inbox/CEPages/CEExtendDueDateRequest'));
const HQCEAdvisors = lazy<any>(() => import('../../pages/Advisers/AdviserDashboard'));
const AdviserProfile = lazy<any>(() => import('../../pages/AdvisorProfile/AdvisorProfile'));
const InvestorDashboard = lazy<any>(() => import('../../pages/Investors/InvestorDashboard'));
const AccountProfile = lazy<any>(() => import('../../pages/Investors/Pages/AccountProfile'));
const InvestorProfile = lazy<any>(() => import('../../pages/Investors/Pages/InvestorProfile'));
const PendingSubmission = lazy<any>(() => import('../../pages/PendingSubmission/PendingSubmission'));

// EDD components
const InvestorsList = lazy<any>(
    () => import('../../pages/DashboardAmla/DashboardEDD/AddNewCase/Investors/InvestorsList'),
);
const QuestionTemplate = lazy<any>(
    () => import('../../pages/DashboardAmla/DashboardEDD/AddNewCase/QuestionTemplate/QuestionTemplate'),
);
const DashboardEDD = lazy<any>(() => import('../../pages/DashboardAmla/DashboardEDD/DashboardEDD'));
const RerouteEddCase = lazy<any>(
    () => import('../../pages/DashboardAmla/DashboardEDD/ReroutedSatisfactory/RerouteEddCase/RerouteEddCase'),
);
const NewCaseForm = lazy<any>(
    () => import('../../pages/DashboardAmla/DashboardEDD/AddNewCase/NewCaseForm/NewCaseForm'),
);
const CancelRequest = lazy<any>(() => import('../../pages/DashboardAmla/DashboardEDD/CancelRequest/CancelRequest'));
const CancelledSatisfactoryCase = lazy<any>(
    () => import('../../pages/DashboardAmla/DashboardEDD/CancelRequest/CancelSatisfactory/CancelledSatisfactoryCase'),
);
const DownloadMonthlyReport = lazy<any>(
    () => import('../../pages/DashboardAmla/DashboardEDD/DownloadMonthlyReport/DownloadMonthlyReport'),
);
const ReroutedSatisfactory = lazy<any>(
    () => import('../../pages/DashboardAmla/DashboardEDD/ReroutedSatisfactory/ReroutedSatisfactory'),
);
const ReroutedUnsatisfactory = lazy<any>(
    () => import('../../pages/DashboardAmla/DashboardEDD/ReroutedUnsatisfactory/ReroutedUnsatisfactory'),
);
const RerouteEddCaseUnsatisfactory = lazy<any>(
    () =>
        import(
            '../../pages/DashboardAmla/DashboardEDD/ReroutedUnsatisfactory/RerouteEddCase/RerouteEddCaseUnsatisfactory'
        ),
);
const SatisfactoryCase = lazy<any>(
    () => import('../../pages/DashboardAmla/DashboardEDD/SatisfactoryCase/SatisfactoryCase'),
);
const UnsatisfactoryCase = lazy<any>(
    () => import('../../pages/DashboardAmla/DashboardEDD/UnsatisfactoryCase/UnsatisfactoryCase'),
);
const Reports = lazy<any>(() => import('../../pages/Reports/ReportDashboard'));
const GenerateReport = lazy<any>(() => import('../../pages/Reports/Pages/GenerateReport'));

export const eddRoutes = [
    { path: ROUTES.amlaEDDManagement, exact: true, component: DashboardEDD },
    { path: ROUTES.investorsList, exact: true, component: InvestorsList },
    { path: ROUTES.questionTemplate, exact: true, component: QuestionTemplate },
    { path: ROUTES.rerouteEddCase, exact: true, component: RerouteEddCase },
    { path: ROUTES.newCaseForm, exact: true, component: NewCaseForm },
    { path: ROUTES.satisfactoryCase, exact: true, component: SatisfactoryCase },
    { path: ROUTES.unsatisfactoryCase, exact: true, component: UnsatisfactoryCase },
    { path: ROUTES.satisfactory, exact: true, component: SatisfactoryCase },
    { path: ROUTES.reroutedEddCaseSatisfactory, exact: true, component: ReroutedSatisfactory },
    { path: ROUTES.reroutedEddCaseUnsatisfactory, exact: true, component: ReroutedUnsatisfactory },
    { path: ROUTES.rerouteEddCaseUnsatisfactory, exact: true, component: RerouteEddCaseUnsatisfactory },
    { path: ROUTES.cancelRequest, exact: true, component: CancelRequest },
    { path: ROUTES.cancelledSatisfactoryCase, exact: true, component: CancelledSatisfactoryCase },
    { path: ROUTES.downloadMonthlyReport, exact: true, component: DownloadMonthlyReport },
    { path: `${ROUTES.hqInbox}/${ROUTES.satisfactory}`, exact: true, component: SatisfactoryCase },
    { path: `${ROUTES.hqInbox}/${ROUTES.reroutedSatisfactory}`, exact: true, component: ReroutedSatisfactory },
    { path: `${ROUTES.hqInbox}/${ROUTES.cancelledSatisfactory}`, exact: true, component: CancelledSatisfactoryCase },
];
export const branchHQCERoutes = [
    { path: ROUTES.dashboard, exact: true, component: HQCEDashboard },
    { path: ROUTES.hqTrackOrder, exact: true, component: TrackOrder },
    { path: ROUTES.viewBranch, exact: true, component: HQCEViewBranch },
    { path: ROUTES.hqAllSubmissions, exact: true, component: AllSubmittedCEDashboard },
    { path: `${ROUTES.viewBranch}/${ROUTES.orderSummary}`, exact: false, component: OrderDetails },
    { path: `${ROUTES.viewBranch}/${ROUTES.orderSummaryChangeRequest}`, exact: false, component: OrderSummaryNew },
    { path: `${ROUTES.hqAllSubmissions}/${ROUTES.orderSummary}`, exact: false, component: OrderDetails },
    {
        path: `${ROUTES.hqAllSubmissions}/${ROUTES.orderSummaryChangeRequest}`,
        exact: false,
        component: OrderSummaryNew,
    },
    {
        path: `${ROUTES.hqInbox}/${ROUTES.orderSummary}`,
        exact: false,
        component: OrderDetails,
    },
    {
        path: `${ROUTES.hqInbox}/${ROUTES.orderSummaryChangeRequest}`,
        exact: false,
        component: OrderSummaryNew,
    },
    {
        path: `${ROUTES.hqAllSubmissions}/${ROUTES.extendTargetDate}`,
        exact: true,
        component: ExtendTargetDateBranch,
    },
    {
        path: `${ROUTES.hqAllSubmissions}/${ROUTES.rejectTargetDate}`,
        exact: true,
        component: RejectRemarks,
    },
    {
        path: `${ROUTES.hqAllSubmissions}/${ROUTES.extendTargetDateRequest}`,
        exact: true,
        component: DueDateExtension,
    },
    {
        path: `${ROUTES.hqAllSubmissions}/${ROUTES.rejectTransaction}`,
        exact: true,
        component: RejectTransaction,
    },
    {
        path: `${ROUTES.hqAllSubmissions}/${ROUTES.rerouteTransaction}`,
        exact: true,
        component: RerouteTransaction,
    },
    {
        path: `${ROUTES.viewBranch}/${ROUTES.extendTargetDate}`,
        exact: true,
        component: ExtendTargetDateBranch,
    },
    {
        path: `${ROUTES.viewBranch}/${ROUTES.rejectTargetDate}`,
        exact: true,
        component: RejectRemarks,
    },
    {
        path: `${ROUTES.viewBranch}/${ROUTES.extendTargetDateRequest}`,
        exact: true,
        component: DueDateExtension,
    },
    {
        path: `${ROUTES.viewBranch}/${ROUTES.rejectTransaction}`,
        exact: true,
        component: RejectTransaction,
    },
    {
        path: `${ROUTES.viewBranch}/${ROUTES.rerouteTransaction}`,
        exact: true,
        component: RerouteTransaction,
    },
    {
        path: ROUTES.hqTargetDateExtension,
        exact: true,
        component: DueDateExtension,
    },
    {
        path: ROUTES.hqRejectExtension,
        exact: true,
        component: RejectRemarks,
    },
    {
        path: ROUTES.hqInboxReRouteReview,
        exact: true,
        component: RerouteTransaction,
    },
    {
        path: ROUTES.hqInboxRejectReview,
        exact: true,
        component: RejectTransaction,
    },
    {
        path: `${ROUTES.hqInbox}/due-date-extension`,
        exact: true,
        component: CEExtendDueDateRequest,
    },
    {
        path: ROUTES.hqPendingSubmissionTrackOrder,
        exact: true,
        component: TrackOrder,
    },
    {
        path: ROUTES.hqAllPendingSubmissionTrackOrder,
        exact: true,
        component: TrackOrder,
    },
    {
        path: ROUTES.hqAllSubmissionsTrackOrder,
        exact: true,
        component: TrackOrder,
    },
    {
        path: ROUTES.hqSingleBranchTrackOrder,
        exact: true,
        component: TrackOrder,
    },
    {
        path: `${ROUTES.hqPendingSubmission}/${ROUTES.orderSummary}`,
        exact: false,
        component: OrderDetails,
    },
    {
        path: `${ROUTES.hqPendingSubmission}/${ROUTES.orderSummaryChangeRequest}`,
        exact: false,
        component: OrderSummaryNew,
    },
    {
        path: ROUTES.hqAllPendingSubmission,
        exact: true,
        component: PendingSubmission,
    },
    {
        path: `${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummary}`,
        exact: false,
        component: OrderDetails,
    },
    {
        path: `${ROUTES.hqAllPendingSubmission}/${ROUTES.orderSummaryChangeRequest}`,
        exact: false,
        component: OrderSummaryNew,
    },
    {
        path: ROUTES.hqPendingSubmission,
        exact: true,
        component: HQCEViewBranch,
    },
    {
        path: ROUTES.hqAdvisers,
        exact: true,
        component: HQCEAdvisors,
    },
    {
        path: ROUTES.hqAdviserProfile,
        exact: true,
        component: AdviserProfile,
    },
    {
        path: ROUTES.hqInvestors,
        exact: true,
        component: InvestorDashboard,
    },
    {
        path: ROUTES.hqAccountProfile,
        exact: true,
        component: AccountProfile,
    },
    {
        path: ROUTES.hqInvestorProfile,
        exact: true,
        component: InvestorProfile,
    },
    // Uncomment this if you have to bypass the permissions
    // {
    //     path: ROUTES.hqReports,
    //     exact: true,
    //     component: GenerateReport,
    // },
];
export const resourcesRoutes = [
    {
        path: ROUTES.hqReports,
        exact: true,
        component: Reports,
    },
    {
        path: ROUTES.hqGenerateReports,
        exact: false,
        component: GenerateReport,
    },
];

export const fundManagementRoutes = [
    { path: ROUTES.financeProductSetingsDashboard, exact: true, component: ProductSettings },
    { path: ROUTES.financeProductSetingsDetails, exact: true, component: DetailsPage },
    { path: ROUTES.financeUploads, exact: true, component: UploadsDashboard },
    { path: ROUTES.financeImportFileUploader, exact: true, component: Import },
    { path: ROUTES.financeImportBulkUploader, exact: true, component: BulkUploadDocuments },
    { path: ROUTES.financeViewImportFile, exact: true, component: ViewImport },
    { path: ROUTES.financeReviewImportFile, exact: true, component: ReviewImport },
    { path: ROUTES.financeViewBulkUpload, exact: true, component: ViewBulkUpload },
    { path: ROUTES.financeReviewBulkUpload, exact: true, component: ReviewBulkImport },
    { path: ROUTES.financeRejectImport, exact: true, component: RejectImport },
    { path: `${ROUTES.hqInbox}/review-bulk-upload`, exact: true, component: ReviewBulkImport },
    { path: `${ROUTES.hqInbox}/review-import-file`, exact: true, component: ReviewImport },
    { path: `${ROUTES.hqInbox}/bulk-import-users/review-import`, exact: true, component: ReviewBulkImportUsers },
];

export const systemAdminRoutes = [
    { path: ROUTES.dashboardSystemAdmin, exact: true, component: SystemAdminDashboard },
    { path: ROUTES.addUser, exact: true, component: AddUser },
    { path: ROUTES.addRoles, exact: true, component: AddRoles },
    { path: ROUTES.editRoles, exact: true, component: EditRoles },
    { path: ROUTES.approveEditRole, exact: true, component: EditRoles },
    { path: ROUTES.addUserGroup, exact: true, component: AddUserGroup },
    { path: ROUTES.editUserGroup, exact: true, component: EditUserGroup },
    { path: ROUTES.bulkImportUsers, exact: true, component: ImportUsers },
    { path: ROUTES.viewImportUsers, exact: true, component: ViewImportUsers },
    { path: ROUTES.reviewBulkImportUsers, exact: true, component: ReviewBulkImportUsers },
    { path: ROUTES.rejectBulkImportUsers, exact: true, component: RejectBulkImportUsers },
    { path: ROUTES.adviserAccess, exact: true, component: AdviserAccessDashboard },
    { path: ROUTES.systemAdminAdviserView, exact: true, component: SystemAdminAdviserProfile },
    { path: `${ROUTES.hqInbox}/review-groups`, exact: true, component: UserGroupViewInbox },
    { path: `${ROUTES.hqInbox}/review-roles`, exact: true, component: RolesViewInbox },
    { path: `${ROUTES.hqInbox}/review-users`, exact: true, component: UserViewInbox },
    ...resourcesRoutes,
];

export const activityLogsRoutes = [
    { path: ROUTES.activityLogs, exact: true, component: ActivityLogsDashboard },
    { path: ROUTES.activityLogsFund, exact: true, component: ReviewImportActivity },
    { path: ROUTES.activityLogsRoles, exact: true, component: RolesActivity },
    { path: ROUTES.activityLogsGroups, exact: true, component: UserGroupActivity },
    { path: ROUTES.activityLogsUser, exact: true, component: CreateUserActivity },
    { path: ROUTES.activityLogsReroute, exact: true, component: RerouteActivity },
    { path: ROUTES.activityLogsDueDate, exact: true, component: DueDateActivity },
    { path: ROUTES.activityLogsOrderSummary, exact: true, component: OrderSummaryActivity },
    { path: ROUTES.activityLogsReject, exact: true, component: RejectActivity },
    { path: ROUTES.activityLogsAdviser, exact: true, component: AdviserActivity },
    { path: ROUTES.activityLogsBulkUpload, exact: true, component: BulkUploadActivity },
    { path: ROUTES.activityLogsBulkImport, exact: true, component: BulkImportActivity },
    { path: ROUTES.activityLogsEpfSettings, exact: true, component: EditEPFActivity },
];

export const systemSettingRoutes = [
    { path: ROUTES.systemConfiguration, exact: true, component: SystemSettingsDashboard },
    { path: ROUTES.editEPFConfiguration, exact: true, component: EditEPF },
    { path: ROUTES.systemSettingRemark, exact: true, component: RejectRemarkSettings },
    { path: `${ROUTES.hqInbox}/configuration/edit-epf`, exact: true, component: SettingsViewInbox },
];
