import React from 'react';
import styled from 'styled-components';

declare interface CustomSpacerProps {
    horizontal?: boolean;
    space: string;
    style?: React.CSSProperties;
}

const Spacer = styled.div<CustomSpacerProps>`
    height: ${(p: CustomSpacerProps) => (p.horizontal === true ? 0 : p.space)};
    width: ${(p: CustomSpacerProps) => (p.horizontal === true ? p.space : 0)};
`;
export const CustomSpacer = (props: CustomSpacerProps): JSX.Element => {
    return <Spacer {...props} />;
};
