import gql from 'graphql-tag';

export const getSystemAdminInboxData = gql`
    query systemadmininboxdata($input: SystemAdminInboxDataInput) {
        systemAdminInboxData(input: $input) {
            data {
                result {
                    data {
                        value
                        authorName
                        approverName
                        approvalStatus
                        source
                        type
                        createdOn
                        remarks
                        fundData
                        isExpired
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
