import React from 'react';
import styled from 'styled-components';
import { TextDarkBlack, TextDarkGrey5, TextWhite } from '../../../constants';
import { IcoMoon } from '../../../icons';
import { FlexedDiv } from '../FlexDiv';

interface ISameBranchOrder {
    sameBranch?: boolean;
}
export const TableGroupingByOrder = (props: IOrderClassification): JSX.Element => {
    return (
        <OrderBar justifyContent={'space-between'} sameBranch={props.sameBranch}>
            <FlexedDiv style={{ columnGap: '24px' }}>
                {Array.isArray(props.principal) ? (
                    props.principal.map((principal, index) => {
                        return (
                            <FlexedDiv key={index + principal.principalNumber} alignItems="center">
                                <PrincipalNumberAvatar
                                    sameBranch={props.sameBranch}
                                    data-testid="principal-number-avatar"
                                >
                                    <TextWhite size="10px" weight="700" lineHeight={'14px'} background="turquoise">
                                        {index + 1}
                                    </TextWhite>
                                </PrincipalNumberAvatar>
                                <TextDarkBlack size="14px" weight="bold" style={{ lineHeight: '18.2px' }}>
                                    {principal.principalName}
                                </TextDarkBlack>
                                <TextDarkGrey5 size="14px" style={{ marginLeft: '8px', lineHeight: '18.2px' }}>
                                    {principal.principalNumber}
                                </TextDarkGrey5>
                            </FlexedDiv>
                        );
                    })
                ) : (
                    <FlexedDiv key={props.principal.principalNumber} alignItems="center">
                        <PrincipalNumberAvatar sameBranch={props.sameBranch} data-testid="principal-number-avatar">
                            <TextWhite size="10px" weight="700" lineHeight={'14px'} background="turquoise">
                                {1}
                            </TextWhite>
                        </PrincipalNumberAvatar>
                        <TextDarkBlack size="14px" weight="bold" style={{ lineHeight: '18.2px' }}>
                            {props.principal.principalName}
                        </TextDarkBlack>
                        <TextDarkGrey5 size="14px" style={{ marginLeft: '8px', lineHeight: '18.2px' }}>
                            {props.principal.principalNumber}
                        </TextDarkGrey5>
                    </FlexedDiv>
                )}
            </FlexedDiv>
            <FlexedDiv style={{ columnGap: '32px' }} justifyContent="space-between">
                <FlexedDiv>
                    <IcoMoon name="transaction-order" size="16px" />
                    <TextDarkBlack size="14px" weight="bold" style={{ lineHeight: '16px', marginLeft: '4px' }}>
                        {props.orderNumber !== undefined ? props.orderNumber : props.orderNo}
                    </TextDarkBlack>
                </FlexedDiv>

                <FlexedDiv style={{ minWidth: '104px' }}>
                    <IcoMoon
                        name={Array.isArray(props.principal) && props.principal.length > 1 ? 'joint-avatar' : 'avatar'}
                        size="16px"
                    />
                    <TextDarkBlack size="14px" weight="bold" style={{ lineHeight: '16px', marginLeft: '4px' }}>
                        {props.accountNo ?? '-'}
                    </TextDarkBlack>
                </FlexedDiv>

                {props.noTransactionOverviewIcon ? null : (
                    <FlexedDiv>
                        <IcoMoon name="transactions-overview" size="16px" />
                        <TextDarkBlack size="14px" weight="bold" style={{ lineHeight: '16px', marginLeft: '4px' }}>
                            {props.noTransactions ?? '-'}
                        </TextDarkBlack>
                    </FlexedDiv>
                )}
            </FlexedDiv>
        </OrderBar>
    );
};

const OrderBar = styled((props) => <FlexedDiv {...props} />)<ISameBranchOrder>`
    background: ${(props) => (props.sameBranch === false ? '#ececec' : '#e7ebf1')};
    border-radius: 8px 8px 0px 0px;
    padding: 4px 16px;
    margin-bottom: 8px;
    align-items: center;
`;
const PrincipalNumberAvatar = styled.div<ISameBranchOrder>`
    background: ${(props) => (props.sameBranch === false ? '#4D4D4D' : '#4D637B')};
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
