import gql from 'graphql-tag';
/**
 * @newCaseDefinitionQuery @query - EDD New Case Form API
 * @description: Retrieving Edd module list (questions dropdown) for amla to create case
 */

export const eddModuleListQuery = gql`
    query eddModuleList($input: EddModuleListInput) {
        eddModuleList(input: $input) {
            data {
                result {
                    module {
                        moduleId
                        name
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
