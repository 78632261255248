import gql from 'graphql-tag';
/**
 * @branchOffices @query - HQ[CE] Dashboard
 * @description: Returns all branches
 */

export const branchOfficesQuery = /* GraphQL */ gql`
    query branchOffices($input: BranchOfficesInput) {
        branchOffices(input: $input) {
            data {
                result {
                    branches {
                        branchId
                        name
                        code
                        state
                        agentCount
                        clientCount
                    }
                    stateList
                    totalResultCount
                    branchCount
                    page
                    pages
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
