import React, { Fragment } from 'react';
import { LABEL } from '../../../constants';
import { Modal } from '../Modal/Modal';
import DownloadProgress from './DownloadProgress';

interface IReportProgressModalProps {
    setToggleModal: (index: boolean) => void;
    testId?: string;
    reportType: string;
    reportName: string;
    toggleModal: boolean;
    progressWidth: number;
    onCancel: () => void;
}

const ReportDownloadProgressModal = ({
    setToggleModal,
    toggleModal,
    testId,
    progressWidth,
    reportType,
    reportName,
    onCancel,
}: IReportProgressModalProps): JSX.Element => {
    return (
        <Fragment>
            {toggleModal ? (
                <Modal
                    modalActive={toggleModal}
                    setModalActive={setToggleModal}
                    data-testid="modal"
                    testId={testId ?? ''}
                    title={'Generating report...'}
                    secondaryBtn={{
                        onClick: onCancel,
                        label: LABEL.cancel,
                        size: 'large',
                    }}
                >
                    <DownloadProgress reportType={reportType} reportName={reportName} progressWidth={progressWidth} />
                </Modal>
            ) : null}
        </Fragment>
    );
};
export default ReportDownloadProgressModal;
