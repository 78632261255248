import gql from 'graphql-tag';

/**
 * @userUpdateStatus @mutation System admin Dashboard
 * @description mutation for modifying status of user
 */

export const userUpdateStatus = gql`
    mutation userUpdateStatus($input: IdActionUpdateInput) {
        userUpdateStatus(input: $input) {
            data {
                result {
                    status
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;

export default userUpdateStatus;
