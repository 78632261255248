export const hyphenateNric = (id: string): string => {
    const formattedNric = id.slice(0, 6) + '-' + id.slice(6, 8) + '-' + id.slice(8);
    return formattedNric;
};

export const formatFatca = (type: string): string => {
    switch (type.toLowerCase()) {
        case 'true':
            return 'Yes';
        case 'false':
            return 'No';
        default:
            return type;
    }
};
