export const statusStyle = (item: IColumnItemAccordion, key: string): React.CSSProperties => {
    let color = '';
    let background = '';
    const tempItem = item.rawData[key] != null || item.rawData[key] != undefined ? item.rawData[key].toLowerCase() : '';
    switch (tempItem) {
        // Status/Warning
        case 'pending':
        case 'pending receive':
        case 'pending target date':
        case 'suspended':
        case 'pending payment':
        case 'pending document & payment':
        case 'pending doc & payment':
        case 'pending doc':
        case 'pending document':
        case 'pending due date':
        case 'pending approved':
        case 'pending physical doc':
            color = '#E89700';
            background = '#FDF7EB';
            break;
        // Status/Review
        case 'in review':
        case 'pending approval':
            color = '#7E2E84';
            background = '#F5EEF5';
            break;
        // Status/Success
        case 'approved target date':
        case 'submitted':
        case 'upcoming':
            color = '#2ECC82';
            background = '#EEFBF5';
            break;
        // Status/Complete
        case 'successful':
        case 'completed':
        case 'active':
        case 'pending initial order':
            color = '#243547';
            background = '#F8F8F8';
            break;
        // Status/Reroute
        case 'br - rerouted':
        case 'flagged by branch':
        case 'hq - rerouted':
        case 'flagged by hq':
            color = '#AB001D';
            background = '#F8EBED';
            break;
        // Status/Error
        case 'void':
        case 'rejected':
        case 'overdue-2':
        case 'overdue-1':
        case 'terminated':
        case 'cancelled':
        case 'failed':
            color = '#E84C3D';
            background = '#FDF1EF';
            break;
        // Status/Enabled
        case 'enabled':
            color = '#2ECC82';
            background = '#EEFBF5';
            break;
        case 'disabled':
            color = '#243547';
            background = '#F8F8F8';
            break;
        default:
            color = '#000';
    }
    return {
        color: color,
        background: background,
        borderRadius: '16px',
        padding: '.25rem .5rem',
        fontSize: '.75rem',
        lineHeight: '1rem',
        textAlign: 'center',
        fontWeight: 600,
        display: 'inline-block',
        letterSpacing: '0.01em',
    };
};
