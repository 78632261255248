/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { emptyCheck } from './emptyCheck';
import { exportXLSX } from './exportXLSX';

type IDocumentColumnItem = {
    name: string;
    url: string;
    documentType: string;
};

export const exportListProductSettings = (tabName: string, data: any[]): void => {
    const fileName = `Export_List_${tabName}_${moment().format('DD/MM/YYYY_h:mm:ssa')}`;

    const keys = Object.keys(data[0]);

    const list: { [k: string]: any }[] = [];
    data.map((item) => {
        const tempObj: string[][] = [];

        keys.map((key) => {
            const temp: any[] = [];
            switch (key) {
                case 'fundAbbr':
                    temp.push('Fund Code', emptyCheck(item[key]));
                    break;
                case 'fundName':
                    temp.push('Fund Name', emptyCheck(item[key]));
                    break;
                case 'fundClass':
                    temp.push('Fund Class', emptyCheck(item[key]));
                    break;
                case 'fundCurrency':
                    temp.push('Fund Currency', emptyCheck(item[key]));
                    break;
                case 'lastUpdated':
                    const date = item[key] !== null ? moment(item[key], 'x').format('DD/MM/YYYY,h:mm:ss a') : '';
                    temp.push('Last Updated', emptyCheck(date));
                    break;
                case 'fundType':
                    temp.push('Type', emptyCheck(item[key]));
                    break;
                case 'fundCategory':
                    temp.push('Category', emptyCheck(item[key]));
                    break;
                case 'riskCategory':
                    temp.push('Risk Category', emptyCheck(item[key]));
                    break;
                case 'isSyariah':
                    const isSyariahVal = item[key] === 0 ? 'Conventional' : 'Shariah';
                    temp.push('Type', emptyCheck(isSyariahVal));
                    break;
                case 'issuingHouse':
                    temp.push('UTMC', emptyCheck(item[key]));
                    break;
                case 'fundStatus':
                    temp.push('Status', emptyCheck(item[key]));
                    break;
                case 'latestNavPerUnit':
                    temp.push('Latest NAV PerUnit', emptyCheck(item[key]));
                    break;
                case 'latestNavDate':
                    const latestNavDate =
                        item[key] !== null ? moment(item[key], 'x').format('DD/MM/YYYY,h:mm:ss a') : '';
                    temp.push('Latest NAV Date', emptyCheck(latestNavDate));
                    break;
                case 'previousNavPerUnit':
                    temp.push('Previous NAV PerUnit', emptyCheck(item[key]));
                    break;
                case 'previousNavDate':
                    const prevNavDate = item[key] !== null ? moment(item[key], 'x').format('DD/MM/YYYY,h:mm:ss a') : '';
                    temp.push('Previous NAV Date', emptyCheck(prevNavDate));
                    break;
                case 'grossDistribution':
                    temp.push('Gross Distribution', emptyCheck(item[key]));
                    break;
                case 'netDistribution':
                    temp.push('Net Distribution', emptyCheck(item[key]));
                    break;
                case 'unitSplit':
                    temp.push('Unit Split', emptyCheck(item[key]));
                    break;
                case 'closingDate':
                    const closingDate = item[key] !== null ? moment(item[key], 'x').format('DD/MM/YYYY,h:mm:ss a') : '';
                    temp.push('Closing Date', emptyCheck(closingDate));
                    break;
                case 'document':
                    item[key].map((doc: IDocumentColumnItem) => {
                        const _documents = [];

                        switch (doc.documentType) {
                            case 'FFS':
                                _documents.push('Monthly Fund Fact', emptyCheck(doc.url));
                                tempObj.push(_documents);
                                break;
                            case 'AR':
                                _documents.push('Annual Report', emptyCheck(doc.url));
                                tempObj.push(_documents);
                                break;
                            case 'PHS':
                                _documents.push('Product Highlight', emptyCheck(doc.url));
                                tempObj.push(_documents);
                                break;
                            case 'PI':
                                _documents.push('Prospectus Information', emptyCheck(doc.url));
                                tempObj.push(_documents);
                                break;
                            default:
                                _documents.push(doc.documentType, emptyCheck(doc.url));
                                tempObj.push(_documents);
                        }
                    });
                    break;
                default:
            }
            if (temp.length !== 0) tempObj.push(temp);
        });
        const obj = Object.fromEntries(tempObj);
        list.push(obj);
    });

    exportXLSX(list, fileName);
};
