/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { IUserLoginContext } from './AuthProvider';
import { AuthActionTypes } from './AuthReducer';

export interface IUserLoginProps {
    username: string;
    password: string;
}

export interface AuthContextData {
    user?: any | null;
    authDispatch: React.Dispatch<AuthActionTypes>;
    //userLogin API response values
    userLoginContext: IUserLoginContext;
    userLoginFn({ username, password }: IUserLoginProps): Promise<void>;
    windowReloadFn: () => void;
    userLogoutFn: () => Promise<void>;
    onLogin: boolean;
    roleBasedRedirect: (permission: any) => void;
}

const AuthContext = React.createContext({} as AuthContextData);

export default AuthContext;
