import React from 'react';
import styled from 'styled-components';
import { LogoSpinner } from '../../atoms/LogoSpinner';
export const RouteLoader = (): JSX.Element => {
    return (
        <BackDrop>
            <LogoSpinner spinnerAfterColor="rgba(0, 0, 0, 0.3)" />
        </BackDrop>
    );
};

//component styles
const BackDrop = styled.div`
    position: fixed;
    z-index: 9999;
    background-color: rgb(0 0 0 / 30%);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
`;
