import gql from 'graphql-tag';

export const getIdInfo = gql`
    query getIdInfo($input: IdInfoInput) {
        getIdInformation(input: $input) {
            data {
                result {
                    info
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
