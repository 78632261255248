import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { CustomSpacer, FlexedDiv } from '../..';
import { LinkButton } from '../../atoms/LinkButton';

export const CollapsibleDisplay = ({
    content,
    displayTextForCollapse,
    displayTextForExpand,
    expanded,
    handleExpand,
    testId,
    textColor,
    contentStyle,
    buttonStyle,
}: ICollapsibleDisplayProps): JSX.Element => {
    const [height, setHeight] = useState<number>(0);
    const [width, setWidth] = useState<number>();
    const ref = useRef<HTMLDivElement>(null);
    const handleHeight = () => {
        if (expanded === true && ref !== null && ref.current?.scrollHeight !== 0) {
            setHeight(ref.current?.scrollHeight as number);
        } else {
            setHeight(0);
        }
    };

    useEffect(() => {
        if (expanded === true && ref !== null && ref.current?.scrollHeight !== 0) {
            setHeight(ref.current?.scrollHeight as number);
        }
    }, [content]);

    useEffect(() => {
        if (expanded !== undefined) {
            handleHeight();
        }
    }, [expanded]);
    useEffect(() => {
        if (ref.current !== undefined && ref.current?.clientWidth !== undefined) {
            // setWidth(ref.current.clientWidth);
            setWidth(100);
        }
    }, []);
    return (
        <Fragment>
            <FlexedDiv direction="column-reverse">
                <LinkButton
                    noFill
                    buttonColor={textColor}
                    suffixIcon="caret-down"
                    id={`${testId}-header`}
                    data-testid={`${testId}-header`}
                    iconStyle={{
                        transform: expanded === true ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                    onClick={() => handleExpand()}
                    style={{ width: `${width ? width - 20 : 0}px`, ...buttonStyle }}
                >
                    {expanded ? displayTextForCollapse : displayTextForExpand}
                </LinkButton>
                <CustomSpacer space="1rem" />
                <ContentContainer
                    isOnFocus={expanded}
                    maxHeight={height}
                    ref={ref}
                    id={`${testId}-container`}
                    data-testid={`${testId}-container`}
                    style={contentStyle}
                >
                    {content}
                </ContentContainer>
            </FlexedDiv>
        </Fragment>
    );
};

const ContentContainer = styled.div<IOnPropStyles>`
    max-height: 0px;
    height: 0px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    ${(props) =>
        props.isOnFocus &&
        css`
            max-height: ${(props: IOnPropStyles) => props.maxHeight}px;
            height: 100%;
        `}
`;
