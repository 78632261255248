export function checkValidUserData(column: string, value: string): boolean {
    let returnVal = false;
    switch (column) {
        case 'Status':
            value.toUpperCase() === 'ACTIVE' || value === 'SUSPENDED' || value === 'TERMINATED'
                ? (returnVal = true)
                : (returnVal = false);
            break;
        case 'Mobile Number':
            value.match(/^(\d{9,10}$)/) !== null ? (returnVal = true) : (returnVal = false);
            break;
        case 'Email Address':
            value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) !== null
                ? (returnVal = true)
                : (returnVal = false);
            break;
        default:
            value !== '' ? (returnVal = true) : (returnVal = false);
    }

    return returnVal;
}

export function cleanUserColumns(columns: ISheetColumn[]): string[] {
    const tempColumns: string[] = [];
    columns.map((item, index) => {
        switch (item.colName.trim()) {
            case 'User Name':
                tempColumns[index] = 'name';
                break;
            case 'Email Address':
                tempColumns[index] = 'email';
                break;
            case 'Department':
                tempColumns[index] = 'department_id';
                break;
            case 'Home Branch':
                tempColumns[index] = 'branch_id';
                break;
            case 'User ID':
                tempColumns[index] = 'username';
                break;
            case 'Status':
                tempColumns[index] = 'status';
                break;
            case 'Mobile Number':
                tempColumns[index] = 'mobile_no';
                break;
            case 'User Group':
            case 'User group':
                tempColumns[index] = 'group_id';
                break;
            case 'Description':
                tempColumns[index] = 'description';
                break;

            default:
                tempColumns[index] = `${item.colName}-ERROR`;
                return;
        }
    });

    return tempColumns;
}
