/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useMemo } from 'react';
import { useState } from 'react';
import { useComponentLoader, useDebounce } from '../../customHooks';
import { API, graphqlOperation } from 'aws-amplify';

import AuthContext from '../AuthContext';
import ErrorHandlingContext from '../ErrorHandling/ErrorHandlingContext';

import InboxContext from './InboxContext';
import { inboxQuery } from '../../_graphql/queries/inbox/inboxQuery';
import { makeNotificationObject } from '../../pages/Inbox/makeNotificationObject';
import { getSource } from '../../pages/Inbox/inboxUtils';

interface InboxProviderProps {
    children: React.ReactNode;
}
const initialSearchState: ISearchInput = {
    value: '',
    column: 'all',
};
export const InboxProvider: React.FC<InboxProviderProps> = ({ children }: InboxProviderProps) => {
    const { userLoginContext } = useContext(AuthContext);
    const { handleErrorHandler, errorMessage, setErrorMessage } = useContext(ErrorHandlingContext);
    const idTokenHeader =
        userLoginContext.idToken !== undefined && userLoginContext.idToken !== '' && userLoginContext.idToken !== null
            ? { Authorization: userLoginContext.idToken, strategy: 'JWT' }
            : undefined;
    const parsedPermission = JSON.parse(userLoginContext.permission);

    const [tab, setTab] = useState(0);
    const [resultLimit, setResultLimit] = useState<number>(10);
    const [page, setPage] = useState<number>(1);
    const [pages, setPages] = useState<number>(1);
    const [message, setMessage] = useState<Array<INotificationObject>>([]);
    const [newMessageCount, setNewMessageCount] = useState<number>(0);
    const [unreadIds, setUnreadIds] = useState<string[]>([]);
    const [searchInput, setSearchInput] = useState(initialSearchState);

    // custom hooks
    const debouncedSearchTerm = useDebounce(searchInput, 700);
    const { loadingHandler, loading } = useComponentLoader();

    //Memorizing data states
    const memoizedDashboardInput: MemoizedInboxDashboardInput = useMemo(() => {
        return {
            tab,
            page,
            resultLimit,
            debouncedSearchTerm,
        };
    }, [tab, resultLimit, page, debouncedSearchTerm]);

    //Memorized fn to get investor dashboard data
    const getDashboardCallBack = useCallback(() => {
        getInboxData();
    }, [memoizedDashboardInput]);

    //Fn to get inbox dashboard data
    const getInboxData = async () => {
        loadingHandler();
        try {
            const response: any = await API.graphql(
                graphqlOperation(inboxQuery, {
                    input: {
                        page: page,
                        resultLimit: resultLimit.toString(),
                        search: searchInput.value,
                        source: getSource(parsedPermission),
                    },
                }),
                idTokenHeader,
            );
            /*** @errorCheck */
            const resultCheck = response.data.inboxDashboard;
            if (resultCheck.error !== null) throw resultCheck.error;
            /*** @passData */
            const { result } = response.data.inboxDashboard.data;
            setMessage(makeNotificationObject(result.inbox));
            const ids: string[] = [];
            result.inbox.map((item: any) => {
                if (item.isRead === false) {
                    ids.push(item.notificationId);
                }
            });
            setUnreadIds(ids);
            if (page > result.pages) {
                setPage(1);
            }
            setPages(parseInt(result.pages));
            setNewMessageCount(result.newMessageCount);
            loadingHandler();
        } catch (error) {
            const _error = error as IErrorHandling;
            loadingHandler();
            if (Object.keys(_error).includes('errorCode')) {
                setErrorMessage({
                    ...errorMessage,
                    message: _error.message,
                    errorCode: _error.errorCode,
                    title: 'Cannot Fetch Inbox Notifications',
                    testId: 'inbox-error-modal',
                });
                handleErrorHandler();
            } else {
                setErrorMessage({
                    ...errorMessage,
                    message: `Sorry, we encountered an unexpected error. Please contact support for more details.`,
                    title: 'Something Went Wrong',
                    testId: 'gql-error-modal',
                });
                handleErrorHandler();
            }
        }
    };

    const ProviderValue: IInboxContext = {
        loading,
        message,
        newMessageCount,
        page,
        pages,
        resultLimit,
        searchInput,
        memoizedDashboardInput,
        tab,
        unreadIds,
        setMessage,
        setNewMessageCount,
        setPage,
        setPages,
        setResultLimit,
        setSearchInput,
        setTab,
        setUnreadIds,
        loadingHandler,
        getInboxData,
        // getSource,
        getDashboardCallBack,
    };
    return <InboxContext.Provider value={ProviderValue}>{children}</InboxContext.Provider>;
};

export default InboxProvider;
