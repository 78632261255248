import React from 'react';
import styled, { css } from 'styled-components';
import { FlexedDiv } from '../..';
import { TextNavyBlue, TextDarkGrey6 } from '../../../constants';
import { IcoMoon } from '../../../icons';

interface ISelected {
    selected?: boolean;
    noHover?: boolean;
}

export const SummaryCard = (props: ISummaryCardProps): JSX.Element => {
    const _tabInput = props.cardId ?? 0;
    const _label = props.dataLabel ?? '';

    const handleClick = () => {
        if (props.handleClick) {
            props.handleClick(_tabInput, props.title);
        }
    };

    return (
        <SummaryCardWrapper data-testid={`summary-card-${_label}`} noHover={props.noClickIcon}>
            <CardTitle selected={props.selected}>{props.title}</CardTitle>
            <CardBody selected={props.selected}>
                <FlexedDiv justifyContent={'space-between'} alignItems="center">
                    <FlexedDiv>
                        {props.dataIcon && <IcoMoon name={props.dataIcon} size="32px"></IcoMoon>}
                        <CardDataInfo>
                            <TextDarkGrey6 size="20px" weight="bold">
                                {props.data}
                            </TextDarkGrey6>
                            <TextNavyBlue weight="400" size="12px" lineHeight="16px">
                                {props.dataLabel}
                            </TextNavyBlue>
                        </CardDataInfo>
                    </FlexedDiv>
                    {!props.noClickIcon && props.data !== '0' && (
                        <CardIconWrapper
                            onClick={() => handleClick()}
                            selected={props.selected}
                            data-testid={`card-icon-wrap-${_label}`}
                        >
                            <IcoMoon name={props.clickIconName || 'arrow-right'} size="16px"></IcoMoon>
                        </CardIconWrapper>
                    )}
                </FlexedDiv>
            </CardBody>
        </SummaryCardWrapper>
    );
};

const CardTitle = styled.div<ISelected>`
    background: ${(props) => (props.selected && !props.noHover ? '#002043' : '#f2f4f6')};
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: ${(props) => (props.selected && !props.noHover ? '#ffffff' : '#4d637b')};
    padding: 8px 16px;
    border-radius: 8px 8px 0px 0px;
`;
const CardBody = styled.div<ISelected>`
    padding: 16px 16px;
    ${(props) => (props.selected && !props.noHover ? 'background: #E7EBF1;' : '')}
    border-radius: 0px 0px 8px 8px;
`;
const CardDataInfo = styled.div`
    margin-left: 8px;
`;

const CardIconWrapper = styled.div<ISelected>`
    visibility: ${(props) => (props.selected && !props.noHover ? 'visible' : 'hidden')};
`;

const SummaryCardWrapper = styled.div<ISelected>`
    z-index: 2;
    border-radius: 8px;
    border: 1px solid #e7ebf1;
    max-width: 370px;
    font-size: 0;
    ${(props) =>
        props.selected && !props.noHover
            ? 'box-shadow: 3px 3px 14px rgba(0, 32, 67, 0.12), -2px 2px 8px rgba(0, 32, 67, 0.06),#002043 0px 0px 0px 1px inset;'
            : 'box-shadow: 0px 2px 8px rgba(0, 32, 67, 0.06), 0px 0px 4px rgba(0, 32, 67, 0.02),#e7ebf1 0px 0px 0px 1px inset;'}

    ${(props) =>
        !props.noHover &&
        css`
            &:hover {
                box-shadow: 3px 3px 14px rgba(0, 32, 67, 0.12), -2px 2px 8px rgba(0, 32, 67, 0.06),
                    #002043 0px 0px 0px 1px inset;
                cursor: pointer;
            }
            &:hover ${CardTitle} {
                background: #002043;
                color: #ffffff;
            }
            &:hover ${CardIconWrapper} {
                visibility: visible;
            }
        `}
`;
