import gql from 'graphql-tag';

export const updateSeen = gql`
    mutation updateSeen($input: SeenInput) {
        updateSeen(input: $input) {
            data {
                result {
                    status
                    message
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
