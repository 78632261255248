/** Returns the address to be displayed if confirm address flag is true in FATCA
 * @param address - Mailing address from Address Information Object in profile
 */
export const getDeclarationAddress = (address: ProfileAddress['address'] | null): string => {
    if (address !== null) {
        let temp = '';
        address.line1 !== null ? (temp = temp.trimStart().concat(address.line1)) : null;
        address.line2 !== null ? (temp = temp.concat(` ${address.line2}`)) : null;
        address.line3 !== null ? (temp = temp.concat(` ${address.line3}`)) : null;
        address.line4 !== null ? (temp = temp.concat(` ${address.line4}`)) : null;
        return temp !== '' ? temp : '-';
    } else return '-';
};
