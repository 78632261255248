/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, Fragment, ReactText, useEffect, useMemo, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import styled, { css } from 'styled-components';

import { InputValidationErrorText, LABEL } from '../../../constants';
import { IcoMoon } from '../../../icons';
import { removeArrayElement } from '../../../utils';
import { FlexedDiv } from '../../atoms';
import CustomCheckbox from '../../atoms/Checkbox';
import Container from '../../atoms/Container';
import Tag from '../../atoms/Tag';
import FilterSearch from '../FilterSearch';

export declare type IMultiSelectLabel = {
    key: string;
    label: string;
    value: ReactText;
    testId?: string;
};
interface IMultiSelectProps extends IFilterSearch {
    showSearch?: boolean;
    checkedState: Array<ReactText>;
    defaultOption?: string;
    disabled?: boolean;
    errorMessage?: string;
    expandable?: boolean;
    handleMultiSelect: (item: Array<ReactText>, fn: string, id: number) => void;
    hasSelectAll?: boolean;
    label: string;
    noOverlay?: boolean;
    options: IMultiSelectLabel[];
    placeHolder: string;
    selectAllLabel?: string;
    selectId: number;
    showCustomBorder?: boolean;
    style?: React.CSSProperties;
    testId?: string;
    hideDisabledMenu?: boolean;
    noSortedOptions?: boolean;
}
const MultiSelectV2 = ({
    showSearch,
    checkedState,
    disabled,
    errorMessage,
    expandable,
    handleMultiSelect,
    hasSelectAll,
    label,
    noOverlay,
    options,
    placeHolder,
    selectAllLabel,
    selectId,
    showCustomBorder,
    style,
    testId,
    hideDisabledMenu,
    noSortedOptions,
}: IMultiSelectProps): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false);
    const [checked, setChecked] = useState<Array<boolean>>([]);
    const [containerWidth, setContainerWidth] = useState<number>(0);
    const [widths, setWidths] = useState<Array<number>>([]);
    const node = useRef<HTMLDivElement>(null);
    const tagsContainerRef = useRef<HTMLDivElement>(null);
    const tagRefs = useRef<Array<HTMLButtonElement | null>>([]);
    const expandCondition = expandable !== undefined ? expandable : false;
    const [expandDiv, setExpandDiv] = useState<boolean>(expandCondition);
    const [selectAll, setSelectAll] = useState({ checked: false, value: '' });
    const [exitHook, setExitHook] = useState<boolean>(false);
    const [isSearching, setIsSearching] = useState(false);
    const [searchedOption, setSearchedOption] = useState<IMultiSelectLabel[]>(options);
    const [selected, setSelected] = useState<IMultiSelectLabel[]>([]);
    const [searchInput, setSearchInput] = useState('');
    // Will check this specific flag to accept the options in the same order otherwise options have to be sorted at all times
    const sortedOptions = noSortedOptions
        ? [...selected].concat(
              options.filter((option) => !selected.some((selectedItem) => selectedItem.label === option.label)),
          )
        : [...selected]
              .sort((a, b) => a.label.localeCompare(b.label))
              .concat(
                  options.filter((option) => !selected.includes(option)).sort((a, b) => a.label.localeCompare(b.label)),
              );

    const searchOptions = (searchInput: string): IMultiSelectLabel[] => {
        const filteredOptions = sortedOptions.filter((option) =>
            option.label.toLowerCase().includes(searchInput.toLowerCase()),
        );
        return [...new Set([...filteredOptions])];
    };

    const handleClear = () => {
        setSearchInput('');
        setIsSearching(false);
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const searchInput = event.target.value;
        setSearchInput(searchInput);
        if (searchInput !== '') {
            setIsSearching(true);
            setSearchedOption(searchOptions(searchInput));
        } else {
            setIsSearching(false);
        }
    };
    const handleOpenSelect = () => {
        setSelected(sortedOptions.filter((option) => checkedState.includes(option.key)));
        setOpen(!open);
    };
    const handleClickOutside = (e: any) => {
        if (node.current?.contains(e.target)) {
            // inside click
            return;
        }
        // outside  click
        setOpen(false);
    };
    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    useEffect(() => {
        const temp: Array<number> = [];
        const resizeObserver = new ResizeObserver((entries: any) => {
            setContainerWidth(entries[0].contentRect.width);
        });

        tagsContainerRef.current !== null ? resizeObserver.observe(tagsContainerRef.current) : null;
        // This part fixes the issue of not checked checkboxes at mounting when checkedState.length is bigger than 0
        if (sortedOptions.length !== 0) {
            const cs = checkedState.filter(Boolean).length;
            const c = checked.filter(Boolean).length;

            if (c !== cs) {
                if (exitHook === false) defaultSelection();
            } else {
                checkedState.length === sortedOptions.length
                    ? defaultSelectAll()
                    : setSelectAll({ value: '', checked: false });
            }
            for (let i = 0; i < checkedState.length; i++) {
                tagRefs.current = tagRefs.current.slice(0, checkedState.length);
                const tempWidth = tagRefs.current[i]?.clientWidth;
                temp.push(tempWidth !== undefined ? tempWidth : 0);
                setWidths(temp);
            }
            if (errorMessage !== undefined && errorMessage !== '') {
                setOpen(!open);
            }
        }
    }, [checkedState, tagsContainerRef, tagRefs, checked]);
    const defaultSelection = () => {
        const ch = [];
        for (let i = 0; i < sortedOptions.length; i++) {
            if (
                checkedState.includes(sortedOptions[i].key as ReactText) ||
                checkedState.includes(sortedOptions[i].value)
            ) {
                ch.push(true);
            } else {
                ch.push(false);
            }
        }
        ch.filter(Boolean).length !== checkedState.filter(Boolean).length ? setExitHook(true) : null;
        setChecked(ch);
    };

    const defaultSelectAll = () => {
        hasSelectAll === true ? setSelectAll({ checked: true, value: 'selectAll' }) : null;
    };
    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const check = e.target.checked;
        let tempValues = [...checkedState];
        const tempChecked = [...checked];
        if (check) {
            tempValues.push(value);
        } else {
            tempValues = removeArrayElement(tempValues, value);
        }
        setChecked(tempChecked);
        handleMultiSelect(tempValues, 'select', selectId);
    };
    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        const check = e.target.checked;
        let tempValues = [...checkedState];
        const tempChecked = [...checked];
        if (check) {
            sortedOptions.map((item, index) => {
                item.key && tempValues.includes(item.key.toString())
                    ? null
                    : item.key && tempValues.push(item.key.toString());
                tempChecked[index] = true;
            });
            setSelectAll({ value: 'All', checked: true });
        } else {
            tempValues = [];
            for (let i = 0; i < tempChecked.length; i++) tempChecked[i] = false;
            setSelectAll({ value: '', checked: false });
        }
        setChecked(tempChecked);
        handleMultiSelect(tempValues, 'selectAll', selectId);
    };

    const handleTag = (index: number | undefined) => {
        if (disabled) return;
        let tempValues = [...checkedState];
        const tempChecked = [...checked];
        if (index !== undefined) {
            let id = -1;
            for (let i = 0; i < sortedOptions.length; i++) {
                if (sortedOptions[i].key === tempValues[index]) {
                    id = i;
                    break;
                }
            }
            tempValues = removeArrayElement(tempValues, tempValues[index]);
            tempChecked[id] = false;
        } else {
            tempValues = [];
            tempChecked.map((_item, index) => {
                const tmp = false;
                tempChecked[index] = tmp;
            });
            setSelectAll({ value: '', checked: false });
        }
        setChecked(tempChecked);
        handleMultiSelect(tempValues, 'remove', selectId);
    };

    const useCounter = (containerWidth: number, widths: Array<number>) => {
        return useMemo(() => {
            let breakPoint = 0;
            let total = 0;
            for (let i = 0; i < widths.length; i++) {
                total = total + widths[i];
                if (total >= containerWidth - 10) {
                    breakPoint = i;
                    break;
                }
            }
            const condition = breakPoint !== 0 ? checkedState.length - breakPoint : 0;
            return condition;
        }, [checkedState, containerWidth, widths]);
    };
    const counter = useCounter(containerWidth, widths);
    const menu = (isSelectAll: boolean) => {
        return (
            <>
                {isSelectAll ? (
                    <Fragment>
                        <MapItem>
                            <CustomCheckbox
                                value={selectAll.value}
                                checked={selectAll.checked}
                                label={selectAllLabel !== undefined ? selectAllLabel : LABEL.selectAll}
                                handleCheckbox={handleSelectAll}
                                disabled={disabled}
                                id={'selectAll'}
                            />
                        </MapItem>
                        <SeparatorLineV2 />
                    </Fragment>
                ) : null}
                {isSearching ? (
                    searchedOption.map((item, index) => (
                        <MapItem key={`${index} ${item}`}>
                            <CustomCheckbox
                                value={item.key}
                                checked={checkedState.includes(item.key)}
                                label={item.value.toString()}
                                handleCheckbox={handleCheck}
                                disabled={disabled}
                            />
                        </MapItem>
                    ))
                ) : (
                    <>
                        {sortedOptions.map((item, index) => (
                            <Fragment key={`${index} ${item}`}>
                                <MapItem>
                                    <CustomCheckbox
                                        value={item.key}
                                        checked={checkedState.includes(item.key)}
                                        label={item.value.toString()}
                                        handleCheckbox={handleCheck}
                                        disabled={disabled}
                                    />
                                </MapItem>
                                {index < sortedOptions.length - 1 &&
                                    selected.includes(item) !== selected.includes(sortedOptions[index + 1]) && (
                                        <SeparatorLineV2 />
                                    )}
                            </Fragment>
                        ))}
                    </>
                )}
            </>
        );
    };

    const findLabel = (val: string) => {
        for (let i = 0; i < sortedOptions.length; i++) {
            if (sortedOptions[i].key === val || sortedOptions[i].value.toString() === val)
                return sortedOptions[i].value;
        }
    };

    return (
        <FlexedDiv direction="column" style={style} data-testid="multiSelect-V2">
            <DropdownLabel>{label}</DropdownLabel>
            <div ref={node}>
                <MultiSelectDivV2
                    renderStyle={open}
                    disabled={disabled}
                    style={style}
                    error={errorMessage !== undefined && errorMessage !== '' && !open}
                    showCustomBorder={showCustomBorder}
                    id={`${testId}-${label.toLowerCase()}-select`}
                    hideDisabledMenu={hideDisabledMenu}
                >
                    {checkedState.length === 0 ? (
                        <TagsContainerV2 renderStyle={expandDiv} ref={tagsContainerRef}>
                            <PlaceHolder>{placeHolder}</PlaceHolder>
                        </TagsContainerV2>
                    ) : (
                        <TagsContainerV2
                            disabled={disabled}
                            renderStyle={expandDiv}
                            ref={tagsContainerRef}
                            id="select_tag"
                        >
                            {selectAllLabel !== undefined && checkedState.length === sortedOptions.length ? (
                                <Fragment>
                                    <Tag
                                        onClose={handleTag}
                                        style={{ cursor: 'default', pointerEvents: disabled ? 'none' : 'unset' }}
                                        data-testid="onClose"
                                    >
                                        {selectAllLabel}
                                    </Tag>
                                </Fragment>
                            ) : (
                                checkedState.map((item, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <Tag
                                                onClose={handleTag}
                                                index={index}
                                                style={{ cursor: 'default', textTransform: 'capitalize' }}
                                                ref={(ref) => (tagRefs.current[index] = ref)}
                                                maxWidth={'4.5rem'}
                                                disabled={disabled}
                                            >
                                                {findLabel(item.toString())}
                                            </Tag>
                                        </Fragment>
                                    );
                                })
                            )}
                        </TagsContainerV2>
                    )}
                    <div style={{ position: 'relative' }}>
                        <FlexedDiv style={{ position: 'absolute', right: 0 }}>
                            {expandDiv === false && counter > 0 ? (
                                <CounterSpan
                                    renderStyle={counter > 0}
                                    onClick={() => setExpandDiv(true)}
                                    id="counterSpanButton"
                                >
                                    <IcoMoon name="plus" size="1rem" color="inherit" />
                                    {counter}
                                </CounterSpan>
                            ) : // <CounterSpan renderStyle={false}>
                            // <div />
                            // </CounterSpan>
                            null}
                            <div
                                style={{
                                    cursor: 'pointer',
                                    right: 0,
                                    pointerEvents: disabled && hideDisabledMenu === true ? 'none' : 'auto',
                                }}
                                onClick={() => {
                                    handleOpenSelect();
                                }}
                                id="handleOSelectButton"
                                data-testid="handleOpenSBtn"
                            >
                                <IcoMoon name="caret-down" size="1.5rem" />
                            </div>
                        </FlexedDiv>
                    </div>
                </MultiSelectDivV2>

                {noOverlay || noOverlay === undefined ? (
                    <MultiSelectMenuV2
                        showSearch={showSearch}
                        style={style}
                        renderStyle={open}
                        showCustomBorder={showCustomBorder}
                        direction="column"
                        disabled={disabled}
                        id={`${testId}-${label.toLowerCase()}-options`}
                        hideDisabledMenu={hideDisabledMenu}
                        data-testid={`${label.toLowerCase()}-multiselect-menu`}
                    >
                        {showSearch ? (
                            <FilterSearch
                                handleClearContents={handleClear}
                                searchValue={searchInput}
                                handleSearch={handleSearch}
                                isSimpleSearch={true}
                                placeHolder={`Search ${label}`}
                            />
                        ) : null}
                        {menu(hasSelectAll !== undefined ? hasSelectAll : false)}
                    </MultiSelectMenuV2>
                ) : (
                    <Container visibility>
                        <MultiSelectMenuOverlayV2
                            style={style}
                            renderStyle={open}
                            direction="column"
                            disabled={disabled}
                            showCustomBorder={showCustomBorder}
                            hideDisabledMenu={hideDisabledMenu}
                            id={`${testId}-${label.toLowerCase()}-options`}
                            data-testid={`${label.toLowerCase()}-multiselect-menu`}
                        >
                            {showSearch ? (
                                <FilterSearch
                                    handleClearContents={handleClear}
                                    searchValue={searchInput}
                                    handleSearch={handleSearch}
                                    isSimpleSearch={true}
                                    placeHolder={`Search ${label}`}
                                />
                            ) : null}

                            {/* <CSSTransition in={open} clas> */}
                            {menu(hasSelectAll !== undefined ? hasSelectAll : false)}
                            {/* </CSSTransition> */}
                        </MultiSelectMenuOverlayV2>
                    </Container>
                )}
            </div>
            {errorMessage !== undefined && errorMessage !== '' && !open ? (
                <Container visibility width="90%">
                    <FlexedDiv style={{ position: 'absolute', marginTop: '0.5rem' }}>
                        <IcoMoon name="validation-error" color="#E84C3D" style={{ height: '1rem', width: '1.25rem' }} />
                        <InputValidationErrorText>{errorMessage}</InputValidationErrorText>
                    </FlexedDiv>
                </Container>
            ) : null}
        </FlexedDiv>
    );
};

interface IOnRenderStyleProps {
    renderStyle: boolean;
    disabled?: boolean;
    error?: boolean;
    showCustomBorder?: boolean;
    hideDisabledMenu?: boolean;
}

const PlaceHolder = styled.div`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #878787;
`;
const MultiSelectDivV2 = styled.div`
    display: flex;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
    padding: 11px 1rem;
    transition: all 0.2s ease;
    background: #ffffff;
    justify-content: space-between;
    border-radius: 32px;
    border: 1px solid #cecece;
    box-sizing: border-box;
    width: 450px;
    ${(props: IOnRenderStyleProps) =>
        props.disabled &&
        css`
            border: 1px solid #cecece;
            background: #f4f4f4;
            opacity: 0.6;
        `}
    ${(props: IOnRenderStyleProps) =>
        props.renderStyle &&
        css`
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border: 2px solid #002043;
            box-shadow: 0px 2px 8px rgba(0, 32, 67, 0.06), 0px 0px 4px rgba(0, 32, 67, 0.02);
            box-sizing: border-box;
        `};
    ${(props: IOnRenderStyleProps) =>
        props.error &&
        css`
            border: 1px solid #e84c3d;
        `}/* &:hover {
border: 1px solid #66798e;
} */
`;
const MultiSelectMenuV2 = styled((props) => <FlexedDiv {...props} />)`
    transition: height 0.4s ease-out;
    &:hover {
        cursor: pointer;
    }
    background: #ffffff;
    box-sizing: border-box;
    padding: 0rem 1.25rem;
    height: 0;
    opacity: 0;
    overflow-y: auto;
    width: 450px;
    border-top: none;
    ${(props: IOnRenderStyleProps) =>
        props.renderStyle &&
        css`
            height: fit-content;
            max-height: 25.5rem;
            opacity: 1;
            padding: 0.5rem 1rem;
            box-shadow: inset 2px -2px 0px #002043, inset -2px -2px 0px #002043;
            border-radius: 0px 0px 16px 16px;
        `}
`;
const MultiSelectMenuOverlayV2 = styled((props) => <FlexedDiv {...props} />)`
    position: absolute;
    z-index: 200;
    transition: height 0.4s ease-out;
    &:hover {
        cursor: pointer;
    }
    background: #ffffff;
    border-top: none;
    box-sizing: border-box;
    padding: 0rem 1.25rem;
    height: 0px;
    /* visibility: hidden; */
    opacity: 0;
    overflow-y: auto;
    width: 450px;
    ${(props: IOnRenderStyleProps) =>
        props.renderStyle &&
        css`
            height: fit-content;
            max-height: 25.5rem;
            opacity: 1;
            padding: 0.5rem 1rem;
            box-shadow: inset 2px -2px 0px #002043, inset -2px -2px 0px #002043;
            border-radius: 0px 0px 16px 16px;
        `}
`;
const TagsContainerV2 = styled.div`
    width: 380px;
    overflow: hidden;
    flex-wrap: wrap;
    height: 1.5rem;
    display: flex;
    ${(props: IOnRenderStyleProps) =>
        props.renderStyle &&
        css`
            height: auto;
            overflow: auto;
        `}

    ${(props: IOnRenderStyleProps) =>
        props.disabled &&
        css`
            overflow: hidden;
            width: 380px;
        `}
`;

const DropdownLabel = styled.div`
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: #333333;
    font-weight: 700;
`;
const MapItem = styled.div`
    margin: 0.5rem 0rem;
    padding-left: 0.5rem;
`;
const CounterSpan = styled.span`
    display: none;
    align-items: center;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    ${(props: IOnRenderStyleProps) =>
        props.renderStyle &&
        css`
            &:hover {
                fill: #0089ec;
                color: #0089ec;
                cursor: pointer;
            }
            display: flex;
        `};
`;
const SeparatorLineV2 = styled.div`
    margin: 0px -1rem;
    height: 0;
    border: 0.5px solid #eaebee;
    opacity: 0.5;
`;
export default MultiSelectV2;
