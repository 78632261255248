import { API, graphqlOperation } from 'aws-amplify';
import { updateSeen } from '../_graphql/mutations/updateSeen/updateSeen';

let status = false;

export const updateIsSeenDashboard = (
    dashboard: string,
    tabs: string[],
    idTokenHeader?: { Authorization: string; strategy: string },
): boolean => {
    updateSeenDash(dashboard, tabs, idTokenHeader);
    return status;
};
const updateSeenDash = async (
    dashboard: string,
    tabs: string[],
    idTokenHeader?: { Authorization: string; strategy: string },
) => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response: any = await API.graphql(
            graphqlOperation(updateSeen, {
                input: {
                    dashboard: dashboard,
                    tab: tabs,
                },
            }),
            idTokenHeader,
        );
        const resultCheck = response.data.updateSeen;

        if (resultCheck.error !== null) throw resultCheck.error;
        if (resultCheck.data.result.status === true) status = resultCheck.data.result.status;
        return status;
    } catch (error) {}
};
