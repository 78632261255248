import React, { Fragment, FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import FlexSpacer, { CustomSpacer, FlexedDiv } from '../..';
import CustomCheckbox from '../../atoms/Checkbox';
import { IcoMoon } from '../../../icons';

declare interface CheckBoxState {
    checked: boolean;
    value: string;
}

declare interface CollapsibleHeaderStyleProps {
    children: ReactNode;
}

declare interface BasicListCollapsibleProps {
    checkboxDisabled?: boolean;
    content: ReactNode;
    checked?: boolean;
    disabled?: boolean;
    expanded?: boolean;
    index?: number;
    handleChecked?: () => void;
    handleExpand: (index: number) => void;
    isCheckable: boolean;
    subtitle?: string;
    title: string;
}

export const BasicListCollapsible: FunctionComponent<BasicListCollapsibleProps> = ({
    checkboxDisabled,
    content,
    checked,
    disabled,
    expanded,
    index,
    handleChecked,
    handleExpand,
    isCheckable,
    subtitle,
    title,
}: BasicListCollapsibleProps) => {
    // const [expand, setExpand] = useState<boolean>(false);
    const [height, setHeight] = useState<number>(0);
    const [width, setWidth] = useState<number>();
    const ref = useRef<HTMLDivElement>(null);

    // const handleExpand = () => {
    // if (handleExpandCollapsible !== undefined && index !== undefined) {
    //     handleExpandCollapsible(index);
    // }
    // setExpand(!expand);
    // if (expand === false && ref !== null && ref.current?.scrollHeight !== 0) {
    //     setHeight(ref.current?.scrollHeight as number);
    // } else {
    //     setHeight(0);
    // }
    // };

    const handleHeight = () => {
        if (expanded === true && ref !== null && ref.current?.scrollHeight !== 0) {
            setHeight(ref.current?.scrollHeight as number);
        } else {
            setHeight(0);
        }
    };

    const CollapsibleHeaderStyle = ({ children }: CollapsibleHeaderStyleProps) => {
        return (
            <Fragment>
                {expanded === true ? (
                    <CollapsedHeader container={`${width}px`}>{children}</CollapsedHeader>
                ) : (
                    <CollapsibleHeader>{children}</CollapsibleHeader>
                )}
            </Fragment>
        );
    };

    useEffect(() => {
        if (expanded === true && ref !== null && ref.current?.scrollHeight !== 0) {
            setHeight(ref.current?.scrollHeight as number);
        }
    }, [content]);

    useEffect(() => {
        if (expanded !== undefined) {
            handleHeight();
        }
    }, [expanded]);

    useEffect(() => {
        if (ref.current !== undefined && ref.current?.clientWidth !== undefined) {
            setWidth(ref.current.clientWidth);
        }
    }, []);

    return (
        <StyledContainer disabled={disabled}>
            <CollapsibleHeaderStyle>
                <FlexedDiv style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '1rem', flex: 1 }}>
                    {isCheckable === true && checked !== undefined ? (
                        <Fragment>
                            <div style={{ alignSelf: 'center' }} data-testid="custom-checkbox-div">
                                <CustomCheckbox
                                    checked={checked}
                                    disabled={checkboxDisabled}
                                    handleCheckbox={handleChecked}
                                    id="custom-checkbox"
                                />
                            </div>
                            <CustomSpacer horizontal={true} space={'1.25rem'} />
                        </Fragment>
                    ) : null}
                    <FlexedDiv direction="column">
                        <TitleText>{title}</TitleText>
                        {subtitle !== undefined ? <SubTitleText>{subtitle}</SubTitleText> : null}
                    </FlexedDiv>
                    <FlexSpacer />
                    <Icon
                        onClick={() => handleExpand(index !== undefined ? index : 0)}
                        style={{ alignSelf: 'center' }}
                        data-testid="content-expanded"
                    >
                        <IcoMoon
                            name={'caret-down'}
                            size="1.5rem"
                            style={{
                                transform: expanded === true ? 'rotate(180deg)' : 'rotate(0deg)',
                            }}
                        />
                    </Icon>
                </FlexedDiv>
            </CollapsibleHeaderStyle>
            {expanded === true ? <CustomSpacer space={'4.5rem'} /> : null}
            <CollapsibleContainer
                ref={ref}
                style={{
                    maxHeight: `${height}px`,
                    overflow: 'hidden',
                    marginTop: '0rem',
                    background: '#002043',
                }}
                data-testid="collapsible-container"
            >
                <div style={{ background: '#ffffff' }}>{content}</div>
            </CollapsibleContainer>
        </StyledContainer>
    );
};

declare interface CollapsedHeaderProps {
    container?: string;
}

declare interface StyledContainerProps {
    disabled?: boolean;
}

const StyledContainer = styled.div`
    background-color: #ffffff;
    border-radius: 16px;
    ${(props: StyledContainerProps) =>
        props.disabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`;

const CollapsibleHeader = styled.div`
    height: 4.5rem;
    align-items: center;
    border-width: 5px;
    border-color: #eaebee;
    border-radius: 16px;
    box-shadow: 4px 4px 16px rgba(0, 32, 67, 0.1);
    z-index: 1;
    display: flex;
    flex: 1;
`;

const CollapsedHeader = styled.div`
    height: 4.5rem;
    align-items: center;
    background-color: #ffffff;
    border-bottom: 1px solid #c9c9cd;
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 4px 4px 16px rgba(0, 32, 67, 0.1);
    position: absolute;
    display: flex;
    ${(props: CollapsedHeaderProps) =>
        props.container &&
        css`
            width: ${props.container};
        `}
    z-index: 2;
`;

const Icon = styled.div`
    &:hover {
        cursor: pointer;
    }
`;

const CollapsibleContainer = styled.div`
    border-radius: 16px;
    transition: max-height 0.2s ease-out;
    background-color: #ffffff;
    z-index: 0;
`;

const TitleText = styled.div`
    font-size: 1rem;
    font-weight: 800;
    color: #002043;
    line-height: 1.5rem;
`;

const SubTitleText = styled.div`
    font-size: 0.75rem;
    font-weight: 400;
    color: #002043;
    line-height: 1rem;
    margin-top: 0.5rem;
`;
